import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';

const LaptopMobile =()=>{

    return(
        <>
        <DevicesOutlinedIcon sx={{ color: '#E4002B'}} />
        </>
    )
}
export default LaptopMobile;