const initialState = {
  familiares: [],
  errorField: {
    planEspecial: "",
    gamaEspecial: "",
    nombre: "",
    apellido: "",
    fechaNac: "",
    genero: "",
    nacionalidad: "",
    numDoc: "",
    tipoDoc: "",
    obraSocial: ""
  },
};

const SET_FAMILIARES = "SET_FAMILIARES";
const SET_ERRORS_FIELD = "SET_ERRORS_FIELD";

export const setFamiliares = (familiares) => {
  return {
    type: SET_FAMILIARES,
    payload: familiares,
  };
};

export const setErrorField = (field) => {
  return {
    type: SET_ERRORS_FIELD,
    payload: field,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAMILIARES:
      return {
        ...state,
        familiares: action.payload,
      };
    case SET_ERRORS_FIELD:
      return {
        ...state,
        errorField: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
