import { Box } from "@mui/material";
// import logo from "../logo_cma_hor.svg";
// import logoMobile from "../logo_cma.svg";
import { useHistory } from "react-router-dom";

const NavLogo = ({ variant }) => {
  const history = useHistory();
  const logo = "/images/logos/edcm-smg-logo-20241202.jfif";

  const handleOnClick = () => {
    history.push("/");
  };

  return (
    <>
      <Box onClick={handleOnClick} sx={{ cursor: "pointer", objectFit: "fill" }}>
        <img
          src={logo}
          alt="logo cma"
          height={variant === "mobile" ? "150" : "70" }
          width="150"
          style={{ width: "100%"  }}
        />
      </Box>
    </>
  );
};

export default NavLogo;
