import { Container, Typography, Box, Button,  } from '@mui/material';
import { useParams } from 'react-router-dom';
import CustomRedButton from '../../../../../../UI/CustomRedButton/CustomRedButton';
import InputImage from '../../../../../../componentes/InputImage/InputImage';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RequestApi from '../../../../../../Request-api/RequestApi'
import useIsMobile from '../../../../../../Utils/useIsMobile'

const ValidarFamiliar = () => { 
    const { isMobile } = useIsMobile();
    const history = useHistory();
    const { dni } = useParams();
    const [pending, setPending] = useState(false);
    const { familiares } = useSelector(state => state.grupoFamiliar);
    const [familiar, setFamiliar] = useState({})
    const [files, setFiles] = useState([]);

    const handleNewFile = (file) => {
        setFiles([
          ...files,
          file
        ])
      }

    useEffect(() => {
        let filter = familiares.filter(fam => fam.nroDoc === parseInt(dni))
        if(filter.length) {
            setFamiliar(filter[0])
        } else {
            history.push('/pacientes/familiares');
        }
    },[])

    
    const handleEnviar = () => {
        if(files.length === 2) {
            const multiPartFiles = new FormData();
            multiPartFiles.append('file', files[0])
            multiPartFiles.append('file', files[1])
            RequestApi.uploadAWSFile(multiPartFiles)
            .then(r => {
                if(r.estado === 0){
                    RequestApi.validarMenor({
                        idEdns: familiar.idEdns,
                        canal: isMobile ? 2 : 1,
                        idTipoDoc: familiar.tipoDoc,
                        nroDoc: familiar.nroDoc,
                        documentacion: `${files[0].name};${files[1].name}`,
                        ipCliente: window.CLIENT_IP,
                        dispositivo: navigator.userAgent,
                        tipoValidacion: 2,
                        idUsuario: familiar.nroDoc
                    })
                    .then(res => {
                        if(res.estado === 0) {
                            setPending(true)
                        }
                    })
                    .catch(e => {
                        //error
                    })
                }
            }).catch(e => {
                //error
            })
        }
    }

    const goHome = () => {
        history.push('/pacientes')
    }

    return (
        <>  
            <Container sx={{display: 'flex', justifyContent:'center', alignContent:'flex-start', marginTop:'1rem', flexWrap:'wrap'}}>
                <Box sx={{marginBottom:'1.5rem'}} display='flex' width='100%' justifyContent='space-between' height='fit-content' alignItems='center'>
                    <Button>{'< Volver'}</Button>
                </Box>
                <Box width='100%' maxWidth='350px'  sx={{paddingX:'2rem'}}>

                {
                !pending ? (
                                <>
                                    <Typography textAlign='center' marginBottom='2rem' variant='h5'>
                                    Validar identidad
                                    </Typography>
                                    <Typography sx={{mb:2}}>Subir la documentación de <span style={{fontWeight: 'bold'}}>{familiar.nombre} {familiar.apellido1}</span></Typography>
                                    <InputImage label='Subir frente del documento' newFile={handleNewFile} fileName='DNI-MENOR-FRENTE' />
                                    <InputImage label='Subir dorso del documento' newFile={handleNewFile} fileName='DNI-MENOR-DORSO' />
                                    <CustomRedButton onClick={handleEnviar} text='Continuar' sx={{marginTop: '1rem'}}/>
                                </>
                            ) : (
                                <>
                                    <Typography textAlign='center' marginBottom='5rem' variant='h5'>
                                    ¡Solicitud enviada!
                                    </Typography>
                                    <Typography textAlign='center'>
                                        ¡Gracias!
                                        <br />
                                        Hemos recibido tu solicitud para verificar la 
                                        identidad de {familiar.nombre} {familiar.apellido1}.
                                        <br />
                                        Muy pronto recibirás novedades.
                                    </Typography>
                                    <CustomRedButton onClick={goHome} text='Volver al inicio' sx={{marginTop: '1rem'}}/>
                                </>
                )
                }
                </Box>
            </Container>
        </>
    )
}
export default ValidarFamiliar;