import HistoryIcon from "@mui/icons-material/History";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box, Button, Grid, Typography } from "@mui/material";
import DeskItem from "./DeskItem";
import { createSvgIcon } from "@mui/material/utils";
import HistoryCards from "../../../../../Clientes/HomeClientes/Items/Inicio/Cards/HistoryCards";
import SliderCards from "../Cards/SliderCards";
import Loader from "../../../../../../componentes/Loader/Loader";
import { useEffect, useRef, useState } from "react";
import TurnCard from "../Cards/TurnCard";
import RequestApi from "../../../../../../Request-api/RequestApi";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ESTUDIOS_IMAGEN,
  SET_ESTUDIOS_LABORATORIO,
  SET_HISTORIAL_DASHBOARD,
  SET_TURNOS_DASHBOARD,
} from "../../../../../../Redux/Actions/Types/estudios";
import ImagenStudyCard from "../Cards/ImagenStudyCard";
import LabStudyCard from "../Cards/LabStudyCard";
import moment from "moment";
import utils from "../../../../../../Utils/useDate";
import { resetAuthLogin } from "../../../../../../Redux/Reducers/authLoginReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TurnosIcon = createSvgIcon(
  <svg viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 18C19.7344 18 23.5714 13.9711 23.5714 9C23.5714 4.02891 19.7344 0 15 0C10.2656 0 6.42857 4.02891 6.42857 9C6.42857 13.9711 10.2656 18 15 18ZM6.96429 29.8125C6.96429 30.7477 7.6808 31.5 8.57143 31.5C9.46205 31.5 10.1786 30.7477 10.1786 29.8125C10.1786 28.8773 9.46205 28.125 8.57143 28.125C7.6808 28.125 6.96429 28.8773 6.96429 29.8125ZM21.4286 20.2922V23.7375C23.8728 24.2578 25.7143 26.5359 25.7143 29.25V32.182C25.7143 32.7164 25.3527 33.1805 24.8504 33.2859L22.6942 33.7359C22.4062 33.7992 22.125 33.6023 22.0647 33.293L21.8571 32.1891C21.7969 31.8867 21.9844 31.5844 22.279 31.5281L23.5714 31.2539V29.25C23.5714 24.8344 17.1429 24.6727 17.1429 29.3836V31.2609L18.4353 31.5352C18.7232 31.5984 18.9107 31.8937 18.8571 32.1961L18.6496 33.3C18.5893 33.6023 18.308 33.7992 18.0201 33.743L15.9308 33.4477C15.4018 33.3703 15.0067 32.8992 15.0067 32.3297V29.25C15.0067 26.5359 16.8482 24.2648 19.2924 23.7375V20.5594C19.1451 20.6086 18.9978 20.6367 18.8504 20.693C17.6451 21.1359 16.3527 21.382 15.0067 21.382C13.6607 21.382 12.3683 21.1359 11.1629 20.693C10.6674 20.5102 10.1652 20.3977 9.64955 20.3273V26.0648C11.1964 26.55 12.3281 28.0406 12.3281 29.8195C12.3281 31.9922 10.6473 33.757 8.57812 33.757C6.50893 33.757 4.82812 31.9922 4.82812 29.8195C4.82812 28.0406 5.95982 26.55 7.5067 26.0648V20.4117C3.24777 21.1641 0 25.0172 0 29.7V32.85C0 34.5867 1.34598 36 3 36H27C28.654 36 30 34.5867 30 32.85V29.7C30 24.6375 26.1964 20.5383 21.4286 20.2922Z"
      fill="#E4002B"
    />
  </svg>,
  "Turnos"
);
const LabIcon = createSvgIcon(
  <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.102 14.1855L11.7855 7.55859V2.25H12.0801C12.57 2.25 12.9641 1.87383 12.9641 1.40625V0.84375C12.9641 0.376172 12.57 0 12.0801 0H4.41934C3.92949 0 3.5354 0.376172 3.5354 0.84375V1.40625C3.5354 1.87383 3.92949 2.25 4.41934 2.25H4.71399V7.55859L0.397426 14.1855C-0.681714 15.8414 0.563165 18 2.61095 18H13.8885C15.94 18 17.1812 15.8379 16.102 14.1855ZM5.07861 11.25L6.85385 8.52187C6.99012 8.33906 7.06747 8.11406 7.06747 7.875V2.25H9.42463V7.875C9.42463 8.11758 9.50566 8.33906 9.63825 8.52187L11.4135 11.25H5.07861Z"
      fill="#E4002B"
    />
  </svg>,
  "Lab"
);

const Dashboard = () => {
  const token = sessionStorage.getItem("token");
  const CANT_MAX = 5;
  const fechaDesde = moment().format("DD/MM/YYYY");
  const fechaHasta = moment().add(90, "days").format("DD/MM/YYYY");
  const fechaHistorialDesde = moment().add(-360, "days").format("DD/MM/YYYY");

  const auxRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { docNum, fechaNac, docType } = useSelector((state) => state.authLogin);
  const {
    imagenEstudio,
    laboratorioEstudio,
    processingImgEstudio,
    processingLabEstudio,
    turnosDashboard,
    processingTurnosDashboard,
    historialDashboard,
    processingHistorialDashboard,
  } = useSelector((state) => state.estudiosReducer);
  const datosLogin = useSelector((state) => state.authLogin);

  useEffect(() => {
    const usuLogin =
      utils.convertDate(datosLogin.fechaNac) +
      datosLogin?.docNum +
      "@swissmedical.com.ar";

    const fetchData = async () => {
      try {
        const response1 = await RequestApi.getEstudiosImagen_dashboard(
          fechaNac.replaceAll("-", ""),
          docNum.toString(),
          docType.value,
          token
        );
        const response2 = await RequestApi.getEstudiosLab_dashboard(
          fechaNac.replaceAll("-", ""),
          docNum.toString(),
          docType.value,
          token
        );
        const response3 = await RequestApi.getTurnos_dashboard(
          fechaDesde,
          fechaHasta,
          usuLogin,
          token
        );
        const response4 = await RequestApi.getHistorial_dashboard(
          fechaHistorialDesde,
          usuLogin,
          CANT_MAX,
          token
        );

        if (response1) {
          dispatch({
            type: SET_ESTUDIOS_IMAGEN,
            payload: {
              imagenEstudio: response1?.informes,
              processingImgEstudio: true,
            },
          });
        }
        if (response2) {
          dispatch({
            type: SET_ESTUDIOS_LABORATORIO,
            payload: {
              laboratorioEstudio: response2?.laboratorio,
              processingLabEstudio: true,
            },
          });
        }
        if (response3) {
          dispatch({
            type: SET_TURNOS_DASHBOARD,
            payload: {
              turnosDashboard: response3?.respuesta?.turnos?.turno,
              processingTurnosDashboard: true,
            },
          });
        }
        if (response4) {
          dispatch({
            type: SET_HISTORIAL_DASHBOARD,
            payload: {
              historialDashboard: response4?.respuesta?.turnos?.turno,
              processingHistorialDashboard: true,
            },
          });
        }
      } catch (err) {
        handleLogout();
        console.error(err);
      }
    };

    if (!auxRef.current) {
      fetchData();
    }

    return () => {
      auxRef.current = true;
    };
  }, [auxRef]);

  const handleLogout = () => {
    dispatch(resetAuthLogin());
    sessionStorage.clear();
    history.push("/login");
  };

  return (
    <>
      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <DeskItem
          icon={
            <>
              <TurnosIcon sx={{ fontSize: "1.3rem" }} />
            </>
          }
          name="Turnos"
          textButton="Accedé a todos tus Turnos"
          path={"/pacientes/turnos"}
        >
          {!processingTurnosDashboard ? (
            <Box
              sx={{ position: "relative", width: "100%", mt: 1, zIndex: "99" }}
            >
              <Loader />
            </Box>
          ) : (
            <>
              {turnosDashboard && turnosDashboard.length > 0 ? (
                <Box>
                  <TurnCard item={turnosDashboard[0]} />
                </Box>
              ) : (
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{ fontWeight: "initial", mb: "1rem", color: "#E4002B" }}
                  >
                    Todavía no tenés turnos
                  </Typography>
                  <Typography component="p" sx={{ fontSize: ".9rem" }}>
                    Comenzá a gestionar tus turnos en instituciones de Swiss
                    Medical Group con la mayor comodidad.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DeskItem>
      </Grid>
      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <DeskItem
          icon={
            <>
              <HistoryIcon sx={{ fontSize: "1.3rem", color: "#E4002B" }} />
            </>
          }
          textButton="Accedé a tu historial"
          name="Historial de atenciones"
          path={"/pacientes/atenciones"}
        >
          <Grid container justifyContent="flex-start" sx={{ gap: 2 }}>
            {!processingHistorialDashboard ? (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  zIndex: "99",
                  height: "100%",
                  minHeight: "80px",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <>
                {historialDashboard && historialDashboard.length > 0 ? (
                  <Grid item xs={11} sx={{ zIndex: "10", cursor: "grab" }}>
                    {historialDashboard.length === 1 ? (
                      <HistoryCards item={historialDashboard[0]} />
                    ) : (
                      <SliderCards numberCards={1} centerMode={true}>
                        {Object.values(historialDashboard).map(
                          (item, index) => {
                            return <HistoryCards key={index} item={item} />;
                          }
                        )}
                      </SliderCards>
                    )}
                  </Grid>
                ) : (
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{ fontWeight: "initial", mb: "1rem", color: "#E4002B" }}
                  >
                    No registramos atenciones durante el último mes
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </DeskItem>
      </Grid>
      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <DeskItem
          icon={
            <>
              <LabIcon sx={{ fontSize: "1.3rem", color: "#E4002B" }} />
            </>
          }
          textButton="Accedé a tus estudios"
          name="Imágenes"
          path={"/pacientes/estudios"}
        >
          {!processingImgEstudio ? (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                zIndex: "99",
                height: "100%",
                minHeight: "80px",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <>
              {imagenEstudio && imagenEstudio.length > 0 ? (
                <Box>
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={{ fontWeight: "initial", mb: "8px", color: "#E4002B" }}
                  >
                    Detalles del último estudio de imagen realizado:
                  </Typography>
                  <ImagenStudyCard item={imagenEstudio[0]} />
                </Box>
              ) : (
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{ fontWeight: "initial", mb: "1rem", color: "#E4002B" }}
                  >
                    No hay estudios para mostrar
                  </Typography>
                  <Typography component="p" sx={{ fontSize: ".9rem" }}>
                    Aquí verás los resultados de tus próximos estudios de
                    imagen.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DeskItem>
      </Grid>
      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <DeskItem
          icon={
            <>
              <CameraAltIcon sx={{ fontSize: "1.3rem", color: "#E4002B" }} />
            </>
          }
          textButton="Accedé a tus estudios"
          name="Laboratorio"
          path={"/pacientes/estudios"}
        >
          {!processingLabEstudio ? (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                zIndex: "99",
                height: "100%",
                minHeight: "80px",
              }}
            >
              <Loader />
            </Box>
          ) : (
            <>
              {laboratorioEstudio && laboratorioEstudio.length > 0 ? (
                <Box>
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={{ fontWeight: "initial", mb: "8px", color: "#E4002B" }}
                  >
                    Detalles del último estudio de laboratorio realizado:
                  </Typography>
                  <LabStudyCard
                    centro={laboratorioEstudio[0].centro}
                    fecha={laboratorioEstudio[0].fecha}
                    fechaEntrega={laboratorioEstudio[0].fechaEntrega}
                    estado={laboratorioEstudio[0].estado}
                    nombre={laboratorioEstudio[0].nombre}
                    apellido={laboratorioEstudio[0].apellido}
                    key={laboratorioEstudio[0].estado}
                  />
                </Box>
              ) : (
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{ fontWeight: "initial", mb: "1rem", color: "#E4002B" }}
                  >
                    No hay estudios para mostrar
                  </Typography>
                  <Typography component="p" sx={{ fontSize: ".9rem" }}>
                    Aquí verás los resultados de tus próximos estudios de
                    laboratorio.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DeskItem>
      </Grid>
    </>
  );
};

export default Dashboard;
