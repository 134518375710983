import { Slider } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { useState } from "react";

const CustomSlider = styled(Slider)({
  color: '#E4002B',
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-focusVisible': {
      boxShadow: 'inherit',
      cursor: 'move',
      cursor: 'grab',
      cursor: '-moz-grab',
      cursor: '-webkit-grab'
    },
    '&.Mui-active': {
      cursor: 'grabbing',
      cursor: '-moz-grabbing',
      cursor: '-webkit-grabbing',
    }
  },
  '& .MuiSlider-valueLabel': {
    color: '#000',
    fontSize: '.8rem',
    fontFamily: "'Lato', sans-serif",
    fontWeight: 'bold',
    background: 'unset',
    padding: 0
  },
})


const TimeSlider = () => {
  const [value, setValue] = useState([0, 990]);

    const valueLabelFormat = (value) => {
      return moment('07:00', 'hhmm').add('minutes', value).format('HH:mm');
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    return (
        <CustomSlider 
            getAriaLabel={() => 'Horarios de atención'}
            value={value}
            onChange={handleChange}
            min={0}
            max={990}
            step={15}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="on"
        />
      )
        
      
}

export default TimeSlider;