import { SvgIcon } from "@mui/material";

function DownloadIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M7.94531 2.25H10.0547C10.4054 2.25 10.6875 2.53213 10.6875 2.88281V7.3125H12.9999C13.4692 7.3125 13.7039 7.87939 13.3717 8.21162L9.36123 12.2247C9.16348 12.4225 8.83916 12.4225 8.64141 12.2247L4.62568 8.21162C4.29346 7.87939 4.52812 7.3125 4.99746 7.3125H7.3125V2.88281C7.3125 2.53213 7.59463 2.25 7.94531 2.25ZM15.75 12.1641V15.1172C15.75 15.4679 15.4679 15.75 15.1172 15.75H2.88281C2.53213 15.75 2.25 15.4679 2.25 15.1172V12.1641C2.25 11.8134 2.53213 11.5312 2.88281 11.5312H6.75088L8.04287 12.8232C8.57285 13.3532 9.42715 13.3532 9.95713 12.8232L11.2491 11.5312H15.1172C15.4679 11.5312 15.75 11.8134 15.75 12.1641ZM12.4805 14.4844C12.4805 14.1943 12.2432 13.957 11.9531 13.957C11.6631 13.957 11.4258 14.1943 11.4258 14.4844C11.4258 14.7744 11.6631 15.0117 11.9531 15.0117C12.2432 15.0117 12.4805 14.7744 12.4805 14.4844ZM14.168 14.4844C14.168 14.1943 13.9307 13.957 13.6406 13.957C13.3506 13.957 13.1133 14.1943 13.1133 14.4844C13.1133 14.7744 13.3506 15.0117 13.6406 15.0117C13.9307 15.0117 14.168 14.7744 14.168 14.4844Z" fill="#363636"/>
      </SvgIcon>
    );
  }

export default DownloadIcon;