import useIsMobile from "../../Utils/useIsMobile";
import { useState, useEffect } from "react";
import { Box, Container } from '@mui/material';
import RecoveryPass from "./RecoveryPass/RecoveryPass";
import RequestApi from '../../Request-api/RequestApi'
import NavbarLogin from "../../componentes/NavbarLogin/NavbarLogin";
import Login from './Login/Login';
import "./LoginClientes.css"


function LoginClientes({location}) {
  const { isMobile } = useIsMobile();
  const [docs, setDocs] = useState([]);
  const [token, setToken] = useState('');
  const [tryLogin, setTryLogin] = useState(true);

  useEffect(() => {
    setToken(new URLSearchParams(location.search).get('token'));
    if (!docs.length) {
      RequestApi.getTipoDocs().then(docList => {
        // console.log("docList", docList);
        docList.shift()
        setDocs(docList);
      })
    }
  }, [])

  useEffect(()=>{
    if(token && docs.length){
      setTryLogin(false)
    }
  },[token, docs])

  const handleChOption = () => {
    setTryLogin(!tryLogin)
  }

  return (
    <>
      {isMobile && <NavbarLogin />}
      <Box display='flex' >
        <Box height='100vh' width='60%' display={isMobile && 'none'} sx={{ backgroundImage: 'url("../../../images/back_login.png")', backgroundSize: 'cover' }}>
          <p className="card-title">¡Hola!</p>
          <p className="card-sub-title">
            Ingresá para reservar turnos y ver resultados de estudios.
          </p>
          <p className="card-desc">
            Si estás asociado a Swiss Medical Medicina Privada accedé a estas funcionalidades desde <a href="https://www.swissmedical.com" className="card-link">www.swissmedical.com</a>
          </p>
        </Box>
        <Box height='100vh' width={isMobile ? '100%' : '40%'} display='flex' alignContent='center' >
          <Container sx={{display: 'flex', justifyContent:'center'}}>
            {tryLogin ? <Login chOption={handleChOption} docs={docs} /> : <RecoveryPass chOption={handleChOption} docs={docs} token={token} />}
          </Container>
        </Box>
      </Box>
    </>
  );
}
export default LoginClientes;
