import { Alert, AlertTitle } from "@mui/material";
import { Link } from "react-router-dom";

const RedNotify = ({pendientes}) => {
    return (
        <>  
            {
                pendientes?.map(pend => {
                    const {estadoCuenta, estadoVinculo} = pend;
                    if(estadoVinculo === 1) {
                        //verificar vinculo
                        return (
                            <Alert severity="error" sx={{color: 'red', marginY:'1rem'}}>
                                <AlertTitle sx={{fontWeight: 'bold'}}>Atención:</AlertTitle>
                                Es necesario que verifiques tu vinculo con <span style={{fontWeight: 'bold'}}>{pend.nombre}</span> para ver sus resultados médicos online. {<Link style={{color:'red'}} to={`/pacientes/verificar-vinculo/${pend.doc}`}>Verificar vínculo</Link>}
                            </Alert>
                        )
                    } else if (estadoVinculo === 2) {
                        //pendiente de verificación
                        return (
                            <Alert severity="warning" sx={{ marginY:'1rem'}}>
                                <span style={{fontWeight: 'bold'}}>{pend.nombre}</span> se encuentra en proceso de verificación de vínculo.
                            </Alert>
                        )
                    } else if (estadoVinculo === 3 && (estadoCuenta === 1 || estadoCuenta === 4)) {
                        //falta validar
                        return (
                            <Alert severity="error" sx={{color: 'red', marginY:'1rem'}}>
                                <AlertTitle sx={{fontWeight: 'bold'}}>Atención:</AlertTitle>
                                Es necesario que valides la identidad de <span style={{fontWeight: 'bold'}}>{pend.nombre}</span> para ver sus resultados médicos online. {<Link style={{color:'red'}} to={`/pacientes/validar-familiar/${pend.doc}`}>Validar identidad</Link>}
                            </Alert>
                        )
                    } else if (estadoVinculo === 3 && estadoCuenta === 2) {
                        //pendiente de validación
                        return (
                            <Alert severity="warning" sx={{ marginY:'1rem'}}>
                                <span style={{fontWeight: 'bold'}}>{pend.nombre}</span> se encuentra en proceso de validación de identidad.
                            </Alert>
                        )
                    }
                })
            }
        </>
    )
}

export default RedNotify;