import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RequestApi from "../../../../../../Request-api/RequestApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


const ShowProducts = () => {
    const history = useHistory();

    const [productos, setProductos] = useState([]);
    const handleBack = () => {
        history.goBack()
    }

    const {docType, docNum} = useSelector(state => state.authLogin);

    useEffect(() => {
        RequestApi.getProductos(docType.value, docNum)
        .then(prods => setProductos(prods.productos))
    }, [])

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item xs={12} mb={3}>
                        <Button onClick={handleBack}>Volver</Button>
                        <Typography>Cambiar contraseña</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} mb={2}>
                        <ul>
                            {productos.map( pro => <li>{pro.descEntidad}</li>)}
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
export default ShowProducts;