import { Typography, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Divider, CardMedia } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import EffectiveIcon from "./Icons/EffectiveIcon";
import CardIcon from "./Icons/CardIcon";
import DebitIcon from "./Icons/DebitIcon";
import CheckIcon from "./Icons/CheckIcon";
import BankIcon from "./Icons/BankIcon";
import { useEffect } from 'react';


const styles = {
    section: {
        marginY: { xs: '1rem', sm: '-1rem', md: '2rem', lg: '2.5rem' },
        marginX: { xs: '1rem', sm: '3rem', md: '4rem', lg: '0' },
        marginBottom: { xs: '2rem', sm: '3rem', md: '3rem', lg: '7rem' },


        "& .gridImg": {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: { sm: '0', md: '-1rem' }
        },
        "& .containerImgText": {
            display: "flex",
            alignItems: { sm: 'center', md: 'flex-start' },
            flexDirection: 'row-reverse'
        },
        "& .listItems": {
            paddingLeft: '0'
        },
        "& .containerTextIcon": {
            paddingRight: { sm: '2rem', md: '3rem', lg: '6rem' }
        },
        "& .textTypography": {
            marginTop: { xs: '2rem', md: '0' },
            fontFamily: "'Lato', sans-serif", height: '46px',
            marginBottom: { xs: '2.5rem', lg: '1rem' }
        },
        "& .textStrong": {
            fontFamily: "'Lato', sans-serif",
            fontSize: '1rem'

        },
        "& .imgCard": {
            width: { lg: '95%' },
            marginLeft: { lg: '40px' }
        },
        "& .styleList": {
            color: "#797979",
            marginBottom: '4rem'
        }


    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "8rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerSubtitle": {
            fontSize: "1rem",
            color: "#797979",
            letterSpacing: ".6rem"
        },
        "& .headerTitle": {
            marginTop: { xs: '1rem', md: '9rem' },
            fontWeight: "bold",
            fontSize: "2.125rem",
            fontFamily: "'Lato', sans-serif",

        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}


const MediosPago = () => {

    const { headerSection, section } = styles;

    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <Box sx={headerSection}>
                <Typography className="headerTitle">Medios de pago</Typography>
                <HorizontalRuleIcon className="headerIcon" />
            </Box>
            <Box sx={section}>
                <Grid className="containerImgText" container>
                    <Grid item sx={12} sm={6} className="gridImg">
                        <CardMedia className="imgCard" component="img" image="/images/info/medios_de_pago.jpg" alt="medios de pago" />
                    </Grid>
                    <Grid className="containerTextIcon" item sx={12} sm={6}>
                        <Typography className="textTypography">Te ofrecemos <strong className="textStrong">distintas opciones de pago</strong> para que puedas abonar nuestros servicios con la mayor comodidad.</Typography>
                        <Box>
                            <List className="styleList">
                                <ListItem className="listItems">
                                    <ListItemIcon >
                                        <EffectiveIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Pago en efectivo"
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem className="listItems">
                                    <ListItemIcon>
                                        <CardIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Tarjetas de crédito"
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem className="listItems">
                                    <ListItemIcon>
                                        <DebitIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Tarjetas de débito"
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem className="listItems">
                                    <ListItemIcon>
                                        <BankIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Transferencia bancaria"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default MediosPago;