import { useState } from 'react';
import { Box, Avatar, Menu, MenuItem, ListItemIcon, IconButton, Tooltip } from '@mui/material';
import { Logout, Settings, PeopleAlt } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetAuthLogin } from '../../../Redux/Reducers/authLoginReducer'

const ChipMenu = ({enabledmenu, ml = 2}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const {nombre, apellido} = useSelector(state => state.authLogin);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(resetAuthLogin())
    sessionStorage.clear();
    history.push('/login')
}

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Configuración de la cuenta">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: ml }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 42, height: 42 }}>{nombre[0]+apellido[0]}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        hidden={enabledmenu}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={()=> history.push('/pacientes/familiares')}>
          <ListItemIcon>
            <PeopleAlt fontSize="small" />
          </ListItemIcon>
          Grupo Familiar
        </MenuItem>
        <MenuItem onClick={()=> history.push('/pacientes/perfil')}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Mi Perfil
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </>
  );
}

export default ChipMenu;