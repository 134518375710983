
let DateOperatations = {
  getLabelFecha(fecha) {
    const year = fecha.split('/')[2];
    const monthDay = fecha.split('/')[1];
    const day = fecha.split('/')[0];

    const date = new Date(year, monthDay - 1, day);
    const dayOfWeek = date.getUTCDay();
    let diaSemana = '';
    switch (dayOfWeek) {
      case 0:
        diaSemana = 'Domingo';
        break;
      case 1:
        diaSemana = 'Lunes';
        break;
      case 2:
        diaSemana = 'Martes';
        break;
      case 3:
        diaSemana = 'Miercoles';
        break;
      case 4:
        diaSemana = 'Jueves';
        break;
      case 5:
        diaSemana = 'Viernes';
        break;
      case 6:
        diaSemana = 'Sabado';
        break;
      case 7:
        diaSemana = 'Domingo';
        break;
      default:
        diaSemana = '';
    }

    const month = date.getMonth() + 1;
    let strMonth = '';
    switch (month) {
      case 1:
        strMonth = 'Enero';
        break;
      case 2:
        strMonth = 'Febrero';
        break;
      case 3:
        strMonth = 'Marzo';
        break;
      case 4:
        strMonth = 'Abril';
        break;
      case 5:
        strMonth = 'Mayo';
        break;
      case 6:
        strMonth = 'Junio';
        break;
      case 7:
        strMonth = 'Julio';
        break;
      case 8:
        strMonth = 'Agosto';
        break;
      case 9:
        strMonth = 'Septiembre';
        break;
      case 10:
        strMonth = 'Octubre';
        break;
      case 11:
        strMonth = 'Noviembre';
        break;
      case 12:
        strMonth = 'Diciembre';
        break;
      default:
        strMonth = '';
    }

    const f = `${diaSemana} ${day} de ${strMonth} de ${year}`;
    return f;
  }
}

export default DateOperatations