import React, {useState, useEffect} from 'react'
import "./unTurnoPara.css"
import { useSelector } from "react-redux"
import {FormControl, Select, MenuItem, Typography} from '@mui/material';
import moment from "moment";
import { CoPresentOutlined } from '@mui/icons-material';

const UnTurnoPara = ({ setSelectedGenero, setIntegranteSeleccionado, integranteSeleccionado}) => {
    const { familiares } = useSelector(state => state.grupoFamiliar);
    const {nombre, apellido, docNum, fechaNac, genero, plan} = useSelector(state => state.authLogin)
  const [persons, setPersons] = useState([]);
  const [ integrantesFam, setIntegrantesFam] = useState(docNum)
  const [selectedDni, setSelectedDni] = useState()
  const [selectedFecNac, setSelectedFecNac] = useState()
  const combreCom = nombre +" "+ apellido

  const generoFam = familiares.genero === 1 ? ("F"):("M")

  const handleChange = (event) => {
   const selecPerson = persons.find(per => per.doc === event.target.value)
     setIntegrantesFam(selecPerson)
   setSelectedDni(selecPerson.doc)
    setSelectedFecNac(selecPerson.fechaNac)
    setSelectedGenero && setSelectedGenero(selecPerson.genero)
    setIntegranteSeleccionado(selecPerson)
    
  };

  useEffect(() => {
    const integrantes = familiares?.map(fam => {
        return {
            nombreCompleto: `${fam.nombre} ${fam.apellido1}`,
            nombre: fam.nombre,
            apellido: fam.apellido1,
            doc: fam.nroDoc,
            fechaNac: fam.fechaNac,
            genero: generoFam,
            gamaPk: fam.gamaPk,
            planPk: fam.planPk
        }
    })
    setPersons([ {
        nombreCompleto: `${nombre} ${apellido}`,
        nombre: nombre,
        apellido: apellido,
        doc: docNum,
        fechaNac: fechaNac,
        genero: genero,
        gamaPk: plan.gamaPk,
        planPk: plan.planPk
    }, ...integrantes])

    const fecha = selectedFecNac? selectedFecNac : fechaNac
    const fechaMoment = moment(fecha).format("DDMMYYYY")
    const dni = selectedDni? selectedDni : docNum 
    const usuLogin = fechaMoment +dni +"@swissmedical.com.ar"
},[familiares])

  return (
      <>  
      <div style={{ display: "flex", marginTop: "30px" }}>
          <Typography sx={{whiteSpace: 'nowrap', marginRight: '10px', fontSize: '1rem'}}>
          Un Turno para:
          </Typography>
          <FormControl>
              <Select
                  onChange={handleChange}
                  defaultValue={docNum}
                  variant="standard"
                  sx={{
                      "&:before": {
                          border: "none",
                      },
                      fontWeight: 'bold',
                      fontSize: '.9rem'
                  }}
              >
                  {persons.map(person => <MenuItem value={person.doc}>{person.nombreCompleto}</MenuItem>)}
              </Select>
          </FormControl>
          </div>
      </>
  )
};

export default UnTurnoPara;