import React, { useState } from 'react'
import SelectEspePrac from './Steps/SelectEspePrac/SelectEspePrac'
import SelectCentro from './Steps/SelectCentro/SelectCenrto'
import SelectTurno from './Steps/SelectTurno/SelectTurno'
import DetallesConfirmar from './Steps/DetallesConfirmar/DetallesConfirmar'
import TurnoReservado from './Steps/TurnoReservado/TurnoReservado'
import  useIsMobile from "../../../../../../Utils/useIsMobile"
import SelectEspeCentrosDesktop from './Steps/SelectEspePracDesktop/SelectEspeCentrosDesktop'
import DatosFinales from './Steps/datosFinales/DatosFinales'
import DetallesConfirmarDesktop from './Steps/DetallesConfirmar/DetallesConfirmarDesktop'
import TurnoReservadoDesktop from './Steps/TurnoReservado/TurnoReservadoDesktop'
import UnTurnoPara from '../VerTurno/Items/UnTurnoPara'
import { useSelector } from "react-redux";

const NuevoTurno = () => {
  const { isMobile } = useIsMobile()
  const {nombre, apellido, docNum, fechaNac, genero, plan} = useSelector(state => state.authLogin)
  const [step, setStep] = useState(1)
  const [data, setData] = useState({})
  const [integranteSeleccionado, setIntegranteSeleccionado] = useState({ nombreCompleto: `${nombre} ${apellido}`,
  nombre: nombre,
  apellido: apellido,
  doc: docNum,
  fechaNac: fechaNac,
  genero: genero,
  gamaPk: plan.gamaPk,
  planPk: plan.planPk})
  return (
    <div>
         {step === 1 && isMobile && <UnTurnoPara integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 1 && isMobile && <SelectEspePrac setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 2 && isMobile && <SelectCentro setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 3 && isMobile && <SelectTurno setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 4 && isMobile && <DetallesConfirmar setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 5 && isMobile && <TurnoReservado setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         
         {step === 1 && !isMobile &&<UnTurnoPara integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 1 && !isMobile && <SelectEspeCentrosDesktop setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 2 && !isMobile && <DetallesConfirmarDesktop setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
         {step === 3 && !isMobile && <TurnoReservadoDesktop setStep={setStep} setData={setData} data={data} integranteSeleccionado={integranteSeleccionado} setIntegranteSeleccionado={setIntegranteSeleccionado}/>}
    </div>
  )
}

export default NuevoTurno