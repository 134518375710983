import {FormControl, Select, MenuItem, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SelectPerson = ({setSelected}) => {

    const {nombre, apellido, docNum} = useSelector(state => state.authLogin)
    const { familiares } = useSelector(state => state.grupoFamiliar)
    const [persons, setPersons] = useState([]);

    useEffect(()=>{
        setPersons([
            ...familiares.filter(fam => fam.estadoCuenta === 3).map(p => {return {fullName: `${p.nombre} ${p.apellido1}`, dni: p.nroDoc }}),
            {
                fullName: `${nombre} ${apellido}`,
                dni: docNum
            }
        ])
    }, [])

    const handleChange = (e) => {
        setSelected(e.target.value)
    }

    return (
        <>  
            <Typography sx={{whiteSpace: 'nowrap', marginRight: '10px', fontSize: '1rem'}}>
            Resultado de estudios para:
            </Typography>
            <FormControl>
                <Select
                    onChange={handleChange}
                    defaultValue={docNum}
                    variant="standard"
                    sx={{
                        "&:before": {
                            border: "none",
                        },
                        fontWeight: 'bold',
                        fontSize: '.9rem'
                    }}
                >
                    {persons.map(person => <MenuItem value={person.dni}>{person.fullName}</MenuItem>)}
                </Select>
            </FormControl>
        </>
    )
}

export default SelectPerson;