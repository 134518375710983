import { useState } from 'react';
import ShareIcon from '../Icons/ShareIcon';
import {IconButton, Typography, Box, TextField, DialogContentText, FormControlLabel, Checkbox} from '@mui/material';
import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import RequestApi from '../../../../../../Request-api/RequestApi';
import Swal from 'sweetalert2';
import moment from "moment";
import useIsMobile from '../../../../../../Utils/useIsMobile'
import { useSelector } from 'react-redux';


const styles = {
    "& .shareBtn": {
        fontFamily: "'Lato', sans-serif",
        fontWeight: "bold",
        color: "#fff",
        textTransform: "initial",
        borderRadius: "40px",
        backgroundColor: "#E4002B",
        width: "100%",
        height: "40px",
        padding: "15px"
      },
      "& .cancelBtn": {
        width: "100%",
        color: "#000",
        textDecoration: "underline",
        textTransform: "capitalize",
        padding: "1rem",
      }
}

const ShareModal = () => {
    const {isMobile} = useIsMobile();
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [PDF, setPDF] = useState();
    const { fechaNac, docNum, docType } = useSelector(state => state.authLogin)
    const [state, setState] = useState({
        nombre: '',
        email: '',
        desc: ''
    })

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleCheckTerms = () => {
        setChecked(!checked);
    }

    const handleClickOpen = ({fecha, hora}) => {
        RequestApi.getPDF({
            documento: docNum.toString(),
            fechaNac: moment(fechaNac, "YYYY-MM-DD").format("DD-MM-YYYY"),
            fechaEstudio: moment(fecha, "YYYYMMDD").format("DD-MM-YYYY"),
            horaEstudio: moment(hora, "HHmmss").format("HH:mm:ss"),
            acceso: "",
            informeid: ""
        }).then(response => {
            if(response) {
                setPDF(response)
                setOpen(true);
            } else {
                //no hay pdf
            }
        }).catch(e => {
            //error
        })
    };
    
    const handleClose = () => {
    setOpen(false);
    };

    const shareOptions = () => {
        const file = new File([PDF], 'estudio.pdf', {type: 'application/pdf'});
        if (navigator.canShare({files: [file]})) {
            navigator
                .share({files: [file]})
                .then(r => console.log(r))
                .catch(e => console.log(e))
        }
    }
    
    const handleShare = () => {
        const study = Buffer.from(PDF, 'binary').toString('base64');
        RequestApi.shareStudy(state.nombre, state.email, state.desc, study)
        .then(r => {
            handleClose();
            Swal.fire({
                title: '¡Mail enviado exitosamente!',
                text: `${state.email} recibirá el correo con tu estudio médico, en breve.`,
                icon: 'success',
                confirmButtonColor: '#E4002B',
                confirmButtonText: 'Cerrar',
            })
        })   
    }

    return (
        <>
            <IconButton onClick={isMobile ? shareOptions : handleClickOpen}>
                <ShareIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={styles}
                maxWidth={'xs'}
            >
                <DialogTitle id="alert-dialog-title">
                Compartir estudio
                </DialogTitle>
                <DialogContent>
                <Box>
                    <DialogContentText>
                        Enviá a tu médico por email los resultados de los estudios realizados en nuestras clinicas y centros médicos.
                    </DialogContentText>
                    <Box>
                        <Typography className='label'>
                            Nombre destinario
                        </Typography>
                        <TextField fullWidth placeholder='Ingresá el nombre' variant="outlined" name='nombre' onChange={handleOnChange} />
                    </Box>
                    <Box>
                        <Typography className='label'>
                            Email destinario
                        </Typography>
                        <TextField fullWidth placeholder='Ingresá el email' variant="outlined" name='email' onChange={handleOnChange} />
                    </Box>
                    <Box>
                        <Typography className='label'>
                            Comentarios
                        </Typography>
                        <TextField fullWidth multiline rows={3} placeholder='Ingresá aquí tus comentarios' variant="outlined" name='desc' onChange={handleOnChange} />
                    </Box>
                    <DialogContentText>
                        La información que estás enviando tiene carácter confidencial. Por favor aceptá los términos y condiciones.
                    </DialogContentText>
                    <Box>
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckTerms}/>} label="Acepto los términos y condiciones" />
                    </Box>
                </Box>
                </DialogContent>
                <DialogActions>
                <Box display='flex' flexWrap='wrap' width='100%'>
                    <Button variant='outlined' className='shareBtn' onClick={handleShare} autoFocus>Enviar</Button>
                    <Button onClick={handleClose} className='cancelBtn'>Cancelar</Button>
                </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ShareModal;