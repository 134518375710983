import React from 'react';
import Routes from "./Routes"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';

function App() {
  
  return (
    <div  key="app" className="App">   
        <Routes/>
    </div>
  );
}

export default App;
