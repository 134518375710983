import React from "react"
import { Box } from '@mui/material'
import useIsMobile from "../../../../../../../Utils/useIsMobile"
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom"
import "./CheckOrDni.css"


const CheckOrDni = ({next}) => {
    const { isMobile } = useIsMobile()

    const handleAceptar = () => {
        next()
    }
    
    return (
    <>
        <p className="text-bienv">Validar Identidad</p>
        <Box display='flex' justifyContent='center'>
            <Box sx={{maxWidth: { md: '50%'}}}>
                <div style={{ width: "100%", }}>
                    <p className="textInputs-register"> 1. Escaneá el código QR del frente de tu
                        documento de identidad</p>
                </div>
                <div className="botonCamara">
           
                <button style={{border:"0px", backgroundColor:"#fff"}} onClick={handleAceptar}>
                    <img alt="img" src="../images/camaraQr.svg"></img>
                    <p>Escanear código QR</p>
                </button>
         
                </div>
            </Box>
        </Box>
        <ButtonCustom width={"50%"} sx={{position: isMobile ? 'fixed' : 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%, -50%)'}} onClick={handleAceptar} name="Continuar" />
    </>
    )
}
export default CheckOrDni;