import { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useIsMobile from "../../Utils/useIsMobile"
import MenuIcon from '@mui/icons-material/Menu';
import NavMobile from './NavMobile/NavMobile';
import './HeaderClientes.css';
import UserChip from './UserChip/UserChip';

function Header({ type }) {


  const [open, setOpen] = useState(false);
  const { isMobile } = useIsMobile();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickLogo = () => {
    window.open('/');
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="transparent" position="static">
        <Toolbar sx={{height:'82px'}}>
        

        {
          type === "home" &&
            <>
              <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ left:'1rem', color:'#344054'}}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <NavMobile  onDrawnerClose={handleDrawerClose} open={open}/>
            </>
          }
                

          {isMobile &&
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center" >
              <img onClick={handleClickLogo} style={{ marginTop: "15px", marginBottom: "20px", marginLeft: "18px", cursor: 'pointer' }} src="/images/logos/edcm-smg-logo-20241202.jfif" width="150" height="60" alt="Logo cma"></img>
            </Grid>


          }



          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            ></IconButton>
            {type === "home"  ?
              (isMobile ?
                ( <div></div>)
                :
                (
                  <UserChip />
                ))
              :
              !isMobile && <p className="registracion-text">Registración</p>
            }
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;