import React from 'react'
import "./InicioTurno.css"
import { useHistory } from "react-router-dom";
import IconoFlechaNegraL from './IconoFlechaNegraL';

const InicioTurno = () => {
  const history = useHistory();
  const handleInicio = () => {
    history.push('/pacientes')
  };
  return (
    <div className='inicioTurno'><p>
      <u onClick={handleInicio}>Inicio</u>
      <IconoFlechaNegraL style={{ marginLeft: "10px", marginRight: "10px" }}/>
    <b className='turnosMedicos'>Turnos Medicos</b>
   </p>
    </div>
  )
}

export default InicioTurno