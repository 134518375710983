import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../../componentes/Breadcrumb/Breadcrumb";
import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import SelectPerson from "./SelectPerson/SelectPerson";
import StudyCard from "./StudyCard/StudyCard";
import Filters from "./Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import RequestApi from "../../../../../Request-api/RequestApi";

import moment from "moment";
import { SET_ESTUDIOS } from "../../../../../Redux/Actions/Types/estudios";
import Loader from "../../../../../componentes/Loader/Loader";

const ResultadosEstudios = () => {
  const [studies, setStudies] = useState([]);
  const [cards, setCards] = useState([]);
  const filters = useSelector((state) => state.filters);
  const { docNum, fechaNac, docType } = useSelector((state) => state.authLogin);
  const { familiares } = useSelector((state) => state.grupoFamiliar);
  const { processingDownload } = useSelector((state) => state.estudiosReducer);
  const [selected, setSelected] = useState(docNum);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    docNum: docNum,
    fechaNac: fechaNac,
    docType: docType.value,
  });
  const dispatch = useDispatch();
  const auxRef = useRef(null);

  useEffect(() => {
    if (parseInt(selected) === parseInt(docNum)) {
      setUserData({
        docNum: docNum,
        fechaNac: fechaNac,
        docType: docType.value,
      });
    } else {
      let children = familiares.filter(
        (fam) => parseInt(fam.nroDoc) === parseInt(selected)
      )[0];
      if (children) {
        setUserData({
          docNum: children.nroDoc,
          fechaNac: children.fechaNac,
          docType: "DU",
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    if (!auxRef.current) {
      setLoading(true);
      setCards([]);
      RequestApi.getEstudios(
        userData.docNum,
        userData.fechaNac.replaceAll("-", ""),
        userData.docType
      )
        .then((res) => {
          const { informes, laboratorio } = res;
          const estudios = [...informes, ...laboratorio];
          setStudies(
            estudios.map((est) => {
              return {
                id: est.idInforme || est.id,
                type: est.tipoEstudio || "laboratorio",
                creationDate: moment(est.fecha, "YYYYMMDD").format(
                  "DD/MM/YYYY"
                ),
                title: est.tipo || "Laboratorio",
                address: est.centro,
                deliveryDate:
                  est.fechaEntrega &&
                  moment(est.fechaEntrega).format("DD/MM/YYYY"),
                status: est.estado,
                idImagen: est.idImagen,
                hayImagen: est.hayImagen,
                fecha: est.fecha,
                hora: est.hora,
                patientId: est.patientId,
                numeroId: est.numero,
              };
            })
          );
          return () => {
            auxRef.current = true;
          };
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userData]);

  useEffect(() => {
    setCards(
      studies
        ?.filter((study) => {
          const creationDate = moment(study.creationDate, "DD/MM/YYYY");

          const filterType = filters.type ? study.type === filters.type : true;
          const filterStatus = filters.status
            ? study.status === filters.status
            : true;
          const filterDateStart = filters.date.start
            ? creationDate >= moment(filters.date.start, "DD/MM/yyyy")
            : true;
          const filterDateEnd = filters.date.end
            ? creationDate <= moment(filters.date.end, "DD/MM/yyyy")
            : true;
          return filterType && filterStatus && filterDateStart && filterDateEnd;
        })
        .sort((a, b) => {
          return filters.mostRecent
            ? new Date(b.creationDate) - new Date(a.creationDate)
            : new Date(a.creationDate) - new Date(b.creationDate);
        })
    );
  }, [filters, studies]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box width="100%" sx={{ "& *": { fontFamily: "'Lato', sans-serif" } }}>
        <Box sx={{ padding: "1rem" }}>
          <Breadcrumb location="Resultados de estudios" />
        </Box>
        <Box sx={{ paddingLeft: "1.3rem", paddingRight: "1.3rem" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
              sx={{ justifyContent: { xs: "space-evenly", sm: "start" } }}
            >
              <SelectPerson setSelected={setSelected} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Filters />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {processingDownload && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={processingDownload}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            {cards.length &&
              cards.map((card) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  display="flex"
                  justifyContent="center"
                >
                  {" "}
                  <StudyCard study={card} userData={userData} />{" "}
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ResultadosEstudios;
