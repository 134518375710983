import React, { useEffect, useState } from "react";
import "./SelectCentro.css";
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom";
import RecuestApiSinToken from "../../../../../../../../Request-api/RecuestApiSinToken";
import Spinner from "../../../../../../../../UI/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import utils from "../../../../../../../../Utils/useDate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};
const styles = {
  containerTimes: {
    display: "flex",
  },
  times: {
    display: "inline",
    width: "50%",
  },
};

const SelectCenrto = ({ data, setStep, setData, integranteSeleccionado, setIntegranteSeleccionado }) => {
  const [openModalAviso, setOpenModalAviso] = useState(false);

  const history = useHistory();
  const datosLogin = useSelector((state) => state.authLogin);
  let espe = data.espeSelected.value;
  let prac = data.practicSelected.value;
  const [centros, setCentros] = useState([]);
  let [centrosSelected, setCentrosSelected] = useState([]);
  let [preIdCentroSelected, setPreIdCentroSelected] = useState({});
  let servBasico = data.especialidades.find(espe => espe.value === data.espeSelected.value).idXhis

  var nacimiento = moment(integranteSeleccionado.fechaNac);
  var hoy = moment();
  var anios = hoy.diff(nacimiento, "years");
  const getCentros = (espe, prac) => {
    let edad = anios;
    let userLogin =
      utils.convertDate(integranteSeleccionado.fechaNac) +
      integranteSeleccionado.doc +
      "@swissmedical.com.ar";
    RecuestApiSinToken.getCentrosPorEspe(
      userLogin,
      espe,
      prac,
      edad,
      integranteSeleccionado.planPk,
      integranteSeleccionado.gamaPk,
      servBasico
    ).then((response) => setCentros(response));
    
  };
  const handleVolverEspe = () => {
    history.push("/pacientes/turnos");
  };

  const handleConfirmarModal = () => {
    if (centrosSelected.includes(preIdCentroSelected)) {
      setCentrosSelected((prev) =>
        prev.filter((item) => item !== preIdCentroSelected)
      );
    } else {
      setCentrosSelected((prev) => [...prev, preIdCentroSelected]);
    }
    setOpenModalAviso(false);
  };

  const handleSelect = (idCentro, visualiza) => {
    if (visualiza === "S") {
      if (centrosSelected.includes(idCentro)) {
        setCentrosSelected((prev) => prev.filter((item) => item !== idCentro));
      } else {
        setCentrosSelected((prev) => [...prev, idCentro]);
      }
    } else {
      
      setOpenModalAviso(true); 
    }
  };

  const handleAceptar = () => {
    setData((prev) => {
      return { ...prev, centrosSelected, centros, prac };
    });
    setStep(3);
  };

  const selectClass = (id) => {
    return centrosSelected.includes(id)
      ? "card-centro-turno-selected"
      : "card-centro-turno";
  };
  
  useEffect(() => {
    getCentros(espe, prac);
  }, [espe, prac]);

  useEffect(() => {}, [centros]);

  return (
    <div>
      <Modal
        open={openModalAviso}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
          <Typography>
            <h2><b>Práctica sin cobertura.</b></h2>
            <h2 style={{fontSize:"medium"}}>La especialidad o la práctica seleccionada no tiene cobertura para  {datosLogin.plan.name}{datosLogin.plan.gama}.</h2>
            <h2 style={{fontSize:"medium"}}>Reserva tu turno en forma privada llamando al:</h2>
            <h2>0810-333-8876</h2>
          </Typography>
          

          <ButtonCustom
            width={"95%"}
            onClick={() => handleVolverEspe()}
            name={"Aceptar"}
          />
          <p
            onClick={handleConfirmarModal}
            style={{ textDecoration: "underline" }}
          >
            Cancelar 
          </p>
        </Box>
      </Modal>
      <div className={"info-mobile"}>
      </div>
      <div className="containerPrincipal" style={{ padding: "0px 20px" }}>
        <div style={{ padding: "10px" }}>
          <p className="text-select-centro">CENTROS DE MÉDICOS ENCONTRADOS</p>
          <p className="sub-text-select-centro">
            Seleccioná uno o más centros de atención:
          </p>
        </div>

        <div style={{ padding: "10px" }}>
          {centros.length > 0 ? (
            centros.map((cen, i) => (
              <p
                style={{ color: cen.visualiza === "V" ? "red" : "black" }}
                onClick={() => handleSelect(cen.idcentro, cen.visualiza)}
                className={selectClass(cen.idcentro)}
              >
                {cen.centro}
              </p>
            ))
          ) : centros?.idCentro ? (
            <p style={{ margin: "auto" }}>No hay centros disponibles</p>
          ) : (
            <Spinner />
          )}
        </div>
        <div className="button-select-centro">
          <ButtonCustom
            disabled={centrosSelected.length === 0}
            onClick={handleAceptar}
            width={"95%"}
            name={"Ver disponibilidad"}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectCenrto;
