import { Box, Stack } from "@mui/material";
import dataNav from '../../../Data/Navbar.json';
import NavMenuItem from "./NavMenuItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        "& .navButton": {
            fontFamily: "'Lato', sans-serif",
            color: "#000",
            fontWeight: "bold",
            marginLeft: "0",
            fontSize: ".6rem",
            "@media only screen and (min-width: 900px)":{
                fontSize: ".7rem",
            },
            "@media only screen and (min-width: 1100px)":{
                fontSize: ".8rem",
                whiteSpace: "nowrap",
            }
        },
        "& .navItem": {
            display: "flex",
        }
    }
  })
);

const NavMenu = () => {

    const { root } = useStyles();
    const { headers } = dataNav;

    return (
        <>
            <Stack direction="row" spacing={{xs:0, md:2, lg:3}} className={root}>
                {headers.map((header, index) => (
                    <Box key={index} className="navItem">
                        <NavMenuItem header={header} />
                    </Box>
                  )
                )}

            </Stack>
        </>
    )
}

export default NavMenu;