import { useState, useEffect, createRef } from 'react';
import { Formik, Form } from 'formik';
import { Box, Button, Grid, Typography, Backdrop, CircularProgress } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FieldCustom from '../../../../UI/FieldCustom/FieldCustom';
import RequestApi from '../../../../Request-api/RequestApi';
import FormSchema from './FormSchema';
import { RECAP_KEY } from '../../../../constants';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

const styles = {
    section: {
        '& .btn': {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            textTransform: 'initial',
            color: "#fff",
            backgroundColor: "#E4002B",
            borderRadius: "40px",
            borderColor: "#000",
            width: '100%'
        }
    },
    headerSection: {
        my: '3rem',
        fontFamily: "'Lato', sans-serif",
        textAlign: "center",
        "& .headerSubtitle": {
            fontSize: "1rem",
            color: "#363636"
        },
        "& .headerTitle": {
            fontWeight: "bold",
            fontSize: "2.125rem",
        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const FormTurnos = () => {
    const recaptchaInstance = createRef();
    const {headerSection, section} = styles;
    const [docType, setDocType] = useState([]);
    const [allDocTypes, setAllDocTypes] = useState([]);
    const [sede, setSede] = useState([]);
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(false);

    const getTipoDoc = (value) => {
        return allDocTypes.find(obj => obj.id == value)
     }

    const handleRecaptcha = (token) => {
        if(token){
            RequestApi.sendWelltestTurn({
                nroDoc: data.docNum,
                tipoDoc: getTipoDoc(data.docType),
                nombre: data.name,
                apellido: data.lastName,
                email: data.email,
                telefono: data.tel,
                prepaga: data.prepaga,
                credencial: data.credential,
                sede: data.sede
            }, token).then(r => {
                setLoading(false);
                Swal.fire({
                    icon: 'sucess',
                    title: 'Envío de formulario',
                })
            }).catch(err => {
                setLoading(false);
            })
        }
    }

    useEffect(()=> {
        if (!docType.length) {
        RequestApi.getTipoDocs().then(docList => {
            docList.shift()
            setAllDocTypes(docList);
            setDocType(docList.map(doc => { 
                return {
                    name: doc.name, 
                    value: doc.id
                }
            }));
        })
        }

        if(!sede.length) {
            RequestApi.getSedes().then(sedeList => {
                setSede(sedeList.map(sede => {
                    return {
                        name: sede.nombre,
                        value: sede.id
                    }
                }))
            })
        }
    }, [])


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                initialValues={{
                    docType: '2',
                    docNum: '',
                    name: '',
                    lastName: '',
                    tel: '',
                    prepaga: '',
                    email: '',
                    sede: '',
                    credential:''
                }}
                validationSchema={FormSchema}
                onSubmit={values => {
                    setData(values);
                    setLoading(true);
                    
                    window.grecaptcha.execute();
                }}
            >
                {({ errors, touched, values, handleChange }) => (
                    <Form style={{width: '100%'}}>
                        <Grid container sx={section}>
                            <Grid xs={12}>
                                <Box sx={headerSection}>
                                    <Typography className="headerTitle">Formulario para reserva de turnos</Typography>
                                    <HorizontalRuleIcon className="headerIcon"/>
                                    <Typography className='headerSubtitle'>Todos los campos son obligatorios.</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={6} item container sx={{pr: {md: '1rem'}}}>
                                    <Grid xs={4} item sx={{pr:'1rem'}}>
                                        <FieldCustom
                                            id="docType"
                                            name="docType"
                                            variant='select'
                                            options={docType}
                                            placeholder='DNI'
                                            label='Documento'
                                            value={values.docType}
                                            onChange={handleChange}
                                            error={touched.docType && Boolean(errors.docType)}
                                            helperText={touched.docType && errors.docType}
                                        />
                                    </Grid>
                                    <Grid xs={8} item sx={{pl: '1rem'}}>
                                        <FieldCustom 
                                            id="docNum"
                                            name="docNum"
                                            placeholder='Número'
                                            label='hidden'
                                            value={values.docNum}
                                            onChange={handleChange}
                                            error={touched.docNum && Boolean(errors.docNum)}
                                            helperText={touched.docNum && errors.docNum}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <FieldCustom 
                                            id="name"
                                            name="name"
                                            placeholder='Ingresá tu nombre'
                                            label='Nombre'
                                            value={values.name}
                                            onChange={handleChange}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <FieldCustom 
                                            id="lastName"
                                            name="lastName"
                                            placeholder='Ingresá tu apellido'
                                            label='Apellido'
                                            value={values.lastName}
                                            onChange={handleChange}
                                            error={touched.lastName && Boolean(errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <FieldCustom 
                                            id="tel"
                                            name="tel"
                                            placeholder='Ingresá tu teléfono'
                                            label='Teléfono'
                                            value={values.tel}
                                            onChange={handleChange}
                                            error={touched.tel && Boolean(errors.tel)}
                                            helperText={touched.tel && errors.tel}
                                        />
                                    </Grid>
                            </Grid>
                            <Grid xs={12} md={6} item sx={{pl: {md: '1rem'}}}>
                                <Grid xs={12} item>
                                    <FieldCustom 
                                        id="email"
                                        name="email"
                                        placeholder='Ingresá tu e-mail'
                                        label='E-mail'
                                        value={values.email}
                                        onChange={handleChange}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <FieldCustom
                                        id="sede"
                                        name="sede"
                                        variant='select'
                                        options={sede}
                                        placeholder='Swiss Medical Center Microcentro'
                                        label='Sede'
                                        value={values.sede}
                                        onChange={handleChange}
                                        error={touched.sede && Boolean(errors.sede)}
                                        helperText={touched.sede && errors.sede}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <FieldCustom 
                                        id="prepaga"
                                        name="prepaga"
                                        placeholder='Ingresá tu prepaga'
                                        label='Prepaga'
                                        value={values.prepaga}
                                        onChange={handleChange}
                                        error={touched.prepaga && Boolean(errors.prepaga)}
                                        helperText={touched.prepaga && errors.prepaga}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <FieldCustom 
                                        id="credential"
                                        name="credential"
                                        placeholder='Ingresá tu N° de credencial'
                                        label='Credencial'
                                        value={values.credential}
                                        onChange={handleChange}
                                        error={touched.credential && Boolean(errors.credential)}
                                        helperText={touched.credential && errors.credential}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} item display='flex' justifyContent='center' sx={{mt:'2rem', pb:'3rem'}}>
                                <Box sx={{width: '100%', maxWidth: '220px'}}>
                                    <Button type='submit' variant="contained" className='btn'>
                                        Enviar
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <ReCAPTCHA
               ref={recaptchaInstance}
               sitekey={RECAP_KEY}
               size="invisible"
               onChange={handleRecaptcha}
            />
        </>
    )
}

export default FormTurnos;