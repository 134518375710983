const initialState = {
    isOpen: false,
}

const TOGGLE_OPEN = 'TOGGLE_OPEN';

export const toggleOpen = (value) => {
    return {
        type: TOGGLE_OPEN,
        payload: {
            isOpen: value
        }
    }
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_OPEN: 
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export default reducer