
import React from 'react'
import DividerCard from '../../../../../../../../../../componentes/DividerCard/DividerCard'
import "./TurnoCard.css"
import DateOperatations from "../../../../../../../../../../Utils/useDateOperatations"


const TurnoCard = ({turnosXCentro, SelectTurno, centroSeleccionado, centros}) => {
  
    const obtenerDireCentro = (idCentro) => {
        return idCentro
    }

    
    const searchDireCentro = (idCentro) => {
        let direCentro
        centros.forEach(c => {
            if(c.idcentro === idCentro){
                direCentro = c.domicilio
            }
        })
        return direCentro
    }  
    const ordenarPorCentros = (centros) => {
        let ordenadoPorCentros = []
        let centro = centros[0]["id-centro"]
        centros.forEach((c, i) => {
            if(c["id-centro"] === centro && i !== 0){
                ordenadoPorCentros.forEach(oc =>{
                    if(oc.idCentro ===c["id-centro"]){
                        oc.turnos.push(c)
                    }
                })
            }else{
                ordenadoPorCentros.push({
                    idCentro: c["id-centro"], 
                    turnos: [c],
                    nameCentro: c["d-centro"],
                    direCentro: obtenerDireCentro(c["id-centro"]),
                    fechaTurno: c["fecha-turno"]
                })
            }
            centro = c["id-centro"]
        })
        return ordenadoPorCentros
    }  
  return (
    <div>
        {ordenarPorCentros(turnosXCentro).map(t=>
        <div>
              <DividerCard/>   
            <p className="text-name-centro">{t.nameCentro}</p>
            <p style={{margin:"5px 0px", fontWeight:"300"}} >{searchDireCentro(t.direCentro)} </p>
            <p style={{margin:"5px 0px"}}>{DateOperatations.getLabelFecha(t.fechaTurno)+":"} </p>
            <div className="wrapper">{t.turnos.map((h) => 
              <p 
                 className="hora-turno-card"
                 style={{backgroundColor: centroSeleccionado["hora-desde"] === h["hora-desde"]  ? "#E4002B": "#F6F6F6"}}
                 key={h["hora-desde"]} 
                 onClick={() => SelectTurno(h)}
              >{h["hora-desde"]}</p>
            )}</div>
        </div>
         )}
   </div>
  )
}

export default TurnoCard