import {
  FormControl,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

const CustomSelect = ({
  items,
  onChange,
  value,
  label,
  name,
  disabled,
  errorText = "",
}) => {
  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: "1.5rem" }}>
        <Typography>{label}</Typography>
        <Select
          id={`select-${name}`}
          value={value}
          onChange={onChange}
          name={name}
          required
          disabled={disabled}
          placeholder="Seleccionar"
        >
          {items?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: "red", fontSize: "13.5px" }}>
          {errorText}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomSelect;
