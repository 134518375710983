const initialState = {
    type: '',
    status: '',
    date: {
        start: '',
        end: ''
    },
    mostRecent: true
}

const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';

export const setFilters = (values) => {
    return {
        type: SET_FILTERS,
        payload: values
    }
}

export const resetFilters = () => {
    return {
        type: RESET_FILTERS
    }
}


/* export const removeInstance = () => {
    return {
        type: REMOVE_INSTANCE
    }
}
 */

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS: 
            return {
                ...state,
                ...action.payload
            }
        case RESET_FILTERS:
            return {
                ...initialState
            }
        default:
            return state;
    }
}

export default reducer