import { useEffect, useState } from "react";
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
  NotFoundException
} from "@zxing/library";
import RequestApi from "../../Request-api/RequestApi"
import { useSelector } from "react-redux";
import useIsMobile from "../../Utils/useIsMobile";
import Swal from "sweetalert2";
import { Box, Button } from "@mui/material";

export default function ScannerComponent({next}) {
  const { isMobile } = useIsMobile();
  const [disabled, setDisabled] = useState(true);
  const [notifyError, setNotifyError] = useState("")
  const {docNum, idEDNS, docType} = useSelector(state => state.authLogin);

  const captureStart = (callback = () => { }) => {
    const formats = [BarcodeFormat.PDF_417];
    const hints = new Map();
    const source = "";
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    const codeReader = new BrowserMultiFormatReader();
    codeReader.decodeFromVideoDevice(source, "video", (result, err) => {
      if (result) {
        const resultado = result.text.split('@')
        if (resultado.length > 8) {
          const docScanned = resultado[4]
          return callback(docScanned);
        }
      }
      if (err && !(err instanceof NotFoundException)) {
        return next();
      }
    });
  };

  useEffect(() => {
    setNotifyError(setTimeout(()=>{
      Swal.fire({
        title:"No pudimos escanear tu documento",
        text: "No es posible escanear tu DNI, para poder validarte por favor completa el siguiente formulario",
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
        willClose: next(4)
      })
    }, 300000))
 
    setTimeout(()=>{
      setDisabled(false)
    }, 10000)
    captureStart((docScanned) => {
      if(docScanned === docNum) {
        //terminar validación
        RequestApi.validarIdentidadManual({
          "idEdns": idEDNS,
          "canal": isMobile ? 2 : 1,
          "idTipoDoc": docType.id,
          "nroDoc": docNum,
          "ipCliente": window.CLIENT_IP,
          "dispositivo": navigator.userAgent,
          "tipoValidacion": 1,
          "idUsuario": docNum
        }).then((r) => {
          next(5)
          clearTimeout(notifyError)
        })
      } else {
        next(4)
        clearTimeout(notifyError)
      }
    })
  }, []);

  const handleButton = () =>{
   next(4); 
   clearTimeout(notifyError)
  }

  return (
    <>
      <Box sx={{ position: "fixed", left:0, width:'100vw', height:'95%' }}>
          <video
            id="video"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: '100%',
              minHeight: '100%'
            }}
          ></video>
          <p style={{
            position: "absolute",
            top: "120px",
            background: "white",
            color: "#363636",
            borderRadius: "15px",
            marginLeft: `${(window.innerWidth / 2) - 150}px`,
            padding: "5px  15px",
            fontSize: "15px",
            fontWeight: "500"
          }}>
            Coloca su documento dentro del marco.
          </p>
          <div style={{
            borderRadius: "10px",
            position: "absolute",
            width: "200px",
            height: "60px",
            border: "7px solid #E4002B ",
            backgroundColor: "rgba(255, 0, 0, 0)",
            marginLeft: `${(window.innerWidth / 2) - 100}px`,
            marginTop: `${(window.innerHeight / 2)}px`,
            zIndex: "2"
          }}></div>

          <Box sx={{position: 'fixed', bottom: 0, backgroundColor: '#000', width: '100%'}}>
            <Button sx={{color:'#fff'}} disabled={disabled} onClick={handleButton}>Intentar validarme de otra manera</Button>
          </Box>
      </Box>
    </>
  );
}
