import React from 'react';
import { Typography, Box, Grid, CardMedia, Container, Divider, Breadcrumbs, Link,Paper, List } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useEffect } from 'react';
import Navbar from '../../componentes/Navbar/Navbar';
import Footer from '../../componentes/Footer/Footer';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from 'react-router-dom';
import Camera from '../../UI/Icons/Camera';


const styles = {
    section: {
        marginY: { xs: '1rem', sm: '-1rem', md: '2rem', lg: '2.5rem' },
        marginX: { xs: '1rem', sm: '4rem', md: '4rem', lg: '0' },
        marginBottom: { xs: '6rem', sm: '5rem', md: '3rem', lg: '7rem' },
        "& .itemDesc": {
            fontFamily: "'Lato', sans-serif",
            color: "#666666"
        },
        "& .subtitleAcc": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold"
        },
        "& .titleAcc": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            mt: "1rem"
        },
        "& .subtitleAccRed": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            my: "1rem"
        },
        "& .anotherTitle": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B"
        },
        "& .gridImg": {
            display: "flex",
            width: "100%",
            justifyContent: { xs: 'center', md: 'flex-end' },
            marginTop: { sm: '2.5rem', md: '-1rem' },
            paddingLeft: { md: '3rem' }
        },
        "& .imgCard": {
            maxWidth: { md: '372px', lg: '400px' },
            height: { md: '371px' },
            borderRadius: { md: '25px' },
        },
        "& .containerImgText": {
            display: "flex",
            alignItems: 'flex-start',
            flexDirection: 'row-reverse',
        },
        "& .containerText": {
            marginTop: { xs: '2rem', md: '0' },
            marginBottom: '1.5rem'
        },
        "& .textTypography": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: '1rem',
        },
        "& .textTypographyDown": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: '1.5rem'
        },
        "& .contacts": {
            fontFamily: "'Lato', sans-serif",
            color: '#E4002B',
            fontWeight: 'bold',
            marginBottom: '0.2rem'
        }, "& .contactsDown": {
            fontFamily: "'Lato', sans-serif",
            color: '#797979'
        },
        "& .btn": {
            fontFamily: "'Lato', sans- serif",
            fontWeight: "bold",
            color: '#E4002B',
            textTransform: 'initial',
            borderRadius: '40px',
            borderColor: '#E4002B',
            height: '40px',
            padding: '15px',
            paddingX: { lg: '1.5rem' },
            marginY: { xs: '2rem', md: '2.5rem', lg: '0' },
            ':hover': {
                borderColor: '#E4002B',
            }
        },
        "& .containerTel": {
            paddingRight: { md: '2.5rem' }
        }
        ,
        "& .containerEmail": {
            paddingRight: { lg: '3rem' }
        },
        "& .containerPaper": {
            padding: "2rem"
           
          },
          "& .listTurnos" : {
            fontFamily: "'Lato', sans-serif",
            paddingLeft: '2rem',
            listStyle: "disc  outside none",
            
        },
        "& .colorItem" : {
            color : "#E4002B"
        }

    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "8rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerTitle": {
            marginTop: { xs: '1rem', md: '9rem' },
            fontWeight: "bold",
            fontSize: "2.125rem",
            fontFamily: "'Lato', sans-serif",

        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const Emergencias = () => {
    const { headerSection, section } = styles;
    const history = useHistory();
    const handleClick = () => {
        history.push("/")
    }
    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <Navbar />
            <Container sx={{
                mt: '74px',
                maxWidth: '1366px',
                px: { xs: 0 },
            }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                    pt=".5rem"
                >
                    <Link
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/"
                        onClick={handleClick}
                    >
                    Inicio
                    </Link>,
                    <Typography key="2" color="text.primary">
                    Emergencias y Urgencias
                    </Typography>
                </Breadcrumbs>
                <Box sx={headerSection}>
                    <Typography className="headerTitle">Emergencias y Urgencias</Typography>
                    <HorizontalRuleIcon className="headerIcon" />
                </Box>
                <Box sx={section}>
                    <Grid className="containerImgText" container>
                        <Grid className="gridImg" item sx={12} md={5}>
                            <CardMedia className="imgCard" component="img" image="/images/info/emergencias.jpg" alt="Emergencias y Urgencias" />
                        </Grid>
                        <Grid item sx={12} md={7}>
                            <Box className='containerText'>
                                <Typography className="itemDesc">
                                En <Typography component="span" sx={{fontWeight: "bold", color: "#E4002B"}}>Swiss Medical Center Barrio Norte</Typography> encontrará guardias activas durante las 24 horas, los 365 días del año, para las siguientes especialidades:
                                </Typography>
                                <Box sx={{mt: "1rem"}}>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Clinica Médica
                                        </Typography>
                                        <Typography className="itemDesc">
                                        Pueyrredón 1441, planta baja y subsuelo.
                                        </Typography>
                                    </Box>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Pediatría:
                                        </Typography>
                                        <Typography className="itemDesc">
                                        Santa Fe 2479, 1° piso.
                                        </Typography>
                                    </Box>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Ortopedia y Traumatología:
                                        </Typography>
                                        <Typography className="itemDesc">
                                        Pueyrredón 1441, planta baja.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ mt: '2rem' }}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <ErrorOutlineIcon sx={{color:'#E4002B', fontSize: "2rem", mr: ".8rem" }}/>
                                    <Typography sx={{my: "1rem", fontWeight: "bold", fontFamily: "'Lato', sans-serif"}}>
                                    Todos los días de 8 a 21 hs:
                                    </Typography>
                                </Box>
                                <Box mt="1rem">
                                    <Typography className="subtitleAcc"> 
                                    Ginecología
                                    </Typography>
                                    <Typography className="itemDesc">
                                    Santa Fe 2479, 2° piso.
                                    </Typography>
                                </Box>
                                <Box mt="1rem">
                                    <Typography className="subtitleAcc"> 
                                    Otorrinolaringología
                                    </Typography>
                                    <Typography className="itemDesc">
                                    Santa Fe 2479.
                                    </Typography>
                                </Box>
                                <Box mt="1rem">
                                    <Typography className="subtitleAcc"> 
                                    Oftalmología
                                    </Typography>
                                    <Typography className="itemDesc">
                                    Pueyrredón 1443, 10° piso.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box mt={5}>
                                <Paper elevation={4}>
                                    <Grid  className="containerPaper" container  >
                                        <Box display={'flex'} alignItems="center">
                                          <Camera/>
                                          <Typography className="anotherTitle" fontSize="1.5rem" marginLeft={2}> E-consulta</Typography>
                                        </Box>
                                         <List className='listTurnos'>
                                            <li className='colorItem'><Typography component="span" color="#000" >Demanda espontánea las 24 hs. para Clínica Médica y Pediatría.</Typography> </li>
                                            <li className='colorItem'><Typography component="span" color="#000">Demanda espontánea de 8 a 20 hs. para Neonatología y Puericultura/Lactancia.</Typography> </li>
                                         </List>
                                         <Box mt={1}>
                                         <Typography color="#E4002B">
                                            Disponible en la web 
                                            <Typography component="span" className="anotherTitle"> www.swissmedical.com </Typography>
                                            y en la </Typography>
                                        <Typography className='anotherTitle'> App Swiss Medical Mobile </Typography>
                                        </Box>
                                    </Grid>
                                </Paper>
                            </Box>
                            <Box>
                                <Box my="2rem">
                                    <Typography mb="1rem" className="titleAcc">
                                    Atención odontológica por guardia
                                    </Typography>
                                    <Divider/>
                                </Box>
                                <Box>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Swiss Medical Center Charcas
                                        </Typography>
                                        <Typography className="itemDesc">
                                        Charcas 3391, guardia odontológica adultos de lunes a domingo de 8:00 a 19:30 hs.
                                        </Typography>
                                    </Box>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Swiss Medical Center Caballito
                                        </Typography>
                                        <Typography className="itemDesc">
                                        Av. Pedro Goyena 1202, de lunes a viernes de 8:00 a 19:00 hs. y sábados de 8:00 a 13:00 hs.
                                        </Typography>
                                    </Box>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Swiss Medical Center Neuquén
                                        </Typography>
                                        <Typography className="itemDesc">
                                        Fotheringhan 155, guardia 12 horas. 
                                        </Typography>
                                    </Box>
                                    <Box mt="1rem">
                                        <Typography className="subtitleAcc"> 
                                        Swiss Medical Center Salta
                                        </Typography>
                                        <Typography className="itemDesc">
                                        España 943 4° piso, guardia 12 horas. 
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    )
}

export default Emergencias