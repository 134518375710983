import variants from "./variants";

/**
 * Componente de MUI personalizado.
 *  - Se agrega un label por default
 *  - Adaptado para el uso de Formik
 *  - Se agrega para variantes para usar SELECT o COMBOBOX
 * 
 * @param {string} variant
 * @version 0.0.1
 * @author Cristian Del Canto <cristian.delcanto@swissmedical.com.ar>
 */

const FieldCustom = ({variant = 'default', ...rest}) => {
    const Field = variants[variant] || variants['default'];
    return <Field variant={variant} {...rest} />
}

export default FieldCustom;