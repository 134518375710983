import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function ColorCheckboxes({checked, onChange, sx}) {
  return (
   
      <Checkbox
        {...label}
        checked={checked}
        onChange={onChange}
        sx={{
          color: "#E4002B",
          '&.Mui-checked': {
            color: "#E4002B",
          },
          ...sx
        }}
      />

  );
}