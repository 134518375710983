import { Box, Grid, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const HistoryCards = ({ item }) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        minWidth: "350px",
        height: "190px",
        position: "relative",
        border: "1px solid red",
        boxShadow: "4px 4px 5px rgb(244,61,39,0.25)",
        borderRadius: "6px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "95%",
          padding: "3px 8px",
          borderBottom: "1px solid red",
          display: "flex",
          alignItems: "center",
          gap: "8px"
        }}
      >
        <CalendarMonthIcon color="error" />
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: "12px",
            color: "#D52D19",
            fontWeight: "600",
          }}
        >
          {item?.nombre_abre_atencion} {item?.ape_razon_atencion}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          left: "20px",
          top: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          height: "100%",
          gap: "6px"
        }}
      >
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: "12px",
          }}
        >
          <Box component="span" sx={{fontWeight: "900", fontSize: "13.5px"}}>Nombre:</Box> {item?.nombre} {item?.ape}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: "13.5px",
          }}
        >
          <Box component="span" sx={{fontWeight: "900"}}>Lugar:</Box> {item?.domicilio}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontSize: "13.5px",
          }}
        >
          <Box component="span" sx={{fontWeight: "900"}}>Centro:</Box> {item?.centro_deno}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          sx={{
            fontSize: "13.5px",
          }}
        >
          <Box component="span" sx={{fontWeight: "900"}}>Fecha desde:</Box> {item?.fecha_desde_dia}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          sx={{
            fontSize: "13.5px",
            mt: "-6px"
          }}
        >
          <Box component="span" sx={{fontWeight: "900"}}>Tipo estudio:</Box> {item?.exten_deno}
        </Typography>
      </Box>
    </Box>
  );
};

export default HistoryCards;
