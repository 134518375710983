import { Button, Popper, Paper, Grow, ClickAwayListener, MenuList, MenuItem } from "@mui/material";
import { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const NavMenuItem = ({header}) => {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }
  
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);


    return (
        <>
            <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className="navButton"
            >
            {header.label}
            </Button>
            <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            style={{minWidth: "200px"}}
            placement="bottom-start"
            transition
            disablePortal
            >
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                    >
                        {header.childrens.map((child, index) => {
                          return (
                            <MenuItem onClick={handleClose} key={index}>
                              {
                                child.href.includes("http") ? 
                                <a style={{textDecoration:"none", color:"#000", width: "100%"}} href={child.href} target="_blank" rel='noreferrer'>{child.label}</a>
                                : <Link style={{textDecoration:"none", color:"#000", width: "100%"}} to={child.href}>{child.label}</Link>
                              }
                            </MenuItem>) 
                        })}
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </>
    )
}

export default NavMenuItem;