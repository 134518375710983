import { Box } from "@mui/material";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import useIsMobile from "../../../Utils/useIsMobile";
import ChipMenu from "../ChipMenu/ChipMenu";
import SelectNotificacion from "./SelectNotificacion";

const UserChip = ({enabledmenu, mobile}) => {
    const { nombre, apellido, plan } = useSelector(state => state.authLogin);
   const [verNotificacion, setVerNotificacion] = useState()
   const { isMobile } = useIsMobile();
   const planName = plan?.name?.trim() || "";
   const planGama = plan?.gama?.trim() || "";


    return (
      <div>
        
      <Box display='flex' alignItems='center' >
            <div>
            <SelectNotificacion/>
            </div>
       {!isMobile && <div style={{marginLeft: "15px"}}>
          <p style={{ margin: "5px", fontSize: "13px" }}><b> Bienvenido </b>{nombre} {apellido}</p>
          <p style={{ margin: "5px", fontSize: "12px" }}>{`${planName}: ${planGama}`}</p>
        </div>}
        {!isMobile && <ChipMenu enabledmenu={enabledmenu}/> }
      </Box>
      </div>
    )
}

export default UserChip;