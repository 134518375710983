import { Grid, Typography, Box } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CardHome from "../../../../componentes/CardHome/CardHome";
import { novedades } from "../../../../Data/Novedades.json";
import useIsMobile from "../../../../Utils/useIsMobile";
import Carousel from 'react-material-ui-carousel';

const styles = {
    cardSection:{
        marginY: "5rem",
        marginX: '2rem',
        "& .containerCards" : {
            flexWrap: 'nowrap'
        }
    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "3rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerSubtitle": {
            fontSize: "1rem",
            color: "#797979",
            letterSpacing: ".6rem"
        },
        "& .headerTitle": {
            marginTop: "1rem",
            fontWeight: "bold",
            fontSize: "1.5rem",
        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const Novedades = () => {
    const { isMobile } = useIsMobile();

    const { cardSection, headerSection } = styles;

    return (
        <>
            <Box sx={headerSection}>
                <Typography className="headerSubtitle">CONOCÉ LAS ÚLTIMAS NOTICIAS</Typography>
                <Typography className="headerTitle">Destacados</Typography>
                <HorizontalRuleIcon className="headerIcon"/>
            </Box>
            <Box sx={cardSection}>
                {isMobile ? (
                    <Carousel
                    animation="slide"
                    duration={1000}
                    navButtonsAlwaysVisible={true}
                    fullHeightHover={false}
                    navButtonsProps={{
                    style: {
                        backgroundColor: 'unset',
                        borderRadius: 0,
                        color: '#000',
                    }
                    }}
                    activeIndicatorIconButtonProps={{
                    style: {
                        color: '#E4002B',
                    }
                    }}
                    navButtonsWrapperProps={{
                    style: {
                        bottom: '40px',
                        top: 'unset',
                    }
                    }}
                    indicatorContainerProps={{
                    style: {
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: '10%',
                        marginTop: '30px',
                    }
                    }}
                    sx={{paddingBottom:"5rem"}}
                > 
                    {novedades.slice(-4).map((novedad,index) => {
                        return (
                            <Grid item xs={12} display='flex' justifyContent='center' key={index}>
                                <CardHome title={novedad.title} desc={novedad.description} thumb={novedad.thumbnail} href={novedad.href}/>
                            </Grid>
                        )
                    })}
                </Carousel>
                ):( 
                    <> 
                        <Grid container className=" containerCards">
                            {novedades.slice(-4).map((novedad,index) => {
                                return (
                                    <Grid item xs={4} display='flex' justifyContent='center'  key={index}>
                                        <CardHome title={novedad.title} desc={novedad.description} thumb={novedad.thumbnail} href={novedad.href}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>
                )}
            </Box>
        </>
    )
}

export default Novedades;