import {
  SET_USER_DATA_FULFILLED,
  SET_SCREEM,
  SET_SELECTED_TURNO
 } from "../Actions/Types/clientes";
 
 
 const initialState = {
   userData: undefined,
   screem: "",
   selectedTurno: {}
 };
 //VerTurnos//
 const clientes = (state = initialState, action) => {
   switch (action.type) {
     case SET_USER_DATA_FULFILLED : {
       return {
         ...state,
         userData: action.payload
       };
     }
     case SET_SCREEM : {
      return {
        ...state,
        screem: action.payload
      };
    }
    case SET_SELECTED_TURNO : {
      return {
        ...state,
        selectedTurno: action.payload
      };
    }
  
     default: {
       return state;
     }
   }
 };
 
 export default clientes;
 