import {useEffect, useState} from 'react';
import {Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom";
import RequestApi from '../../../../Request-api/RequestApi';
import Spinner from '../../../../UI/Spinner/Spinner';
const styles = {
  "& .title": {
    fontFamily: "'Lato', sans-serif",
  },
  "& .desc": {
    fontFamily: "'Lato', sans-serif",
  }
};



const ModalTerminos = ({ open, setOpen, setTerminosCheck}) => {
  const [spinner, setSpinner] = useState(true)
  const [terminos, setTerminos] = useState()
  const handleConfirmar = () => {
    setTerminosCheck(true)
    handleClose()
  }
  const handleClose = () => {
    setOpen(false)
  }

  const getTerminos = () => {
    RequestApi.getTerminos().then(r => {
        setSpinner(false);
        setTerminos(r[0].texto)
    })
  }
  
  useEffect(() => {
    getTerminos()
  }, [])
  
  return (
    <>
      {spinner ? <Spinner/> : (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll='paper'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={styles}
        >
          <DialogTitle className='title' id="scroll-dialog-title">Términos y condiciones</DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              className='desc'
            >
              {terminos}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <ButtonCustom width={"95%"} onClick={() => handleConfirmar()} name="Aceptar Terminos" />
          {/* <p onClick={handleClose} style={{ textDecoration: "underline" }}>Cancelar</p> */}
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
export default ModalTerminos;