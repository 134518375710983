import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';


const styles = {
    card: {
        boxShadow: 'none',
        maxWidth: '310px',
        minHeight: '430px',
        "& .imgCard": {
            width: "50%",
            maxWidth: "250px",
            borderRadius: "100%",
            marginBottom: "1rem",
            marginLeft: "1rem"
        },
        "& .cardBtn": {
            marginTop: "1rem",
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            textTransform: "initial",
            borderRadius: "40px",
            borderColor: "#E4002B",
            height: "40px",
            padding: "15px",
            ':hover': {
                borderColor: '#E4002B',
            }
        },

        "& .desc": {
            sm: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
            }
        },
        "& .desc:active": {
            display: 'block',
        },
        "& .title": {
            sm: { fontSize: '1.2rem' }, md: { fontSize: '1.5rem' }
        }
    }
}

const CardHome = ({ title, desc, thumb, href }) => {

    const { card } = styles;

    const goTo = (href) => {
        if (href) {
            window.open(href);
        }
    }

    return (
        <>
            <Card sx={card}>
                <CardActionArea className='cardBox'>
                    <CardMedia
                        className="imgCard"
                        component="img"
                        image={thumb}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography className='title' gutterBottom variant="h5" component="div" style={{ textAlign: 'initial' }}>
                            {title}
                        </Typography>
                        <Typography className='desc' variant="body2" color="text.secondary">
                            {desc}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" variant='outlined' className='cardBtn' onClick={() => goTo(href)}>
                        Más información
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}

export default CardHome;