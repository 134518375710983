import { SvgIcon } from "@mui/material";

const BankIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.5457 6.0358C28.8453 6.14969 29 6.3626 29 6.67454V7.19444C29 7.65987 28.7728 7.89259 28.3185 7.89259H27.1875V8.59074C27.1875 9.05618 26.9603 9.2889 26.506 9.2889H2.494C2.03967 9.2889 1.8125 9.05618 1.8125 8.59074V7.89259H0.6815C0.227167 7.89259 0 7.65987 0 7.19444V6.67454C0 6.36755 0.149833 6.15464 0.454333 6.0358L13.6493 0.113883C13.9152 0.0346601 14.1955 0 14.5 0C14.8045 0 15.0848 0.0396115 15.3507 0.113883L28.5457 6.03085V6.0358ZM28.0913 24.1383C28.3572 24.1383 28.5747 24.2274 28.7438 24.4007C28.913 24.574 29 24.7968 29 25.0691V25.5346C29 25.8416 28.8502 26 28.5457 26H0.454333C0.149833 26 0 25.8465 0 25.5346V25.0691C0 24.7968 0.087 24.574 0.256167 24.4007C0.425333 24.2274 0.642833 24.1383 0.908667 24.1383H1.81733V21.816C1.81733 21.4298 1.94783 21.0981 2.21367 20.8307C2.4795 20.5584 2.7985 20.4247 3.1755 20.4247H5.44233V10.2099H7.25483V20.4197H10.8798V10.2099H12.6923V20.4197H16.3173V10.2099H18.1298V20.4197H21.7548V10.2099H23.5673V20.4197H25.8342C26.2112 20.4197 26.5302 20.5534 26.796 20.8257C27.0618 21.0981 27.1923 21.4249 27.1923 21.8111V24.1333H28.101L28.0913 24.1383ZM25.375 7.42716V6.61512L14.7272 1.91621C14.6498 1.87659 14.5773 1.85679 14.5 1.85679C14.4227 1.85679 14.3502 1.87659 14.2728 1.91621L3.625 6.61512V7.42716H25.375ZM3.625 22.2815V24.1383H25.375V22.2815H3.625Z" fill="#E4002B"/>
            </svg>
        </SvgIcon>
    )
}

export default BankIcon;