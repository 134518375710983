import { Backdrop, Box, Button, CircularProgress, Container, List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import HeaderLogin from "../../componentes/NavbarLogin/NavbarLogin";
import useIsMobile from "../../Utils/useIsMobile";
import useQuery from "../../Utils/useQuery";
import RequestApi from "../../Request-api/RequestApi";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const UploadForm = () => {
    const history = useHistory();
    const [files, setFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const { isMobile } = useIsMobile();
    const query = useQuery();
    const [state, setState] = useState({
        idForm: "",
        idTipoForm: "",
        idEdns: "",
        descripcionRechazo: ""
    })

    const renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
    
    const handleUploadFile = (e) => {
        const timestamp = new Date().getTime();
        const fileRenamed = renameFile(e.target.files[0], `RESUBIDO-${state.idEdns}-${timestamp}`) 
        setFile([...files, fileRenamed]);
    }

    const handleSubmit = () => {
        if(files.length) {
            const multiPartFiles = new FormData();
            files.forEach(file => {
                multiPartFiles.append('file', file)
            })
            RequestApi.uploadAWSFile(multiPartFiles)
            .then(r => {
                if(r.estado === 0){
                    RequestApi.validationResend(files.map(file => file.name).join(";"), state.idEdns, query.get('token'))
                    .then(res => {
                        setLoading(false)
                        if(res.estado !== 113) {
                          Swal.fire({
                            title:"Documentación enviada",
                            text:"Tu documentación fue enviada y será revisada dentro de las 24hr. Se te enviará una notificación con el estado de tu activación.",
                            timer: 10000,
                            willClose: history.push('/login')
                          })
                        } else {
                            throw new Error(res.mensaje);
                        }
                    }).catch(err => {
                        setLoading(false)
                        Swal.fire({
                            title: "Error al enviar la solicitud",
                            text: "Estamos teniendo problemas al procesar esta solicitud, por favor intente nuevamente más tarde.",
                            icon: "error",
                            willClose: history.push('/login')
                        })
                    });
                }
            }).catch(err => {
                setLoading(false)
                Swal.fire({
                    title: "Error al enviar la solicitud",
                    text: "Estamos teniendo problemas al procesar esta solicitud, por favor intente nuevamente más tarde.",
                    icon: "error",
                    timer: 10000,
                    willClose: history.push('/login')
                })
            });
        } else {
            //Por favor, cargue frente y dorso del documento
        }
    }

    useEffect(()=> {
        RequestApi.resendForm(query.get('token'))
        .then(data => {
            setState({
                ...data
            })
        })
        .catch(err => {
            Swal.fire({
                title: "Esta acción expiró",
                text: "Por favor, vuelve a intentar de hacer el proceso nuevamente.",
                icon: "error",
                timer: 10000,
                willClose: history.push('/login')
            })
        })
    },[])

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {isMobile && <HeaderLogin />}
            <Container sx={{pt: "5rem"}}>
                <Box>
                    <Typography>
                        Por favor, cargue nuevamente los documentos solicitados.
                    </Typography>
                    <Typography>
                        Motivo de rechazo:
                    </Typography>
                    <List>
                        {state.descripcionRechazo.split(";").map(item => <ListItem>{item}</ListItem>)}
                    </List>
                </Box>
                <Box display="flex" justifyContent="center" flexWrap="wrap">
                    {files.map(file =><img alt='docimage' style={{ width:'100%', margin:'0', height:"100%", objectFit:'cover', maxWidth: "300px", maxHeight: "200px"}} src={URL.createObjectURL(file)} />)}
                </Box>
                <Box display="flex" justifyContent="center">
                    <input
                        style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        accept="image/x-png,image/jpg,image/jpeg"
                        onChange={handleUploadFile}
                    />
                    <Button
                        htmlFor="raised-button-file"
                        className="shapefile-icon"
                        component="label"
                    >
                        Subir imágen
                    </Button>
                </Box>
                <Box>
                    <Button onClick={handleSubmit}>
                        Confirmar
                    </Button>
                </Box>
            </Container>
        </>
    )
}
export default UploadForm;