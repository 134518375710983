import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FieldCustom from '../../../../../../UI/FieldCustom/FieldCustom';
import useGender from '../../../../../../Utils/useGender';
import useNationality from '../../../../../../Utils/useNationality';
import useOS from '../../../../../../Utils/useOS';
import ToolTipOS from '../../../../../Register/Forms/DatosGenerales/ToolTIp/ToolTip';
import RequestApi from '../../../../../../Request-api/RequestApi';

const EditarFamiliar = () => {
    const [familiar, setFamiliar] = useState("");
    const nationality = useNationality();
    const gender = useGender();
    const OS = useOS();
    const [tipoPlanes, setTipoPlanes] = useState([]);
    const { familiares } = useSelector(state => state.grupoFamiliar);
    const { dni } = useParams();



    const handleConfirmar = (values) => {
        
    }

    const handleSelectPlan = (e) => {
        const {value} = e.target;
        RequestApi.getTipoPlan(value)
        .then((tipoPlanes) => {
            setTipoPlanes(tipoPlanes?.map((p) => {
                return { name: p.gamaDesc, value: p.gamaPk };
            }));
        });
    }

    useEffect(() => {
        let filter = familiares.filter(fam => fam.nroDoc === parseInt(dni))
        if(filter.length) {
            setFamiliar(filter[0])
        }
    },[])

    return (
        <>
            <Formik
                initialValues={{
                    nombre: familiar.nombre,
                    apellido: familiar.apellido1,
                    fechaNac: familiar.fechaNac,
                    docNum: familiar.nroDoc,
                    docType: familiar.tipoDoc,
                    genero: familiar.genero,
                    nacionalidad: familiar,
                    provincia: familiar,
                    ciudad: familiar,
                    codPostal: familiar,
                    direccion: familiar.domDireccion,
                    obraSocial: familiar.planPk,
                    tipoPlan: familiar.gamaPk,
                    otro_obraSocial: familiar,
                    otro_tipoPlan: familiar
                }}
                onSubmit={handleConfirmar}
            >
                {
                    ({ errors, touched, values, handleChange }) => (
                        <Form>
                            <Grid container>
                                <Grid xs={12} sm={6} item sx={{px:'1rem'}}>
                                    <FieldCustom 
                                        id="nombre"
                                        name="nombre"
                                        placeholder='Ingresá el nombre'
                                        label='Nombre'
                                        value={values.nombre}
                                        onChange={handleChange}
                                        error={touched.nombre && Boolean(errors.nombre)}
                                        helperText={touched.nombre && errors.nombre}
                                    />
                                    <FieldCustom 
                                        id="apellido"
                                        name="apellido"
                                        placeholder='Ingresá el apellido'
                                        label='Apellido'
                                        value={values.nombre}
                                        onChange={handleChange}
                                        error={touched.nombre && Boolean(errors.nombre)}
                                        helperText={touched.nombre && errors.nombre}
                                    />
                                    <FieldCustom 
                                        id="fechaNac"
                                        name="fechaNac"
                                        placeholder='Ingresá su fecha de nacimiento'
                                        label='Fecha de nacimiento'
                                        value={values.fechaNac}
                                        onChange={handleChange}
                                        error={touched.fechaNac && Boolean(errors.fechaNac)}
                                        helperText={touched.fechaNac && errors.fechaNac}
                                    />
                                    <FieldCustom
                                        id="nacionalidad"
                                        name="nacionalidad"
                                        placeholder='Seleccionar'
                                        label='Nacionalidad'
                                        variant='select'
                                        options={nationality?.map((item)=> {return {value: item.nacionalidadPk, name: item.nacionalidadDesc}})}
                                        value={values.nacionalidad}
                                        onChange={handleChange}
                                        error={touched.nacionalidad && Boolean(errors.nacionalidad)}
                                    />
                                    <FieldCustom
                                        id="provincia"
                                        name="provincia"
                                        placeholder='Ingresá su provincia'
                                        label='Provincia'
                                        value={values.provincia}
                                        onChange={handleChange}
                                        error={touched.provincia && Boolean(errors.provincia)}
                                        helperText={touched.provincia && errors.provincia}
                                    />
                                    <FieldCustom
                                        id="ciudad"
                                        name="ciudad"
                                        placeholder='Ingresá su ciudad'
                                        label='Ciudad'
                                        value={values.ciudad}
                                        onChange={handleChange}
                                        error={touched.ciudad && Boolean(errors.ciudad)}
                                        helperText={touched.ciudad && errors.ciudad}
                                    />
                                    <FieldCustom
                                        id="codPostal"
                                        name="codPostal"
                                        placeholder='Ingresá su código postal'
                                        label='Código postal'
                                        value={values.codPostal}
                                        onChange={handleChange}
                                        error={touched.codPostal && Boolean(errors.codPostal)}
                                        helperText={touched.codPostal && errors.codPostal}
                                    />
                                    <FieldCustom
                                        id="direccion"
                                        name="direccion"
                                        placeholder='Ingresá su dirección'
                                        label='Dirección'
                                        value={values.direccion}
                                        onChange={handleChange}
                                        error={touched.direccion && Boolean(errors.direccion)}
                                        helperText={touched.direccion && errors.direccion}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item container sx={{px:'1rem'}}>
                                    <FieldCustom
                                        id="obraSocial"
                                        name="obraSocial"
                                        placeholder='Seleccionar'
                                        label={<><ToolTipOS /> Obra Social / Prepaga</>}
                                        variant='select'
                                        options={OS?.map((p) => {return { name: p.planDesc, value: p.planPk }})}
                                        value={values.obraSocial}
                                        onChange={(e)=>{
                                            handleChange(e);
                                            handleSelectPlan(e);
                                        }}
                                        error={touched.obraSocial && Boolean(errors.obraSocial)}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}>
                                    {values.obraSocial === (-1) && (
                                        <>
                                            <FieldCustom 
                                                id="otro_obraSocial"
                                                name="otro_obraSocial"
                                                placeholder='Ingresá tu obra social'
                                                label='¿Cuál?'
                                                value={values.otro_obraSocial}
                                                onChange={handleChange}
                                                error={touched.otro_obraSocial && Boolean(errors.otro_obraSocial)}
                                                helperText={touched.otro_obraSocial && errors.otro_obraSocial}
                                            />
                                        </>
                                    )} 
                                        <FieldCustom
                                            id="tipoPlan"
                                            name="tipoPlan"
                                            placeholder='Seleccionar'
                                            label={<><ToolTipOS /> Tipo de plan</>}
                                            variant='select'
                                            options={tipoPlanes}
                                            value={values.tipoPlan}
                                            onChange={handleChange}
                                            error={touched.tipoPlan && Boolean(errors.tipoPlan)}
                                        />
                                    {values.tipoPlan === (-1) && (
                                            <FieldCustom 
                                                id="otro_tipoPlan"
                                                name="otro_tipoPlan"
                                                placeholder='Ingresá tu tipo de plan'
                                                label='Tipo de plan'
                                                value={values.otro_tipoPlan}
                                                onChange={handleChange}
                                                error={touched.otro_tipoPlan && Boolean(errors.otro_tipoPlan)}
                                                helperText={touched.otro_tipoPlan && errors.otro_tipoPlan}
                                            />
                                        )
                                    }
                                    <FieldCustom
                                            id="genero"
                                            name="genero"
                                            placeholder='Seleccionar'
                                            label='Género'
                                            variant='select'
                                            options={gender?.map((item)=> {return {value: item.value, name: item.name}})}
                                            value={values.genero}
                                            onChange={handleChange}
                                            error={touched.genero && Boolean(errors.genero)}
                                        />
                                </Grid>       
                            </Grid>
                        </Form>
                    )
                
                } 
            </Formik>
        </>
    )
}

export default EditarFamiliar;