import React, {useState, useEffect} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RequestApi from "../../../Request-api/RequestApi"
import { useSelector } from "react-redux";
import useIsMobile from "../../../Utils/useIsMobile";

export default function SelectNotificacion() {
    const datosLogin = useSelector((state) => state.authLogin);
  const { isMobile } = useIsMobile();
  const [notificacionesQueLlegan, setNotificacionesQueLlegan] = useState();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = useState(false);
  const handleClickListItem = (event) => {
    setNotificacionesQueLlegan(event.currentTarget);
  };

  const handleMenuItemClick = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  useEffect(() => {
    RequestApi.notificaciones( datosLogin.idEDNS
).then(response => {
        setNotificacionesQueLlegan(response);
        
    })
}, [])

  return (
    <div >
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: "background.paper" }}
      >
        <ListItem
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event) => handleMenuItemClick(event)}
        >
            <Badge badgeContent={notificacionesQueLlegan && notificacionesQueLlegan.length} color="primary">
      <NotificationsActiveIcon color="action" primary="When device is locked" />
    </Badge>
        </ListItem>
      </List>
      <Menu 
      
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{ horizontal: isMobile ? ('left') : ('right'), vertical:  'top' }}
        transformOrigin={{
          horizontal: isMobile ? ('left') : ('right'), vertical:  'top'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {notificacionesQueLlegan && notificacionesQueLlegan.map((notificacion, index) => (
          <MenuItem
            key={notificacion.observacion}
            disabled={index === 0}
            selected={index === selectedIndex}
          >
            {notificacion.observacion}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}