import { createRef, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import SelectCustom from "../../../../UI/SelectCustom/SelectCustom";
import TextFieldCustom from "../../../../UI/TextFieldCustom/TextFieldCustom";
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom";
import EmailIcon from '@mui/icons-material/Email';
import RequestApi from '../../../../Request-api/RequestApi';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { RECAP_KEY } from '../../../../constants';
import ReCAPTCHA from "react-google-recaptcha";

const SendEmail = ({chOption, docs, setLoading, getTipoDoc, tokenRecaptcha}) => {
    const history = useHistory();
    const recaptchaInstance = createRef();
    const [step, toggleStep] = useState(true);
    const [personId, setPersonId] = useState();
    const [email, setEmail] = useState();
    const [state, setState] = useState({
        docType: getTipoDoc(2),
        docNum: ""
    })

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const sendPinToEmail = () => {
        RequestApi.forgotService({
            nroDoc: state.docNum,
            tipoDoc: state.docType
        }, tokenRecaptcha).then(r => {
            setLoading(false);
            if(r.estado === 0) {
                setEmail(r.data);
                toggleStep(false)
            } else {
                //error
            }
        })
    }

    const handleInicio = () => {
        chOption()
    }

    const handleRecaptcha = (token) => {
        if(token) {
            RequestApi.getProductos(state.docType.value, state.docNum, token)
            .then(r => {
                const { estado, pId } = r;
                setPersonId(pId)
                if(estado === 0) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'No registrado',
                        text: 'El documento indicado no cuenta con ningún registro, podés registrarte con el siguiente botón:',
                        confirmButtonText: 'Registrarme'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/register')
                        }
                      })
                } else if (estado === 1 || estado === 2) {
                    sendPinToEmail()
                } else if (estado === 3) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'info',
                        title: 'Redireccionamiento',
                        text: 'Por favor, ingrese por nuestra web de socios',
                        confirmButtonText: 'Ir'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.open('https://www.swissmedical.com.ar/prepagaclientes/login')
                        }
                    })
                }
            })
        }
    }

    const handleSendLink = () => {
        setLoading(true)
        recaptchaInstance.current.execute()
    }

    return (
        <>
            <Box display='flex' justifyContent='center' flexDirection='column'>
                { step ? (
                        <>
                            <SelectCustom 
                            name='docType'
                            label="Tipo de documento"
                            options={docs?.map(d => {return {name: d.name, value: d.id}})}
                            onChange={handleOnChange}
                            defaultValue={2}
                            ></SelectCustom>
                            <div>
                                <p className="textInputs" style={{marginLeft:'1.5rem'}}>N° de documento</p>
                                <TextFieldCustom placeholder="Ingresá el número de documento" name='docNum' value={state.docNum} onChange={handleOnChange} ></TextFieldCustom>
                            </div>
                            <ButtonCustom onClick={handleSendLink} width={"95%"}  name="Siguiente" />
                            <Button onClick={handleInicio} sx={{color:'#E4002B', textTransform:'initial'}}>Volver al inicio de sesión</Button>
                        </>
                    ) : (
                        <>
                            <Box>
                                <Typography>Envío de link</Typography>
                                <EmailIcon fontSize="large" sx={{color:"#b9b6b6", backgroundColor:'#ebebeb', p:'1rem', borderRadius:'50%' }}/>
                                <Typography>Te enviamos un email a <span>{email}</span> con el link para el cambio de tu contraseña</Typography>
                                <Typography>Si este no es tú mail o ya no tenés acceso, por favor completá el siguiente formulario: <Button onClick={()=>{history.push(`/recuperar-email?pid=${personId}`)}}>Recuperar email</Button></Typography>
                                <Divider />
                                <Typography>Recordá revisar tambien tú bandeja de correo no deseado</Typography>
                            </Box>
                        </>
                    )
                }
            </Box>
            <ReCAPTCHA
                ref={recaptchaInstance}
                sitekey={RECAP_KEY}
                size="invisible"
                onChange={handleRecaptcha}
            />
        </>
    )
}

export default SendEmail;