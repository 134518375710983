import React, { useState, useEffect } from "react";
import "./VisualizarTurno.css";
import "./visualizarTurnoDesktop.css";
import "../modalIndicaciones/ModalIndicaciones";
import ModalIndicaciones from "../modalIndicaciones/ModalIndicaciones";
import UnTurnoPara from "../Items/UnTurnoPara";
import DividerCard from "../../../../../../../componentes/DividerCard/DividerCard";
import TextFieldCustom from "../../../../../../../UI/TextFieldCustom/TextFieldCustom";
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom";
import TurnoCard from "../Items/TurnoCard";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../../../../../../Utils/useIsMobile";
import RequestApi from "../../../../../../../../src/Request-api/RequestApi";

const VisualizarTurno = () => {
  const history = useHistory();
  const selectedTurno = useSelector((state) => state.clientes.selectedTurno);
  const datosLogin = useSelector((state) => state.authLogin);
  const [openIndicaciones, setOpenIndicaciones] = useState(false);
  const [textoPrepaciones, setTextoPreparaciones] = useState("");
  const { isMobile } = useIsMobile();
  const handleIndicasiones = () => {
    RequestApi.getPreparaciones(
      /* data.prac, */
      datosLogin.plan.gamaPk,
      datosLogin.plan.planPk,
      selectedTurno.apimToken
    ).then((response) => {
      setTextoPreparaciones(response.preparacion.prestacion.descripcion);
    });
  };
  
  const handleModalIndicaciones = () => {
    setOpenIndicaciones(true)
  }

  const handleVolver = () => {
    history.push("/pacientes/turnos");
  };

  useEffect(() => {
    handleIndicasiones();
  }, []);

  return (
    <div className={isMobile ? "visualizarTurno" : "visualizarTurnoDesktop"}>
      <div className={isMobile ? "parteDeArriva" : "parteDeArrivaDesktop"}>
        <p>
          <b>
            <u>Inicio</u>
          </b>
          <img
            style={{ marginRight: "10px", marginLeft: "10px" }}
            alt="img"
            src="../../images/flechaNegraDerecha.svg"
          ></img>
          <b>Turnos Medicos</b>
          <img
            style={{ marginRight: "10px", marginLeft: "10px" }}
            alt="img"
            src="../../images/flechaNegraDerecha.svg"
          ></img>
          <b>Detalle de turno</b>
          <div /* className={isMobile ? "unTurnoPara" : "unTurnoParaDesktop"} */
          >
            {/* <DetallesConfirmar/> */}
            <UnTurnoPara />
          </div>
        </p>
      </div>
      <div className={isMobile ? "modal" : "modalDesktop"}>
        <div>
          <u className={isMobile ? "colorLetra" : "colorLetraDesktop"}>
            {textoPrepaciones && (
              <ModalIndicaciones textoPreparaciones={textoPrepaciones} />
            )}
          </u>
        </div>
      </div>

      <div className={isMobile ? "card" : "cardDesktop"}>
        <p>
          <TurnoCard
            verBotonBorrar={false}
            verBotonDetalle={true}
            nombreDoc={selectedTurno.nombreDoc}
            especialidad={selectedTurno.especialidad}
            fechaYHora={selectedTurno.fechaYHora}
            lugarDeAtencion={selectedTurno.lugarDeAtencion}
            direccion={selectedTurno.direccion}
            consulta_id={selectedTurno.consulta_id}
          />

          {/* <div className={isMobile ? "linea" : "lineaDesktop"}>
            <DividerCard className={isMobile ? "linea" : "lineaDesktop"} />
          </div> */}
        </p>
      </div>
      <div className={isMobile ? "texto" : "textoDesktop"}>
        <p style={{ fontWeight: "500", fontSize: "15px", color: "#363636" }}>
          En caso de modificarse el horario y/o el día de tu turno nos
          contactaremos a:
        </p>
      </div>
      <div className={isMobile ? "textInputs" : "textInputsDesktop"}>
        <p>Correo electrónico</p>
        <TextFieldCustom
          minWidth="270px"
          placeholder="susanaperez@gmail.com"
        ></TextFieldCustom>
        <p /* className={isMobile ? "textInputs" : "textInputsDesktop"} */>
          Teléfono
        </p>
        <TextFieldCustom
          minWidth="270px"
          type="password"
          placeholder="+5491187465874"
        ></TextFieldCustom>

        <div className={isMobile ? "button" : "buttonDesktop"}>
          <ButtonCustom
            className="button"
            primaryColor="#E4002B"
            color="#FFF"
            onClick={handleVolver}
            width="200px"
            name={"Volver"}
          />
        </div>
      </div>
    </div>
  );
};

export default VisualizarTurno;
