import { Avatar, Box, Divider, Grid, List, ListItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import ItemMenu from "./ItemMenu/ItemMenu";
// import CustomizedButtons from "../../../../../UI/ButtonCustom/ButtonCustom";
import Breadcrumb from "../../../../../componentes/Breadcrumb/Breadcrumb";
import { useEffect, useRef } from "react";
import RequestApi from "../../../../../Request-api/RequestApi";
import { setEditProfile } from "../../../../../Redux/Reducers/editProfile";

const paths = [
  {
    title: "Contraseña",
    path: "perfil/editar/password",
    icon: "Password",
  },
  {
    title: "Domicilio",
    path: "perfil/editar/location",
    icon: "Location",
  },
  {
    title: "Email/Tel",
    path: "perfil/editar/email",
    icon: "Email",
  },
  {
    title: "Mi Plan",
    path: "perfil/editar/plan",
    icon: "Plan",
  },
];

const Perfil = () => {
  const dispatch = useDispatch();
  const { nombre, apellido, fechaNac, idEDNS } = useSelector(
    (state) => state.authLogin
  );
  const { domicilio, contacto, prepaga } = useSelector((state) => state.editProfile);
  const auxRef = useRef(null);
  var month_diff = Date.now() - new Date(fechaNac);
  var age_dt = new Date(month_diff);
  var year = age_dt.getUTCFullYear();
  var age = Math.abs(year - 1970);

  useEffect(() => {
    if (!auxRef.current) {
      dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: true });
      RequestApi.getProfile(idEDNS).then((response) => {
        dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: false });
        dispatch(
          setEditProfile("domicilio", {
            ...domicilio,
            nacionalidad: response.nacionalidad,
            provincia: response.provincia,
            ciudad: response.ciudad,
            direccion: response.direccion,
            codPostal: response.codigoPostal,
          })
        );
        dispatch(
          setEditProfile("contacto", {
            ...contacto,
            telefono1: response.telefono,
            email: response.email,
          })
        );
        dispatch(
          setEditProfile("prepaga", {
            ...prepaga,
            gamaDesc: response.gamaDesc,
            planDesc: response.planDesc,
            planEspecial: response.planEspecial,
            gamaEspecial: response.gamaEspecial,
          })
        );
      });
      auxRef.current = true;
    }
    return () => (auxRef.current = true);
  }, []);

  return (
    <>
      <Grid container sx={{ mt: "2rem" }}>
        <Grid
          item
          xs={12}
          sx={{ padding: { xs: "1rem", md: "1rem 0 1rem 2.5rem" } }}
        >
          <Breadcrumb location="Perfil" />
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            alt="Me"
            sx={{
              width: 112,
              height: 112,
              bgcolor: "#fff",
              color: "#e1e1e1",
              my: 1,
            }}
          >
            <AccountCircleIcon
              sx={{ width: "100%", height: "100%", border: 0 }}
            />
          </Avatar>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <List>
            <ListItem>
              <Box sx={{ fontWeight: 900, color: "#585858" }}>
                Nombre y apellido:
              </Box>
              &nbsp;&nbsp;{nombre} {apellido}
            </ListItem>
            <ListItem>
              <Box sx={{ fontWeight: 900, color: "#585858" }}>Edad:</Box>
              &nbsp;&nbsp;{age}
            </ListItem>
            <ListItem>
              <Box sx={{ fontWeight: 900, color: "#585858" }}>Dirección:</Box>
              &nbsp;&nbsp;{domicilio.direccion}
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <List>
            <ListItem>
              <Box sx={{ fontWeight: 900, color: "#585858" }}>Teléfono:</Box>
              &nbsp;&nbsp;{contacto?.telefono1}
            </ListItem>
            <ListItem>
              <Box sx={{ fontWeight: 900, color: "#585858" }}>Email:</Box>
              &nbsp;&nbsp;{contacto?.email}
            </ListItem>
            <ListItem>
              <Box sx={{ fontWeight: 900, color: "#585858" }}>Mi Plan:</Box>
              &nbsp;&nbsp;{`${prepaga?.planDesc} - ${prepaga?.gamaDesc}`}
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: "2rem" }}>Modificar datos</Divider>
        </Grid>
        {paths.map((i) => {
          return <ItemMenu icon={i.icon} path={i.path} name={i.title} />;
        })}
      </Grid>
    </>
  );
};

export default Perfil;
