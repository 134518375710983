import { AppBar, Box, Toolbar } from '@mui/material';


const NavbarLogin = () => {
  const logo = "/images/logos/edcm-smg-logo-20241202.jfif";

  return (
      <AppBar color="default" position="fixed">
        <Toolbar>
          <Box 
            display='flex'
            width='100%'
            justifyContent="center"
            alignItems="center">
              <img style={{ py: '15px' }} src={logo} width="150" height="60" alt='Swiss Medical Center'></img>
          </Box>
        </Toolbar>
      </AppBar>
  );
}

export default NavbarLogin;