import * as Yup from 'yup';

const ERR_REQUIRED = 'Este campo es requerido';

const FormSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Ingrese un nombre válido')
      .max(50, 'Ingrese un nombre válido')
      .required(ERR_REQUIRED),
    lastName: Yup.string()
      .min(2, 'Ingrese un apellido válido')
      .max(50, 'Ingrese un apellido válido')
      .required(ERR_REQUIRED),
    email: Yup.string().email('Ingrese un email válido').required(ERR_REQUIRED),
    docType: Yup.string().required(ERR_REQUIRED),
    docNum: Yup.string().min(6, 'Ingrese un DNI válido').max(9, 'Ingrese un DNI válido').required(ERR_REQUIRED),
    tel: Yup.string().min(5, 'Ingrese un teléfono válido').max(15, 'Ingrese un teléfono válido').required(ERR_REQUIRED),
    prepaga: Yup.string().required(ERR_REQUIRED),
    sede: Yup.string().required(ERR_REQUIRED),
    credential: Yup.string().required(ERR_REQUIRED)
});

/* 
{
  docType: '',
  docNum: '',
  name: '',
  lastName: '',
  tel: '',
  prepaga: '',
  email: '',
  sede: '',
  credential:''
} */

export default FormSchema