import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Drawer, CssBaseline, Toolbar, IconButton } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NavLogo from './components/NavLogo';
import NavMenuMobile from './components/NavMenuMobile';
import { useState } from 'react';


let drawerWidth = '85vw';
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const style = {
  display: 'flex',
  "& .MuiToolbar-root": {
      paddingTop:"5px",
      paddingBottom:"5px",
  },
  "& .navContainer": {
      padding: "1rem",
      height: "4.6rem",
      backgroundColor: '#fff'
  },
  "& .MuiTabs-scroller": {
      backgroundColor: 'red'
  },
  "& .navButtonsContainer": {
      justifyContent: "flex-end"
  },
}

const NavMobile = () => {
  
  const [open, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };


  return (
    <Box sx={style}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar className='navContainer'>
          <NavLogo/>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggleDrawer}
            edge="end"
            sx={{ ml: 2, position:'absolute', right:'2rem', color:'#000'}}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            paddingLeft: "3rem",
            paddingTop: "7rem",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <NavMenuMobile handleDrawerClose={handleToggleDrawer} />
      </Drawer>
      <Box hidden={!open} sx={{position: 'fixed', backgroundColor: 'rgba(0,0,0,.3)', height: '100vh', width: '100vw'}}>
        <IconButton onClick={handleToggleDrawer} sx={{top: '5rem', left: '2vw' , color:'#fff'}}>
            <CloseIcon fontSize='large' />
        </IconButton>
      </Box>
    </Box>
  );
}

export default NavMobile;