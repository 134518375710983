import { Typography, Box, TextField, styled } from "@mui/material";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiFormHelperText-root": {
    position: "relative",
    color: "red",
    fontSize: "13.5px",
  },
}));

const CustomDateInput = ({ onChange, label, errorText = "" }) => {
  return (
    <>
      <Box sx={{ marginBottom: "1.5rem" }}>
        <Typography>{label}</Typography>
        <CustomTextField
          onChange={onChange}
          type="date"
          name="fechaNac"
          fullWidth
          helperText={errorText}
        />
      </Box>
    </>
  );
};

export default CustomDateInput;
