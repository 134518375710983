import React from 'react'
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom"
import {useDispatch} from "react-redux"
import { useHistory } from "react-router-dom";

const TurnoReservadoDesktop = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const handleAceptar = () => {
    history.push('/pacientes/turnos')
  }

  return (
    <div>
      <div className={"info-mobile"} style={{ margin: 20, maxHeight: "100%", maxWidth: "100%" }}><p className={"text-volver-mobile"}>{"<"} Volver</p></div>
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "20px", fontWeight: "500", color: "#363636", marginBottom: "50px" }}>¡Turnos reservado!</p>
        <img alt="img" src="../../images/Sucess.svg" />
        <p style={{ margin: "5px", fontWeight: "500", color: "#363636" }}>¡Gracias!</p>
        <p style={{ margin: "0% 10%", fontWeight: "500", color: "#363636" }}>
          Tu turno se reservó exitosamente.
          En los próximos minutos vas a recibir un
          mail con toda la información del turno.
        </p>
      </div>
      <div className="button-select-espe">
        <ButtonCustom onClick={handleAceptar} width={"100%"} name="Volver al inicio" />
      </div>
    </div>
  )
}

export default TurnoReservadoDesktop;