import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonCustom from "../../../../../../../../../../UI/ButtonCustom/ButtonCustom"
import "./ModalTurnoFilters.css"
import SelectCustom from "../../../../../../../../../../UI/SelectCustom/SelectCustom"
import RecuestApiSinToken from '../../../../../../../../../../Request-api/RecuestApiSinToken';
import Spinner from '../../../../../../../../../../UI/Spinner/Spinner'
import { useSelector } from 'react-redux';
import moment from "moment";
import TimeSlider from './TimeSlider/TimeSlider';
import GenderList from './GenderList/GenderList';
import ProfList from './ProfList/ProfList';

const style = {
  width: '70vw',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center"
};

const ModalTurnoFilters = ({ open, setOpen, getFechaTurnoDispo, data, centros }) => {
  const [spinner, setSpinner] = useState(false)
  const [prestadores, setPrestadores] = useState([])
  const [horaDesde, setHoraDesde] =useState('')
  const [horaHasta, setHoraHasta] =useState('')
  const [sexo, setSexo] =useState('')
  const [prestador, setPrestador] =useState('')
  const handleConfirmar = () => {
    getFechaTurnoDispo(undefined, sexo, prestador, horaDesde, horaHasta)
    handleClose()
  }
  const handleClose = () => {
    setOpen(false)
  }
  const datosLogin = useSelector((state) => state.authLogin);

  const getPrestadores = () => {
    RecuestApiSinToken.getPrestadores( data.practicSelected.value ,data.espeSelected.value, centros, datosLogin.plan.planPk, datosLogin.plan.gamaPk)
    .then(r => {
      setPrestadores(r[0].respuesta.prestadores.prestador)
      setSpinner(false)
    })
  }
  const handleProf = (e) => {
    setPrestador(e.target.value.value)
  }
  const handleSexo = (e) => {
    setSexo(e.target.value.value)
  }
  useEffect(() => {
    getPrestadores()
  }, [])

  
  return (
    <div>
     <Modal
      open={open}
      
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
  {spinner ? ( <Spinner />): ( 
      <Box sx={style}>
        <button className="close" onClick={handleClose}><img alt="img" src="../../images/close.svg"></img> </button>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Filtros de búsqueda
        </Typography> 
        <p>Horarios de atención</p>
        <TimeSlider />
        <ProfList onChange={handleProf} professionals={prestadores?.map(p => {return {value: p.prestad, name:p.nombre+" "+p.aperazon}})}/>
        <GenderList onChange={handleSexo} />
        <ButtonCustom width={"95%"} onClick={() => handleConfirmar()} name={"Aplicar filtros"} />
        <p onClick={handleClose} style={{ textDecoration: "underline" }}>Cancelar</p>
      </Box>)}
     </Modal> 
    </div>
  )
}
export default ModalTurnoFilters;