import NuevoTurno from './NuevoTurno/NuevoTurno'
import VerTurno from './VerTurno/VerTurno'
import ProximosTurnos from './VerTurno/proximosTurnos/ProximosTurnos'
import TurnosPrevios from './VerTurno/turnosPrevios/TurnosPrevios'
import VisualizarTurno from './VerTurno/visualizarTurno/VisualizarTurno'
import { Switch, Route } from "react-router-dom"
import SelectCenrto from './NuevoTurno/Steps/SelectCentro/SelectCenrto'
import SelectEspePrac from './NuevoTurno/Steps/SelectEspePrac/SelectEspePrac'

const TurnosMedicos = () => {


    return (
        <>
            <Switch>
                <Route exact path="/pacientes/turnos" component={VerTurno} />
                <Route exact path="/pacientes/turnos/nuevo" component={NuevoTurno} />
                <Route exact path="/pacientes/turnos/nuevo/SelectEspePrac" component={SelectEspePrac} />
                <Route exact path="/pacientes/turnos/nuevo/selectEspePrac/selectCenrto" component={SelectCenrto} />
                <Route exact path="/pacientes/turnos/ver" component={VisualizarTurno} />
                <Route exact path="/pacientes/turnos/ver/proximos" component={ProximosTurnos} />
                <Route exact path="/pacientes/turnos/ver/previos" component={TurnosPrevios} />
            </Switch>
        </>
    )
}

export default TurnosMedicos;