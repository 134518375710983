import { SvgIcon } from "@mui/material";

function ShareIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M12.8571 10.875C12.1759 10.875 11.5497 11.1041 11.0554 11.4872L7.96713 9.61058C8.05859 9.20837 8.05859 8.7916 7.96713 8.38939L11.0554 6.51281C11.5497 6.89593 12.1759 7.125 12.8571 7.125C14.4548 7.125 15.75 5.86579 15.75 4.3125C15.75 2.75921 14.4548 1.5 12.8571 1.5C11.2595 1.5 9.96429 2.75921 9.96429 4.3125C9.96429 4.52221 9.98809 4.72646 10.0329 4.92308L6.94456 6.79966C6.45028 6.41657 5.82413 6.1875 5.14286 6.1875C3.54519 6.1875 2.25 7.44671 2.25 9C2.25 10.5533 3.54519 11.8125 5.14286 11.8125C5.82413 11.8125 6.45028 11.5834 6.94456 11.2003L10.0329 13.0769C9.98722 13.2774 9.96422 13.4822 9.96429 13.6875C9.96429 15.2408 11.2595 16.5 12.8571 16.5C14.4548 16.5 15.75 15.2408 15.75 13.6875C15.75 12.1342 14.4548 10.875 12.8571 10.875Z" fill="#363636"/>
      </SvgIcon>
    );
  }

export default ShareIcon;