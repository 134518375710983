import useWindowDimensions from "./useWindowDimensions"
import {BREACKPOINT_MOBILE} from "../constants"
const useIsMobile = () => {
    const { width } = useWindowDimensions();
    const isMobile = width < BREACKPOINT_MOBILE
    return {isMobile}
}

export default useIsMobile

