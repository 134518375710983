import { SvgIcon } from "@mui/material";

function GuardiaIcon(props) {
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46 2.59593V15.9514C46 16.6931 45.6292 17.0639 44.8875 17.0639H31.532C30.7903 17.0639 30.4194 16.6931 30.4194 15.9514V15.0243C30.4194 14.2826 30.7903 13.9118 31.532 13.9118H40.6227C38.9539 10.6343 36.5434 8.02331 33.3912 6.07386C30.239 4.12441 26.7761 3.1522 23.0025 3.1522C17.4999 3.1522 12.8193 5.10165 8.95043 8.99553C5.08661 12.8894 3.1522 17.5601 3.1522 22.9975C3.1522 28.4349 5.10165 33.1858 8.99553 37.0496C12.8894 40.9134 17.5601 42.8478 22.9975 42.8478C28.0691 42.8478 32.4892 41.1489 36.2578 37.7462C36.814 37.3152 37.3402 37.3452 37.8364 37.8414L38.4879 38.4929C39.0441 39.0491 39.0141 39.5753 38.3926 40.0715C34.0026 44.0255 28.8709 46.005 22.9975 46.005C16.628 46.005 11.2056 43.7649 6.72034 39.2797C2.24011 34.7944 0 29.367 0 22.9975C0 18.858 1.02233 15.0193 3.06199 11.4962C5.10165 7.9732 7.88299 5.17682 11.406 3.10709C14.9291 1.03737 18.7678 0 22.9073 0C27.0467 0 30.9757 1.02233 34.4987 3.06199C38.0218 5.10165 40.8031 7.88299 42.8478 11.411V2.59593C42.8478 1.85423 43.2187 1.48339 43.9603 1.48339H44.8875C45.6292 1.48339 46 1.85423 46 2.59593ZM14.6534 30.1388C14.2225 29.5224 14.2826 28.9962 14.8389 28.5602L21.5141 23.7392V10.0179C21.5141 9.27617 21.885 8.90533 22.6266 8.90533H23.3683C24.11 8.90533 24.4809 9.27617 24.4809 10.0179V25.2276L16.5979 30.9757C15.9815 31.4067 15.4553 31.3165 15.0193 30.6951L14.6484 30.1388H14.6534Z" fill="#E4002B"/>
        </svg>
      </SvgIcon>
    );
  }

export default GuardiaIcon;