import { SvgIcon } from "@mui/material";

const EffectiveIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.35 0C31.7944 0 32.1816 0.164444 32.5116 0.493333C32.8372 0.822222 33 1.21333 33 1.66667V18.3333C33 18.7867 32.8372 19.1778 32.5116 19.5067C32.186 19.8356 31.7988 20 31.35 20H1.65C1.2012 20 0.814 19.8356 0.4884 19.5067C0.1628 19.1778 0 18.7867 0 18.3333V1.66667C0 1.21778 0.1628 0.826667 0.4884 0.493333C0.814 0.16 1.2012 0 1.65 0H31.35ZM31.35 13.3333V6.66667C29.9728 6.66667 28.8068 6.18222 27.8432 5.20889C26.8796 4.23556 26.4 3.05778 26.4 1.66667H6.6C6.6 3.05778 6.1204 4.23556 5.1568 5.20889C4.1932 6.18222 3.0272 6.66667 1.65 6.66667V13.3333C3.0228 13.3333 4.1932 13.8178 5.1568 14.7911C6.1204 15.7644 6.6 16.9422 6.6 18.3333H26.4C26.4 16.9467 26.8796 15.7644 27.8432 14.7911C28.8068 13.8178 29.9728 13.3333 31.35 13.3333ZM1.65 1.66667V5C2.5432 5 3.3176 4.67111 3.9688 4.00889C4.62 3.34667 4.95 2.56889 4.95 1.66667H1.65ZM1.65 18.3333H4.95C4.95 17.4311 4.6244 16.6489 3.9688 15.9911C3.3176 15.3333 2.5432 15 1.65 15V18.3333ZM12.9932 5.88444C13.9568 4.73778 15.1228 4.16444 16.5 4.16444C17.8772 4.16444 19.0432 4.73778 20.0068 5.88444C20.9704 7.03111 21.45 8.40444 21.45 10C21.45 11.5956 20.9704 12.9689 20.0068 14.1156C19.0432 15.2622 17.8772 15.8356 16.5 15.8356C15.1228 15.8356 13.9568 15.2622 12.9932 14.1156C12.0296 12.9689 11.55 11.6 11.55 10C11.55 8.4 12.0296 7.03111 12.9932 5.88444ZM14.1812 12.9422C14.8324 13.76 15.6068 14.1644 16.5 14.1644C17.3932 14.1644 18.1676 13.7556 18.8188 12.9422C19.47 12.1289 19.8 11.1467 19.8 10C19.8 8.85333 19.4744 7.87556 18.8188 7.05778C18.1676 6.24444 17.3932 5.83556 16.5 5.83556C15.6068 5.83556 14.8324 6.24444 14.1812 7.05778C13.53 7.87556 13.2 8.85333 13.2 10C13.2 11.1467 13.5256 12.1289 14.1812 12.9422ZM31.35 5V1.66667H28.05C28.05 2.56889 28.3756 3.35111 29.0312 4.00889C29.6824 4.66667 30.4568 5 31.35 5ZM31.35 18.3333V15C30.4568 15 29.6824 15.3289 29.0312 15.9911C28.38 16.6533 28.05 17.4311 28.05 18.3333H31.35Z" fill="#E4002B"/>
            </svg>
        </SvgIcon>
    )
}

export default EffectiveIcon;