import { Box, Grid, ClickAwayListener, Fade } from "@mui/material";
import { webs } from "../nav_webs.json";

const styles = {
    padding: '3rem',
    paddingTop: '1rem'
}

const NavOthersWebs = ({handleOnBlur, open}) => {

    return (
        <>
           <ClickAwayListener onClickAway={handleOnBlur}>
           <Fade in={open} timeout={500}>
                <Box sx={styles}>
                    <Grid container spacing={5}>
                        {webs.map((web) => {
                            return (
                                <Grid item xs={4} md={3} lg={2} display="flex" justifyContent='center' sx={{filter:'grayscale(100%)', cursor: 'pointer' ,"&:hover":{filter:'grayscale(0)'}}}>
                                    <a href={web.url} target="_blank" rel='noreferrer'><img src={web.icon} alt=""/></a>
                                </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
           </Fade>
           </ClickAwayListener>
        </>
    )
}

export default NavOthersWebs;