import React, { useEffect, useState } from "react";
import SelectCustom from "../../../../../../../../UI/SelectCustom/SelectCustom";
import RecuestApiSinToken from "../../../../../../../../Request-api/RecuestApiSinToken";
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom";
import "./SelectEspePrac.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Spinner from "../../../../../../../../UI/Spinner/Spinner";

const SelectEspePrac = ({ setStep, setData, data, integranteSeleccionado, setIntegranteSeleccionado }) => {
  const history = useHistory();
  const [spinner, setSpinner] = useState(true);
  const [especialidades, setEspecialidades] = useState([]);
  const [practicas, setPracticas] = useState([]);
  const [espeSelected, setEspeSelected] = useState(null);
  const [practicSelected, setPracticSelected] = useState();

  var nacimiento = moment(integranteSeleccionado.fechaNac);
  var hoy = moment();
  var anios = hoy.diff(nacimiento, "years");
  const handleVolver = () => {
    history.push("/pacientes/turnos");
  };
  const handleEspe = (e) => {
    setEspeSelected(e.target);
    RecuestApiSinToken.getPracticas(
      anios,
      integranteSeleccionado.genero,
      e.target.value,
      integranteSeleccionado.planPk,
      integranteSeleccionado.gamaPk
    ).then((response) =>
      setPracticas(
        response.map((prac) => {
          
          return { name: prac.abre, value: prac.prestac };
        })
      )
     
    );
  };
  
  const handlePrac = (e) => {
    setPracticSelected(e.target);
  };

  const getEspe = () => {
    RecuestApiSinToken.getEspecialidades(
      anios,
      integranteSeleccionado.genero,
      integranteSeleccionado.planPk,
      integranteSeleccionado.gamaPk
    ).then((response) => {
      setEspecialidades(
        response.map((esp) => {
          return { name: esp.abre, value: esp.espe, visualiza: esp.visualiza, idXhis: esp.idXhis };
        })
      );
      setSpinner(false);
    });
  };

  const handleBuscar = () => {
    setData((prev) => {
      return { ...prev, espeSelected, practicSelected, especialidades };
    });
    setStep(2);
  };
  useEffect(() => {
    getEspe();
  }, [integranteSeleccionado]);
  return (
    <>
      <div className="selectEspe">
        <div className={"info-mobile"}>
        
        </div>
        {spinner ? (
          <Spinner />
        ) : (
          <div className="selects">
            <div>
              <p className="etiquetasP"> </p>
              <p>
                <b className="especialidadselct">Especialidad/Estudio</b>
                <SelectCustom
                  name="Seleccioná especialidad"
                  /* minWidth={isMobile && widthText} */
                  options={especialidades}
                  placeholder="Seleccionar"
                  disable={!especialidades}
                  onChange={(e) => handleEspe(e)}
                ></SelectCustom>
                <b
                  style={{ float:"left" }}
                  className="tipoDeConsultaSelect"
                >
                  Tipo de consulta
                </b>
                <SelectCustom
                  className="select2"
                  name="Seleccioná tipo de práctica"
                  /* minWidth={isMobile && widthText} */
                  options={practicas}
                  /* disable={!espeSelected} */
                  placeholder="Seleccionar"
                  onChange={(e) => handlePrac(e)}
                ></SelectCustom>

                <div className="button-select-espe">
                  <ButtonCustom
                    disabled={!(espeSelected && practicSelected)}
                    onClick={handleBuscar}
                    width={"95%"}
                    name={"Buscar"}
                  />
                </div>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectEspePrac;
