import React from 'react'
import Divider from '@mui/material/Divider';
const DividerCard = () => {
return(
 
         <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <Divider  orientation="horizontal" flexItem />
            </div>
  
)
}
export default DividerCard 