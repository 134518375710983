import React, { useEffect, useState } from "react";
import moment from "moment";
import Spinner from "../../../../../../../../UI/Spinner/Spinner";
import "./selectTurnoDesktop.css";
import TurnoCard from "./Components/TurnoCard/TurnoCard";
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom";
import { textToUpperCase } from "../../../../../../../../Utils/useTextToUpperCase";
import ModalTurnoFilters from "./Components/ModalTurnoFilters/ModalTurnoFilters";
import { useSelector } from "react-redux";
import utils from "../../../../../../../../Utils/useDate";
import RequestAPI from "../../../../../../../../Request-api/RequestApi";

const SelectTurnoDesktop = ({ setStep, data, setData }) => {
  let idCentros = data.centrosSelected.toString().replace(/,/g, ";");
  let [turnosDispo, setTurnosDispo] = useState([]);
  const [ultimaFecha, setUltimaFecha] = useState("");
  const [turnos, setTurnos] = useState([]);
  const [retros, setRetros] = useState(0);
  const [spinner, setSpinner] = useState(true);
  const [centroSeleccionado, setCentroSeleccionado] = useState({});
  const [openFilters, setOpenFIlters] = useState(false);
  const [errorNoTurno, setErorrNoTurno] = useState(false);
  const datosLogin = useSelector((state) => state.authLogin);
  const SelectTurno = (turno) => {
    setData((prev) => {
      return { ...prev, turno };
    });
    setCentroSeleccionado(turno);
  };

  const handleAceptar = () => {
    if (centroSeleccionado) {
      setStep(2);
    }
  };

  const parceTurnosPorPrestador = (turnos) => {
    let turs = Array.isArray(turnos) ? turnos : [turnos];
    let agrupadoPorPrestador = [];
    turs.forEach((turn) => {
      let agrgado = false;
      agrupadoPorPrestador.forEach((p) => {
        if (p.prestador === turn.prestador) {
          p.turnosXCentro.push(turn);
          agrgado = true;
        }
      });
      if (!agrgado) {
        agrupadoPorPrestador.push({
          namePrestador: turn["nombre-abre"] + " " + turn["ape-razon"],
          prestador: turn.prestador,
          turnosXCentro: [turn],
        });
      }
    });
    return agrupadoPorPrestador;
  };

  const getTurnos = (fecha, centros, sexo, prestador, horaDesde, horaHasta) => {
    let body = {
      prestadorId: prestador || 0,
      dias: "LUN;MAR;MIE;JUE;VIE;SAB;DOM;",
      fechaDesde: fecha,
      fechaHasta: fecha,
      horaDesde: horaDesde || "00:00",
      horaHasta: horaHasta || "23:59",
      primeraVez: "",
      estadoTurno: "L",
      idCentros: centros,
      practicas: data.practicSelected.value,
      traerTurnoCompleto: "T",
      esConsultorio: true,
      sistema: "CMA",
      especialidadId: data.espeSelected.value,
      cantidad: 1,
      sexoPrestador: sexo || "",
      usuLogin:
        utils.convertDate(datosLogin.fechaNac) +
        datosLogin.docNum +
        "@swissmedical.com.ar",
      "resu-temporal": 0,
    };
    RequestAPI.getHsTurnos(body).then((res) => {
      setTurnosDispo((prev) => [
        ...prev,
        {
          fecha: fecha,
          turs: parceTurnosPorPrestador(
            res.respuesta["resultado-busqueda"].turno
          ),
        },
      ]);
      setTurnos({
        fecha: fecha,
        turs: parceTurnosPorPrestador(
          res.respuesta["resultado-busqueda"].turno
        ),
      });
      setSpinner(false);
    });
  };

  const fechaAteriorTurno = () => {
    let cantRetros = retros;
    if (turnosDispo.length > cantRetros) {
      cantRetros = cantRetros + 1;
      setTurnos(turnosDispo[turnosDispo.length - (1 + cantRetros)]);
      setRetros(cantRetros);
    }
  };

  const getFechaTurnoDispo = (fecha, sexo, prestador, horaDesde, horaHasta) => {
    setSpinner(true);
    let fechaDesde = fecha || moment().format("DD/MM/YYYY");

    let body = {
      prestadorId: prestador || 0,
      dias: "LUN;MAR;MIE;JUE;VIE;SAB;DOM;",
      fechaDesde: fechaDesde,
      horaDesde: horaDesde || "00:00",
      horaHasta: horaHasta || "23:59",
      primeraVez: "",
      estadoTurno: "L",
      idCentros: idCentros,
      practicas: data.practicSelected.value,
      traerTurnoCompleto: "T",
      esConsultorio: true,
      sistema: "CMA",
      especialidadId: data.espeSelected.value,
      cantidad: 1,
      sexoPrestador: sexo || "",
      usuLogin:
        utils.convertDate(datosLogin.fechaNac) +
        datosLogin.docNum +
        "@swissmedical.com.ar",
      "resu-temporal": 0,
    };

    RequestAPI.getFechaTurnoDispo(body).then((res) => {
      let fecha = res.respuesta["resultado-busqueda"].turno["fecha-turno"];
      setUltimaFecha(fecha);
      getTurnos(fecha, idCentros, sexo, prestador, horaDesde, horaHasta);
    });
  };
  useEffect(() => {
    getFechaTurnoDispo();
  }, []);

  const fechaSiguienteTurno = () => {
    let cantRetros = retros;
    if (cantRetros > 0) {
      cantRetros = cantRetros - 1;
      setTurnos(turnosDispo[turnosDispo.length - (1 + cantRetros)]);
      setRetros(cantRetros);
    } else {
      getFechaTurnoDispo(
        moment(ultimaFecha, "DD/MM/YYYY").add(1, "days").format("DD/MM/YYYY")
      );
    }
  };

  const styles = {
    buttonFilter: {
      display: "flex",
      margin: "10px",

      padding: "0px 10px",
    },
    img: {
      paddingRight: "10px",
    },
  };
  return (
    <div className="containerPrincipal">
     
      <div className="modalFiltro">
        <ModalTurnoFilters
          getFechaTurnoDispo={getFechaTurnoDispo}
          open={openFilters}
          setOpen={setOpenFIlters}
          data={data}
          centros={idCentros}
        />
      </div>
      
      {!spinner && (
        <div>
          <div onClick={() => setOpenFIlters(true)} style={styles.buttonFilter}>
            <img style={styles.img} src="../../images/Filtros.svg" alt="img" />
            <p>Filtros de búsqueda</p>
          </div>
          <div style={{ display: "flex", paddingLeft: "10px" }}>
            <p className="text-turno-fecha">Médicos disponibles el:</p>
            <p className="subText-turno-fecha">{turnos.fecha}</p>
            <img src="../../images/Calendar.svg" alt="img" />
          </div>
          <div>
            <div className="turno-button-back"></div>
          </div>
          <p>
        <div
          onClick={
            turnosDispo.length > 1 &&
            turnosDispo.length !== retros + 1 &&
            fechaAteriorTurno
          } style={{ color: "black",alignItems:"center",marginLeft:"40%" }}
        >
          <img src="../../images/ArrowBack.svg" alt="img" /> Anterior Turno
        </div>
        <div /* className="turno-button-next" */ style={{ color: "black",marginLeft:"40%",marginTop:"30px" }}>
          <p onClick={fechaSiguienteTurno} className={"text-siguiente"}>
            Próximo Turno <img src="../../images/ArrowNext.svg" alt="img" />
          </p>
        </div>
      </p>
        </div>
        
      )}

      <div className="container-horarios-turnos">
        {spinner ? (
          <Spinner />
        ) : (
          <div>
            {turnos.turs.map((turn) => {
              return (
                <div className="container-card-turno">
                  <p className="text-name-presta">
                    {textToUpperCase(turn.namePrestador)}
                  </p>
                  <p style={{ margin: "2px 0px" }}>{data.espeSelected.name}</p>
                  <TurnoCard
                    turnosXCentro={turn.turnosXCentro}
                    SelectTurno={SelectTurno}
                    centroSeleccionado={centroSeleccionado}
                    centros={data.centros}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>
        {!spinner && (
          <ButtonCustom
            disabled={!centroSeleccionado}
            className="reservarTurno"
            onClick={handleAceptar}
            width={"40%"}
            name={"Reservar turno"}
          />
        )}
      </div>
    </div>
  );
};

export default SelectTurnoDesktop;
