import { Box, Button, Typography } from "@mui/material";
import { formatDate } from "../../../../../../Utils/general";

const ImagenStudyCard = ({
    item
}) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        minWidth: "250px",
        height: "100%",
        position: "relative",
        border: "1px solid red",
        boxShadow: "4px 4px 5px rgb(244,61,39,0.25)",
        borderRadius: "6px",
        p: "12px 24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "4px",
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontWeight: "600", fontSize: "13.5px" }}
      >
        {item?.tipo}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontWeight: "600", fontSize: "13.5px" }}
      >
        {item?.estudio}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "600", fontSize: "13.5px" }}>
        Fecha:&nbsp;
        <Box component="span" sx={{ fontWeight: "300" }}>
          {formatDate(item?.fecha)}
        </Box>
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontWeight: "600", fontSize: "13.5px" }}
      >
        Centro de atención:&nbsp;
        <Box component="span" sx={{ fontWeight: "300" }}>
          {item?.centro}
        </Box>
      </Typography>
    </Box>
  );
};

export default ImagenStudyCard;
