import { Breadcrumbs, Typography, Link, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from 'react-router-dom';

const CustomBreadcrumb = ({links, site}) => {

  const history = useHistory();
  
  const breadcrumbs = links.map(item => {
        const {text, location} = item;
        return (
                <Link underline="hover" key="1" color="inherit" name={location} onClick={handleBackThis} >
                    {text}
                </Link>
        )
    }
  )
    
    breadcrumbs.push(
        <Typography key="3" color="text.primary">
        {site}
        </Typography>
    )

  function handleBackThis(e) {
    history.push(e.target.name);
  }

  return (
    <>
        <Stack spacing={2} sx={{marginBottom:"1rem"}}>
          <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
          </Breadcrumbs>
        </Stack>
    </>
  );
}

export default CustomBreadcrumb;