import { Grid } from '@mui/material';
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom"
import RequestApi from "../../../../Request-api/RequestApi";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { RegisterSchemaF1 } from '../RegisterSchema';
import FieldCustom from '../../../../UI/FieldCustom/FieldCustom';
import ReCAPTCHA from 'react-google-recaptcha';
import { createRef, useEffect, useState } from 'react';
import { RECAP_KEY } from '../../../../constants';

const DatosPrincipales = ({ state, next, getTipoDoc, docs, setProductos, setLoading, setPreRegistro }) => {
    const history = useHistory();
    const recaptchaInstance = createRef();
    const [tokenRec, setTokenRec] = useState();

    const handleRecaptcha = (token) => {
        setTokenRec(token)
    }

    useEffect(()=>{
        setLoading(true)
        recaptchaInstance.current.executeAsync().then(()=>{
           setLoading(false)
        });
    },[])



    const savePreRegistro = (values) => {
        setPreRegistro(values);
        setLoading(true);
        RequestApi.checkPreregistro(values.tipoDoc, values.dni, values.email)
        .then(r => {
            if (r.estado === 0) {
                RequestApi.getProductos(getTipoDoc(values.tipoDoc).value, values.dni, state.tokenRecaptcha)
                .then(status => {
                    if(status.estado === 0) {
                        RequestApi.sendPinMail({
                            "tipoDocumento": values.tipoDoc,
                            "nroDocumento": values.dni,
                            "email": values.email,
                            "nombre": values.nombre,
                            "apellido": values.apellido
                        }, tokenRec).then(r => {
                            setLoading(false)
                            next()
                        })
                    } else if (status.estado === 1 || status.estado === 2) {
                        setLoading(false)
                        Swal.fire({
                            icon: 'info',
                            title: 'Por favor, continúe por aquí',
                            text: 'Intente ingresar con su número de documento y contraseña',
                            footer: '<a href="/login">¿Olvidaste tu contraseña?</a>'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push('/login')
                            }
                        })
                    } else if (status.estado === 3) {
                        setLoading(false)
                        Swal.fire({
                            icon: 'info',
                            title: 'Por favor, continúe por aquí',
                            text: 'Te invitamos a que ingreses por nuestra web de socios',
                            confirmButtonText: 'Ir',
                            willClose: () => { window.location.reload() }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.open('https://www.swissmedical.com.ar/prepagaclientes/login')
                            }
                        })
                    }
                })

            } else if (r.estado === 1) {
                setLoading(false)
                next(3)
            }
        })
    }

    return (
        <>
            <Formik
                initialValues={{
                    nombre: '',
                    apellido: '',
                    tipoDoc: 2,
                    dni: '',
                    email: '',
                    repetirEmail: ''
                }}
                validationSchema={RegisterSchemaF1}
                onSubmit={values => {
                    savePreRegistro(values);
                }}
            >
                {({ errors, touched, values, handleChange }) => (                 
                    <Form>
                        <Grid container>
                            <Grid xs={12} sm={6} item container sx={{px:'1rem'}}>
                                <Grid xs={12} item>
                                    <FieldCustom
                                        id="nombre"
                                        name="nombre"
                                        placeholder='Ingresá tu nombre'
                                        label='Nombre (s)'
                                        value={values.nombre}
                                        onChange={handleChange}
                                        error={touched.nombre && Boolean(errors.nombre)}
                                        helperText={touched.nombre && errors.nombre}
                                    />
                                    <FieldCustom
                                        id="apellido"
                                        name="apellido"
                                        placeholder='Ingresá tu apellido'
                                        label='Apellido (s)'
                                        value={values.apellido}
                                        onChange={handleChange}
                                        error={touched.apellido && Boolean(errors.apellido)}
                                        helperText={touched.apellido && errors.apellido}
                                    />
                                </Grid>
                                <Grid xs={4} item sx={{pr:'1rem'}}>
                                    <FieldCustom
                                        id="tipoDoc"
                                        name="tipoDoc"
                                        variant='select'
                                        options={docs?.map(d => { return { name: d.name, value: d.id } })}
                                        placeholder='DNI'
                                        label='Documento'
                                        value={values.tipoDoc}
                                        onChange={handleChange}
                                        error={touched.tipoDoc && Boolean(errors.tipoDoc)}
                                        helperText={touched.tipoDoc && errors.tipoDoc}
                                    />
                                </Grid>
                                <Grid xs={8} item sx={{pl: '1rem'}}>
                                    <FieldCustom 
                                        id="dni"
                                        name="dni"
                                        placeholder='Número'
                                        label='hidden'
                                        value={values.dni}
                                        onChange={handleChange}
                                        error={touched.dni && Boolean(errors.dni)}
                                        helperText={touched.dni && errors.dni}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} item sx={{px:'1rem'}}>
                                <FieldCustom
                                    id="email"
                                    name="email"
                                    placeholder='Ingresá tu correo electrónico'
                                    label='Correo electrónico'
                                    value={values.email}
                                    onChange={handleChange}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                /> 
                                <FieldCustom
                                    id="repetirEmail"
                                    name="repetirEmail"
                                    placeholder='Repetí tu correo electrónico'
                                    label='Repetir correo electrónico'
                                    value={values.repetirEmail}
                                    onChange={handleChange}
                                    error={touched.repetirEmail && Boolean(errors.repetirEmail)}
                                    helperText={touched.repetirEmail && errors.repetirEmail}
                                /> 
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: "40px", paddingBottom: "40px", maxWidth: '500px'}}>
                            <ButtonCustom width="40%" type='submit' name="Continuar"/>
                        </div>
                    </Form>
                )}
            </Formik>
            <ReCAPTCHA
               ref={recaptchaInstance}
               sitekey={RECAP_KEY}
               size="invisible"
               onChange={handleRecaptcha}
            />
        </>
    )
}
export default DatosPrincipales;
