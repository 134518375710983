import { Box, Typography, Grid, Divider, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";
import moment from "moment";
import DownloadIcon from "../Icons/DownloadIcon";
import ViewIcon from "../Icons/ViewIcon";
import ShareModal from "../ShareModal/ShareModal";
import RequestApi from "../../../../../../Request-api/RequestApi";
import { SET_DOWNLOAD_ESTUDIOS } from "../../../../../../Redux/Actions/Types/estudios";

const styles = {
  paddingTop: "2rem",
  minWidth: "250px",
  "& .cardHeader": {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "& p": {
      fontSize: ".8rem",
    },
    "& .contractBox": {
      textAlign: "end",
    },
  },
  "& .cardBody": {
    textAlign: "center",
    "& .title": {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    "& .address": {
      fontSize: "0.8rem",
    },
    "& .deliveryDate": {
      fontWeight: "bold",
    },
  },
  "& .cardFooter": {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "& .controlBox": {
      textAlign: "end",
    },
  },
};

const StudyCard = ({ study, userData }) => {
  const {
    id,
    type,
    title,
    address,
    deliveryDate,
    status,
    creationDate,
    idImagen,
    fecha,
    hayImagen,
    hora,
    patientId,
    numeroId,
  } = study;

  const dispatch = useDispatch();
  const handleViewImage = () => {
    RequestApi.getImgToken(
      userData.fechaNac.replaceAll("-", ""),
      userData.docNum.toString(),
      userData.docType,
      idImagen,
      patientId
    ).then((r) => {
      window.open(
        "https://imagenes.swissmedical.com.ar/portal/?urlToken=" + r.imgToken
      );
    });
  };

  const handleDownload = () => {
    type === "imagen" ? handleDownloadPDF_Inf() : handleDownloadPDF_Lab();
  };

  const handleDownloadPDF_Inf = () => {
    RequestApi.getPDF_inf({
      documento: userData.docNum.toString(),
      fechaNac: moment(userData.fechaNac, "YYYY-MM-DD").format("DD-MM-YYYY"),
      fechaEstudio: moment(fecha, "YYYYMMDD").format("DD-MM-YYYY"),
      horaEstudio: moment(hora, "HHmmss").format("HH:mm:ss"),
      acceso: "",
      informeid: "",
    }).then((res) => {
      fileDownload(
        res,
        type + "-" + moment(fecha, "YYYYMMDD").format("DD-MM-YYYY") + ".pdf"
      );
    });
  };

  const handleDownloadPDF_Lab = () => {
    dispatch({ type: SET_DOWNLOAD_ESTUDIOS, payload: true });
    RequestApi.getPDF_lab({
      documento: userData.docNum.toString(),
      tipoDocumento: userData.docType,
      id: id,
    }).then((res) => {
      dispatch({ type: SET_DOWNLOAD_ESTUDIOS, payload: false });
      fileDownload(
        res,
        "estudio-" + moment(fecha, "YYYYMMDD").format("DD-MM-YYYY") + ".pdf"
      );
    });
  };

  return (
    <>
      <Box sx={styles}>
        <Grid container className="cardHeader">
          <Grid item xs={6} className="dateCreation">
            <Typography>{creationDate}</Typography>
          </Grid>
          <Grid item xs={6} className="contractBox">
            <Typography>N° {id}</Typography>
          </Grid>
        </Grid>
        <Box className="cardBody">
          <Typography variant="subtitle1" className="title">
            {title}
          </Typography>
          <Typography className="address">{address}</Typography>
          {deliveryDate && (
            <Typography variant="caption" className="deliveryDate">
              Fecha de entrega: {deliveryDate}
            </Typography>
          )}
        </Box>
        <Divider />
        <Grid container className="cardFooter">
          <Grid item xs={6}>
            <Typography
              sx={{
                color:
                  status === "Completo"
                    ? "#32786E"
                    : status == "Parcial"
                    ? "#F08332"
                    : "#363636",
              }}
            >
              {status}
            </Typography>
          </Grid>
          <Grid item xs={6} className="controlBox">
            <IconButton onClick={handleDownload}>
              <DownloadIcon />
            </IconButton>
            {hayImagen && (
              <IconButton onClick={handleViewImage}>
                <ViewIcon />
              </IconButton>
            )}
            <ShareModal fecha={fecha} hora={hora} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StudyCard;
