import * as Yup from 'yup';

const ERR_REQUIRED = 'Este campo es requerido';

export const PassScheme = Yup.object().shape({
    newPass: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial"
      ).required(ERR_REQUIRED),
    repeatPass: Yup.string().oneOf([Yup.ref('newPass'), null], 'Las contraseñas no coinciden').required(ERR_REQUIRED),
});