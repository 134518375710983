import { Autocomplete, TextField } from "@mui/material";

const ProfList = ({professionals}) => {

    return (
        <Autocomplete
            disablePortal
            id="combobox"
            options={professionals}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Profesional" />}
        />
    )
}

export default ProfList;