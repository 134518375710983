import { Button, Container, Typography, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import InputImage from '../../../../../../componentes/InputImage/InputImage';
import CustomRedButton from '../../../../../../UI/CustomRedButton/CustomRedButton';
import CustomSelect from '../../../../../../UI/CustomSelect/CustomSelect';
import { useSelector } from 'react-redux';
import useIsMobile from '../../../../../../Utils/useIsMobile';
import RequestApi from '../../../../../../Request-api/RequestApi';
import Swal from 'sweetalert2';

const ValidarVinculo = () => {
    const { isMobile } = useIsMobile();
    const history = useHistory();
    const { dni } = useParams();
    const [pending, setPending] = useState(false);
    const { familiares } = useSelector(state => state.grupoFamiliar);
    const { idEDNS } = useSelector(state => state.authLogin);
    const [familiar, setFamiliar] = useState({});
    const [file, setFile] = useState(null);
    const [docTipo, setDocTipo] = useState(2);
    const [docs, setDocs] = useState([]);
    const [isValid, setIsValid] = useState(false)


    const handleChange = (e) => {
        setDocTipo(e.target.value)
    }

    const handleNewFile = (img) => {
        setFile(img);
    }

    const goHome = () => {
        history.push('/pacientes')
    }

    const handleEnviar = () => {
        if(docTipo && file) {
            const multiPartFiles = new FormData();
            multiPartFiles.append('file', file)
            RequestApi.uploadAWSFile(multiPartFiles)
            .then(r => {
                if(r.estado === 0){
                    RequestApi.validarVinculo({
                        idEdnsEfector: idEDNS,
                        idEdns: familiar.idEdns,
                        canal: isMobile ? 2 : 1,
                        idTipoDoc: familiar.tipoDoc,
                        nroDoc: familiar.nroDoc,
                        documentacion: file.name,
                        tipoDocumentacion: docTipo,
                        ipCliente: window.CLIENT_IP,
                        dispositivo: navigator.userAgent,
                        tipoValidacion: 2,
                        idUsuario: familiar.nroDoc
                    })
                    .then(res => {
                        if(res.estado === 0) {
                            setPending(true)
                        } else {
                            Swal.fire({
                                title: "Error en la validación",
                                text: res.mensaje,
                                icon: 'error',
                                willClose: history.push('/pacientes/familiares')
                            });
                        }
                    })
                    .catch(e => {
                        //error
                    })
                }
            }).catch(e => {
                //error
            })
        }
    }

    useEffect(() => {
        RequestApi.getTipoDocumentacion().then(r => {
            setDocs(r)
        })

        let filter = familiares.filter(fam => fam.nroDoc === parseInt(dni))
        if(filter.length) {
            setFamiliar(filter[0])
        } else {
            history.push('/pacientes/familiares');
        }
    },[])

    useEffect(() => {
        if(file) {
            setIsValid(true)
        }
    },[file])

    return (
        <>
            <Container sx={{display: 'flex', justifyContent:'center', alignContent:'flex-start', marginTop:'1rem', flexWrap:'wrap'}}>
                <Box sx={{marginBottom:'1.5rem'}} display='flex' width='100%' justifyContent='space-between' height='fit-content' alignItems='center'>
                    <Button onClick={()=> {history.goBack()}}>{'< Volver'}</Button>
                </Box>
                <Box width='100%' maxWidth='350px'  sx={{paddingX:'2rem'}}>
                {
                    !pending ? (
                                <>
                                    <Typography textAlign='center' marginBottom='2rem' variant='h5'>
                                        Validar vinculo
                                    </Typography>
                                    <Typography sx={{mb:2}}>Subir documentación donde indique que el titular de la cuenta es padre/madre/tutor del familiar que intenta vincular</Typography>
                                    <CustomSelect
                                        label='Elegir tipo de documentación' 
                                        items={docs.map(item => {return {value:item.id, name:item.name}})}
                                        onChange={handleChange}
                                        name='tipoDocumentacion'
                                        value={docTipo}
                                    />
                                    <InputImage label='Subir imágen' newFile={handleNewFile} fileName='DOCU-MENOR' />
                                    <CustomRedButton disabled={!isValid} onClick={handleEnviar} text='Continuar' sx={{marginTop: '1rem'}}/>
                                </>
                            ) : (
                                <>
                                    <Typography textAlign='center' marginBottom='5rem' variant='h5'>
                                        ¡Solicitud enviada!
                                    </Typography>
                                    <Typography textAlign='center'>
                                        ¡Gracias!
                                        <br />
                                        Hemos recibido tu solicitud para validar tu vinculo con {familiar.nombre} {familiar.apellido1}.
                                        <br />
                                        Muy pronto recibirás novedades.
                                    </Typography>
                                    <CustomRedButton onClick={goHome} text='Volver al inicio' sx={{marginTop: '1rem'}}/>
                                </>
                    )
                }
                </Box>
            </Container>
        </>
    )

}

export default ValidarVinculo;