import { Tooltip, Typography, Grid } from "@mui/material";
import SelectRecent from "../SelectRecent/SelectRecent";
import FiltersModal from "./FiltersModal/FiltersModal";

const Filters = () => {

    return (
        <>
            <Grid container>
                <Grid item xs={6} display='flex' alignItems='center'>
                    <Tooltip title="Filter">
                        <FiltersModal />
                    </Tooltip>
                    <Typography sx={{fontWeight: 'bold'}}>
                        Filtros
                    </Typography>
                </Grid>
                <Grid item xs={6} display='flex' alignItems='center' justifyContent='end'>
                    <SelectRecent />
                </Grid>
            </Grid>
        </>
    )
}

export default Filters;