import { Box, IconButton, Paper, Typography } from "@mui/material";

const MobItem = ({getIcon, name, goTo}) => {

    return (
        <>
            <Box width='200px' m={2}>
                <Paper elevation={1} sx={{borderRadius: '30px', backgroundColor:'#FCFCFC', minHeight:'135px'}} >
                    <IconButton aria-label="Example" sx={{width: '100%', aspectRatio:'1 / 1', flexDirection:'column'}} onClick={goTo}>                
                            {getIcon()}
                            <Typography pt='1rem'  px='.4rem' fontWeight='bold' fontSize='1rem' sx={{fontFamily: "'Lato', sans-serif"}}>{name}</Typography>
                    </IconButton>
                </Paper>
            </Box>
        </>
    )
}
export default MobItem;