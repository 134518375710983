import HeaderClientes from "../../../componentes/HeaderClientes/HeaderClientes"
import MenuClientes from "../../../componentes/MenuClientes/MenuClientes"
import Inicio from "./Items/Inicio/Inicio"
import ValidarTitular from './Items/Validacion/ValidarTitular'
import TurnosMedicos from "./Items/TurnosMedicos/TurnosMedicos"
import ResultadosEstudios from './Items/ResultadosEstudios/ResultadosEstudios'
import GrupoFamiliar from './Items/GrupoFamiliar/GrupoFamiliar'
import VincularFamiliar from './Items/GrupoFamiliar/VincularFamiliar/VincularFamiliar'
import ValidarFamiliar from './Items/GrupoFamiliar/ValidarFamiliar/ValidarFamiliar'
import { Switch, Route } from "react-router-dom"
import "./HomeClientes.css"
import Perfil from "./Items/Perfil/Perfil"
import EditPassword from "./Items/Perfil/Forms/EditPassword";
import EditEmail from "./Items/Perfil/Forms/EditEmail";
import EditLocation from "./Items/Perfil/Forms/EditLocation";
import EditPlan from "./Items/Perfil/Forms/EditPlan";
import ShowProducts from "./Items/Perfil/Forms/ShowProducts";
import { Backdrop, Box, CircularProgress, Grid } from "@mui/material"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import ValidarVinculo from "./Items/GrupoFamiliar/ValidarVinculo/ValidarVinculo"
import { useState } from "react"
import EditarFamiliar from "./Items/GrupoFamiliar/EditarFamiliar/EditarFamiliar"

const HomeClientes = () => {
  const [loading, setLoading] = useState(false)
  
  return (
      <>
        <HeaderClientes type="home"/>
        <Box display='flex'>
          <MenuClientes />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container width='100%'>
            <Grid item xs={12}>
              <Switch>
                <Route exact path="/pacientes" component={Inicio} />
                <Route exact path="/pacientes/validar-titular" component={ValidarTitular} />
                <PrivateRoute path={[
                  "/pacientes/estudios",
                  "/pacientes/familiares",
                  "/pacientes/vincular-familiar",
                  "/pacientes/familiares/edit/:dni",
                  "/pacientes/validar-familiar/:dni",
                  "/pacientes/verificar-vinculo/:dni"
                ]} setLoading={setLoading} >
                    <Route exact path="/pacientes/estudios" component={ResultadosEstudios} />
                    <Route exact path="/pacientes/familiares" component={GrupoFamiliar} />
                    <Route exact path="/pacientes/familiares/edit/:dni" component={EditarFamiliar} />
                    <Route exact path="/pacientes/vincular-familiar" component={VincularFamiliar} />
                    <Route exact path="/pacientes/validar-familiar/:dni" component={ValidarFamiliar} />
                    <Route exact path="/pacientes/verificar-vinculo/:dni" component={ValidarVinculo} />
                </PrivateRoute>
                <Route exact path="/pacientes/perfil" component={Perfil} />
                <Route exact path="/pacientes/perfil/editar/password" component={EditPassword} />
                <Route exact path="/pacientes/perfil/editar/email" component={EditEmail} />
                <Route exact path="/pacientes/perfil/editar/location" component={EditLocation} />
                <Route exact path="/pacientes/perfil/editar/plan" component={EditPlan} />
                <Route exact path="/pacientes/perfil/productos" component={ShowProducts} />
                <Route path="/pacientes/turnos" component={TurnosMedicos} />
              </Switch>
            </Grid>
          </Grid>
        </Box>
      </>
  )
   
    
}
export default HomeClientes;