import { useState } from "react";
import { Grid } from "@mui/material";
import { createSvgIcon } from '@mui/material/utils';
import { useHistory } from "react-router-dom";
import ModalAlertValidarCuenta from "../../Validacion/Steps/ModalAlertValidarCuenta/ModalAlertValidarCuenta";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import DeskItem from "./DeskItem";
import useIsMobile from "../../../../../../Utils/useIsMobile"
import MobItem from "./MobItem";


const TurnosIcon = createSvgIcon(
    <svg viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18C19.7344 18 23.5714 13.9711 23.5714 9C23.5714 4.02891 19.7344 0 15 0C10.2656 0 6.42857 4.02891 6.42857 9C6.42857 13.9711 10.2656 18 15 18ZM6.96429 29.8125C6.96429 30.7477 7.6808 31.5 8.57143 31.5C9.46205 31.5 10.1786 30.7477 10.1786 29.8125C10.1786 28.8773 9.46205 28.125 8.57143 28.125C7.6808 28.125 6.96429 28.8773 6.96429 29.8125ZM21.4286 20.2922V23.7375C23.8728 24.2578 25.7143 26.5359 25.7143 29.25V32.182C25.7143 32.7164 25.3527 33.1805 24.8504 33.2859L22.6942 33.7359C22.4062 33.7992 22.125 33.6023 22.0647 33.293L21.8571 32.1891C21.7969 31.8867 21.9844 31.5844 22.279 31.5281L23.5714 31.2539V29.25C23.5714 24.8344 17.1429 24.6727 17.1429 29.3836V31.2609L18.4353 31.5352C18.7232 31.5984 18.9107 31.8937 18.8571 32.1961L18.6496 33.3C18.5893 33.6023 18.308 33.7992 18.0201 33.743L15.9308 33.4477C15.4018 33.3703 15.0067 32.8992 15.0067 32.3297V29.25C15.0067 26.5359 16.8482 24.2648 19.2924 23.7375V20.5594C19.1451 20.6086 18.9978 20.6367 18.8504 20.693C17.6451 21.1359 16.3527 21.382 15.0067 21.382C13.6607 21.382 12.3683 21.1359 11.1629 20.693C10.6674 20.5102 10.1652 20.3977 9.64955 20.3273V26.0648C11.1964 26.55 12.3281 28.0406 12.3281 29.8195C12.3281 31.9922 10.6473 33.757 8.57812 33.757C6.50893 33.757 4.82812 31.9922 4.82812 29.8195C4.82812 28.0406 5.95982 26.55 7.5067 26.0648V20.4117C3.24777 21.1641 0 25.0172 0 29.7V32.85C0 34.5867 1.34598 36 3 36H27C28.654 36 30 34.5867 30 32.85V29.7C30 24.6375 26.1964 20.5383 21.4286 20.2922Z" fill="#E4002B"/>
    </svg>,
    'Turnos',
);
const EstudiosIcon = createSvgIcon(
    <svg viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.2041 28.3711L23.571 15.1172V4.5H24.1603C25.14 4.5 25.9281 3.74766 25.9281 2.8125V1.6875C25.9281 0.752344 25.14 0 24.1603 0H8.83868C7.85898 0 7.07081 0.752344 7.07081 1.6875V2.8125C7.07081 3.74766 7.85898 4.5 8.83868 4.5H9.42797V15.1172L0.794853 28.3711C-1.36343 31.6828 1.12633 36 5.2219 36H27.777C31.88 36 34.3624 31.6758 32.2041 28.3711ZM10.1572 22.5L13.7077 17.0437C13.9802 16.6781 14.1349 16.2281 14.1349 15.75V4.5H18.8493V15.75C18.8493 16.2352 19.0113 16.6781 19.2765 17.0437L22.827 22.5H10.1572Z" fill="#E4002B"/>
    </svg>,
    'Estudios',
);
const DatosIcon = createSvgIcon(
    <svg viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.1784 36C26.1332 36 27.0489 35.6207 27.724 34.9456C28.3991 34.2705 28.7784 33.3548 28.7784 32.4V3.6C28.7784 2.64522 28.3991 1.72955 27.724 1.05442C27.0489 0.379284 26.1332 0 25.1784 0H3.6C2.64522 0 1.72955 0.379284 1.05442 1.05442C0.379285 1.72955 0 2.64522 0 3.6V32.4C0 33.3548 0.379285 34.2705 1.05442 34.9456C1.72955 35.6207 2.64522 36 3.6 36H25.1784ZM9 5.4H19.8V9H9V5.4ZM18.45 16.65C18.4457 17.723 18.0177 18.7508 17.2592 19.5097C16.5006 20.2686 15.473 20.6971 14.4 20.7018C12.177 20.7018 10.35 18.8748 10.35 16.65C10.35 14.4252 12.177 12.6 14.4 12.6C15.4728 12.6043 16.5005 13.0323 17.2591 13.7909C18.0177 14.5495 18.4457 15.5772 18.45 16.65ZM6.3 29.1384C6.3 26.1432 9.9504 23.0634 14.4 23.0634C18.8496 23.0634 22.5 26.1432 22.5 29.1384V30.1518H6.3V29.1384Z" fill="#E4002B"/>
    </svg>,
    'Datos',
);
const FamiliaresIcon = createSvgIcon(
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 42C21 42 18 42 18 39C18 36 21 27 33 27C45 27 48 36 48 39C48 42 45 42 45 42H21ZM33 24C35.3869 24 37.6761 23.0518 39.364 21.364C41.0518 19.6761 42 17.3869 42 15C42 12.6131 41.0518 10.3239 39.364 8.63604C37.6761 6.94821 35.3869 6 33 6C30.6131 6 28.3239 6.94821 26.636 8.63604C24.9482 10.3239 24 12.6131 24 15C24 17.3869 24.9482 19.6761 26.636 21.364C28.3239 23.0518 30.6131 24 33 24Z" fill="#E4002B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.648 41.9998C15.2033 41.0633 14.9815 40.0364 15 38.9998C15 34.9348 17.04 30.7498 20.808 27.8398C18.9273 27.2603 16.9678 26.9769 15 26.9998C3 26.9998 0 35.9998 0 38.9998C0 41.9998 3 41.9998 3 41.9998H15.648Z" fill="#E4002B"/>
        <path d="M13.5 24C15.4891 24 17.3968 23.2098 18.8033 21.8033C20.2098 20.3968 21 18.4891 21 16.5C21 14.5109 20.2098 12.6032 18.8033 11.1967C17.3968 9.79018 15.4891 9 13.5 9C11.5109 9 9.60322 9.79018 8.1967 11.1967C6.79018 12.6032 6 14.5109 6 16.5C6 18.4891 6.79018 20.3968 8.1967 21.8033C9.60322 23.2098 11.5109 24 13.5 24Z" fill="#E4002B"/>
    </svg>,
    'Familiares',
);


const ItemMenu = ({icon, name, path}) => {
    const { isMobile } = useIsMobile();
    const history = useHistory();
    //const [open, setOpen] = useState(false);


    const goTo = (path) => {
        history.push(`/pacientes/${path}`);
    }

    const getIcon = () => {
        switch(icon) {
            case 'Turnos':
                return <TurnosIcon sx={{fontSize: isMobile ? '3rem' : '1.3rem'}}/>
            case 'Estudios':
                return <EstudiosIcon sx={{fontSize: isMobile ? '3rem' : '1.3rem'}}/>
            case 'Perfil':
                return <DatosIcon sx={{fontSize: isMobile ? '3rem' : '1.3rem'}}/>
            case 'Familiares':
                return <FamiliaresIcon sx={{fontSize: isMobile ? '3rem' : '1.3rem'}}/>
            default:
                return null
        }
    }

    return (
        <>
            {/* <ModalAlertValidarCuenta open={open} setOpen={setOpen}/> */}
            <Grid item xs={6} display='flex' justifyContent='center'>
                <MobItem getIcon={getIcon} name={name} goTo={() => goTo(path)} />
            </Grid>
        </>
    )
}
export default ItemMenu;