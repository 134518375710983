import React, {useEffect, useState} from "react";
import "./turnosPrevios.css"
import TurnoCard from "../Items/TurnoCard";
import UnTurnoPara from "../Items/UnTurnoPara";
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom";
import InicioTurno from "../Items/InicioTurno"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useHistory } from "react-router-dom";
import utils from "../../../../../../../Utils/useDate";
import RequestApi from '../../../../../../../Request-api/RequestApi';
import IconoMedico from "../proximosTurnos/IconoMedico"

const TurnosPrevios = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const [PreviosTurnos, setPreviosTurnos]=useState([])
  const {fechaNac, docNum, docType, genero, nombre, apellido, tel, plan} = useSelector((state) => state.authLogin);
  const [integranteSeleccionado, setIntegranteSeleccionado] = useState({ nombreCompleto: `${nombre} ${apellido}`,
  nombre: nombre,
  apellido: apellido,
  doc: docNum,
  fechaNac: fechaNac,
  genero: genero,
  gamaPk: plan.gamaPk,
  planPk: plan.planPk})

const handleNuevoTurno = () => {
  history.push('/pacientes/turnos/nuevo')
}; 

const convertObject = (parametro) => {
  let procesado = parametro
  if(!Array.isArray(parametro)){
    procesado = [ procesado ]
  }
  return procesado
}

useEffect(() => {
  let fechaHoy = moment().format("DD/MM/YYYY") 
  let fechaDentroDe3Meses = moment().add(-360, "days").format("DD/MM/YYYY")
  let body = {
    sistema: "CMA",
    fechaDesde: fechaDentroDe3Meses,
    fechaHasta: fechaHoy,
    usuLogin: utils.convertDate(integranteSeleccionado.fechaNac)+integranteSeleccionado.doc+"@swissmedical.com.ar",
  };
  RequestApi.getTurnos(body).then((resp) => {
    setPreviosTurnos(convertObject(resp?.respuesta?.turnos?.turno));
  });
}, []);
  return (
    <div className="turnosPrevios" style={{ margin: 20 }}>
        <div style={{ margin: 20 }}>
        <InicioTurno />
      </div>

      <UnTurnoPara turnoPara=" Susana Perez" setIntegranteSeleccionado={setIntegranteSeleccionado} />

      <div className="button">
        <ButtonCustom
          primaryColor="#E4002B"
          color="#FFF"
          onClick={handleNuevoTurno}
          width="95%"
          name={"Obtener Nuevo turno"}
        />
      </div>
      <div style={{ display: "flex" }}>
      <IconoMedico style={{ marginRight: "10px" }}/>
        <b className="turnos">Turnos Previos </b>
      </div>
    

      {PreviosTurnos ? 
      <div>{PreviosTurnos.map((turno) => {
      
        return (
          <TurnoCard
            verBotonBorrar={true}
            nombreDoc={turno.nombre_abre_atencion+" "+turno.ape_razon_atencion}
            especialidad={turno.exten_deno}
            fechaYHora={turno.fecha_desde_dia}
            lugarDeAtencion={turno.centro_deno}
            direccion={turno.domicilio}
          />
        );
      })}
      
      </div>
      :
      <p>no hay turnos</p>
      }
      </div>
  )
}

export default TurnosPrevios