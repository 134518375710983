import { useEffect, useState } from "react";
import RequestApi from '../Request-api/RequestApi'

const useNationality = () => {
    const [nationality, setNationality] = useState([])

    useEffect(() => {

        if(nationality.length === 0){
            RequestApi.getNacionalidades()
            .then(res => {
                setNationality(res)
            });
        }

    })

    return nationality;
}

export default useNationality;