import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const GenderList = () => {
    return (
        <>
            <FormControl>
                <FormLabel id="gender-list">Género del profesional</FormLabel>
                <RadioGroup
                    aria-labelledby="gender-list"
                    defaultValue="all"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="other" control={<Radio />} label="Sin distinción" />
                    <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                    <FormControlLabel value="F" control={<Radio />} label="Femenino" />
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default GenderList;