import React from 'react'
import "./iconos.css"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const IcNota = ({onClick}) => {
  
  return (
    <>
    
    <CalendarMonthIcon onClick={onClick}/>
      
    {/* <svg onClick={onClick} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='iconoNota'>
<path d="M13.0357 11.5714H18.5V0.964286C18.5 0.429911 18.0701 0 17.5357 0H1.46429C0.929911 0 0.5 0.429911 0.5 0.964286V17.0357C0.5 17.5701 0.929911 18 1.46429 18H12.0714V12.5357C12.0714 12.0054 12.5054 11.5714 13.0357 11.5714ZM18.2188 13.7812L14.2812 17.7188C14.1004 17.8996 13.8554 18 13.5982 18H13.3571V12.8571H18.5V13.1022C18.5 13.3554 18.3996 13.6004 18.2188 13.7812Z" fill="#363636"/>
</svg> */}
</>
  )
}

export default IcNota