import {useState} from 'react';
import DatePicker from '../../../../../../../UI/DatePicker/DatePicker';
import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import FilterIcon from "../../Icons/FilterIcon";
import {IconButton, Typography, Select, MenuItem, Box, Grid} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

const styles = {
  "$ > div": {
    maxWidth: '400px',
  },
  "& .label": {
    fontWeight: "bold",
    marginBottom: "1rem",
    marginTop: "1rem"
  },
  "& #alert-dialog-title": {
    fontWeight: "bold",
    marginBottom: "1rem"
  },
  "& .filterBtn": {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "bold",
    color: "#E4002B",
    textTransform: "initial",
    borderRadius: "40px",
    borderColor: "#E4002B",
    width: "100%",
    height: "40px",
    padding: "15px"
  },
  "& .cancelBtn": {
    width: "100%",
    color: "#000",
    textDecoration: "underline",
    textTransform: "capitalize",
    padding: "1rem",
  }
}

const FiltersModal = () => {
  const dispatch = useDispatch();
  const {status, type} = useSelector((state) => state.filters)
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    status: "",
    type: "",
    date : {
      start: "",
      end: ""
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
      setState({
        ...state,
        [e.target.name]: e.target.value
      })
  }

  const handleApplyFilters = () => {
    dispatch({
      type: 'SET_FILTERS',
      payload: {
          ...state
      }
    })

    handleClose();
  }

  const handleChangeDate = (date, name) => {
    setState({
      ...state,
      date: {
        ...state.date,
        [name.split("-")[1]]: date
      }
    })
  }

  return (
    <>
      <IconButton onClick={handleClickOpen}>
          <FilterIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={styles}
        maxWidth={'xs'}
      >
        <DialogTitle id="alert-dialog-title">
          Aplicar filtros
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <Typography className='label'>
                Estados
              </Typography>
              <Select
                  onChange={handleChange}
                  defaultValue={status ? status : 0}
                  variant="outlined"
                  name='status'
                  fullWidth
              >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value='Completo'>Completo</MenuItem>
                  <MenuItem value='Parcial'>Parcial</MenuItem>
                  <MenuItem value='Pendiente'>Pendiente</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography className='label'>
                Tipo de estudio
              </Typography>
              <Select
                  onChange={handleChange}
                  defaultValue={type ? type : 0}
                  variant="outlined"
                  name='type'
                  fullWidth
              >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value='imagen'>Imagen</MenuItem>
                  <MenuItem value='laboratorio'>Laboratorio</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography className='label'>
                Fecha
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <DatePicker isMobile={false} label='Desde' name='date-start' handleChange={handleChangeDate} sx={{"& fieldset":{borderRight:0, borderRadius: '10px 0px 0px 10px'}}}/>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker isMobile={false} label='Hasta' name='date-end' handleChange={handleChangeDate} sx={{"& fieldset":{borderLeft:0, borderRadius: '0px 10px 10px 0px'}}}/>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' flexWrap='wrap' width='100%'>
            <Button variant='outlined' className='filterBtn' onClick={handleApplyFilters} autoFocus>Aplicar filtros</Button>
            <Button onClick={handleClose} className='cancelBtn'>Cancelar</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FiltersModal;