import { Grid } from "@mui/material"
import { useEffect, useState } from "react"

const SpecCol = ({list, centerId}) => {

    const [spec, setSpec] = useState([])

    useEffect(()=>{

        if(list.length >= 20){
            const middleIndex = Math.ceil(list.length / 2);
            const firstHalf = list.splice(0, middleIndex);   
            const secondHalf = list.splice(-middleIndex);
            const cols = [firstHalf, secondHalf]
            setSpec(cols)
        } else {
            setSpec([list])
        }
    }, []) 

    return (
        <>
            <Grid container>
                {spec.map((col, index)=>{
                    return (
                        <Grid item xs={12} md={6} key={`${centerId}-${index}`}>
                            <ul>
                                {col.map(item => {
                                    return (
                                        <li className="accLi" key={item}>{item}</li>
                                    )
                                })}
                            </ul>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
export default SpecCol