import { useEffect, useState } from "react";
import RequestApi from '../Request-api/RequestApi'

const useProvince = () => {
    const [provinces, setProvinces] = useState([])

    useEffect(() => {

        if(provinces.length === 0){
            RequestApi.getProvincias()
            .then(res => {
                setProvinces(res)
            });
        }

    })

    return provinces;
}

export default useProvince;