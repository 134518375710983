import {FormControl, Select, MenuItem} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";

const SelectRecent = () => {
    const {mostRecent} = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch({
            type: 'SET_FILTERS',
            payload: {
                mostRecent: e.target.value
            }
        });
    }

    return (
        <>
            <FormControl>
                <Select
                    onChange={handleChange}
                    defaultValue={true}
                    value={mostRecent}
                    variant="standard"
                    sx={{
                        "&:before": {
                            border: "none",
                        },
                        fontWeight: 'bold'
                    }}
                >
                    <MenuItem value={true}>Más recientes</MenuItem>
                    <MenuItem value={false}>Más antiguos</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

export default SelectRecent;