import { useParams, useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "../../componentes/Navbar/Navbar";
import { centers } from "../../Data/Centers.json";
import HeaderCM from "./components/HeaderCM/HeaderCM";
import BodyCM from "./components/BodyCM/BodyCM";
import Footer from "../../componentes/Footer/Footer";
import React, { useEffect } from 'react';

const CentrosMedicos = () => {
    const { center_id } = useParams();

    const item = centers.filter(center => center.id === center_id)[0];
    const history = useHistory();

    if (!item) {
        history.push("/");
    }

    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, [center_id]);

    return (
        <>
            <Navbar />
            <HeaderCM title={item.title} img={item.imgPortada} />
            <Box>
                <BodyCM location={item.title} sections={item.sections} address={item.address} days={item.days} mapRef={item.mapRef} specialties={item.specialties} centerId={center_id} />
            </Box>
            <Footer />
        </>
    )
}

export default CentrosMedicos;