import { useState } from "react";
import CheckCuil from "./Steps/CheckCuil/CheckCuil"
import CheckOrDni from "./Steps/CheckOrDni/CheckOrDni"
import ValidacionLista from './Steps/ValidacionLista/ValidacionLista'
import ScannerComponent from "../../../../../componentes/ScannerComponent/ScannerComponent"
import FormularioErrorQr from './Steps/FormularioErrorQr/FormularioErrorQr'
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import StepBar from "../../../../../componentes/StepBar/StepBar"

const ValidarTitular = () => {
    const history = useHistory();
    const [step, setStep] = useState(1);
    

    const handleNext = (st) => {
        st ? setStep(st) : setStep(step+1);
    }

    const handleVolver = () => {
        history.goBack();
    }

    return (
        <>
            <Container sx={{minHeight: '85vh', position: 'relative'}}>
                {step !== 3 && <StepBar step={step} siguienteOn={false} totalSteps={4} handleVolver={handleVolver} />}
                {step === 1 && (<CheckCuil next={handleNext}/>)}
                {step === 2 && (<CheckOrDni next={handleNext}/>)}
                {step === 3 && (<ScannerComponent next={handleNext}/>)}
                {step === 4 && (<FormularioErrorQr next={handleNext}/>)}
                {step === 5 && (<ValidacionLista />)}
            </Container>
        </>
    )
}

export default ValidarTitular;