import NavMobile from './NavMobile';
import NavDesktop from './NavDesktop';
import  useIsMobile from "../../Utils/useIsMobile";



const Navbar = () => {

    const { isMobile } = useIsMobile();
    
    return (
        <>  
            {
                isMobile ? <NavMobile /> : <NavDesktop />
            }
        </>
    )
}

export default Navbar;