import React, {useEffect, useState} from "react";
import TurnoCard from "../Items/TurnoCard";
import "./proximosTurnos.css";
import UnTurnoPara from "../Items/UnTurnoPara";
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom";
import InicioTurno from "../Items/InicioTurno"
import { useDispatch, useSelector } from "react-redux"
import { SET_SCREEM } from "../../../../../../../Redux/Actions/Types/clientes"
import moment from "moment"
import utils from "../../../../../../../Utils/useDate";
import RequestApi from '../../../../../../../Request-api/RequestApi';
import Spinner from "../../../../../../../UI/Spinner/Spinner";
import IconoMedico from "./IconoMedico"

const ProximosTurnos = () => {
 /*  const [turnos, setTurnos]=useState([]) */
  const dispatch = useDispatch()
  const [ProximosTurnos, setProximosTurnos]=useState([])
  const handleNuevoTurno = () => {
    dispatch({ type: SET_SCREEM, payload: "NuevoTurno" })
}

const [spinner, setSpinner] = useState(true);
const {fechaNac, docNum, docType, genero, nombre, apellido, tel, plan} = useSelector((state) => state.authLogin);
  const [integranteSeleccionado, setIntegranteSeleccionado] = useState({ nombreCompleto: `${nombre} ${apellido}`,
  nombre: nombre,
  apellido: apellido,
  doc: docNum,
  fechaNac: fechaNac,
  genero: genero,
  gamaPk: plan.gamaPk,
  planPk: plan.planPk})

const convertObject = (parametro) => {
  let procesado = parametro
  if(!Array.isArray(parametro)){
    procesado = [ procesado ]
  }
  return procesado
}

useEffect(() => {
  let fechaHoy = moment();
  let body = {
    sistema: "CMA",
    fechaDesde: /* "27/04/2022" */ fechaHoy.format("DD/MM/YYYY"),
    fechaHasta: /* "27/07/2022" */ fechaHoy.add(90, "days").format("DD/MM/YYYY"),
    usuLogin: utils.convertDate(integranteSeleccionado.fechaNac)+integranteSeleccionado.doc+"@swissmedical.com.ar",

  };
  RequestApi.getTurnos(body).then((resp) => {
    if(resp?.respuesta?.turnos?.turno){setProximosTurnos(convertObject(resp?.respuesta?.turnos?.turno));}
    
    setSpinner(false)
  });
}, [integranteSeleccionado]);

  return (
    < > {spinner ? (
      <Spinner />
    ) : (<div className="proximosTurnos" style={{ margin: 20 }}>
    <div style={{ margin: 20 }}>
    <InicioTurno />
  </div>

  <UnTurnoPara turnoPara=" Susana Perez" setIntegranteSeleccionado={setIntegranteSeleccionado}/>

  <div className="button">
    <ButtonCustom
      primaryColor="#E4002B"
      color="#FFF"
      onClick={handleNuevoTurno}
      width="95%"
      name={"Obtener Nuevo turno"}
    />
  </div>
  <div style={{ display: "flex" }}>
  <IconoMedico style={{ marginRight: "10px" }}/>
    <b className="turnos">Próximos turnos </b>
  </div>
{ProximosTurnos ? 
  (<div>{ ProximosTurnos.map(turno => {
  
    return (
      <TurnoCard
      verBotonBorrar={true}
      nombreDoc={turno.nombre_abre_atencion+" "+turno.ape_razon_atencion}
      especialidad={turno.exten_deno}
      fechaYHora={turno.fecha_desde_dia}
      lugarDeAtencion={turno.centro_deno}
      direccion={turno.domicilio}
      consulta_id={turno.consulta_id}
    />
    );
  })}
  
  </div>)
  :
  (<p className="noHayTurnos">No hay turnos</p>)
  }
  </div>)}
      </>
  )
}

export default ProximosTurnos