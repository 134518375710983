import { Container, Grid, Box, Typography, Button, CardMedia } from "@mui/material";
import { useHistory } from "react-router-dom";

const styles = {
    boxContent: {
        marginY: "5rem",
        marginX: '2rem',
        fontFamily: "'Lato', sans-serif",
        "& .MuiGrid-item": {            
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
        },
        "& .btn": {
            marginTop: "1rem",
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            textTransform: "initial",
            borderRadius: "40px",
            borderColor: "#E4002B",
            height: "40px",
            padding: "15px",
            margin: "1rem",
        },
        "& .title": {
            margin: {md: "1rem"},
            fontWeight: "bold",
            fontSize: "1.5rem",
        },
        "& .desc": {
            fontFamily: "'Lato', sans-serif",
            marginY: "1rem",
            marginX: {md: '1rem'}
        },
        "& .imgCard": {
            maxWidth: "490px"
        }
    }
}

const Welltest = () => {
    const { boxContent } = styles;
    const history = useHistory();

    const handleMoreInfo = () => {
        history.push('/info/welltest');
    }

    return (
        <>
            <Box sx={boxContent}>
                <Typography className="title" sx={{ display:{md: 'none'}, marginBottom: {xs:'3rem'} }}>Welltest</Typography>
                <Grid container direction={{xs:"column-reverse", md:"row"}}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ maxWidth:'490px', mx:{xs:'auto', md:0} }}>
                            <Typography gutterBottom variant="h5" component="div" className="title" sx={{ textAlign:{md: 'start'}, display:{xs: 'none', md:'block'} }}>
                                Welltest
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className="desc">
                                Es un chequeo médico preventivo que ha sido diseñado para conocer el estado actual de su salud. Este examen médico integral incluye una cuidadosa evaluación de su estado nutricional y de sus antecedentes tanto hereditarios como personales, exámenes físicos, medición de capacidad respiratoria y riesgo coronario.
                            </Typography>
                            <Box sx={{width:'100%'}} display='flex' justifyContent={{xs:'center', md:'start'}}>
                                <Button size="small" variant='outlined' className='btn' onClick={handleMoreInfo}>
                                    Más información
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardMedia
                            className="imgCard"
                            component="img"
                            image="/images/welltest_body.jpg"
                            alt="green iguana"
                            sx={{mx:'auto'}}
                            />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Welltest;