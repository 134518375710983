import { Grid } from "@mui/material"
import "./StepBarDesktop.css"
const StepBarDesktop = ({step}) => {
return(
  <>
    <Grid container sx={{mt: '30px'}}>
      <Grid item xs={3}>
          <div  className={step >= 1 ? "title-step title-active": "title-step"}>1. Datos Personales</div>
          <div  className={step >= 1 ? "box-step box-active": "box-step"}></div>
      </Grid>
      <Grid item xs={3}>
        <div  className={step >= 2 ? "title-step title-active": "title-step"}>2. Verificar email </div>
        <div  className={step >= 2 ? "box-step box-active": "box-step"}></div>
      </Grid>
      <Grid item xs={3}>
        <div  className={step >= 3 ? "title-step title-active": "title-step"}>3. Datos Personales</div>
        <div  className={step >= 3 ? "box-step box-active": "box-step"}></div>
      </Grid>
      <Grid item xs={3}>
        <div  className={step >= 4 ? "title-step title-active": "title-step"}>4. Terminos y condiciones</div>
        <div  className={step >= 4 ? "box-step box-active": "box-step"}></div>
      </Grid>
    </Grid>

    {/* <div style={{display:"flex", paddingTop:"30px"}}>
    <div className="step">
      <div  className={step >= 1 ? "title-step title-active": "title-step"}>1. Datos Personales</div>
      <div  className={step >= 1 ? "box-step box-active": "box-step"}></div>
    </div>
    <div className="step">
      <div  className={step >= 2 ? "title-step title-active": "title-step"}>2. Verificar email </div>
      <div  className={step >= 2 ? "box-step box-active": "box-step"}></div>
    </div>
    <div className="step">
      <div  className={step >= 3 ? "title-step title-active": "title-step"}>3. Datos Personales</div>
      <div  className={step >= 3 ? "box-step box-active": "box-step"}></div>
    </div>
    <div className="step">
      <div  className={step >= 4 ? "title-step title-active": "title-step"}>4. Terminos y condiciones</div>
      <div  className={step >= 4 ? "box-step box-active": "box-step"}></div>
    </div>
      </div> */}
 </>
)
}
export default StepBarDesktop