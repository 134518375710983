import { useEffect, useState } from "react";
import RequestApi from '../Request-api/RequestApi'

const useGender = () => {
    const [gender, setGender] = useState([])

    useEffect(() => {

        if(gender.length === 0){
            RequestApi.getGeneros()
            .then(res => {
                setGender(res)
            });
        }

    })

    return gender;
}

export default useGender;