import { useEffect, useState } from "react";
import RequestApi from '../Request-api/RequestApi'

const useTypeDocs = () => {
    const [docs, setDocs] = useState([])

    useEffect(() => {

        if(docs.length === 0){
            RequestApi.getTipoDocs()
            .then(res => {
                setDocs(res)
            });
        }

    })

    return docs
}

export default useTypeDocs;