import { Breadcrumbs, Typography, Link, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from 'react-router-dom';

const Breadcrumb = ({location}) => {

  const history = useHistory();
  
  const breadcrumbs = [   
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleGoHome}>
      Inicio
    </Link>,
    <Typography key="3" color="text.primary">
      {location}
    </Typography>,
  ];

  function handleGoHome(event) {
    event.preventDefault();
    history.push("/pacientes");
  }

  return (
    <>
        <Stack spacing={2}>
          <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
          </Breadcrumbs>
        </Stack>
    </>
  );
}

export default Breadcrumb;