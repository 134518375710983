import { createRef, useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl';
import ReactPinField from "react-pin-field"
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom"
import RequestApi from "../../../../Request-api/RequestApi"
import { Typography } from '@mui/material';
//import ReCAPTCHA from "react-google-recaptcha";
//import { RECAP_KEY } from '../../../../constants';

const VerificarEmail = ({ next, state, setLoading }) => {
  const [ volverAintentar, setVolverAintentar] = useState(false)
  const [code, useCode] = useState("");
  const [error, setError] = useState() 
  const [time, setTime] = useState({
    hours: parseInt(0),
    minutes: parseInt(1),
    seconds: parseInt(59)
  });
  //const [token, setToken] = useState("")
  //const recaptchaF2 = createRef();
/* 
  useEffect(() => {
    setLoading(true)
    recaptchaF2.current.executeAsync().then(()=>{
      setLoading(false)
    })
  },[]) */

 /*  const handleRecaptcha = (r) => {
    setToken(r)
  }
 */
  const tick = () => {
    if (time.hours == 0 && time.minutes == 0 && time.seconds == 0)
      setVolverAintentar(true);
    else if (time.minutes == 0 && time.seconds == 0)
      setTime({
        hours: time.hours - 1,
        minutes: 59,
        seconds: 59
      });
    else if (time.seconds == 0)
      setTime({
        hours: time.hours,
        minutes: time.minutes - 1,
        seconds: 59
      });
    else
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds - 1
      });
  };
  const handleConfirmar = () => {
    setLoading(true)
    RequestApi.checkPinMail({
      "tipoDocumento": state.tipoDoc,
      "nroDocumento": state.dni,
      "pin": code
      }).then(r => {
      if(r.estado === 0){
        const {nombre, apellido, tipoDoc, dni, email} = state;
        RequestApi.savePreregistro(nombre, apellido, tipoDoc, dni, email)
        .then(resp => {
          if(resp.estado === 0){
            setLoading(false)
            next()
          } else {
            setLoading(false)
            setError(resp.mensaje)
          }
        })
      } else if(r.estado === 5){
        setLoading(false)
        setError(r.mensaje)
      } 
    })

}
const handleReenviar = () => {
   setVolverAintentar(false)
   setTime({
    hours: parseInt(0),
    minutes: parseInt(1),
    seconds: parseInt(59)
  });
  RequestApi.sendPinMail({
    "tipoDocumento":state.tipoDocu,
    "nroDocumento":state.documento,
    "email":state.mail,
    "nombre": state.nombre,
    "apellido":state.apellido
  }).then(r => {
    //error
  })
}

 useEffect(() => {
  let timerID = setInterval(() => tick(), 1000);
  return () => clearInterval(timerID);
});

  return(
    <>

      <FormControl fullWidth>
        <p className="text-title"  style={{maxWidth: '400px'}}>
            Te enviamos un e-mail a<b> {state.email} </b>con el código de confirmación.
            Ingresalo para continuar. 
        </p>
        <p className="textInputs-step2">Ingresá el código</p>
        <ReactPinField
          onChange={useCode}
          length={6}
          secret
          style={{
            caretColor: "transparent",
            width: 38,
            height: 50,
            borderRadius: 6,
            border: "2px solid #bdb3c6",
            outline: "none",
            textAlign: "center",
            margin: 4,
            fontSize:"20px"
          }}
        />
        {error&&<p style={{display:"flex", color:"red"}} >{error}</p>}
        <div style={{display:"flex"}}>
        {volverAintentar ? <p onClick={handleReenviar} style={{textDecoration:"underline red", color:"red"}}>Reenviar</p>: 
        <div style={{display:"flex"}}>
        <Typography  sx={{fontSize:{xs:'0.7rem', sm:'1rem', md:'1rem'}, marginRight:'5px'}} className="text-title-cod-step2">
            ¿Todavía no lo recibiste? Volver a enviar el código en 
        </Typography>   
        <Typography sx={{fontSize:{xs:'0.7rem', sm:'1rem', md:'1rem'}}}  className="text-time-step2">
          {`${time.minutes.toString().padStart(2, "0")}
          :${time.seconds.toString().padStart(2, "0")}`}
        </Typography>
        </div>}
        </div>
        <div className= "alert-step2"  style={{maxWidth: '400px'}}>
          <p className="text-mail-step2" >Recordá revisar también tu bandeja de correo no deseado. Tenés 20 minutos para activarlo,
              delo contrario tendrás que registrarte nuevamente. 
          </p>
        </div>
        <div style={{ paddingTop: "40px", paddingBottom: "40px", maxWidth: '500px'}}>
              <ButtonCustom width="40%" onClick={handleConfirmar} name="Continuar" />
        </div>
      </FormControl>
      {/* <ReCAPTCHA
          ref={recaptchaF2}
          sitekey={RECAP_KEY}
          size="invisible"
          onChange={handleRecaptcha}
      /> */}
    </>
  )
}
export default VerificarEmail; 