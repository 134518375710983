import { Container, Typography, Box, Grid } from "@mui/material";

const style = {
    "&":{
        padding: "0",
        maxWidth: "100%"
    },
    "& .footerHeader": {
        fontFamily: "'Lato', sans-serif",
        backgroundColor: "#F6F6F6",
        textAlign: "center",
        padding: "1rem",
    },
    "& .footerHeader p": {
        fontSize: ".8rem",
    },
    "& .footerBody": {
        display: 'flex',
        justifyContent: "space-between",
        backgroundColor: "#363636",
        padding: "2rem",
        paddingLeft: "2.5rem",
        paddingRight: "2.5rem"
    },
    "& .footerContainer": {
        display: 'flex',
        justifyContent: "space-between",
        
    },
    "& .footerBody p": {
        fontFamily: "'Lato', sans-serif",
        color: "#CECECE",
        fontSize: ".8rem"
    },
    "& .footerLogo": {
        display: "flex",
        alignItems: "center",
        justifyContent: {xs:'center', md: "end"},
        mt: {xs: "1rem", md: "0"}
    }
}


let terms = ['© SWISS MEDICAL CENTER', 'Todos los derechos reservados', 'Política de Privacidad del Sitio', 'Términos y Condiciones']

const Footer = () => {

    return (
        <>
            <Container sx={style}>
                <Box className="footerHeader">
                    <Typography>
                        {terms.join(" | ")}
                    </Typography>
                </Box>
                <Box className="footerBody">
                    <Grid container className="footerContainer">
                        <Grid item xs={12} md={6}> 
                            <Box sx={{mx:'auto', maxWidth: {xs: "400px", md: "100%"}}}>
                                <Typography>
                                    Superintendencia de Servicios de Salud
                                </Typography>
                                <Typography>
                                    Organo Control de Obras Sociales y entidades de Medicina Prepaga
                                </Typography>
                                <Typography>
                                    0800-222-SALUD (72583) | www.sssalud.gob.ar | RNEMP Nº 1332
                                </Typography>
                            </Box>                      
                        </Grid>
                        <Grid item xs={12} md={6} className='footerLogo' >
                            <img src="/images/VeriSign.svg" alt='logo verisign'/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default Footer;