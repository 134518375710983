import { Button, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';

const styles = {
    button: {
        color: "#fff",
        borderColor: "#fff",
        borderRadius: "50px",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        "&:hover": {
            borderColor: "#E4002B",
            color: "#E4002B",
        },
        "& p": {
            fontSize: "0.8rem",
            fontFamily: "'Lato', sans-serif",
            marginLeft: "10px",
            textTransform: "initial",
            fontWeight: "bold",
        }
    }
}

const CallButton = () => {
    const { button } = styles;

    const handleCall = () => {
        window.open("tel:0810-333-8876");
    }

    return (
        <Button variant="outlined" sx={button} onClick={handleCall}>
            <CallIcon color="secundary" />
            <Typography>Central de turnos 0810-333-8876</Typography>
        </Button>
    );
}

export default CallButton;