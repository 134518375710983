import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom"
import "./ModalAlertValidarCuenta.css"
import { useHistory } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    height: 300,
    bgcolor: 'background.paper',
  /*   border: '2px solid #000', */
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    textAlign: "center"
  };
  

const ModalAlertValidarCuenta =({open, setOpen})=>{
    const history = useHistory();

    const handleConfirmar = () => {
      history.push('/pacientes/validar-titular')
    }

    const handleClose = () => {
        setOpen(false)
    }
    
    return(
     <Modal
        open={open}
      /*   onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        <button className="close" onClick={handleClose}><img  alt="img" src="../../images/close.svg"></img> </button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
             ¡Atención!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               Es necesario que valides tu identidad
               para ver tus resultados médicos online.
          </Typography>
          <ButtonCustom width={"95%"} onClick={() => handleConfirmar()} name={"Continuar"} />
          <p onClick={handleClose} style={{textDecoration: "underline"  }}>Cancelar</p>
        </Box>
      </Modal>
      )
}
export default ModalAlertValidarCuenta;