import React, { useState, useEffect } from "react";
import DividerCard from "../../../../../../../../componentes/DividerCard/DividerCard";
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom";
import DateOperatations from "../../../../../../../../Utils/useDateOperatations";
import TextFieldCustom from "../../../../../../../../UI/TextFieldCustom/TextFieldCustom";
import { textToUpperCase } from "../../../../../../../../Utils/useTextToUpperCase";
import Spinner from "../../../../../../../../UI/Spinner/Spinner";
import "./DetallesConfirmar.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import utils from "../../../../../../../../Utils/useDate";
import RequestApi from "../../../../../../../../Request-api/RequestApi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ModalIndicaciones from "../../../VerTurno/modalIndicaciones/ModalIndicaciones";
import useIsMobile from "../../../../../../../../Utils/useIsMobile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #FFF",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};
const styles = {
  containerTimes: {
    display: "flex",
  },
  times: {
    display: "inline",
    width: "50%",
  },
};

const DetallesConfirmar = ({ setStep, data, integranteSeleccionado}) => {
  const { isMobile } = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const datosLogin = useSelector((state) => state.authLogin);
  const selectedTurno = useSelector((state) => state.clientes.selectedTurno);
  const handleVolver = () => {
    history.push("/pacientes/turnos");
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [openOrden, setOpenOrden] = useState(false);
  const [checkOrden, setCheckOrden] = useState();
  const [openIndicaciones, setOpenIndicaciones] = useState(false);
  const [textoPreparaciones, setTextoPreparaciones] = useState("");
  const [openModalIndicaciones, setOpenModalIndicaciones] = useState();
  const [selectedDni, setSelectedDni] = useState()
  const [selectedFecNac, setSelectedFecNac] = useState()
  const [consultaId, setConsultaId] = useState()
  const [guardarConsultaId, setGuardarConsultaId] = useState()

  const handleIndicasiones = () => {
    RequestApi.getPreparaciones(data.prac
      , integranteSeleccionado.gamaPk, integranteSeleccionado.planPk, selectedTurno.apimToken)
    .then((response) => {
      setTextoPreparaciones(response.preparacion.descripcion);
    });
  };
  const handleModalIndicaciones = () => {
    setOpenIndicaciones(true)
  }
  const closeModal = () => {
    setOpenOrden(false);
  };
  const openModal = () => {
    setOpenOrden(true);
  };

  const handleEnviarImg = (consultaId) => {
    const multiPartFiles = new FormData();
    multiPartFiles.append("file", selectedImage);
    let body = {
      idEdnsEfector: datosLogin.idEDNS,
      idEdns: datosLogin.idEDNS,
      canal: 2,
      idTipoDoc: 2,
      nroDoc: datosLogin.docNum,
      documentacion: selectedImage.name,
      tipoDocumentacion: 2,
      ipCliente: "12.168.1.1",
      dispositivo: "Redmi Note 7",
      tipoValidacion: 2,
      idUsuario: "14031940",
      nSolic: consultaId
    };
    RequestApi.uploadAWSFile(multiPartFiles).then((res) => {
      RequestApi.guardarOrden(body, res.name);
      closeModal();
    });
  };

  const handleAceptar = () => {
    setSpinner(true);
    let body = {
      prestadorId: data.turno.prestador,
      dia: "MIE",
      fechaDesde: data.turno["fecha-turno"],
      horaDesde: data.turno["hora-desde"],
      idCentro: data.turno["id-centro"],
      cantidadTurnos: 1,
      primeraVez: data.turno["primera-vez"],
      usuLogin:
        utils.convertDate(integranteSeleccionado.fechaNac) +
        integranteSeleccionado.doc +
        "@swissmedical.com.ar",
      especialidadId: data.espeSelected.value,
      consultorioId: data.turno.consultorio,
      practicas: data.practicSelected.value,
      duracionTurno: data.turno["duracion-turno"],
      origenAfiliado: "web",
    };
    RequestApi.sacarTurno(body).then((res) => {
      if (res.respuesta.datosrespuesta.estado !== "Error") {
        setStep(5);
      } else {
        setMensajeError(res.respuesta.datosrespuesta.mensajerespuesta);
      }
      handleEnviarImg(res.respuesta["resultado-confirmacion"]["ticket"]["consulta_id"])
      setSpinner(false);
    });
  };
  const searchDireCentro = (idCentro) => {
    let direCentro;
    data.centros.forEach((c) => {
      if (c.idcentro === idCentro) {
        direCentro = c.domicilio;
      }
    });
    return direCentro;
  };

  const pantallaError = () => {
    return (
      <>
        <p style={{ margin: "30px" }}>{mensajeError}</p>
        <div className="button-select-espe">
          <ButtonCustom
            onClick={handleVolver}
            width={"108%"}
            name="Volver al inicio"
          />
        </div>
      </>
    );
  };
  useEffect(() => {
    RequestApi.checkOrden(
      data.espeSelected.value,
      data.practicSelected.value,
      data.turno["id-centro"]
    ).then((res) => {
      setCheckOrden(res.estado);
    });
  }, []);

  useEffect(() => {
    handleIndicasiones()
  }, []);
  return (
    <>
      <div>
        <Modal
          open={openOrden}
          /*   onClose={handleClose} */
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>
              <p>
                <h2>
                  <b>
                    <u>Adjuntar orden medica:</u>
                  </b>
                </h2>
              </p>
            </Typography>
            <div>
              {selectedImage && (
                <div>
                  <img
                    alt="not fount"
                    width={"250px"}
                    src={URL.createObjectURL(selectedImage)}
                  />
                  <br />
                </div>
              )}
              <br />

              <br />
              <input
                type="file"
                name="myImage"
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </div>
            <ButtonCustom
              primaryColor="#E4002B"
              color="#FFF"
              width={"95%"}
              onClick={closeModal}
              name={"aceptar"}
            />
          </Box>
        </Modal>
      </div>
      {spinner ? (
        <Spinner />
      ) : (
        <>
          {mensajeError ? (
            pantallaError()
          ) : (
            <div>
              <div style={{ margin: "0px 20px", padding: "20px" }}>
                <p className="title-detalles-conf">Detalles del turno</p>
                <p className="subtitle-detalles-conf">
                </p>
                <div className={isMobile ? "modal" : "modalDesktop"}>
                  <div >
                    <u
                      className={isMobile ? "colorLetra" : "colorLetraDesktop"}
                    >
                      
                      {textoPreparaciones&&<ModalIndicaciones
                        textoPreparaciones={textoPreparaciones}
                      />}
                    </u>
                  </div>
                </div>
                <DividerCard />
                <p className="text-name-detalles-conf">
                  {textToUpperCase(
                    data.turno["nombre-abre"] + " " + data.turno["ape-razon"]
                  )}
                </p>
                <p className="puesto-name-detalles-conf">
                  {data.espeSelected.value.name}
                </p>
                <div style={{ margin: "20px 0px" }}>
                  <p className="data-detalles-conf">
                    <b>Fecha y hora: </b>
                    {DateOperatations.getLabelFecha(data.turno["fecha-turno"]) +
                      "  " +
                      data.turno["hora-desde"] +
                      "hs"}
                  </p>
                  <p className="data-detalles-conf">
                    <b>Lugar de Atención: </b>
                    {data.turno["d-centro"]}
                  </p>
                  <p className="data-detalles-conf">
                    <b>Dirección: </b>
                    {searchDireCentro(data.turno["id-centro"])}
                  </p>
                </div>
                <DividerCard />

                {checkOrden === 1 && (
                  <div>
                    <ButtonCustom
                      primaryColor="#E4002B"
                      color="#FFF"
                      onClick={openModal}
                      /* onClose={closeModal} */
                      width="95%"
                      name={"Adjuntar la orden médica"}
                    ></ButtonCustom>

                    <div style={{ textAlign: "center" }}>
                      <p style={{ color: "#363636" }}>
                        Hasta 5 MB (jpg, png o pdf)
                      </p>
                      <p style={{ color: "red" }}>
                        Recordá llevar la orden médica al momento de presentarte
                        al turno.{" "}
                      </p>
                    </div>
                    <DividerCard />
                  </div>
                  )} 

                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#363636",
                  }}
                >
                  En caso de modificarse el horario y/o el día de tu turno nos
                  contactaremos a:
                </p>
                <p className="textInputs">Correo electrónico</p>
                <TextFieldCustom
                  minWidth="270px"
                  type="email" id="email" name="email"
                  placeholder={datosLogin.email}
                ></TextFieldCustom>
                <p className="textInputs">Teléfono</p>
                <TextFieldCustom
                  minWidth="270px"
                  type="tel" id="tel" name="tel"
                  placeholder={datosLogin.tel} pattern="[0-9]{1,10}"
                ></TextFieldCustom>
              </div>

              <ButtonCustom
                onClick={handleAceptar}
                width="95%"
                name="Reservar turno"
              />
              <ButtonCustom
                onClick={handleVolver}
                width="30%"
                height="30%"
                name="Menu"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DetallesConfirmar;
