import HeaderLogin from "../../componentes/NavbarLogin/NavbarLogin";
import useIsMobile from "../../Utils/useIsMobile";
import FieldCustom from "../../UI/FieldCustom/FieldCustom";
import ButtonCustom from '../../UI/ButtonCustom/ButtonCustom';
import RequestApi from '../../Request-api/RequestApi';
import Swal from "sweetalert2";
import { Backdrop, Box, CircularProgress, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RECAP_KEY } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";

const RecoveryEmail = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { isMobile } = useIsMobile();
    const [docs, setDocs] = useState([]);
    const [ state, setState ] = useState("")
    const search = useLocation().search

    const handleRecaptcha = (token) => {
        if (token) {
            handleSubmit(state, token);
        }
    }

    useEffect(()=>{
        RequestApi.getTipoDocs().then(docList => {
            setDocs(docList);
         })
    },[])

    const handleAceptar = (values) => {    
        setLoading(true)
        setState(values)
        window.grecaptcha.execute();
    }


    const handleSubmit = (values, token) => {
        if(values.docFrente && values.docDorso) {
            const multiPartFiles = new FormData();
            multiPartFiles.append('file', values.docFrente)
            multiPartFiles.append('file', values.docDorso)
            const pid = new URLSearchParams(search).get('pid')
            RequestApi.uploadAWSFile(multiPartFiles)
            .then(r => {
                if(r.estado === 0){
                    RequestApi.recoveryEmail({
                        "canal": isMobile ? 2 : 1,
                        "idTipoDoc": values.docType,
                        "nroDoc": values.docNum,
                        "documentacion":`${values.docFrente.name};${values.docDorso.name}`,
                        "tipoDocumentacion": 2,
                        "email": values.email,
                        "emailRepetir": values.email,
                        "ipCliente": window.CLIENT_IP,
                        "dispositivo": navigator.userAgent,
                        "tipoValidacion": 2,
                        "idUsuario": values.docNum,
                        "idPersona": pid
                    }, token).then(res => {
                        setLoading(false)
                        if(res.estado === 0){
                          Swal.fire({
                            title:"Documentación enviada",
                            text:"Nuestro equipo la revisará en las próximas 24 horas. Te enviaremos una notificación con el estado de tu activación.",
                            timer: 10000,
                            confirmButtonText: "Entendido",
                            willClose: history.push('/login')
                          })
                        }
                    }).catch(err => {
                        setLoading(false)
                        Swal.fire({
                            title: "Error al enviar la solicitud",
                            text: "Estamos teniendo problemas al procesar esta solicitud, por favor intente nuevamente más tarde.",
                            icon: "error"
                        })
                    });
                }
            }).catch(err => {
                setLoading(false)
                Swal.fire({
                    title: "Error al enviar la solicitud",
                    text: "Estamos teniendo problemas al procesar esta solicitud, por favor intente nuevamente más tarde.",
                    icon: "error"
                })
            });
        } else {
            //Por favor, cargue frente y dorso del documento
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {isMobile && <HeaderLogin />}
            <Box display='flex' >
                <Box height='100vh' width='60%' display={isMobile && 'none'} sx={{ backgroundImage: 'url("../../../images/back_login.png")', backgroundSize: 'cover' }}>
                <p className="card-title">¡Hola!</p>
                <p className="card-sub-title">
                    Ingresá para reservar turnos y ver resultados de estudios.
                </p>
                <p className="card-desc">
                    Si estás asociado a Swiss Medical Medicina Privada accedé a estas funcionalidades desde <a href="https://www.swissmedical.com" className="card-link">www.swissmedical.com</a>
                </p>
                </Box>
                <Box height='100vh' width={isMobile ? '100%' : '40%'} display='flex' alignContent='center' >
                    <Container sx={{width: '100%', display: 'flex', justifyContent: 'center', pt: {xs:'5rem', sm: 0} }}>
                        <Formik
                            initialValues={{
                                docNum: '',
                                docType: 2,
                                email: '',
                                docFrente: '',
                                docDorso: ''
                            }}
                            onSubmit={handleAceptar}
                        >
                            {({ errors, touched, values, handleChange, setFieldValue }) => (
                                <Form style={{maxWidth:'30rem'}}>
                                    <Grid container>
                                        <Grid xs={12} item container sx={{px:'1rem'}}>
                                            <Grid xs={4} item>
                                                <FieldCustom
                                                    id="docType"
                                                    name="docType"
                                                    variant='select'
                                                    options={docs?.map(d => { return { name: d.name, value: d.id } })}
                                                    placeholder='DNI'
                                                    label='Documento'
                                                    value={values.docType}
                                                    onChange={handleChange}
                                                    error={touched.docType && Boolean(errors.docType)}
                                                    helperText={touched.docType && errors.docType}
                                                />
                                            </Grid>
                                            <Grid xs={8} item sx={{pl: '1rem'}}>
                                                <FieldCustom 
                                                    id="docNum"
                                                    name="docNum"
                                                    placeholder='Número'
                                                    label='hidden'
                                                    value={values.docNum}
                                                    onChange={handleChange}
                                                    error={touched.docNum && Boolean(errors.docNum)}
                                                    helperText={touched.docNum && errors.docNum}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} item container sx={{px:'1rem'}} >
                                            <FieldCustom 
                                                id="email"
                                                name="email"
                                                placeholder='Email'
                                                label='Nuevo email'
                                                value={values.email}
                                                onChange={handleChange}
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Grid>
                                        <Grid xs={12} item container sx={{p:'1rem'}} justifyContent='center'>
                                            <FieldCustom 
                                                id="docFrente"
                                                variant='file'
                                                label='Subir foto del frente DNI'
                                                newFile={setFieldValue}
                                                fileName='DNI-FRENTE-RECOVERY-EMAIL'
                                            />
                                            <FieldCustom 
                                                id="docDorso"
                                                variant='file'
                                                label='Subir foto del dorso DNI'
                                                newFile={setFieldValue}
                                                fileName='DNI-DORSO-RECOVERY-EMAIL'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{width: '100%'}} display='flex' justifyContent='center'>
                                        <ButtonCustom width="40%" type='submit' name="Continuar" sx={{mt:'1rem'}}/>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Box>
            </Box>
            
            <ReCAPTCHA
                sitekey={RECAP_KEY}
                size="invisible"
                onChange={handleRecaptcha}
            />
        </>
    )
}

export default RecoveryEmail;