import React from 'react';
import { Typography, Box, Grid, CardMedia, Container, Breadcrumbs, Link } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useEffect } from 'react';
import CentersAcc from './CentersAcc/CentersAcc'
import Navbar from '../../componentes/Navbar/Navbar';
import Footer from '../../componentes/Footer/Footer';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { useHistory } from 'react-router-dom';
import DividerCard from '../../componentes/DividerCard/DividerCard';


const styles = {
    section: {
        marginY: { xs: '1rem', sm: '-1rem', md: '2rem', lg: '2.5rem' },
        marginX: { xs: '1rem', sm: '4rem', md: '4rem', lg: '0' },
        marginBottom: { xs: '6rem', sm: '5rem', md: '3rem', lg: '7rem' },
        "& ul": {
            pl: 3,
            "& li": {
                fontFamily: "'Lato', sans-serif",
            }
        },
        "& .itemDesc": {
            fontFamily: "'Lato', sans-serif",
            color: "#666666"
        },
        "& .subtitleAcc": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold"
        },
        "& .titleAcc": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            mt: "1rem"
        },
        "& .subtitleAccRed": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            my: "1rem"
        },
        "& .anotherTitle": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B"
        },
        "& .gridImg": {
            display: "flex",
            width: "100%",
            justifyContent: { xs: 'center', md: 'flex-end' },
            marginTop: { sm: '2.5rem', md: '-1rem' },
            paddingLeft: { md: '3rem' }
        },
        "& .imgCard": {
            maxWidth: { md: '372px', lg: '400px' },
            height: { md: '371px' },
            borderRadius: { md: '25px' },
        },
        "& .containerImgText": {
            display: "flex",
            alignItems: 'flex-start',
            flexDirection: 'row-reverse',
        },
        "& .containerText": {
            marginTop: { xs: '2rem', md: '0' },
            marginBottom: '1.5rem'
        },
        "& .textTypography": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: '1rem',
        },
        "& .textTypographyDown": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: '1.5rem'
        },
        "& .contacts": {
            fontFamily: "'Lato', sans-serif",
            color: '#E4002B',
            fontWeight: 'bold',
            marginBottom: '0.2rem'
        }, "& .contactsDown": {
            fontFamily: "'Lato', sans-serif",
            color: '#797979'
        },
        "& .btn": {
            fontFamily: "'Lato', sans- serif",
            fontWeight: "bold",
            color: '#E4002B',
            textTransform: 'initial',
            borderRadius: '40px',
            borderColor: '#E4002B',
            height: '40px',
            padding: '15px',
            paddingX: { lg: '1.5rem' },
            marginY: { xs: '2rem', md: '2.5rem', lg: '0' },
            ':hover': {
                borderColor: '#E4002B',
            }
        },
        "& .containerTel": {
            paddingRight: { md: '2.5rem' }
        }
        ,
        "& .containerEmail": {
            paddingRight: { lg: '3rem' }
        },
        "& .textMail": {
            mt: "2rem",
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold"
        },
        "& .contactMail" : {
            mt: "0.5rem",
            fontFamily: "'Lato', sans-serif",
            fontSize: "1rem",
            color: "#797979",
        }

    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "8rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerTitle": {
            marginTop: { xs: '1rem', md: '9rem' },
            fontWeight: "bold",
            fontSize: "2.125rem",
            fontFamily: "'Lato', sans-serif",

        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const services = ["Atención de guardia","Atención programada","Estudios de diagnóstico","Pentacam","Topografía corneal","Tomografía Óptica Confocal (OCT)","Ecografía ocular","Ecometría por ultrasonido","IOL Master (Ecometría por láser).","Campimetría computarizada","Recontador endotelial","Queratoautorefractometría"]

const Oftalmologia = () => {
    const { headerSection, section } = styles;
    const history = useHistory();
    const handleClick = (e) => {
        e.preventDefault();
        history.push("/")
    }
    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <Navbar />
            <Container sx={{
                mt: '74px',
                maxWidth: '1366px',
                px: { xs: 0 },
            }}>
                <Breadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb"
                    pt=".5rem"
                >
                    <Link
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/"
                        onClick={handleClick}
                    >
                    Inicio
                    </Link>,
                    <Typography key="2" color="text.primary">
                    Oftalmología
                    </Typography>
                </Breadcrumbs>
                <Box sx={headerSection}>
                    <Typography className="headerTitle">Oftalmología de Alta Complejidad</Typography>
                    <HorizontalRuleIcon className="headerIcon" />
                </Box>
                <Box sx={section}>
                    <Grid className="containerImgText" container>
                        <Grid className="gridImg" item sx={12} md={5}>
                            <CardMedia className="imgCard" component="img" image="/images/info/oftalmologia.jpg" alt="Oftalmología de Alta Complejidad" />
                        </Grid>
                        <Grid item sx={12} md={7}>
                            <Box className='containerText'>
                                <Typography className="itemDesc">
                                Conformado por un destacado staff de profesionales y con equipamiento diagnóstico y terapéutico de última generación, el servicio de Oftalmología de Alta Complejidad se encuentra preparado para atender las más variadas patologías de la visión.
                                </Typography>
                                <Typography className="titleAcc"> 
                                Encuentre este servicio en:
                                </Typography>
                            </Box>
                            <Box sx={{ mt: '2rem' }}>
                                <CentersAcc title="Swiss Medical Center Barrio Norte"> 
                                    <Typography className='subtitleAcc'>Pueyrredón 1443, pisos 10 y 11</Typography>
                                    <ul>
                                        {services.map((s,index) => <li key={index}>{s}</li>)}
                                    </ul>
                                </CentersAcc>
                                <CentersAcc title="Swiss Medical Center Barrio Parque">
                                    <Typography className='subtitleAcc'>San Martín de Tours 2980</Typography>
                                    <Box>
                                        <Typography className='subtitleAccRed'>
                                            Quirófano 1: 
                                        </Typography>
                                        <Typography className='itemDesc'>
                                            Destinado a la cirugía oftalmológica de cataratas, retina, vítreo, glaucoma, plásticas, entre otras, y terapias antiogénicas.
                                            Cuenta con equipamiento de primer nivel mundial:
                                        </Typography>
                                        <ul>
                                            <li>Microscopio Carl Zeiss Opmi Lumera</li>
                                            <li>Facoemulsificador Alcon Infiniti</li>
                                            <li>Vitréctomo Alcon Constellation</li>
                                        </ul>
                                    </Box>
                                    <Box>
                                        <Typography className='subtitleAccRed'>
                                            Quirófano 2: 
                                        </Typography>
                                        <Typography className='itemDesc'>
                                            Excimer Láser Alcon Wave Light 500 para cirugía refractiva, de última generación.
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className='subtitleAccRed'>
                                            Consultorio 3: 
                                        </Typography>
                                        <ul>
                                            <li>Láser argón</li>
                                            <li>Yag láser</li>
                                        </ul>
                                    </Box>
                                </CentersAcc>
                            </Box>
                            <DividerCard />
                            <Box>
                                <Typography className='textMail'>
                                    Por dudas o consultas, escribinos al siguientes mail:
                                </Typography>
                                <Typography className='contactMail'>
                                    Reoftalmología@swissmedical.com.ar
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    )
}

export default Oftalmologia