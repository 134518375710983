import { Breadcrumbs, Typography, Link, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from 'react-router-dom';

const Breadcrumb = ({ location }) => {

  const history = useHistory();

  const breadcrumbs = [
    <Link ml='1rem' underline="hover" key="1" color="inherit" href="/" onClick={handleGoHome}>
      Home
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleGoHome}>
      Centros médicos
    </Link>,
    <Typography sx={{ marginLeft: { xs: '1rem', md: '0' } }} key="3" color="text.primary">
      {location}
    </Typography>,
  ];

  function handleGoHome(event) {
    event.preventDefault();
    history.push("/");
  }

  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </>
  );
}

export default Breadcrumb;