import { AxiosInstance } from "../axios-instance";
import { idServices } from "../constants";

let ClientesApi = {
  checkFinal(body) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/validacion/final",
      body
    ).then(({ data }) => data);
  },
  getTipoDocs() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/documentos/tipos"
    ).then(({ data }) => data);
  },
  getEstCivil() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/estados/civiles"
    ).then(({ data }) => data);
  },
  getFechaTurnoDispo(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/turnos/primero-disponible`,
      body
    ).then(({ data }) => data);
  },
  getHsTurnos(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/turnos/disponibles`,
      body
    ).then(({ data }) => data);
  },
  sacarTurno(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/turnos/confirmar`,
      body
    ).then(({ data }) => data);
  },
  getPreparaciones(prestacion, namePlan, gamaPlan, tokenRecaptcha) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/apim/cons/preparaciones?prestacion=${prestacion}&consulta=&filtro=A&plan=${namePlan}&gama=${gamaPlan}`,
      { headers: { recaptcha: tokenRecaptcha } }
    ).then(({ data }) => data);
  },
  eliminarTurnos(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/turnos/anulacion`,
      body
    );
  },
  getTurnos(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/turnos/consulta`,
      body
    ).then(({ data }) => data);
  },
  /*  restriccionesPorCentro(edad, sexo, plan, gama, centro, servBasico) {
    return AxiosInstance.get(`/adminsitio/registernosociosmg/apim/cons/restr/especialidades?edad=${edad}&sexo=${sexo}&plan=${plan}&gama=${gama}&centro=${centro}&servBasico=${servBasico}`).then(({ data }) => data);
  }, */
  checkOrden(servBas, prestacion, centro) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/apim/cons/checkOrdenes?servBas=${servBas}&prestacion=${prestacion}&centro=${centro}`
    ).then(({ data }) => data);
  },
  guardarOrden(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/formulario/5/validacion`,
      body
    );
  },
  notificaciones(idEdns) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/nosocios/${idEdns}/notificaciones`
    ).then(({ data }) => data);
  },
  getProvincias() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/provincias"
    ).then(({ data }) => data);
  },
  checkCuil(cuilCuit, tipoDoc, nroDoc, cuiId) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/apim/srv/cui/${cuiId}/${cuilCuit}/docu/${tipoDoc}/${nroDoc}/validar`
    ).then(({ data }) => data);
  },
  getCuilCuit(docType, docNum, fechaNa) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/apim/srv/doc/${docType}/${docNum}/${fechaNa}/cuitCuil`
    ).then(({ data }) => data);
  },
  getProductos(tipoDocumento, documento, tokenRecaptcha) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/registro/personas/${tipoDocumento}/${documento}/productos`,
      { headers: { recaptcha: tokenRecaptcha } }
    ).then(({ data }) => data);
  },
  checkPinMail(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/pin/verificar`,
      body
    ).then(({ data }) => data);
  },
  sendPinMail(body, tokenRecaptcha) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/pin`,
      body,
      { headers: { recaptcha: tokenRecaptcha } }
    ).then(({ data }) => data);
  },
  getTerminos() {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/registro/entidades/${idServices.edns}/canales/1/terminos`
    ).then(({ data }) => data);
  },
  crearUsuario(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/crear`,
      body
    ).then(({ data }) => data);
  },
  crearUsuarioLite(body, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/nosocios/crear`,
      body,
      { headers: { Authorization: token } }
    ).then(({ data }) => data);
  },
  registerLite(body, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/nosocios/register-lite`,
      body,
      { headers: { Authorization: token } }
    ).then(({ data }) => data);
  },
  aceptarTerminos(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/aceptar/terminos`,
      body
    ).then(({ data }) => data);
  },
  getPlanes() {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/maestro/planes`
    ).then(({ data }) => data);
  },
  getTipoPlan(idPlan) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/maestro/planes/${idPlan}/tipos`
    ).then(({ data }) => data);
  },
  validarIdentidadManual(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/formulario/1/validacion`,
      body
    ).then(({ data }) => data);
  },
  login(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/login/nosocio`,
      body
    ).then(({ data }) => data);
  },
  traerCentros(body) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/maestro/centros/planes/2/gamas/4783`,
      body
    ).then(({ data }) => data);
  },
  getGrupo(id) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/nosocios/${id}/grupofam`
    ).then(({ data }) => data);
  },
  shareStudy(destName, destEmail, destComents, bodyStudy) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/estudios/compartir",
      {
        nombre: destName,
        destinatario: destEmail,
        comentario: destComents,
        imgBase64: bodyStudy,
      }
    );
  },
  getPDF_inf(body) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/apim/turnos/get/informes/pdf",
      body,
      { responseType: "arraybuffer" }
    ).then(({ data }) => data);
  },
  getPDF_lab(body) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/apim/turnos/get/laboratorio/pdf",
      body,
      { responseType: "arraybuffer" }
    ).then(({ data }) => data);
  },
  getEstudiosImagen_dashboard(fechaNac, documento, tipoDocumento, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/imagenes/estudios`,
      {
        fechaNac,
        documento,
        tipoDocumento,
      },
      { headers: { Authorization: token } }
    ).then(({ data }) => data).catch((err) => err);
  },
  getEstudiosLab_dashboard(fechaNac, documento, tipoDocumento, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/laboratorio/estudios`,
      {
        fechaNac,
        documento,
        tipoDocumento,
      },
      { headers: { Authorization: token } }
    ).then(({ data }) => data).catch((err) => err);
  },
  getTurnos_dashboard(fechaDesde, fechaHasta, usuLogin, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/dashboard/turnos/consulta`,
      {
        sistema: "CMA",
        fechaDesde,
        fechaHasta,
        usuLogin,
      },
      { headers: { Authorization: token } }
    ).then(({ data }) => data).catch((err) => err);
  },
  getHistorial_dashboard(fecha, usuLogin, cantMax = 5, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/apim/dashboard/turnos/historial`,
      {
        sistema: "CMA",
        fecha,
        usuLogin,
        cantMax
      },
      { headers: { Authorization: token } }
    ).then(({ data }) => data).catch((err) => err);
  },
  getGeneros() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/generos"
    ).then(({ data }) => data);
  },
  getNacionalidades() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/nacionalidades"
    ).then(({ data }) => data);
  },
  checkPreregistro(docType, docNum, mail) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/check/prerregistro`,
      {
        tipoDocumento: docType,
        nroDocumento: docNum,
        email: mail,
      }
    ).then(({ data }) => data);
  },
  savePreregistro(nombre, apellido, tipoDoc, docNum, email, tokenRecaptcha) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/registro/grabar/prerregistro",
      {
        nombre: nombre,
        apellido: apellido,
        mApellido: null,
        tipoDocumento: tipoDoc,
        documento: docNum,
        email: email,
        estado: 1,
        tokenRecaptcha: tokenRecaptcha,
      }
    ).then(({ data }) => data);
  },
  uploadAWSFile(multiPartFiles) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/aws/s3/uploadFile",
      multiPartFiles,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then(({ data }) => data);
  },
  recoveryPass(idPersona, token) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/change-password/pin/${idPersona}`,
      null,
      { headers: { Authorization: token } }
    ).then(({ data }) => data);
  },
  chPassword(idPersona, password, oldPass, tokenRecaptcha) {
    return AxiosInstance.put(
      "/adminsitio/registernosociosmg/registro/personas/upd/password",
      {
        idPersona: idPersona,
        password: password,
        oldPassword: oldPass,
      },
      { headers: { recaptcha: tokenRecaptcha } }
    ).then(({ data }) => data);
  },
  chPasswordSocio(idPersona, password, token) {
    return AxiosInstance.put(
      "/adminsitio/registernosociosmg/registro/personas/upd/password/valid",
      {
        idPersona: idPersona,
        password: password,
      },
      { headers: { Authorization: token } }
    ).then(({ data }) => data);
  },
  getProfile(idEDNS) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/nosocios/${idEDNS}/datos-perfil`
    ).then(({ data }) => data);
  },
  putProfile(obj, idEDNS) {
    return AxiosInstance.put(
      `/adminsitio/registernosociosmg/nosocios/${idEDNS}/modificar`,
      {
        ...obj,
      }
    ).then(({ data }) => data);
  },
  setFamiliar(obj) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/nosocios/asociar-nuevo",
      obj
    ).then(({ data }) => data);
  },
  asociarFamiliar(tituData, inteData) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/nosocios/asociar",
      {
        canal: 1,
        vinculo: 3,
        titular: {
          idEdns: tituData.idEdns,
          nroDoc: tituData.nroDoc,
          tipoDoc: tituData.tipoDoc,
        },
        integrante: {
          idEdns: inteData.idEdns,
          nroDoc: inteData.nroDoc,
          tipoDoc: inteData.tipoDoc,
        },
      }
    );
  },
  verificarIntegrante(tipoDoc, numDoc) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/nosocios/integrante/${tipoDoc}/${numDoc}/verificar`
    ).then(({ data }) => data);
  },
  validarMenor(obj) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/registro/formulario/2/validacion",
      obj
    ).then(({ data }) => data);
  },
  validarVinculo(obj) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/registro/formulario/4/validacion",
      obj
    ).then(({ data }) => data);
  },
  getTipoDocumentacion() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/documentacion/tipos"
    ).then(({ data }) => data);
  },
  putEmail(idPersona, idEdns, email) {
    return AxiosInstance.put(
      "/adminsitio/registernosociosmg/registro/personas/upd/email",
      {
        idPersona: idPersona,
        idEdns: idEdns,
        nuevoEmail: email,
      }
    ).then(({ data }) => data);
  },
  getAfiliadoStatus(docNum, docType, tokenRecaptcha) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/apim/cons/planactivo/${docNum}/${docType}`,
      { headers: { recaptcha: tokenRecaptcha } }
    ).then(({ data }) => data);
  },
  forgotService(body, tokenRecaptcha) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/registro/forgot-password",
      body,
      { headers: { recaptcha: tokenRecaptcha } }
    ).then(({ data }) => data);
  },
  desasociarFamiliar(idEDNS, idEdnsVinculo) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/nosocios/des-asociar",
      {
        idEdnsTitular: idEDNS,
        idEdnsRelacionado: idEdnsVinculo,
      }
    ).then(({ data }) => data);
  },
  getProductosV2(idPersona, tokenRecaptcha, jwtToken) {
    return AxiosInstance.get(
      `/adminsitio/registernosociosmg/registro/personas/${idPersona}/productos`,
      { headers: { recaptcha: tokenRecaptcha, Authorization: jwtToken } }
    ).then(({ data }) => data);
  },
  getSedes() {
    return AxiosInstance.get(
      "/adminsitio/registernosociosmg/maestro/welltest/centros"
    ).then(({ data }) => data);
  },
  sendWelltestTurn(body, tokenRecaptcha) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/landing/welltest/form",
      body,
      { headers: { recaptcha: tokenRecaptcha } }
    );
  },
  recoveryEmail(body, tokenRecaptcha) {
    return AxiosInstance.post(
      `/adminsitio/registernosociosmg/registro/formulario/3/validacion`,
      body,
      {
        headers: {
          recaptcha: tokenRecaptcha,
        },
      }
    ).then(({ data }) => data);
  },
  getEstudios(docNum, fechaNac, docType) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/apim/turnos/estudios",
      {
        fechaNac: fechaNac,
        documento: `${docNum}`,
        tipoDocumento: docType,
      }
    ).then(({ data }) => data);
  },
  getImgToken(fechaNac, docNum, docType, accNum, patientId) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/apim/turnos/imagen",
      {
        fechaNac: fechaNac,
        documento: docNum,
        tipoDocumento: docType,
        accessionNumber: accNum,
        patientId: patientId,
      }
    ).then(({ data }) => data);
  },
  registerEbooking(
    fechaNac,
    docNum,
    docType,
    email,
    sexo,
    nombre,
    apellido,
    tel
  ) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/apim/turnos/register",
      {
        naci_fecha: fechaNac,
        docu_nro: docNum,
        docu_tipo: docType,
        email: email,
        sexo: sexo,
        nombre: nombre,
        ape: apellido,
        telefono: tel,
      }
    );
  },
  submitContact(apeYnombre, tel, email, asunto, comentario, tokenRecaptcha) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/landing/contacto/form",
      {
        apeYnombre: apeYnombre,
        telefono: tel,
        email: email,
        asunto: asunto,
        comentarioSugerencia: comentario,
      },
      { headers: { recaptcha: tokenRecaptcha } }
    );
  },
  resendForm(token) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/validacion/resend/link",
      null,
      { headers: { Authorization: `Bearer ${token}` } }
    ).then(({ data }) => data);
  },
  validationResend(docNames, idUsuario, token) {
    return AxiosInstance.post(
      "/adminsitio/registernosociosmg/validacion/formularios/resend",
      {
        documentacion: docNames,
        canal: 2,
        ipCliente: window.CLIENT_IP,
        dispositivo: "Redmi Note 7",
        tipoValidacion: 2,
        idUsuario: `${idUsuario}`,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
};

export default ClientesApi;
