import { Grid } from '@mui/material';
import { centers } from '../../../../../Data/Centers.json';
import CenterCard from '../CenterCard/CenterCard';
import Carousel from 'react-material-ui-carousel';
import useIsMedium from "../../../../../Utils/useIsMedium";
import useIsLarge from "../../../../../Utils/useIsLarge";
import useIsMobile from "../../../../../Utils/useIsMobile";

export default function CentersRow() {
  const { isMedium } = useIsMedium();
  const { isLarge } = useIsLarge();
  const { isMobile } = useIsMobile();

  const buildCarouselTemplate = () => {

    let allCenters = [];
    let packCenters = [];

    centers.forEach(center => {
      packCenters.push(center);
      if (!isMedium && isLarge) {
        if (packCenters.length === 2) {
          allCenters.push(packCenters);
          packCenters = [];
        }
      } else {
        if (packCenters.length === 3 || (centers.length % 3 !== 0 && center === centers[centers.length - 1])) {
          allCenters.push(packCenters);
          packCenters = [];
        }
      }
    })

    return allCenters
  }
  return (
    <>
      <Carousel
        animation="slide"
        duration={1000}
        autoPlay={false}
        navButtonsAlwaysVisible={true}
        fullHeightHover={false}
        navButtonsProps={{
          style: {
            backgroundColor: 'unset',
            borderRadius: 0,
            color: '#000',
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#E4002B',
          }
        }}
        navButtonsWrapperProps={{
          style: {
            bottom: '40px',
            top: 'unset',
            display: isMobile ? 'none' : ''
          }
        }}
        indicatorContainerProps={{
          style: {
            width: '80%',
            marginLeft: '10%',
            marginRight: '10%',
            marginTop: '30px',
            display: isMobile ? 'none' : ''
          }
        }}
        sx={{ paddingBottom: "5rem" }}
      >
        {
          isMedium
            ? centers.map((center,index) => {
              return (
                <Grid container key={index} >
                  <Grid item xs justifyContent='center' display='flex' >
                    <CenterCard title={center.title} address={center.sections[0].title} thumb={center.thumbnail} href={center.href}  />
                  </Grid>
                </Grid>
              )
            }
            )
            : buildCarouselTemplate().map((pack,index) => {
              return (
                <Grid container key={index}>
                  {
                    pack.map((center,index) => {
                      return (
                        <Grid item xs={6} lg={4} justifyContent='center' display='flex' key={index}>
                          <CenterCard title={center.title} address={center.sections[0].title} thumb={center.thumbnail} href={center.href}/>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              )
            })
        }
      </Carousel>
    </>
  );
}