import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import RequestApi from "../../../../../../Request-api/RequestApi";
import useOS from "../../../../../../Utils/useOS";
import ToolTipOS from "../../../../../Register/Forms/DatosGenerales/ToolTIp/ToolTip";
import SelectCustom from "../../../../../../UI/SelectCustom/SelectCustom";
import TextFieldCustom from "../../../../../../UI/TextFieldCustom/TextFieldCustom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setEditProfile } from "../../../../../../Redux/Reducers/editProfile";
import Spinner from "../../../../../../UI/Spinner/Spinner";

const EditPlan = () => {
  const planes = useOS();
  const [tipoPlanes, setTipoPlanes] = useState([]);
  const auxRef = useRef(null);
  const { idEDNS } = useSelector((state) => state.authLogin);
  const { prepaga, loading } = useSelector((state) => state.editProfile);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    if (e.target.name === "planPk") {
      dispatch(
        setEditProfile("prepaga", {
          ...prepaga,
          planPk: e.target.value,
          gamaPk: "",
        })
      );
    } else if (e.target.name === "gamaPk") {
      dispatch(
        setEditProfile("prepaga", {
          ...prepaga,
          gamaPk: e.target.value,
        })
      );
    } else if (prepaga?.gamaPk === -1) {
      if (e.target.name === "gamaEspecial") {
        dispatch(
          setEditProfile("prepaga", {
            ...prepaga,
            gamaEspecial: e.target.value,
          })
        );
      } else {
        dispatch(
          setEditProfile("prepaga", {
            ...prepaga,
            planEspecial: e.target.value,
          })
        );
      }
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleConfirmar = () => {
    RequestApi.putProfile(
      {
        planPk: prepaga?.planPk,
        gamaPk: prepaga?.gamaPk,
        planEspecial: prepaga?.planEspecial,
        gamaEspecial: prepaga?.gamaEspecial,
      },
      idEDNS
    ).then((r) => {
      if (r.estado === 0) {
        Swal.fire({
          icon: "success",
          title: "Información actualizada",
          text: "Tús datos fueron modificados correctamente.",
          willClose: () => {
            history.goBack();
          },
        });
      }
    });
  };

  useEffect(() => {
    RequestApi.getTipoPlan(prepaga.planPk).then((r) => {
      setTipoPlanes(r);
    });
  }, [prepaga.planPk]);

  useEffect(() => {
    if (!auxRef.current) {
      dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: true });
      RequestApi.getProfile(idEDNS).then((response) => {
        dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: false });
        dispatch(
          setEditProfile("prepaga", {
            ...prepaga,
            planPk: response.planPk,
            planDesc: response.planDesc,
            gamaPk: response.gamaPk,
            gamaDesc: response.gamaDesc,
            gamaEspecial: response.gamaDesc,
            planEspecial: response.planDesc,
          })
        );
      });
      auxRef.current = true;
    }
    return () => (auxRef.current = true);
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "30px",
                p: { xs: "2rem 1rem", md: "1rem 2rem 1rem 0.5rem" },
                mb: "2rem",
              }}
            >
              <Typography>Cambiar mi plan</Typography>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  bgcolor: "#BCBCBC",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  p: "3px 20px",
                  letterSpacing: "2px",
                  "&:hover": {
                    bgcolor: "#BCBCBC",
                    filter: "hue-rotate(90deg) brightness(110%)",
                  },
                }}
              >
                Volver
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectCustom
                placeholder="Seleccionar"
                icon={<ToolTipOS />}
                label="Obra Social / Prepaga"
                value={prepaga?.planPk}
                options={planes?.map((p) => {
                  return { name: p.planDesc, value: p.planPk };
                })}
                onChange={handleOnChange}
                name="planPk"
              />
              {prepaga?.planPk === -1 && (
                <>
                  <p className="textInputs-register">¿Cuál?</p>
                  <TextFieldCustom
                    onChange={handleOnChange}
                    placeholder="Ingresá tu obra social"
                    value={prepaga?.planEspecial}
                    name="planEspecial"
                  ></TextFieldCustom>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <>
                <SelectCustom
                  placeholder="Seleccionar"
                  icon={<ToolTipOS />}
                  label="Tipo de plan"
                  value={prepaga?.gamaPk}
                  options={tipoPlanes?.map((p) => {
                    return { name: p.gamaDesc, value: p.gamaPk };
                  })}
                  onChange={handleOnChange}
                  disable={!tipoPlanes.length}
                  name="gamaPk"
                />
                {prepaga?.gamaPk === -1 && (
                  <>
                    <p className="textInputs-register">¿Cuál?</p>
                    <TextFieldCustom
                      onChange={handleOnChange}
                      placeholder="Ingresá el tipo de plan"
                      value={prepaga?.gamaEspecial}
                      name="gamaEspecial"
                    ></TextFieldCustom>
                  </>
                )}
              </>
            </Grid>
            <Grid item sx={{ mt: 4, p: "0.5rem" }}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleConfirmar}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
export default EditPlan;
