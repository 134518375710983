import React from 'react';
import { Typography, Box, Grid, CardMedia, Divider, Button } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useEffect } from 'react';


const styles = {
    section: {
        marginY: { xs: '1rem', sm: '-1rem', md: '2rem', lg: '2.5rem' },
        marginX: { xs: '1rem', sm: '4rem', md: '4rem', lg: '0' },
        marginBottom: { xs: '6rem', sm: '5rem', md: '3rem', lg: '7rem' },


        "& .gridImg": {
            display: "flex",
            width: "100%",
            justifyContent: { xs: 'center', md: 'flex-end' },
            marginTop: { sm: '2.5rem', md: '-1rem' },
            paddingLeft: { md: '3rem' }
        },
        "& .imgCard": {
            maxWidth: { md: '372px', lg: '400px' },
            height: { md: '371px' },
            borderRadius: { md: '25px' },
        },
        "& .containerImgText": {
            display: "flex",
            alignItems: 'flex-start',
            flexDirection: 'row-reverse',
        },
        "& .containerText": {
            marginTop: { xs: '2rem', md: '0' },
            marginBottom: '1.5rem'
        },
        "& .textTypography": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: '1rem'
        },
        "& .textTypographyDown": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: '1.5rem'
        },
        "& .contacts": {
            fontFamily: "'Lato', sans-serif",
            color: '#E4002B',
            fontWeight: 'bold',
            marginBottom: '0.2rem'
        }, "& .contactsDown": {
            fontFamily: "'Lato', sans-serif",
            color: '#797979'
        },
        "& .btn": {
            fontFamily: "'Lato', sans- serif",
            fontWeight: "bold",
            color: '#E4002B',
            textTransform: 'initial',
            borderRadius: '40px',
            borderColor: '#E4002B',
            height: '40px',
            padding: '15px',
            paddingX: { lg: '1.5rem' },
            marginY: { xs: '2rem', md: '2.5rem', lg: '0' },
            ':hover': {
                borderColor: '#E4002B',
            }
        },
        "& .containerTel": {
            paddingRight: { md: '2.5rem' }
        }
        ,
        "& .containerEmail": {
            paddingRight: { lg: '3rem' }
        }

    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "8rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerTitle": {
            marginTop: { xs: '1rem', md: '9rem' },
            fontWeight: "bold",
            fontSize: "2.125rem",
            fontFamily: "'Lato', sans-serif",

        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const LineaDenuncia = () => {
    const { headerSection, section } = styles;

    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <Box sx={headerSection}>
                <Typography className="headerTitle">Línea de denuncias</Typography>
                <HorizontalRuleIcon className="headerIcon" />
            </Box>
            <Box sx={section}>
                <Grid className="containerImgText" container>
                    <Grid className="gridImg" item sx={12} md={5}>
                        <CardMedia className="imgCard" component="img" image="/images/info/linea_de_denuncia.jpg" alt="linea de denuncia" />
                    </Grid>
                    <Grid item sx={12} md={7}>
                        <Box className='containerText'>
                            <Typography className="textTypography">A efectos de continuar con la implementación de las mejores prácticas de mercado y en línea con estándares de integridad y transparencia, ponemos a tu disposición un canal confidencial de comunicación para el tratamiento de denuncias.
                            </Typography>
                            <Typography className="textTypographyDown">
                                En caso de tomar conocimiento sobre alguna actividad fraudulenta relacionada a hurtos de activos de la compañía, malversación de fondos y robo de información, hechos de corrupción, hurtos de activos de la compañía, presunta mala praxis, conflicto de intereses, incumplimientos y/o conductas antiéticas, y toda otra situación irregular relacionada con la empresa, te solicitamos por favor que te contactes a los siguientes canales de denuncia disponibles:
                            </Typography>
                            <Divider />
                        </Box>
                        <Box sx={{ display: { lg: 'flex' } }} >
                            <Grid sx={{ display: { md: 'flex' } }} containerst >
                                <Grid className='containerTel' items>
                                    <Typography className='contacts'>Teléfono</Typography>
                                    <Typography sx={{ display: { lg: 'flex' }, flexDirection: { lg: 'column' } }} className='contactsDown'>0800-999-7745 <Box component="span" >o 4363-4041</Box></Typography>
                                </Grid>
                                <Grid className='containerEmail' items>
                                    <Typography sx={{ marginTop: { xs: '1.5rem', md: '0' } }} className='contacts'>Email</Typography>
                                    <Typography className='contactsDown'>denuncias@swissmedical.com.ar</Typography>
                                </Grid>
                            </Grid>
                            <Box>
                                <Button
                                    rel="noopener noreferrer"
                                    href="https://forms.office.com/Pages/ResponsePage.aspx?id=lNZmVu8c-EOD-QW0-dMsQwEcjBUaXSFFmYW2rElPvrBUOVBKN1k5NUJFV0k0UzBKWjFITlJGQkVQSi4u"
                                    target="_blank"
                                    sx={{ width: { xs: '100%', sm: '40%', md: '45%', lg: '100%' } }}
                                    size="small"
                                    variant='outlined'
                                    className='btn'
                                >
                                    Formulario de denuncias
                                </Button>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default LineaDenuncia