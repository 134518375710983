import { ListItem, Typography } from "@mui/material"

const ServiceItem = ({title, desc}) => {

    return (
        <>
            <ListItem sx={{mt:'1rem', px:0}}>
                <Typography sx={{fontWeight:'bold', fontFamily:"'Lato', sans-serif"}}>{title}</Typography>
            </ListItem>
            <ListItem sx={{py: 0, px:0}}>
                <Typography sx={{color:'#797979', fontFamily:"'Lato', sans-serif"}}>{desc}</Typography>
            </ListItem>
        </>
    )
}

export default ServiceItem