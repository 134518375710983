import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import "./SelectCustom.css"


const BootstrapInput = styled(InputBase)(({ theme }) => ({
   /*  'label + &': {
      marginTop: theme.spacing(3),
    }, */
    '& .MuiInputBase-input': {
      textAlign: "initial",
      borderRadius: 6,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
     
      '&:focus': {
        borderRadius: 4,
       /*  borderColor: '#80bdff', */
     /*    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)', */
      },
    },
  }));

export default function ControlledOpenSelect({icon, defaultValue, options, label, onChange, value, minWidth, disable, placeholder, name, sx}) {
 
  const [open, setOpen] = React.useState(false);
  const useMinWidth = minWidth ? minWidth : 300

  

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: '90%', maxWidth: '300px', ...sx}}>
        <p className="textInputSelect">{icon} {label}</p>
        <Select
          displayEmpty
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          disabled={disable}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          onChange={onChange}
          IconComponent = {KeyboardArrowDownIcon}
          defaultValue={defaultValue}
          name={name}
          input={<BootstrapInput />} 
        >
           <option hidden selected>{placeholder}</option>
          {options.map(op => 
            <MenuItem key={op.value} value={op.value} disable={disable} style={{color: op.visualiza === "V"? "red": "black"}}> {op.name}</MenuItem>
 
          )}
      
        </Select>
      </FormControl>
    </>
  );
}