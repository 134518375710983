import Navbar from "../../componentes/Navbar/Navbar";
import Footer from "../../componentes/Footer/Footer";
import { Switch, Route } from "react-router-dom";
import { Container } from "@mui/material";
import MediosPago from "./MediosPago/MediosPago";
import WellTest from "./WellTest/WellTest";
import LineaDenuncia from "./LineaDenuncia/LineaDenuncia";
import Contacto from "./Contacto/Contacto";
import React, { useEffect } from 'react';

const styles = {
    marginTop: '74px',
    maxWidth: '1366px',
    paddingX: { xs: 0 },
}


const InformacionGeneral = () => {

    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <Navbar />
            <Container sx={styles}>
                <Switch>
                    <Route exact path='/info/mediosdepago' component={MediosPago} />
                    <Route exact path='/info/welltest' component={WellTest} />
                    <Route exact path='/info/lineadenuncia' component={LineaDenuncia} />
                    <Route exact path='/info/contacto' component={Contacto} />

                </Switch>
            </Container>
            <Footer />
        </>
    )
}

export default InformacionGeneral;