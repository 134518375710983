import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
    boxShadow: 'none',
    "& .accTitle": {
        fontWeight: 'bold',
        lineHeight: 1.9,
        fontFamily: "'Lato', sans-serif",
    },
    "& .accLogo": {
        width: "32px",
        mr: "1rem"
    },
    "& .accLi": {
        fontFamily: "'Lato', sans-serif",
    }
}

const CentersAcc = ({title, children}) => {
    return (
        <>
            <Accordion sx={styles} key={title}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6" className='accTitle'>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default CentersAcc;