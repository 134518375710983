import { FormControl, styled, TextField, Typography } from "@mui/material";

const CustomTextField = styled(TextField)(({ custom1, normal }) => ({
  ...(normal && {
    "& .MuiFormHelperText-root": {
      position: "relative",
      color: "red",
      fontSize: "13.5px",
    },
  }),
//   ...(custom1 && {
//     "& .MuiFormHelperText-root": {
//       color: "red",
//       fontSize: "13.5px",
//       width: "100%",
//       position: "absolute",
//       marginTop: "55px",
//       left: "1rem",
//     },
//   }),
}));

const CustomInput = ({
  label,
  name,
  placeholder,
  onChange,
  type = "text",
  helperText,
  error,
  onBlur,
  sx,
  accept,
  multiline = false,
  rows = 4,
  custom1 = "false",
  normal = "false"
}) => {
  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: "1.5rem" }}>
        <Typography sx={{ marginBottom: ".8rem" }}>{label}</Typography>
        <CustomTextField
          custom1={custom1}
          normal={normal}
          id={`input-${type}-${name}`}
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          multiline={multiline}
          rows={rows}
          helperText={helperText}
          error={error}
          onBlur={onBlur}
          sx={sx}
          inputProps={{
            accept: accept,
          }}
        />
      </FormControl>
    </>
  );
};

export default CustomInput;
