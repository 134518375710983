import { useState, useEffect, useCallback } from "react";
import { Backdrop, CircularProgress, Container, Grid } from "@mui/material";
import CustomSelect from "../../../../../../UI/CustomSelect/CustomSelect";
import CustomInput from "../../../../../../UI/CustomInput/CustomInput";
import CustomDateInput from "../../../../../../UI/CustomDateInput/CustomDateInput";
import CustomRedButton from "../../../../../../UI/CustomRedButton/CustomRedButton";
import CustomBreadcrumb from "../../../../../../UI/CustomBreadcrumb/CustomBreadcrumb";
import RedNotify from "../../../../../../UI/RedNotify/RedNotify";
import useTypeDocs from "../../../../../../Utils/useTypeDocs";
import useNationality from "../../../../../../Utils/useNationality";
import useGender from "../../../../../../Utils/useGender";
import useOS from "../../../../../../Utils/useOS";
import RequestApi from "../../../../../../Request-api/RequestApi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { setErrorField } from "../../../../../../Redux/Reducers/grupoFamiliarReducer";

const VincularFamiliar = () => {
  const docTypes = useTypeDocs();
  const nationality = useNationality();
  const gender = useGender();
  const OS = useOS();
  const history = useHistory();
  const [idPlan, setIdPlan] = useState("");
  const [tipoPlanes, setTipoPlanes] = useState([]);
  const { idEDNS, tel, email, docNum, docType } = useSelector(
    (state) => state.authLogin
  );
  const { errorField } = useSelector((state) => state.grupoFamiliar);
  const [loading, setLoading] = useState(false);
  const dispath = useDispatch();

  const [state, setState] = useState({
    vinculo: 3,
    nombre: "",
    apellido: "",
    fechaNac: "",
    nacionalidad: "",
    direccion: "",
    codPostal: "",
    provincia: "",
    ciudad: "",
    tipoDoc: "DU",
    numDoc: "",
    genero: "",
    obraSocial: "",
    tipoPlan: "",
    gamaEspecial: null,
    planEspecial: null,
  });

  const handleChange = (event) => {
    if (event.target.name === "obraSocial") {
      setIdPlan(event.target.value);
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const dniRequirements = (dni = "") => {
    if (dni === "") {
      return "Este campo es requerido";
    } else if (dni && dni.length > 9) {
      return "Exceso en la longitud de caracteres";
    }
  };

  const handleContinuar = () => {
    dispath(
      setErrorField({
        ...errorField,
        planEspecial:
          (state?.tipoPlan === -1 && !state?.planEspecial) ||
          (state?.obraSocial === -1 && !state?.planEspecial)
            ? "Este campo es requerido"
            : "",
        gamaEspecial:
          state?.obraSocial === -1 && !state?.gamaEspecial
            ? "Este campo es requerido"
            : "",
        nombre: !state?.nombre ? "Este campo es requerido" : "",
        apellido: !state?.apellido ? "Este campo es requerido" : "",
        fechaNac: !state?.fechaNac ? "Este campo es requerido" : "",
        genero: !state?.genero ? "Este campo es requerido" : "",
        nacionalidad: !state?.nacionalidad ? "Este campo es requerido" : "",
        numDoc: dniRequirements(state?.numDoc),
        tipoDoc: !state?.tipoDoc ? "Este campo es requerido" : "",
        obraSocial: !state?.obraSocial ? "Este campo es requerido" : "",
      })
    );
    setTimeout(() => {
      dispath(
        setErrorField({
          ...errorField,
          planEspecial: "",
          gamaEspecial: "",
          nombre: "",
          apellido: "",
          fechaNac: "",
          genero: "",
          nacionalidad: "",
          numDoc: "",
          tipoDoc: "",
          obraSocial: ""
        })
      );
    }, 5000);

    if (
      (state?.tipoPlan !== -1 ||
        (state?.tipoPlan === -1 && state?.planEspecial) ||
        (state?.obraSocial === -1 &&
          state?.planEspecial &&
          state?.gamaEspecial)) &&
      state?.nombre &&
      state?.apellido &&
      state?.fechaNac &&
      state?.genero &&
      state?.tipoDoc &&
      state?.numDoc &&
      state?.obraSocial &&
      state?.numDoc.length < 10 &&
      state?.nacionalidad
    ) {
      setLoading(true);
      RequestApi.verificarIntegrante(
        docTypes.filter((doc) => doc.value === state.tipoDoc)[0].id,
        state.numDoc
      ).then((r) => {
        if (r.estado === -1) {
          RequestApi.setFamiliar({
            titular: {
              idEdns: idEDNS,
              nroDoc: docNum,
              tipoDoc: docType,
            },
            asociado: {
              vinculo: 3,
              datos: {
                tipoDoc: docTypes.filter(
                  (doc) => doc.value === state.tipoDoc
                )[0],
                nroDoc: state.numDoc,
                nombre: state.nombre,
                apellido1: state.apellido,
                telefono1: tel,
                genero: gender.filter((gend) => gend.value === state.genero)[0],
                fechaNac: state.fechaNac,
                domDireccion: state.direccion,
                nacionalidad: state.nacionalidad,
                provincia: state.provincia,
                ciudad: state.ciudad,
                codigoPostal: state.codPostal,
                email: email,
                planPk: state.obraSocial,
                gamaPk: state.tipoPlan,
                planEspecial:
                  state?.tipoPlan === -1 ? state?.planEspecial : null,
                gamaEspecial:
                  state?.obraSocial === -1 ? state?.gamaEspecial : null,
              },
            },
          })
            .then((res) => {
              if (res.estado === 0) {
                history.push("/pacientes");
                Swal.fire({
                  title: "Familiar vinculado",
                  text: "Familiar vinculado correctamente",
                  icon: "success",
                  willClose: history.push("/pacientes/familiares"),
                });
              } else {
                Swal.fire({
                  title: "Familiar ya vinculado",
                  text: "Este familiar ya se vinculó anteriormente. Por favor revisar el estado actual desde la sección Grupo Familiar",
                  icon: "warning",
                });
              }
              setLoading(false);
            })
            .catch((e) => {
              Swal.fire({
                title: "No pudimos asociarte a este familiar",
                text: e,
                icon: "error",
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
          const inteData = r.pacientes[0];
          Swal.fire({
            title: "Este familiar ya existe en nuestras bases",
            text: "¿Deseas asociarlo a esta cuenta?.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Asociar",
          }).then((result) => {
            if (result.isConfirmed) {
              setLoading(true);
              RequestApi.asociarFamiliar(
                {
                  idEdns: idEDNS,
                  nroDoc: docNum,
                  tipoDoc: docTypes.filter(
                    (doc) => doc.value === docType.value
                  )[0],
                },
                {
                  idEdns: inteData.idEdns,
                  nroDoc: inteData.nroDoc,
                  tipoDoc: docTypes.filter(
                    (doc) => doc.id === inteData.tipoDoc
                  )[0],
                }
              )
                .then(() => {
                  history.push("/pacientes");
                  Swal.fire({
                    title: "Familiar vinculado",
                    text: "Familiar vinculado correctamente",
                    icon: "success",
                    willClose: history.push("/pacientes/familiares"),
                  });
                })
                .catch((err) => {
                  Swal.fire({
                    title: "No pudimos asociarte a este familiar",
                    text: err,
                    icon: "error",
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              history.push("/pacientes/familiares");
            }
          });
        }
      });
    }
  };

  const links = [
    {
      text: "Inicio",
      location: "/pacientes",
    },
    {
      text: "Grupo familiar",
      location: "/pacientes/familiares",
    },
  ];

  useEffect(() => {
    idPlan &&
      RequestApi.getTipoPlan(idPlan).then((r) => {
        setTipoPlanes(r);
      });
  }, [idPlan]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Grid
          container
          spacing={{ xs: 0, md: 3 }}
          paddingY={3}
          maxWidth
          margin={0}
        >
          <Grid item xs={12}>
            <CustomBreadcrumb links={links} site="Vincular familiar" />
            <RedNotify />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect
              items={[{ value: 3, name: "Hijo/a" }]}
              onChange={handleChange}
              value={state?.vinculo || ""}
              name="vinculo"
              label="Tipo de vinculo *"
              disabled
            />
            <CustomInput
              onChange={handleChange}
              label="Nombre *"
              name="nombre"
              placeholder="Ingresá su nombre"
              helperText={errorField?.nombre || ""}
            />
            <CustomInput
              onChange={handleChange}
              label="Apellido *"
              name="apellido"
              helperText={errorField?.apellido || ""}
              placeholder="Ingresá su apellido"
            />
            <CustomDateInput
              onChange={handleChange}
              label="Fecha de nacimiento *"
              errorText={errorField?.fechaNac || ""}
            />
            <CustomSelect
              items={nationality?.map((item) => {
                return {
                  value: item.nacionalidadPk,
                  name: item.nacionalidadDesc,
                };
              })}
              errorText={errorField?.nacionalidad || ""}
              onChange={handleChange}
              value={state.nacionalidad}
              name="nacionalidad"
              label="Nacionalidad *"
            />
            <CustomInput
              onChange={handleChange}
              label="Provincia"
              name="provincia"
              placeholder="Ingresá la provincia"
            />
            <CustomInput
              onChange={handleChange}
              label="Ciudad"
              name="ciudad"
              placeholder="Ingresá la ciudad"
            />
            <CustomInput
              onChange={handleChange}
              label="Código postal"
              name="codPostal"
              placeholder="Ingresá el código postal"
            />
            <CustomInput
              onChange={handleChange}
              label="Dirección"
              name="direccion"
              placeholder="Ingresá la dirección"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect
              items={docTypes?.map((item) => {
                return { value: item.value, name: item.name };
              })}
              onChange={handleChange}
              value={state?.tipoDoc || ""}
              errorText={errorField?.tipoDoc || ""}
              name="tipoDoc"
              label="Tipo de documento *"
            />
            <CustomInput
              onChange={handleChange}
              label="Numero de documento *"
              value={state?.numDoc || ""}
              helperText={errorField?.numDoc || ""}
              placeholder="Ingresá el número de su documento"
              type="number"
              name="numDoc"
            />
            <CustomSelect
              items={gender?.map((item) => {
                return { value: item.value, name: item.name };
              })}
              onChange={handleChange}
              value={state?.genero || ""}
              name="genero"
              errorText={errorField?.genero || ""}
              label="Género *"
            />
            <CustomSelect
              placeholder="Seleccionar"
              label="Obra Social / Prepaga *"
              errorText={errorField?.obraSocial || ""}
              items={OS?.map((p) => {
                return { name: p.planDesc, value: p.planPk };
              }).sort((a, b) => {
                const textA = a.name.toUpperCase();
                const textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })}
              onChange={handleChange}
              name="obraSocial"
            ></CustomSelect>
            {idPlan === -1 ? (
              <>
                <p className="textInputs-register">¿Cuál? *</p>
                <CustomInput
                  onChange={handleChange}
                  placeholder="Ingresá tu obra social *"
                  helperText={errorField?.gamaEspecial || ""}
                  name="gamaEspecial"
                ></CustomInput>
                <p className="textInputs-register">Tipo de plan *</p>
                <CustomInput
                  onChange={handleChange}
                  placeholder="Ingresá tu tipo de plan *"
                  helperText={errorField?.planEspecial || ""}
                  name="planEspecial"
                ></CustomInput>
              </>
            ) : (
              <CustomSelect
                placeholder="Seleccionar"
                label="Tipo de plan *"
                items={tipoPlanes
                  ?.map((p) => {
                    return { name: p.gamaDesc, value: p.gamaPk };
                  })
                  .sort()}
                onChange={handleChange}
                disabled={!tipoPlanes.length}
                name="tipoPlan"
              ></CustomSelect>
            )}
            {state?.tipoPlan === -1 && state?.obraSocial !== -1 && (
              <CustomInput
                onChange={handleChange}
                multiline={true}
                rows={4}
                label="Descripción *"
                helperText={errorField?.planEspecial || ""}
                placeholder="Descripción del tipo de plan"
                type="text"
                name="planEspecial"
              />
            )}
            <CustomRedButton text="Continuar" onClick={handleContinuar} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default VincularFamiliar;
