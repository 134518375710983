import { SvgIcon } from "@mui/material";

const CardIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.2524 0C31.0151 0 31.6653 0.209524 32.1982 0.624762C32.7311 1.04 33 1.54667 33 2.14095V17.859C33 18.4533 32.7311 18.96 32.1982 19.3752C31.6653 19.7905 31.0151 20 30.2524 20H2.74756C1.98489 20 1.33467 19.7905 0.801778 19.3752C0.268889 18.96 0 18.4533 0 17.859V2.14095C0 1.54667 0.268889 1.04 0.801778 0.624762C1.33467 0.209524 1.98489 0 2.74756 0H30.2524ZM2.74756 1.42857C2.47867 1.42857 2.25867 1.49714 2.08756 1.63048C1.91644 1.76381 1.82844 1.93524 1.82844 2.14476V4.28571H31.1667V2.14095C31.1667 1.93143 31.0787 1.76 30.9076 1.62667C30.7364 1.49333 30.5164 1.42476 30.2476 1.42476H2.74756V1.42857ZM30.2524 18.5714C30.5213 18.5714 30.7413 18.5029 30.9124 18.3695C31.0836 18.2362 31.1716 18.0648 31.1716 17.8552V8.57143H1.83333V17.859C1.83333 18.0686 1.92133 18.24 2.09244 18.3733C2.26356 18.5067 2.48356 18.5752 2.75244 18.5752H30.2524V18.5714ZM11 14.8229V15.181C11 15.539 10.7702 15.7181 10.3107 15.7181H6.18933C5.72978 15.7181 5.5 15.539 5.5 15.181V14.8229C5.5 14.4648 5.72978 14.2857 6.18933 14.2857H10.3156C10.7751 14.2857 11.0049 14.4648 11.0049 14.8229H11ZM22 14.8229V15.181C22 15.539 21.7702 15.7181 21.3107 15.7181H13.5178C13.0582 15.7181 12.8284 15.539 12.8284 15.181V14.8229C12.8284 14.4648 13.0582 14.2857 13.5178 14.2857H21.3107C21.7702 14.2857 22 14.4648 22 14.8229Z" fill="#E4002B"/>
            </svg>
        </SvgIcon>
    )
}

export default CardIcon;