import { useState, useEffect, createRef } from "react"
import HeaderClientes from "../../componentes/HeaderClientes/HeaderClientes"
import useIsMobile from "../../Utils/useIsMobile"
import SelectProducto from "./Forms/SelectProducto/SelectProducto"
import DatosGenerales from "./Forms/DatosGenerales/DatosGenerales"
import DatosPrincipales from "./Forms/DatosPrincipales/DatosPrincipales"
import VerificarEmail from "./Forms/VerificarEmail/VerificarEmail"
import { Box, Container, Backdrop, CircularProgress } from "@mui/material"
import CustomStepBar from "./CustomStepBar/CustomStepBar";
import RequestApi from "../../Request-api/RequestApi"
import { RECAP_KEY } from '../../constants';
import ReCAPTCHA from "react-google-recaptcha";
import "./Register.css"

const Register = () => {
   const [step, setStep] = useState(1); 
   const { isMobile } = useIsMobile();
   const [docs, setDocs] = useState([]);
   const [productos, setProductos] = useState([]);
   const [loading, setLoading] = useState(true);
   const recaptchaInstance = createRef();

   const [state, setState] = useState({
      preRegistro: {
         nombre: "",
         apellido: "",
         dni: "",
         email: "",
         tipoDoc: 2,
         tokenRecaptcha: ""
      },
      registro: {
         nacionalidad: "",
         telefono: "",
         codArea: "",
         provincia: "",
         ciudad: "",
         direccion: "",
         codPostal: "",
         piso: "",
         depto: "",
         fechaNacimiento: "",
         genero: "",
         password: "",
         obraSocial: "",
         tipoPlan: "",
         otro_obraSocial: null,
         otro_tipoPlan: null
      }
   })

   const getTipoDoc = (value) => {
      return docs.find(obj => obj.id == value)
   }


   const handleNextStep = (st) => {
      st !== undefined ? setStep(st) : setStep(step + 1)
   }

   const setPreRegistro = (values) => {
      setState({
         ...state,
         preRegistro: {
            ...state.preRegistro,
            ...values
         }
      })
   }

   const setRegistro = (values) => {
      setState({
         ...state,
         registro: {
            ...state.registro,
            ...values
         }
      })
   }

   const handleOnChangeRegistro = (e) => {
      setState({
         ...state,
         registro: {
            ...state.registro,
            [e.target.name]: e.target.value
         }
      })
   }

   const handleRecaptcha = (r) => {
      if(r) {
         setState({
            ...state,
            preRegistro: {
               ...state.preRegistro,
               tokenRecaptcha: r
            }
         })
      }
   }

   useEffect(() => {
      setLoading(true)
      recaptchaInstance.current.executeAsync().then(()=>{
         setLoading(false)
      });
      RequestApi.getTipoDocs().then(docList => {
         setDocs(docList);
      })
   }, [])

   return (
      <>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <HeaderClientes />
         <Container>
            <CustomStepBar step={step} setStep={setStep} />
            <Box>
               {step === 1 && <DatosPrincipales next={handleNextStep} setPreRegistro={setPreRegistro} state={state.preRegistro} docs={docs} getTipoDoc={getTipoDoc} setProductos={setProductos} setLoading={setLoading} updateRecaptcha={recaptchaInstance}/>}
               {step === 2 && <VerificarEmail next={handleNextStep} state={state.preRegistro} setLoading={setLoading} />}
               {step === 3 && <DatosGenerales next={handleNextStep} setRegistro={setRegistro} onChange={handleOnChangeRegistro} state={state.registro} updateRecaptcha={recaptchaInstance}/>}
               {step === 4 && <SelectProducto state={state} getTipoDoc={getTipoDoc} productos={productos} setLoading={setLoading} />}
            </Box>
            <ReCAPTCHA
               ref={recaptchaInstance}
               sitekey={RECAP_KEY}
               size="invisible"
               onChange={handleRecaptcha}
            />


            {!isMobile && <img className="float-lower-left" style={{
               position: "fixed",
               bottom: "0",
               right: "0",
               zIndex: -5
            }} alt="logo" src="../../../images/ilustracion_registro.png" />}
         </Container>


      </>
   )
}
export default Register

