import Default from './Default';
import Select from './Select';
import ComboBox from './ComboBox';
import FileInput from './FileInput';

const variants = {
    default: Default,
    select: Select,
    combobox: ComboBox,
    file: FileInput
};

export default variants;