import { SvgIcon } from "@mui/material";

function DeleteIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M14.4643 1.12501H10.4464L10.1317 0.467584C10.065 0.327034 9.96233 0.208807 9.83515 0.126202C9.70798 0.0435979 9.56137 -0.000106452 9.41183 6.16384e-06H5.58482C5.43562 -0.000596081 5.28927 0.0429453 5.16255 0.125642C5.03582 0.208338 4.93385 0.326845 4.8683 0.467584L4.55357 1.12501H0.535714C0.393634 1.12501 0.257373 1.18427 0.156907 1.28976C0.0564412 1.39525 0 1.53832 0 1.68751L0 2.81251C0 2.96169 0.0564412 3.10476 0.156907 3.21025C0.257373 3.31574 0.393634 3.37501 0.535714 3.37501H14.4643C14.6064 3.37501 14.7426 3.31574 14.8431 3.21025C14.9436 3.10476 15 2.96169 15 2.81251V1.68751C15 1.53832 14.9436 1.39525 14.8431 1.28976C14.7426 1.18427 14.6064 1.12501 14.4643 1.12501ZM1.78125 16.418C1.8068 16.8464 1.98688 17.2485 2.28484 17.5424C2.5828 17.8363 2.97623 17.9999 3.38504 18H11.615C12.0238 17.9999 12.4172 17.8363 12.7152 17.5424C13.0131 17.2485 13.1932 16.8464 13.2187 16.418L13.9286 4.5H1.07143L1.78125 16.418Z" fill="#363636"/>
      </SvgIcon>
    );
  }

export default DeleteIcon;