import {Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button} from '@mui/material';
import { headers } from '../../../Data/Navbar.json';
import NavMenuItemMobile from './NavMenuItemMobile';
import MenuIngresar from '../../MenuIngresar/MenuIngresar';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleOpen } from '../../../Redux/Reducers/signinModalReducer';

const styles = {
  "& .accordion": {
    boxShadow: 'none',
    "& .accDetail": {
      paddingY: 0,
    },
    "& .accSummary": {
      minHeight: 'auto',
      "& p":{
        fontFamily: "'Lato', sans-serif",
        fontWeight: 'bold',
        fontSize: '.8rem',
      }
    }
  },
  "& .subMenu": {
    paddingY: 0,
    "& .btnMenu": {
      paddingY: .3,
      "& span": {
        fontFamily: "'Lato', sans-serif",
        fontWeight: 'bold',
        fontSize: '.8rem',
        textTransform: 'uppercase',
        color: "#666666",
      }
    }
  },
  "& .boxButtons": {
    display: 'flex',
    alignItems:'center',
    flexDirection:'column',
    width: '100%',
    padding: '1rem',
    "& button": {
      borderRadius: "40px",
      fontFamily:"'Lato', sans-serif",
      fontSize:'.8rem',
      textTransform: 'capitalize',
      width: '100%',
      margin: '.5rem',
      padding: '.5rem'
    },
    "& .btnAsociar": {
      border: '1px solid #ccc',
      color: "#000",
      borderColor: "#000",
    },
    "& .btnIngresar": {
        color: "#fff",
        backgroundColor: "#E4002B",
        borderColor: "#000"
    },
  }
}

const NavMenuMobile = ({handleDrawerClose}) => {

  const dispatch = useDispatch();
  const handleToggleAsociarme = () => {
    window.open('https://asociarme.swissmedical.com.ar/contacto', '_blank');
  }

  return (
        <>
          <Box sx={styles}>
            {headers.map((header, index) => {
              return (
                <Accordion className='accordion' key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={`header-${index}`}
                    className='accSummary'
                  >
                    <Typography>{header.label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='accDetail'>
                    <NavMenuItemMobile childrens={header.childrens} handleDrawerClose={handleDrawerClose}/>
                  </AccordionDetails>
                </Accordion>
              )
            })
            }
            <Box className='boxButtons'>
              <Button className="btnAsociar" onClick={handleToggleAsociarme}>Quiero asociarme</Button>
              <Button className="btnIngresar" onClick={() => dispatch(toggleOpen(true))}>Ingresar</Button>
            </Box>
          </Box>
          <MenuIngresar />
        </>
  );
}

export default NavMenuMobile;