import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpecCol from './SpecCol/SpecCol';

const styles = {
    boxShadow: 'none',
    "& .accTitle": {
        fontWeight: 'bold',
        lineHeight: 1.9,
        fontFamily: "'Lato', sans-serif",
    },
    "& .accLogo": {
        width: "32px",
        mr: "1rem"
    },
    "& .accLi": {
        fontFamily: "'Lato', sans-serif",
    }
}

const Especialidades = ({specialties, centerId}) => {
    return (
        <>
            {specialties.map((specialty, indx) => {
                return(
                    <Accordion sx={styles} key={`${centerId}-${indx}`}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <img src={specialty.icon} className='accLogo' alt={`Logo ${specialty.title}`} />
                        <Typography variant="h6" className='accTitle'>{specialty.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SpecCol list={specialty.list} centorId={centerId}/>
                            {
                            specialty.explanations ? 
                            <span>
                            {specialty.explanations.map((item, index) => {
                                return (
                                    <Typography key={`${centerId}-${index}`}>{item}</Typography>
                                )
                            })}
                            </span> : null
                            }
                        </AccordionDetails>
                    </Accordion>        
                )
            })}
        </>
    )
}

export default Especialidades;