import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useHistory } from "react-router-dom";


const NavMenuItemMobile = ({childrens, handleDrawerClose}) => {

    const history = useHistory();

    const handleOnClick = (href) => {
        if(!href.includes('http')){
            history.push(href);
        } else {
            window.open(href, '_blank');
        }
        handleDrawerClose();
        window.scrollTo(0, 0);
    }


    return (
        <>
            <List className="subMenu">
            {childrens.map((child) => (
                <ListItem key={child.label} disablePadding>
                    <ListItemButton className="btnMenu">
                        <ListItemText primary={child.label} onClick={()=> handleOnClick(child.href)}/>
                    </ListItemButton>
                </ListItem>
            ))}
            </List>
        </>
    )
}

export default NavMenuItemMobile;