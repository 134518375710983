import { useEffect, useRef } from "react";
import RequestApi from "../../../../../../Request-api/RequestApi";
import SelectCustom from "../../../../../../UI/SelectCustom/SelectCustom";
import TextFieldCustom from "../../../../../../UI/TextFieldCustom/TextFieldCustom";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useNationality from "../../../../../../Utils/useNationality";
import useProvince from "../../../../../../Utils/useProvince";
import Swal from "sweetalert2";
import { setEditProfile } from "../../../../../../Redux/Reducers/editProfile";
import Spinner from "../../../../../../UI/Spinner/Spinner";

const EditLocation = () => {
  const nacionalidades = useNationality();
  const provincias = useProvince();
  const history = useHistory();
  const dispatch = useDispatch();
  const auxRef = useRef(null);
  const { idEDNS, location } = useSelector((state) => state.authLogin);
  const { domicilio, loading } = useSelector((state) => state.editProfile);

  const handleOnChange = (e) => {
    dispatch(
      setEditProfile("domicilio", {
        ...domicilio,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleConfirmar = () => {
    RequestApi.putProfile(
      {
        domDireccion: domicilio.direccion,
        nacionalidad: domicilio.nacionalidad,
        provincia: domicilio.provincia,
        ciudad: domicilio.ciudad,
        codigoPostal: domicilio.codPostal,
      },
      idEDNS
    ).then(() => {
      Swal.fire({
        icon: "success",
        title: "Información actualizada",
        text: "Tús datos fueron modificados correctamente.",
        willClose: () => {
          history.goBack();
        },
      });
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!auxRef.current) {
      dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: true });
      RequestApi.getProfile(idEDNS).then((response) => {
        dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: false });
        dispatch(
          setEditProfile("domicilio", {
            ...domicilio,
            nacionalidad: response.nacionalidad,
            provincia: response.provincia,
            ciudad: response.ciudad,
            direccion: response.direccion,
            codPostal: response.codigoPostal,
          })
        );
      });
      auxRef.current = true;
    }
    return () => (auxRef.current = true);
  }, []);

  return (
    <>
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "30px",
                p: { xs: "2rem 1rem", md: "1rem 2rem 1rem 0.5rem" },
              }}
            >
              <Typography>Cambiar domicilio</Typography>
              <Button
                onClick={handleBack}
                sx={{
                  borderRadius: "8px",
                  bgcolor: "#BCBCBC",
                  color: "#FFFFFF",
                  fontWeight: "500",
                  p: "3px 20px",
                  letterSpacing: "2px",
                  "&:hover": {
                    bgcolor: "#BCBCBC",
                    filter: "hue-rotate(90deg) brightness(110%)"
                  }
                }}
              >
                Volver
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectCustom
                label="Nacionalidad"
                placeholder="Seleccionar"
                options={nacionalidades?.map((n) => {
                  return { name: n.nacionalidadDesc, value: n.nacionalidadPk };
                })}
                fullWidth
                name="nacionalidad"
                onChange={handleOnChange}
                value={domicilio.nacionalidad || ""}
              ></SelectCustom>
              <SelectCustom
                label="Provincia"
                placeholder="Seleccionar"
                options={provincias?.map((n) => {
                  return { name: n.nombreProvincia, value: n.idProvincia };
                })}
                fullWidth
                name="provincia"
                onChange={handleOnChange}
                value={domicilio.provincia || ""}
              ></SelectCustom>

              <p className="textInputs-register">Ciudad</p>
              <TextFieldCustom
                onChange={handleOnChange}
                placeholder="Ingresá tu Ciudad"
                name="ciudad"
                value={domicilio.ciudad || ""}
              ></TextFieldCustom>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <p className="textInputs-register">
                  Dirección (Calle y Número)
                </p>
                <TextFieldCustom
                  onChange={handleOnChange}
                  placeholder="Ingresá tu Dirección"
                  name="direccion"
                  value={domicilio.direccion || ""}
                ></TextFieldCustom>
              </div>
              <div>
                <p className="textInputs-register">Código Postal</p>
                <TextFieldCustom
                  onChange={handleOnChange}
                  placeholder="Ingresá tu Código Postal"
                  name="codPostal"
                  value={domicilio.codPostal || ""}
                ></TextFieldCustom>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <p className="textInputs-register">Piso (Opcional)</p>
                <TextFieldCustom
                  onChange={handleOnChange}
                  placeholder="Ingresá tu Piso"
                  name="piso"
                  value={domicilio.piso || ""}
                ></TextFieldCustom>
              </div>
              <div>
                <p className="textInputs-register">Depto (Opcional)</p>
                <TextFieldCustom
                  onChange={handleOnChange}
                  placeholder="Ingresá tu Depto"
                  name="depto"
                  value={domicilio.depto || ""}
                ></TextFieldCustom>
              </div>
            </Grid>
            <Grid item sx={{mt: 3, p: "0.5rem"}}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleConfirmar}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};
export default EditLocation;
