import * as React from "react"
const IconoFlechaNegraL = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#363636"
      d="m8.227 6.634-5.266 5.101a.947.947 0 0 1-1.313 0l-.875-.847a.877.877 0 0 1 0-1.272L4.506 6 .773 2.384a.877.877 0 0 1 0-1.272l.875-.848a.947.947 0 0 1 1.313 0l5.266 5.102a.873.873 0 0 1 0 1.268Z"
    />
  </svg>
)
export default IconoFlechaNegraL