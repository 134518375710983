import React from "react";
import {
  Typography,
  Box,
  Grid,
  CardMedia,
  Container,
  Breadcrumbs,
  Link,
  Button,
  Paper
} from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useEffect } from "react";
import Navbar from "../../componentes/Navbar/Navbar";
import Footer from "../../componentes/Footer/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory } from "react-router-dom";
import DividerCard from "../../componentes/DividerCard/DividerCard";
import Whatsapp from "../../UI/Icons/Whatsapp";
import LaptopMobile from "../../UI/Icons/LaptopMobile";

const styles = {
  section: {
    marginY: { xs: "1rem", sm: "-1rem", md: "2rem", lg: "2.5rem" },
    marginX: { xs: "1rem", sm: "4rem", md: "4rem", lg: "0" },
    marginBottom: { xs: "6rem", sm: "5rem", md: "3rem", lg: "7rem" },
    "& .itemDesc": {
      fontFamily: "'Lato', sans-serif",
      color: "#666666",
    },
    "& .subtitleAcc": {
      fontFamily: "'Lato', sans-serif",
      fontWeight: "bold",
    },
    "& .titleAcc": {
      fontFamily: "'Lato', sans-serif",
      fontWeight: "bold",
      color: "#E4002B",
      mt: "1rem",
    },
    "& .gridImg": {
      display: "flex",
      width: "100%",
      justifyContent: { xs: "center", md: "flex-end" },
      marginTop: { sm: "2.5rem", md: "-1rem" },
      paddingLeft: { md: "3rem" },
    },
    "& .imgCard": {
      maxWidth: { md: "372px", lg: "400px" },
      height: { md: "371px" },
      borderRadius: { md: "25px" },
    },
    "& .containerImgText": {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row-reverse",
    },
    "& .containerText": {
      marginTop: { xs: "2rem", md: "0" },
      marginBottom: "1.5rem",
    },
    "& .contacts": {
      fontFamily: "'Lato', sans-serif",
      color: "#E4002B",
      fontWeight: "bold",
      marginBottom: "0.2rem",
    },
    "& .contactsDown": {
      fontFamily: "'Lato', sans-serif",
      color: "#797979",
    },
    "& .btnTurnDown": {
      width: { xs: "100%", sm: "40%" },
      borderRadius: "2rem",
      paddingY: "12px",
      color: "#E4002B",
      textTransform: "initial",
      fontFamily: "'Lato', sans-serif",
      fontWeight: "bold",
      borderColor: "#E4002B",
      ":hover": {
        borderColor: "#E4002B",
      },
      marginTop: { xs: "1rem", sm: "0" },
      marginBottom: "1rem",
    },
    "& .infoText": {
      fontFamily: "'Lato', sans-serif",
      fontSize: "1rem",
    },
    "& .containerPaper": {
      padding: "2rem"
     
    },
    "& .styleText": {
      fontFamily: "'Lato', sans-serif",
      fontStyle: "italic",
      fontWeight: "bold",
      fontSize: ".8rem",
      mt: "1rem",
    },
    "& .textNegrita" : {
        fontFamily:"'Lato', sans-serif",
         fontWeight: "bold"
    }
  },

  headerSection: {
    marginX: "1.5rem",
    marginTop: "8rem",
    marginBottom: "0",
    textAlign: "center",
    fontFamily: "'Lato', sans-serif",
    "& .headerTitle": {
      marginTop: { xs: "1rem", md: "9rem" },
      fontWeight: "bold",
      fontSize: "2.125rem",
      fontFamily: "'Lato', sans-serif",
    },
    "& .headerIcon": {
      color: "#E4002B",
      fontSize: "3rem",
      marginTop: "-10px",
    },
  },
};

const EconsultaReceta = () => {

  const { headerSection, section } = styles;
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const handleSocios = (e) =>{
    e.preventDefault();
    window.open('https://www.swissmedical.com.ar/prepagaclientes/login', '_blank');
  }
  /* Scroll Top */
  useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

  return (
    <>
      <Navbar />
      <Container
        sx={{
          mt: "74px",
          maxWidth: "1366px",
          px: { xs: 0 },
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          pt=".5rem"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href="/"
            onClick={handleClick}
          >
            Inicio
          </Link>
          ,
          <Typography key="2" color="text.primary">
            E-consulta y Receta Electrónica
          </Typography>
        </Breadcrumbs>
        <Box sx={headerSection}>
          <Typography className="headerTitle">
            E-consulta y Receta Electrónica
          </Typography>
          <HorizontalRuleIcon className="headerIcon" />
        </Box>
        <Box sx={section}>
          <Grid className="containerImgText" container>
            <Grid className="gridImg" item sx={12} md={5}>
              <CardMedia
                className="imgCard"
                component="img"
                image="/images/cards/card_four.jpg"
                alt="E-consulta y Receta Electrónica"
              />
            </Grid>
            <Grid item sx={12} md={7}>
              <Box className="containerText">
                <Typography className="subtitleAcc">
                  Nuestro servicio de atención médica por videollamada está
                  disponible en la web{" "}
                  <Typography
                    component="span">
                      <Link  underline="hover"
                       sx={{ fontWeight: "bold", color: "#E4002B" }}
                       href="#"
                       onClick={handleSocios}>
                        www.swissmedical.com
                      </Link>
                  </Typography>{" "}
                  y en la
                  <Typography
                    component="span"
                    sx={{ fontWeight: "bold", color: "#E4002B" }}
                  >
                    {" "}
                    APP Swiss Medical Mobile
                  </Typography>
                </Typography>
                <Box sx={{ mt: "1rem" }}>
                  <Typography className="infoText">
                    Allí podés atender tus consultas:
                  </Typography>
                  <Box mt="1rem">
                    <Typography className="subtitleAcc">
                      Espontáneas | las 24 hs.:
                    </Typography>
                    <Typography className="itemDesc">
                      En las especialidades Clínica Médica y Pediatría.
                    </Typography>
                  </Box>
                  <Box mt="1rem">
                    <Typography className="subtitleAcc">
                      Espontáneas | de 8 a 20 hs.:
                    </Typography>
                    <Typography className="itemDesc">
                      En Neonatología y Puericultura / Lactancia.
                    </Typography>
                  </Box>
                  <Box mt="1rem">
                    <Typography className="subtitleAcc">
                      Programadas:
                    </Typography>
                    <Typography className="itemDesc">
                      En más de 30 especialidades, agendando un turno
                      previamente.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ my: "2rem" }}>
                <Button
                  rel="noopener noreferrer"
                  href="https://www.swissmedical.com.ar/prepagaclientes/novedades/articulos/2021/10/06/atencion-programada-por-videollamada/"
                  target="_blank"
                  className="btnTurnDown"
                  size="small"
                  variant="outlined"
                >
                  Atención programada
                </Button>
                <Box mt="1rem">
                  <Typography className="infoText">
                    Si el médico te indica medicación, o necesitás aplicarte
                    alguna vacuna, obtendrás una receta digital que podrás
                    presentar en la farmacia o vacunatorio, directamente desde
                    tu teléfono.
                  </Typography>
                </Box>
                <Box mt="1rem">
                  <Typography className="infoText">
                    En caso de que el profesional solicite estudios
                    complementarios básicos, podrás realizarlos con la orden
                    médica digital en{" "}
                    <Typography
                      component="span"
                      fontStyle="italic"
                      fontFamily="'Lato', sans-serif"
                    >
                      {" "}
                      Swiss Medical Center Microcentro, Barrio Norte, Barrio
                      Parque, Campana, Lomas, de los Arcos (sólo laboratorio) y
                      Clínica Olivos.{" "}
                    </Typography>
                  </Typography>
                </Box>
                <Box mt="1rem">
                  <Typography className="infoText">
                    Si decidís realizarlo en otro centro, el médico generará una
                    orden física que podrás retirar en dos lugares:{" "}
                    <Typography
                      component="span"
                      fontStyle="italic"
                      fontFamily="'Lato', sans-serif"
                    >
                      Swiss Medical Center Microcentro y Barrio Parque.
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <DividerCard />
              <Box>
                <Box my="2rem">
                  <Typography mb="1rem" className="titleAcc" variant="h5">
                    Solicitá tus recetas:
                  </Typography>
                </Box>
                <Grid mt="1rem" container spacing={2}>
                  <Grid item xs={12} md={5}>
                    <Grid container direction="row" alignItems="center">
                      <Whatsapp />
                      <Typography className="subtitleAcc" ml={2}>
                        {" "}
                        Swity
                      </Typography>
                    </Grid>
                    <Typography className="contactsDown">
                      Escribiendo al{" "}
                      <Box
                        component="span">
                        <Link 
                         href="https://wa.me/5491150519982"
                         target="_blank"
                         sx={{ fontWeight: "bold", color: "#E4002B" }} 
                         underline="hover" >
                          11-5051-9982
                        </Link>
                        
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Grid container direction="row" alignItems="center">
                      <LaptopMobile />
                      <Typography
                        sx={{ marginTop: { xs: "1.5rem", md: "0" } }}
                        ml={2}
                        className="subtitleAcc"
                      >
                        {" "}
                        Desde nuestra web
                      </Typography>
                    </Grid>

                    <Box>
                      <Typography className="contactsDown">
                        <Box
                          component="span"
                          sx={{ fontWeight: "bold", color: "#E4002B" }}
                        >
                          swissmedical.com{" "}
                        </Box>
                        ingresando en{" "}
                      </Typography>
                      <Typography className="contactsDown">
                        Mi Swiss Medical {">"} Gestión Médica {">"} Mis Recetas
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Paper elevation={4}>
                    <Grid display={"flex"} className="containerPaper" container>
                      <Typography className="textNegrita sizeLetter" >
                        <Typography component="span" className="contactsDown sizeLetter">
                          Una vez lista la receta, te llegará a tu mail una
                          notificación de
                        </Typography>{" "}
                        “Nueva receta disponible”.
                      </Typography>
                      <Typography mt={2} className="textNegrita sizeLetter">
                        <Typography component="span" className="contactsDown sizeLetter">
                          Podes descargarlas desde la{" "}
                        </Typography>
                        APP Swiss Medical Mobile {">"} Mis Recetas o ingresando
                        <Typography component="span" className="contactsDown sizeLetter"> a la </Typography>  Web {">"} “Mi Swiss Medical”{">"} “Gestión médica”
                        {">"} “Mis recetas”
                      </Typography>
                      <Box>
                        <Typography
                          sx={{ fontWeight: "bold", color: "#E4002B" }}
                          mt={3}
                        >
                          ¿Qué medicación no podés solicitar?
                        </Typography>
                        <Typography className="sizeLetter"   fontFamily= "'Lato', sans-serif" >
                          Antibióticos, psicofármacos{" "}
                          <Typography component="span" className="styleText " >
                            (antidepresivos, ansiolíticos y antipsicóticos)
                          </Typography>{" "}
                          , medicamentos para el  tratamiento del cáncer, HIV, Hepatitis B y C e
                          inmunosupresión y medicamentos de venta libre.
                        </Typography>
                      </Box>
                    </Grid>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default EconsultaReceta;
