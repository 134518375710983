import React, { useEffect, useState } from "react";
import SelectCustom from "../../../../../../../../UI/SelectCustom/SelectCustom";
import useIsMobile from "../../../../../../../../Utils/useIsMobile";
import RecuestApiSinToken from "../../../../../../../../Request-api/RecuestApiSinToken";
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom";
import { useDispatch } from "react-redux";
import Spinner from "../../../../../../../../UI/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import "./SelectEspeCentrosDesktop.css";
import SelectTurnoDesktop from "../SelectTurno/SelecTurnoDesktop";
import { useSelector } from "react-redux";
import utils from "../../../../../../../../Utils/useDate";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: 350,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};
const styles = {
  containerTimes: {
    display: "flex",
  },
  times: {
    display: "inline",
    width: "50%",
  },
};

const SelectEspeCentrosDesktop = ({
  setStep,
  setData,
  data,
  integranteSeleccionado,
}) => {
  const history = useHistory();
  const [spinner, setSpinner] = useState(true);
  const [especialidades, setEspecialidades] = useState([]);
  const [practicas, setPracticas] = useState([]);
  const [espeSelected, setEspeSelected] = useState(null);
  const [practicSelected, setPracticSelected] = useState();
  const datosLogin = useSelector((state) => state.authLogin);
  const [idXhis, setIdXhis] = useState("");

  const [openModalAviso, setOpenModalAviso] = useState(false);
  const [centros, setCentros] = useState([]);
  const [verCentros, setVerCentros] = useState(false);
  const [verHorarios, setVerHorarios] = useState(false);
  let [centrosSelected, setCentrosSelected] = useState([]);
  let [preIdCentroSelected, setPreIdCentroSelected] = useState({});
  const selectClass = (id) => {
    return centrosSelected.includes(id)
      ? "card-centro-turno-selected"
      : "card-centro-turno";
  };
  var nacimiento = moment(integranteSeleccionado.fechaNac);
  var hoy = moment();
  var anios = hoy.diff(nacimiento, "years");

  const handleVolver = () => {
    history.push("/pacientes/turnos");
  };
  const handleVolverEspe = () => {
    history.push("/pacientes/turnos");
  };
  const handleEspe = (e) => {
    setEspeSelected(e.target);
    RecuestApiSinToken.getPracticas(
      anios,
      integranteSeleccionado.genero,
      e.target.value,
      integranteSeleccionado.planPk,
      integranteSeleccionado.gamaPk
    ).then((response) =>
      setPracticas(
        response.map((prac) => {
          return { name: prac.abre, value: prac.prestac };
        })
      )
    );
  };

  const handlePrac = (e) => {
    setPracticSelected(e.target);
  };

  const handleConfirmarModal = () => {
    if (centrosSelected.includes(preIdCentroSelected)) {
      setCentrosSelected((prev) =>
        prev.filter((item) => item !== preIdCentroSelected)
      );
    } else {
      setCentrosSelected((prev) => [...prev, preIdCentroSelected]);
    }
    setOpenModalAviso(false);
  };

  const getEspe = () => {
    RecuestApiSinToken.getEspecialidades(
      anios,
      integranteSeleccionado.genero,
      integranteSeleccionado.planPk,
      integranteSeleccionado.gamaPk
    ).then((response) => {
      setEspecialidades(
        response.map((esp) => {
          return {
            name: esp.abre,
            value: esp.espe,
            visualiza: esp.visualiza,
            idXhis: esp.idXhis,
          };
        })
      );
      setSpinner(false);
    });
  };

  const handleBuscar = () => {
    getCentros(espeSelected.value, practicSelected.value);
    setData((prev) => {
      return { ...prev, espeSelected, practicSelected, especialidades };
    });
    setSpinner(true)
    /* setStep(2); */
  };

  const getCentros = (espe, prac ) => {
    const servBasico = especialidades.find(
      (espe) => espe.value === espeSelected.value
    ).idXhis;
    let edad = anios;
    let userLogin =
      utils.convertDate(integranteSeleccionado.fechaNac) +
      integranteSeleccionado.docNum +
      "@swissmedical.com.ar";
    RecuestApiSinToken.getCentrosPorEspe(
      userLogin,
      espe,
      prac,
      edad,
      integranteSeleccionado.planPk,
      integranteSeleccionado.gamaPk,
      servBasico
    ).then((response) => {
      setVerCentros(true);
      setCentros(response);
      setSpinner(false)
    });
  };

  const handleSelect = (idCentro, visualiza) => {
    if (visualiza === "S") {
      if (centrosSelected.includes(idCentro)) {
        setCentrosSelected((prev) => prev.filter((item) => item !== idCentro));
      } else {
        setCentrosSelected((prev) => [...prev, idCentro]);
      }
    } else {
      setPreIdCentroSelected(idCentro);
      setOpenModalAviso(true); //
    }
  };

  const handleAceptar = () => {
    setData((prev) => {
      return { ...prev, centrosSelected, centros };
    });
    /* setStep(2) */ setVerCentros(false);
    setVerHorarios(true);
  };

  useEffect(() => {
    getEspe();
  }, [integranteSeleccionado]);

  useEffect(() => {}, [centros]);

  const CentrosMedicos = () => {
    return (
      <>
        {spinner ? (
          <Spinner />
        ) : (
          <div className="containerCentros" >
            <div style={{ padding: "10px" }}>
              <p className="text-select-centro">
                CENTROS DE MÉDICOS ENCONTRADOS
              </p>
              <p className="sub-text-select-centro">
                Seleccioná uno o más centros de atención:
              </p>
            </div>

            <div style={{ padding: "10px" }}>
              {centros.length > 0 &&
                centros.map((cen, i) => (
                  <p
                    style={{ color: cen.visualiza === "V" ? "red" : "black" }}
                    onClick={() => handleSelect(cen.idcentro, cen.visualiza)}
                    className={selectClass(cen.idcentro)}
                  >
                    {cen.centro}
                  </p>
                ))}
            </div>
            <div className="button-select-centro">
              {centros.length > 0 && (
                <ButtonCustom
                  disabled={centrosSelected.length === 0}
                  onClick={handleAceptar}
                  width={"95%"}
                  name={"Ver disponibilidad"}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div >
        <Modal
          open={openModalAviso}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography>
            <h2><b>Práctica sin cobertura.</b></h2>
            <h2 style={{fontSize:"medium"}}>La especialidad o la práctica seleccionada no tiene cobertura para  {datosLogin.plan.name}{datosLogin.plan.gama}.</h2>
            <h2 style={{fontSize:"medium"}}>Reserva tu turno en forma privada llamando al:</h2>
            <h2>0810-333-8876</h2>
            </Typography>

            <ButtonCustom
              width={"95%"}
              onClick={() => handleVolverEspe()}
              name={"Aceptar"}
            />
          </Box>
        </Modal>
        <div className={"volverYTurnos"}>
          <p
            onClick={handleVolver}
            className={"text-volver"}
            style={{ marginBottom: "20px" }}
          >
            {"<"} Volver{" "}
          </p>
        </div>
        {spinner ? (
          <Spinner />
        ) : (
          <div className="selects">
            <div>
              <div>
                <p
                  style={{
                    marginLeft: "20px",
                    marginRight: "90px",
                  }}
                >
                  <p className="etiquetasP">
                    {" "}
                    <b className="especialidadselct">Especialidad/Estudio</b>
                    <b className="tipoDeConsultaSelect">Tipo de consulta</b>
                  </p>
                  <SelectCustom
                    name="Seleccioná especialidad"
                    options={especialidades}
                    placeholder="Seleccionar"
                    disable={!especialidades}
                    onChange={(e) => handleEspe(e)}
                  >
                    seleccionar
                  </SelectCustom>

                  <SelectCustom
                    className="select2"
                    name="Seleccioná tipo de práctica"
                    options={practicas}
                    placeholder="Seleccionar"
                    onChange={(e) => handlePrac(e)}
                  ></SelectCustom>

                  <div className="button-select-espe">
                    <ButtonCustom
                      disabled={!(espeSelected && practicSelected)}
                      onClick={handleBuscar}
                      width={"180%"}
                      name={"Buscar"}
                    />
                  </div>
                </p>
              </div>
              {verCentros && CentrosMedicos()}
            </div>
          </div>
        )}
        {spinner ? (
          <Spinner />
        ) : (
          verHorarios && (
            <SelectTurnoDesktop
              setStep={setStep}
              setData={setData}
              data={data}
            />
          )
        )}
      </div>
    </>
  );
};

export default SelectEspeCentrosDesktop;
