import { Grid, Typography, Button, Box, Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../../../Redux/Reducers/signinModalReducer";

const syles = {
    header: {
        display: "flex",
        alignItems: "center",
        width: '100%',
        height: '70vh',
        marginTop: '74px',
        position: 'relative',
        zIndex: '-1',
    },
    card: {
        marginX: { xs: '1rem', md: '1.04rem' },
        "& h1": {
            color: '#fff',
            fontFamily: "'Lato', sans-serif",
            fontWeight: "600",
            fontSize: { xs: "2rem", md: "2.5rem" },
            paddingTop: "1rem",
            paddingBottom: "1rem",
            "@media only screen and (min-width: 640px)": {
                fontSize: "3.5rem",
            },
        },
        "& button": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            mt: '1rem',
            mr: '1rem',
            borderRadius: "40px",
            textTransform: "initial",
            lineHeight: "1.5",
            fontSize: ".7rem",
            height: "40px"
        },
        "& .btnTurno": {
            color: "#000",
            backgroundColor: "#fff",
            borderColor: "#000"
        },
        "& .btnLlamar": {
            color: "#fff",
            borderColor: "#fff"
        },
        "& .aclaration": {
            fontFamily: "'Lato', sans-serif",
            color: '#fff',
            fontSize: '.7rem',
            position: 'absolute',
            bottom: '1rem',
            textType: 'italic'
        }
    }
}


const HeaderCM = ({ title, img }) => {

    const { header, card } = syles;
    const dispatch = useDispatch();

    /* buttonCall */
    const handleCall = () => {
        window.open("tel:0810-333-8876");
    }

    return (
        <>
            <Box sx={header} style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', zIndex: '0', backgroundPosition:"center" }} >
                <Container> 
                    <Grid sx={card} xs={6}>
                        <Typography variant="h1">{title}</Typography>
                        <Box display='flex' flexWrap='wrap'>
                            <Button onClick={() => dispatch(toggleOpen(true))} className="btnTurno" variant="contained">Turnos online</Button>
                            <Button onClick={handleCall} variant="outlined" className="btnLlamar">Central de turnos 0810-333-8876 (*)</Button>
                        </Box>
                        <Typography className='aclaration'>(*) Exclusivo para asociados a Swiss Medical Medicina Privada</Typography>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default HeaderCM;