import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';



const BootstrapInput = styled(TextField)(({ theme }) => ({

  '& .MuiInputBase-input': {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    /*    width: 'auto', */
    padding: '10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

  },
}));





export default function CustomizedInputs({ sx, type, onChange, minWidth, placeholder, name, validate, onBlur, labelN, value }) {

  const useMinWidth = minWidth || '100%'

  const [error, setError] = useState('')

  useEffect(() => {
    if (validate) {
      setError(validate[name])
    }

  }, [validate])

  return (
    <FormControl sx={{ m: 1, width: '90%', maxWidth: '300px' }}>
      <BootstrapInput 
        label={labelN} 
        error={!!error} 
        helperText={error} 
        onBlur={onBlur} 
        placeholder={placeholder} 
        onChange={onChange} 
        type={type} 
        id="bootstrap-input" 
        name={name}
        sx={sx} 
        value={value}
      />
    </FormControl>
  );
}