import {useState} from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const DatePicker = ({isMobile, label, handleChange, name, sx, placeholder}) => {

    const [value, setValue] = useState(null);

    const handleDatePickerChange = (date) => {
        setValue(date.format('DD/MM/yyyy'));
        handleChange(date.format('DD/MM/yyyy'), name)
    }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
            label={label}
            inputFormat="DD/MM/yyyy"
            renderInput={(params) => <TextField sx={sx} placeholder={placeholder} {...params}/>}
            value={value}
            onChange={handleDatePickerChange}
            />
    </LocalizationProvider>
  );
}

export default DatePicker;