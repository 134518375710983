import { Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";

const Item = ({icon, text, divider}) => {

    return (
        <>
            <ListItem disablePadding sx={{alignItems:"start", mt:'6px'}}>
                <ListItemIcon sx={{mt:'6px'}}>
                    {icon}
                </ListItemIcon>
                <ListItemText>
                    {text}
                </ListItemText>
            </ListItem>
            {divider && <Divider sx={{my: '1rem'}}/>}
        </>
    )
}

export default Item;