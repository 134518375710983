import { SvgIcon } from "@mui/material";

function FilterIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M21.875 17.1429H8.75V16.5C8.75 16.1464 8.46875 15.8571 8.125 15.8571H6.875C6.53125 15.8571 6.25 16.1464 6.25 16.5V17.1429H3.125C2.78125 17.1429 2.5 17.4321 2.5 17.7857V19.0714C2.5 19.425 2.78125 19.7143 3.125 19.7143H6.25V20.3571C6.25 20.7107 6.53125 21 6.875 21H8.125C8.46875 21 8.75 20.7107 8.75 20.3571V19.7143H21.875C22.2188 19.7143 22.5 19.425 22.5 19.0714V17.7857C22.5 17.4321 22.2188 17.1429 21.875 17.1429ZM21.875 10.7143H18.75V10.0714C18.75 9.71786 18.4688 9.42857 18.125 9.42857H16.875C16.5312 9.42857 16.25 9.71786 16.25 10.0714V10.7143H3.125C2.78125 10.7143 2.5 11.0036 2.5 11.3571V12.6429C2.5 12.9964 2.78125 13.2857 3.125 13.2857H16.25V13.9286C16.25 14.2821 16.5312 14.5714 16.875 14.5714H18.125C18.4688 14.5714 18.75 14.2821 18.75 13.9286V13.2857H21.875C22.2188 13.2857 22.5 12.9964 22.5 12.6429V11.3571C22.5 11.0036 22.2188 10.7143 21.875 10.7143ZM21.875 4.28571H13.75V3.64286C13.75 3.28929 13.4688 3 13.125 3H11.875C11.5312 3 11.25 3.28929 11.25 3.64286V4.28571H3.125C2.78125 4.28571 2.5 4.575 2.5 4.92857V6.21429C2.5 6.56786 2.78125 6.85714 3.125 6.85714H11.25V7.5C11.25 7.85357 11.5312 8.14286 11.875 8.14286H13.125C13.4688 8.14286 13.75 7.85357 13.75 7.5V6.85714H21.875C22.2188 6.85714 22.5 6.56786 22.5 6.21429V4.92857C22.5 4.575 22.2188 4.28571 21.875 4.28571Z" fill="#E4002B"/>
      </SvgIcon>
    );
  }

export default FilterIcon;