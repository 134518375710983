import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export default function CustomizedButtons({name, color, onClick, width, disabled, primaryColor, sx}) {

  const backgroundColor = color || '#E4002B'

  const primary = primaryColor || 'none'
  
  const BootstrapButton = styled(Button)({

    border: "1px solid "+primary,
    color: primary,
    fontWeight: "400",
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 30,
    while: "500px",
    width: width? width:"80%",
    padding: '12px 12px ',
    margin: "30px 6px 6px 6px" ,
  /*   border: '1px solid', */
    lineHeight: 1.5,
    backgroundColor: backgroundColor,
    /* borderColor: '#0063cc', */
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: backgroundColor,

  
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor:backgroundColor,
   /*    borderColor: '#005cbf', */
    },
    '&:focus': {
 /*      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)', */
    },
   
  });

  return (
   
      <BootstrapButton type='submit' disabled={disabled} onClick={onClick} variant="contained" sx={sx}>
       {name}
      </BootstrapButton>
 
  );
}