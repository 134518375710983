import { createRef, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import RequestApi from '../../../Request-api/RequestApi';
import NewPassForm from "./NewPassForm/NewPassForm";
import ProductForm from "./ProductForm/ProductForm";
import SendEmail from "./SendEmail/SendEmail";
import { RECAP_KEY } from '../../../constants';
import ReCAPTCHA from "react-google-recaptcha";

const RecoveryPass = ({chOption, docs}) => {
    const search = useLocation().search;
    const history = useHistory();
    const recaptchaInstance = createRef();
    const [step, setStep] = useState(1);
    const getTipoDoc = value => docs.find(obj => parseInt(obj.id) === parseInt(value));
    const getProduct = (productId, products) => products.filter(prod => prod.idEntidad === productId);
    const [loading, setLoading] = useState(false);
    const [tokenRecaptcha, setTokenRecaptcha] = useState('');
    const [user, setUser] = useState({
        jwtToken: "",
        idPersona: ""
    });

    const handleNext = (st) => {
        st ? setStep(st) : setStep(step + 1);
    }

    const handleRecaptcha = (token) => {
        if(token) {
            setLoading(false);
            const jwtToken = new URLSearchParams(search).get('token');
            const idPersona = new URLSearchParams(search).get('pId');
            if(jwtToken && idPersona) {
                setLoading(true);
                RequestApi.getProductosV2(idPersona, token, jwtToken)
                .then(r => {
                    const { pId, estado } = r;
                    setLoading(false);
                    setUser({
                        ...user,
                        jwtToken,
                        idPersona: pId
                    });
                    if(estado === 1) {
                        handleNext(3);
                    } else if (estado === 2) {
                        handleNext(2);

                    } else {
                        history.push('/login');
                    }
                })
            } else {
                setTokenRecaptcha(token);
            }
        }
    }
    
    useEffect(() => {
        setLoading(true);
        recaptchaInstance.current.execute();
    }, [])

    return (
        <>
            {step === 1 && <SendEmail chOption={chOption} docs={docs} getTipoDoc={getTipoDoc} setLoading={setLoading} getProduct={getProduct} tokenRecaptcha={tokenRecaptcha} />}
            {step === 2 && <ProductForm user={user} next={handleNext} setLoading={setLoading}/> }
            {step === 3 && <NewPassForm user={user} setLoading={setLoading} chOption={chOption}/> }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ReCAPTCHA
                ref={recaptchaInstance}
                sitekey={RECAP_KEY}
                size="invisible"
                onChange={handleRecaptcha}
            />
        </>
    )
}

export default RecoveryPass;