import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { recoveryLogin } from '../Redux/Reducers/authLoginReducer';
import { setFamiliares } from '../Redux/Reducers/grupoFamiliarReducer';

const PrivateRoute = ({ children, ...rest }) => {
    const dispatch = useDispatch();
    const authLogin = useSelector(state => state.authLogin);
    const { familiares } = useSelector(state => state.grupoFamiliar);

    if(sessionStorage.getItem('authLogin') && !authLogin.idPersona) {
        dispatch(recoveryLogin(JSON.parse(sessionStorage.getItem('authLogin'))));
        dispatch(setFamiliares(JSON.parse(sessionStorage.getItem('familiares'))));

    } else if(!sessionStorage.getItem('authLogin') && authLogin.idPersona) {
        sessionStorage.setItem('authLogin', JSON.stringify(authLogin))
        sessionStorage.setItem('familiares', JSON.stringify(familiares))
    }

    return (
        <Route
            {...rest}
            render={() => {
                return authLogin.idPersona ? (
                    children
                ) : (
                    <Redirect to="/login" />
                );
            }}
        />
    );
}

export default PrivateRoute;