import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box } from '@mui/material';
import useIsMobile from "../../../../../../../Utils/useIsMobile";
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom";
import RequestApi from "../../../../../../../Request-api/RequestApi";
import FieldCustom from "../../../../../../../UI/FieldCustom/FieldCustom";
import { CuilCuitScheme } from "./CheckCuilScheme";

const CheckCuil = ({ next }) => {
    const history = useHistory();
    const { isMobile } = useIsMobile()
    const { docType, docNum, cuilCuit } = useSelector(state => state.authLogin);

    useEffect(()=>{
        if(!cuilCuit) {
            history.push('/pacientes');
        }
    })

    const handleCuilCuit = ({cuilCuitNro}, setStatus) => {
        RequestApi.checkCuil(cuilCuitNro, docType.id, docNum, cuilCuit.tipoResultado)
        .then(response => {
            if (response.estado === 0) {
                if (docType.value === 'DU' && isMobile) {
                    next()
                } else {
                    next(4)
                }
            } else {
                setStatus('El cuíl/cuit ingresado no es correcto');
                setTimeout(()=>{
                    setStatus(null)
                }, 2000)
            }
        })
        .catch(e => {
            setStatus(e.mensaje)
        })

    }

    return (
        <>
            <Box sx={{display:'flex', alignItems:'center', flexDirection: 'column'}}>
                <p className="text-bienv">Validar Identidad</p>
                <Formik
                    initialValues={{
                        cuilCuitNro: ''
                    }}
                    validationSchema={CuilCuitScheme}
                    onSubmit={(values, { setStatus })=>{
                        handleCuilCuit(values, setStatus)
                    }}
                >
                    {({ errors, touched, values, handleChange, status }) => ( 
                        <Form style={{width: '100%'}}>
                            <Box sx={{px: '1rem', maxWidth: '400px'}}>
                                <FieldCustom
                                    id="cuilCuitNro"
                                    name="cuilCuitNro"
                                    placeholder={`Ingresá tu ${cuilCuit.tipoResultadoDesc} sin guiones o espacios`}
                                    label={`1. Por favor, ingresá tu número de ${cuilCuit.tipoResultadoDesc}`}
                                    value={values.cuilCuitNro}
                                    onChange={handleChange}
                                    error={touched.cuilCuitNro && Boolean(errors.cuilCuitNro)}
                                    helperText={touched.cuilCuitNro && errors.cuilCuitNro}
                                />
                                {!!status && <small style={{color: '#d32f2f'}}>{status}</small>}
                                <ButtonCustom width={"50%"} sx={{position: isMobile ? 'fixed' : 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%, -50%)'}} name="Continuar" />
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}
export default CheckCuil