import { FormControl, MenuItem } from '@mui/material';
import SelectInput from '@mui/material/Select';

const Select = ({label, options, placeholder, variant, defaultValue, ...rest}) => {

    return (
        <>
            <FormControl fullWidth>
                <p style={{visibility: label === 'hidden' && 'hidden', fontSize: '15px', color:'#363636', fontWeight: 'bold'}}>{label}</p>
                <SelectInput 
                    id="simple-select"
                    defaultValue={defaultValue}
                    {...rest}
                >
                    <MenuItem disabled value="">
                        <em>{placeholder}</em>
                    </MenuItem>
                    {options.map(op => 
                        <MenuItem key={op.value} value={op.value}> {op.name}</MenuItem>
                    )}
                </SelectInput>
            </FormControl>
        </>
    )
}

export default Select;