import InputImage from "../../../../../../../componentes/InputImage/InputImage";
import RequestApi from "../../../../../../../Request-api/RequestApi";
import useIsMobile from "../../../../../../../Utils/useIsMobile";
import ButtonCustom from "../../../../../../../UI/ButtonCustom/ButtonCustom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Grid, Typography, Backdrop, CircularProgress } from "@mui/material";
import { updateStatusToPending } from "../../../../../../../Redux/Reducers/authLoginReducer";

const styles = {
  profile: {
    "& .list": {
      "& li": {
        py: 0,
      },
    },
  },
};

const FormularioErrorQr = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { docNum, docType, idEDNS } = useSelector((state) => state.authLogin);
  const { isMobile } = useIsMobile();
  const [files, setFiles] = useState([]);
  const [form, setForm] = useState(1);
  const [loading, setLoading] = useState(false);
  const { profile } = styles;

  const handleNewFile = (file) => {
    setFiles([...files, file]);
  };

  const nextForm = () => {
    setForm(2);
  };

  const handleAceptar = () => {
    setLoading(true);
    if (files.length === 3) {
      const multiPartFiles = new FormData();
      multiPartFiles.append("file", files[0]);
      multiPartFiles.append("file", files[1]);
      multiPartFiles.append("file", files[2]);
      RequestApi.uploadAWSFile(multiPartFiles).then((r) => {
        if (r.estado === 0) {
          RequestApi.validarIdentidadManual({
            idEdns: idEDNS,
            canal: isMobile ? 2 : 1,
            idTipoDoc: docType.id,
            nroDoc: docNum,
            documentacion: `${files[0].name};${files[1].name};${files[2].name}`,
            ipCliente: window.CLIENT_IP,
            dispositivo: navigator.userAgent,
            //1 automatica - 2 manual
            tipoValidacion: 2,
            idUsuario: docNum,
          }).then((res) => {
            if (res.estado === 0) {
              dispatch(updateStatusToPending());
              Swal.fire({
                title: "Documentación enviada",
                text: "Nuestro equipo la revisará en las próximas 24 horas. Te enviaremos una notificación con el estado de tu activación.",
                timer: 10000,
                confirmButtonText: "Entendido",
                willClose: history.push("/pacientes"),
              });
            }
          })
          .finally(()=> {
            setLoading(false);
          });
        }
      });
    } else {
      //Por favor, cargue frente y dorso del documento
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <p className="text-bienv">Validar Identidad</p>
      {form === 1 ? (
        <>
          <Grid container>
            <Grid xs={12} md={6}>
              <InputImage
                label="Subir frente del documento"
                newFile={handleNewFile}
                fileName="DNI-FRENTE"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <InputImage
                label="Subir dorso del documento"
                newFile={handleNewFile}
                fileName="DNI-DORSO"
              />
            </Grid>
          </Grid>
          <ButtonCustom
            width={"50%"}
            sx={{
              position: isMobile ? "fixed" : "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={nextForm}
            name="Continuar"
          />
        </>
      ) : (
        <>
          <Grid container>
            <Grid xs={12} md={6} sx={profile}>
              <Typography className="title-list">Tener en cuenta:</Typography>
              <ul className="list">
                <li>Utilizar un ambiente iluminado, con fondo blanco.</li>
                <li>Su cara debe cubrir la mayor parte de la imagen.</li>
                <li>No tomarse la foto con accesorios ni filtros.</li>
              </ul>
              <p style={{ fontWeight: "bold" }}>
                Para una mejor identificacion, te pedimos que en la selfie nos
                envies una foto sosteniendo tu DNI con una de las manos.
              </p>
              <InputImage
                label="Subir foto selfie del titular"
                newFile={handleNewFile}
                fileName="SELFIE-TITULAR"
              />
            </Grid>
          </Grid>
          <ButtonCustom
            width={"50%"}
            sx={{
              position: isMobile ? "fixed" : "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={handleAceptar}
            name="Enviar"
          />
        </>
      )}
    </>
  );
};

export default FormularioErrorQr;
