import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography, Box, Container } from "@mui/material";
import CustomRedButton from "../../../../../UI/CustomRedButton/CustomRedButton";
import RedNotify from "../../../../../UI/RedNotify/RedNotify";
import CustomBreadcrumb from "../../../../../UI/CustomBreadcrumb/CustomBreadcrumb";
import PersonaVinculada from "./PersonaVinculada/PersonaVinculada";

const links = [
    {
        text: 'Inicio',
        location: '/pacientes'
    }
]

const GrupoFamiliar = () => {
    const { familiares } = useSelector(state => state.grupoFamiliar);
    const history = useHistory();
    const [pendientes, setPendientes] = useState([]);

    const handleVincular = () => {
        history.push('/pacientes/vincular-familiar')
    }

    useEffect(() => {
        setPendientes(familiares?.map(fam => {
            return {
                nombre: `${fam.nombre} ${fam.apellido1}`,
                doc: fam.nroDoc,
                estadoVinculo: fam.estadoVinculo,
                estadoCuenta: fam.estadoCuenta
            }
        }).filter(obj => obj !== undefined))

    },[familiares])

    return (
        <>
            {
                familiares.length ? (
                    <Container sx={{marginTop:"1rem"}}>
                        <RedNotify pendientes={pendientes} />
                        <CustomBreadcrumb links={links} site='Grupo familiar' />
                        <CustomRedButton onClick={handleVincular} text='Vincular nuevo familiar' />
                        <RedNotify/>
                        <Box sx={{marginTop:'2rem'}}>
                            {
                                familiares?.map((persona, index) => {
                                    return (
                                        <PersonaVinculada {...persona} />
                                    )
                              }
                             )
                            }
                        </Box>
                    </Container>
                ) : (
                    <Box height='90vh' width={{sm: '100%'}} display='flex' flexDirection='column' justifyContent='center' alignItems='center'  sx={{paddingX:'2rem', textAlign:'center', "& img":{marginBottom:'1rem'}, "& p":{marginBottom: '14px' } }}>
                        <img src='/images/empty_state.svg' width='100px' alt="icono vincular familiar"/>
                        <Typography>¡Todavía no agregaste a nadie!</Typography>
                        <Typography>Comenzá a gestionar tu grupo familiar desde aquí</Typography>
                        <CustomRedButton onClick={handleVincular} text='Vincular familiar' sx={{maxWidth:'200px', marginTop:'1rem'}} />
                    </Box>
                )
            }
        </>
    )
}

export default GrupoFamiliar;