import * as React from "react"
const IconoMedico = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#E4002B"
      d="M12.5 12c2.367 0 4.286-2.014 4.286-4.5S14.867 3 12.5 3c-2.367 0-4.286 2.014-4.286 4.5S10.133 12 12.5 12Zm-4.018 5.906c0 .468.358.844.804.844.445 0 .803-.376.803-.844 0-.467-.358-.843-.803-.843-.446 0-.804.376-.804.843Zm7.232-4.76v1.723c1.222.26 2.143 1.399 2.143 2.756v1.466c0 .267-.18.5-.432.552l-1.078.225c-.144.032-.285-.067-.315-.221l-.103-.552a.278.278 0 0 1 .21-.33l.647-.138v-1.002c0-2.208-3.215-2.289-3.215.067v.939l.647.137c.144.031.237.179.21.33l-.103.552a.273.273 0 0 1-.315.222l-1.045-.148a.554.554 0 0 1-.462-.56v-1.539c0-1.357.921-2.493 2.143-2.756v-1.59c-.074.025-.147.04-.22.068a5.557 5.557 0 0 1-1.923.344c-.673 0-1.319-.123-1.921-.345a3.55 3.55 0 0 0-.757-.182v2.868c.773.243 1.34.988 1.34 1.878 0 1.086-.841 1.968-1.876 1.968s-1.875-.882-1.875-1.968c0-.89.566-1.635 1.34-1.878v-2.826C6.623 13.582 5 15.509 5 17.85v1.575C5 20.293 5.673 21 6.5 21h12c.827 0 1.5-.707 1.5-1.575V17.85c0-2.531-1.902-4.58-4.286-4.704Z"
    />
  </svg>
)
export default IconoMedico