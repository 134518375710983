import React, { createRef, useState } from 'react';
import { Grid, Button, Backdrop, CircularProgress } from "@mui/material";
import FieldCustom from '../../../../UI/FieldCustom/FieldCustom';
import { Formik, Form } from 'formik';
import FormSchema from './FormSchema';
import RequestApi from '../../../../Request-api/RequestApi'
import { RECAP_KEY } from "../../../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";



const FormContacto = () => {
    const recaptchaInstance = createRef();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        nameLastName: "",
        tel: "",
        email: "",
        asunto: "",
        comment: ""
    })

    const handleRecaptcha = (values) => {
        setLoading(true);
        window.grecaptcha.execute()
        setState({
            ...values
        })
    }

    const handleSubmit = (token) => {
        if (token) {
            RequestApi.submitContact(state.nameLastName, state.tel, state.email, state.asunto, state.comment, token)
            .then(res => {
                Swal.fire({
                    title: "Formulario enviado",
                    text: "Muchas gracias por tu contacto, te responderemos en la brevedad",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })
            })
            .catch(res => {
                Swal.fire({
                    title: "Formulario de contacto",
                    text: "Error al enviar el formulario, por favor intente más tarde.",
                    icon: "error",
                    confirmButtonText: "Cerrar"
                })
            })
            .finally(() => {
                setLoading(false);
            })
        } else {
            window.location.reload();
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                initialValues={{
                    nameLastName: '',
                    tel: '',
                    email: '',
                    asunto: '',
                    comment: ''
                }}
                validationSchema={FormSchema}
                onSubmit={handleRecaptcha}
            >
                {({ errors, touched, values, handleChange }) => (
                    <Form>
                        <Grid container>
                            <Grid xs={12} md={6} item sx={{ pr: { md: '1rem' } }}>
                                <FieldCustom
                                    id="nameLastName"
                                    name="nameLastName"
                                    placeholder='Ingresá tu nombre y apellido'
                                    label='Nombre y Apellido'
                                    value={values.nameLastName}
                                    onChange={handleChange}
                                    error={touched.nameLastName && Boolean(errors.nameLastName)}
                                    helperText={touched.nameLastName && errors.nameLastName}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item sx={{ pl: { md: '1rem' } }}>
                                <FieldCustom
                                    id="tel"
                                    name="tel"
                                    placeholder='Ingresá tu número de  teléfono'
                                    label='Teléfono'
                                    value={values.tel}
                                    onChange={handleChange}
                                    error={touched.tel && Boolean(errors.tel)}
                                    helperText={touched.tel && errors.tel}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item sx={{ pr: { md: '1rem' } }}>
                                <FieldCustom
                                    id="email"
                                    name="email"
                                    placeholder='Ingresá tu e-mail'
                                    label='E-mail'
                                    value={values.email}
                                    onChange={handleChange}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item sx={{ pl: { md: '1rem' } }}>
                                <FieldCustom
                                    id="asunto"
                                    name="asunto"
                                    placeholder='Ingresá el asunto de tu consulta'
                                    label='Asunto'
                                    value={values.asunto}
                                    onChange={handleChange}
                                    error={touched.asunto && Boolean(errors.asunto)}
                                    helperText={touched.asunto && errors.asunto}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <FieldCustom
                                    id="comment"
                                    name="comment"
                                    placeholder='Dejanos tu comentario'
                                    label='Comentarios/Sugerencias'
                                    multiline
                                    value={values.comment}
                                    onChange={handleChange}
                                    error={touched.comment && Boolean(errors.comment)}
                                    helperText={touched.comment && errors.comment}

                                />
                            </Grid>
                        </Grid>
                        <Grid display={'flex'} justifyContent='flex-end' width={'100%'} item >
                            <Button className='btnSutmit' type='submit' size="small" variant="contained" >
                                Enviar
                            </Button>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <ReCAPTCHA
                ref={recaptchaInstance}
                sitekey={RECAP_KEY}
                size="invisible"
                onChange={handleSubmit}
            />
        </>
    )
}

export default FormContacto