import  useIsMobile from "../../../../../Utils/useIsMobile"
import { useSelector } from 'react-redux'
import { Grid, Container, Button, Box, Backdrop, CircularProgress} from "@mui/material";
import { useState, useEffect } from "react";
import RequestApi from "../../../../../Request-api/RequestApi";
import { items } from './MenuItems.json'
import ItemMenu from "./ItemMenu/ItemMenu";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAuthLogin } from "../../../../../Redux/Reducers/authLoginReducer";
import { setFamValidados, setFamPendientes, setFamiliares } from '../../../../../Redux/Reducers/grupoFamiliarReducer'
import moment from "moment";
import utils from "../../../../../Utils/useDate";
import "./Inicio.css"
import "./Menu.css"
import Dashboard from "./ItemMenu/Dashboard";

const Inicio = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    const [loading, setLoading] = useState(false);
    const {nombre, apellido, docNum, fechaNac, idEDNS} = useSelector(state => state.authLogin);
    const { id } = useSelector(state => state.authLogin.status);
    const [dashboard, setDashboard] = useState({

    })

    useEffect(()=>{
        /* let fechaHoy = moment();
        RequestApi.getTurnos({
            sistema: "CMA",
            fechaDesde: fechaHoy.format("DD/MM/YYYY"),
            fechaHasta: fechaHoy
            .add(90, "days")
            .format("DD/MM/YYYY"),
            usuLogin: utils.convertDate(fechaNac)+docNum+"@swissmedical.com.ar",
        }).then(r => {
            setDashboard({})
        }) */
        if(id === 3) {
            //services
            RequestApi.getGrupo(idEDNS)
            .then(fam => {
                dispatch(setFamiliares(fam));
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const handleLogout = () => {
        dispatch(resetAuthLogin())
        sessionStorage.clear();
        history.push('/login')
    }
  
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <p className="text-bienv">¡Bienvenido {nombre} {apellido}!</p>
                <Grid container display='flex' justifyContent='center'>
                    {
                        isMobile ? 
                        items.map(i => <ItemMenu icon={i.icon} name={i.name} path={i.path}/>)
                        : <Dashboard/>
                    }
                </Grid>
                {
                    isMobile ?
                    <Box mt={5} width='100%' display='flex' justifyContent='center'>
                        <Button onClick={handleLogout}>Cerrar sesión</Button>
                    </Box> 
                    : null
                }
            </Container>
        </>
    )
}
export default Inicio