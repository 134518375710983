import { Grid, AppBar, Toolbar } from '@mui/material';
import NavLogo from './components/NavLogo';
import NavMenu from './components/NavMenu';
import NavButtons from './components/NavButtons';
import NavOthersWebs from './components/NavOthersWebs';
import { useState } from 'react';

const styles = {
    "& .MuiToolbar-root": {
        paddingTop:"5px",
        paddingBottom:"5px",
    },
    "& .navContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    "& .navLogoContainer": {
        justifyContent: "flex-start"
    },
    "& .navButtonsContainer": {
        justifyContent: "flex-end"
    }
}

const NavDesktop = () => {

    const [openCubic, setOpenCubic] = useState(false);

    const handleOnBlurCubic = () => {
        setOpenCubic(!openCubic);
    }

    return (
        <>
            <AppBar position="fixed" color="inherit"  sx={styles} p={5}>
                <Toolbar>
                    <Grid container>
                        <Grid xs={2} className="navContainer navLogoContainer">
                            <NavLogo />
                        </Grid>
                        <Grid item xs={7} className="navContainer">
                            <NavMenu />
                        </Grid>
                        <Grid item xs={3} className="navContainer navButtonsContainer">
                            <NavButtons openCubic={openCubic} handleToggleCubicMenu={handleOnBlurCubic} />
                        </Grid>
                    </Grid>
                </Toolbar>
                {openCubic && <NavOthersWebs handleOnBlur={handleOnBlurCubic} open={openCubic}/>}
            </AppBar>
        </>
    )
}

export default NavDesktop;