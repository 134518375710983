import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import RequestApi from "../../../../Request-api/RequestApi";
import { setCuilCuit } from "../../../../Redux/Reducers/authLoginReducer";

const PrivateRoute = ({ children, setLoading, ...rest }) => {
    const { id } = useSelector(state => state.authLogin.status);
    const { docNum, docType, fechaNac } = useSelector(state => state.authLogin);
    const dispatch = useDispatch();
    const history = useHistory();

    if(id !== 3) {
        if(id === 1 || id === 4) {
            Swal.fire({
                icon: 'warning',
                title: '¡Atención!',
                text: 'Es necesario que valides tu identidad para continuar.',
                footer: '<p style="color:red;font-weight:bold">Este proceso es mejor desde un díspositivo móvil.</p>',
                confirmButtonText: 'Aceptar',
                preConfirm: () => { 
                    setLoading(true)
                    RequestApi.getCuilCuit(docType.id, docNum, fechaNac)
                    .then(res => {
                        setLoading(false)
                        if(!res.tipoResultadoDesc){
                            Swal.fire({
                                title: 'Validación de identidad',
                                text: 'Aun no tenemos estudios cargados en nuestros sistemas. Hasta tanto no cuentes con estudios médicos en Swiss Medical, no podrás acceder a esta sección.',
                                icon: 'warning',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            })
                        } else {
                            setLoading(false)
                            dispatch(setCuilCuit(res))
                            history.push('/pacientes/validar-titular')
                        }
                    }).catch(err => {
                        setLoading(false)
                        //error
                    })
                 }
            })
        } else if (id === 2) {
            Swal.fire({
                icon: 'info',
                title: 'Pendiente de validación',
                text: 'Cuando tus datos se validen correctamente, te notificaremos.',
                confirmButtonText: 'Aceptar',
              })
        }
    }

    return (
        <Route
            {...rest}
            render={() => {
                return id === 3 ? (
                    children
                ) : (
                    <Redirect to="/pacientes" />
                );
            }}
        />
    );
}

export default PrivateRoute;