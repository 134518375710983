import { useEffect, useState } from "react";
import TurnoCard from "./Items/TurnoCard";
import "./verTurno.css";
import UnTurnoPara from "./Items/UnTurnoPara";
import ButtonCustom from "../../../../../../UI/ButtonCustom/ButtonCustom";
import InicioTurno from "./Items/InicioTurno";
import RequestApi from '../../../../../../Request-api/RequestApi';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useIsMobile from "../../../../../../Utils/useIsMobile";
import "./verTurnoDesktop.css";

const VerTurno = () => {
  const history = useHistory();
  const [turnos, setTurnos] = useState([]);
  const { isMobile } = useIsMobile();
  const {fechaNac, docNum, docType, genero, nombre, apellido, tel, plan} = useSelector((state) => state.authLogin);
  const [integranteSeleccionado, setIntegranteSeleccionado] = useState({ nombreCompleto: `${nombre} ${apellido}`,
  nombre: nombre,
  apellido: apellido,
  doc: docNum,
  fechaNac: fechaNac,
  genero: genero,
  gamaPk: plan.gamaPk,
  planPk: plan.planPk})

  const handleNuevoTurno = () => {
    history.push("/pacientes/turnos/nuevo");
  };
  const handleTurnosPreviosDelAño = () => {
    history.push("/pacientes/turnos/ver/previos");
  };
  const handleProximosTurnos = () => {
    history.push("/pacientes/turnos/ver/proximos");
  };

  useEffect(()=> {
    const fechaDesde = moment().add(-90, "days").format("DD/MM/YYYY");
    const fechaHasta = moment().add(90, "days").format("DD/MM/YYYY");
    const fechaMoment = moment(integranteSeleccionado.fechaNac).format("DDMMYYYY")
    const usuLogin = fechaMoment +integranteSeleccionado.doc +"@swissmedical.com.ar";
    RequestApi.getTurnos({
      sistema: "CMA",
      fechaDesde: fechaDesde,
      fechaHasta: fechaHasta,
      usuLogin: usuLogin
    }).then(({ respuesta }) => {
      
      if(respuesta.datosrespuesta.mensajerespuesta === "No se encontro ningun paciente en XHIS") {
        RequestApi.registerEbooking(integranteSeleccionado.fechaNac, integranteSeleccionado.doc, docType.value, usuLogin, integranteSeleccionado.genero, integranteSeleccionado.nombre, integranteSeleccionado.apellido, tel).then(r => {
        })
      } else {
        if(Array.isArray(respuesta.turnos)){
          setTurnos(respuesta.turnos.map(t => t.turno))
        }else{
          setTurnos(typeof  respuesta.turnos === "string" ? [] : [respuesta.turnos].map(t => t.turno))
        }
      }
    })
  }, [integranteSeleccionado])
  return (
    <div
      className={isMobile ? "containerPrincipal" : "containerPrincipalDesktop"}
    >
      <div className={isMobile ? "parteDeArriva" : "parteDeArrivaDesktop"}>
        <div
          className={
            isMobile ? "containerInicioTurno" : "containerInicioTurnoDesktop"
          }
        >
          <InicioTurno />
        </div>

        <div className={isMobile ? "unTurnoPara" : "unTurnoParaDesktop"}>
          <UnTurnoPara setIntegranteSeleccionado={setIntegranteSeleccionado} />
        </div>
      </div>

      <div className={isMobile ? "button" : "buttonDesktop"}>
        <ButtonCustom
          primaryColor="#E4002B"
          color="#FFF"
          onClick={handleNuevoTurno}
          width="250px"
          name={"Obtener Nuevo turno"}
        />
      </div>

      <div className={isMobile ? "dosCards" : "dosCardsDesktop"}>
        <p >
          <div
            className={isMobile ? "alinearProximos" : "alinearProximosDesktop"}
          >
            <div
              className={isMobile ? "medicoYFlecha" : "medicoYFlechaDesktop"}
              style={{ marginBottom: "15px" }}
            >
              <img
                style={{ marginRight: "10px", marginLeft: "20px" }}
                alt="img"
                src="../../images/medico.svg"
              ></img>
              <b style={{ marginRight: "100px" }}>Próximos turnos </b>
              <img
                onClick={handleProximosTurnos}
                alt="img"
                src="../../images/flechaRojaDerecha.svg"
              ></img>
            </div>
            {turnos.length ? (
              <div>
                {
                  turnos.filter(turno => moment(turno.fecha_desde, "DD/MM/YYYY HH:mm")
                  .isSameOrAfter(moment()))
                  .map((turno) => {
                    
                    return (
                      <TurnoCard
                        integranteSeleccionado={integranteSeleccionado}
                        verBotonBorrar={true}
                        verBotonDetalle={true}
                        nombreDoc={
                          turno.nombre_abre_atencion +
                          " " +
                          turno.ape_razon_atencion
                        }
                        especialidad={turno.exten_deno}
                        fechaYHora={turno.fecha_desde_dia}
                        lugarDeAtencion={turno.centro_deno}
                        direccion={turno.domicilio}
                        consulta_id={turno.consulta_id}
                        preparacionId={turno.preparaciones.preparacion?.id}
                      />
                    );
                  })
                }
              </div>
            ) : (
              <p style={{ marginTop: "20px", marginLeft: "20px" }}>
                No hay turnos disponibles
              </p>
            )}
          </div>

          <div
            className={isMobile ? "alinearPrevios" : "alinearPreviosDesktop"}
          >
            <div
              className={isMobile ? "medicoYFlecha" : "medicoYFlechaDesktop"}
            >
              <p>
                <img
                  style={{ marginRight: "10px", marginLeft: "20px" }}
                  alt="img"
                  src="../../images/medico.svg"
                ></img>
                <b style={{ marginRight: "110px" }}> Turnos previos</b>
                <img
                  onClick={handleTurnosPreviosDelAño}
                  alt="img"
                  src="../../images/flechaRojaDerecha.svg"
                ></img>
              </p>
            </div>

            {turnos.length ? (
              <div>
                {
                  turnos.filter(turno => moment(turno.fecha_desde, "DD/MM/YYYY HH:mm")
                  .isBefore(moment()))
                  .map((turno) => {
                    return (
                      <TurnoCard
                        integranteSeleccionado={integranteSeleccionado}
                        verBotonBorrar={true}
                        verBotonDetalle={false}
                        nombreDoc={
                          turno.nombre_abre_atencion +
                          " " +
                          turno.ape_razon_atencion
                        }
                        especialidad={turno.exten_deno}
                        fechaYHora={turno.fecha_desde_dia}
                        lugarDeAtencion={turno.centro_deno}
                        direccion={turno.domicilio}
                        consulta_id={turno.consulta_id
                        }
                        preparacionId={turno.preparaciones.preparacion?.id}
                      />
                    );
                  })
                }
              </div>
            ) : (
              <p style={{ marginTop: "20px", marginLeft: "20px" }}>
                No hay turnos disponibles
              </p>
            )}
          </div>
        </p>
      </div>
    </div>
  );
};

export default VerTurno;
