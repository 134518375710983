import { Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { toggleOpen } from "../../../Redux/Reducers/signinModalReducer";
import MenuIngresar from "../../MenuIngresar/MenuIngresar";
import cubicMenu from "../CubicMenu.svg";
import cubicMenuRed from "../CubicMenuRed.svg";

const useStyles = makeStyles(theme => ({
    root: {
        "& button": {
            marginRight: "5px",
            paddingLeft: "1vw",
            paddingRight: "1vw",
            paddingTop: "2px",
            paddingBottom: "2px",
            fontFamily: "'Lato', sans-serif",
            borderRadius: "40px",
            fontWeight: "bold",
            textTransform: "initial",
            lineHeight: "1.5",
            fontSize: ".6rem",
            height: "40px",
            "@media only screen and (min-width: 900px)": {
                fontSize: ".7rem"
            },
            "@media only screen and (min-width: 1250px)": {
                fontSize: ".8rem",
                whiteSpace: "nowrap",
                paddingLeft: "1.5vw",
                paddingRight: "1.5vw",
                marginRight: ".5rem",
            },
            "@media only screen and (min-width: 1300px)": {
                paddingLeft: "2vw",
                paddingRight: "2vw",
            },
        },
        "& .btnAsociar": {
            color: "#000",
            borderColor: "#000"
        },
        "& .btnIngresar": {
            color: "#fff",
            backgroundColor: "#E4002B",
            borderColor: "#000",
            boxShadow: 'none'

        },
        "& .btnMenu": {
            minWidth: "40px",
            marginRight: "0",
            padding: "2px"
        }
    }
})
);

const NavButtons = ({ openCubic, handleToggleCubicMenu  }) => {
    
    const { root } = useStyles();
    const dispatch = useDispatch();

    const handleToggleAsociarme = () => {
        window.open('https://asociarme.swissmedical.com.ar/contacto', '_blank');
    }

    return (
        <>
            <Stack direction="row" className={root}>
                <Button variant="outlined" className="btnAsociar" onClick={handleToggleAsociarme}> Quiero asociarme </Button>
                <Button variant="contained" className="btnIngresar" onClick={() => dispatch(toggleOpen(true))}> Ingresar </Button>
                <Button className="btnMenu" onClick={handleToggleCubicMenu}><img src={openCubic ? cubicMenuRed : cubicMenu} alt="menu" /></Button>
            </Stack>
            <MenuIngresar />
        </>
    );
}

export default NavButtons;