import * as Yup from 'yup';

const ERR_REQUIRED = 'Este campo es requerido';

const FormSchema = Yup.object().shape({
    nameLastName: Yup.string()
        .min(2, 'Ingrese un nombre y apellido válido')
        .max(50, 'Ingrese un nombre y apellido válido')
        .required(ERR_REQUIRED),
    tel: Yup.string()
        .min(5, 'Ingrese un teléfono válido')
        .max(15, 'Ingrese un teléfono válido')
        .required(ERR_REQUIRED),
    email: Yup.string().email('Ingrese un email válido').required(ERR_REQUIRED),
    asunto: Yup.string()
        .min(1, 'Ingrese un asunto')
        .max(150, 'Ingrese un asunto')
        .required(ERR_REQUIRED),
    comment: Yup.string()
        .min(3, 'Ingrese un comentario')
        .required(ERR_REQUIRED)
});


export default FormSchema