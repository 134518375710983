import { Box, List, Alert, AlertTitle, Typography } from "@mui/material"
import { useEffect } from "react";

const styles = {
    marginTop: "2rem",
    address: {
        title: {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: "#363636",
        },
        content: {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1rem",
            color: "#363636",
        }
    },
    info: {
        title: {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: "#E4002B",
        },
        description : {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1rem",
            color: "#363636",
        },
        content: {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1rem",
            color: "#797979",
        }
    },
    emergency: {
        title: {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: "#E4002B",
        },
        subtitle: {
            fontFamily: "'Lato', sans-serif",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#000"
        },
        list: {
            fontFamily: "'Lato', sans-serif",
            paddingLeft: '2rem',
            listStyle: "disc outside none",
            color: "#797979"
        }
    },
    alert: {
        backgroundColor: '#fff',
        color: '#000',
        px: 0,
        "& .title": {
            textAlign: 'start',
            fontWeight: 'bold'
        },
        "& strong": {
            color: "#E4002B"
        }
    },
    alertOdonto: {
        backgroundColor: '#fff',
        color: '#797979',
        px: 0,
        marginBottom: '-0.5rem',
        maxWidth: { xs: '335px', md: '450px' },
        "& .textAlert": {
            marginTop: '2rem',
        },
        "& strong": {
            color: "#F08332"
        }
    }
}

const Seccion = ({ section }) => {

    const { type } = section;
    const { address, info, emergency, alert, alertOdonto } = styles;

    if (type === "address") {
        return (
            <>
                <Box style={styles}>
                    <Typography variant="h6" sx={address.title}>
                        {section.title}
                    </Typography>
                    <Typography sx={address.content}>
                        {section.content}
                    </Typography>
                </Box>
            </>
        )
    }

    if (type === "info") {
        return section.sections.map(secInfo => {
            return (
                <Box style={styles}>
                    <Typography variant="h6" sx={info.title}>
                        {secInfo.title} {section.open === false ? (<><span>(cerrado momentáneamente)</span></>) : ''}
                        {secInfo.description ?<Typography component='span'>({secInfo.description})</Typography> : '' }
                    </Typography>
                    <Typography sx={address.content}>
                        {secInfo.subtitle}
                    </Typography>
                    <Typography sx={info.content}>
                        {secInfo.content}
                    </Typography>

                    {
                        secInfo.list && (
                            <List sx={emergency.list}>
                                {secInfo.list.map(item => <li>{item}</li>)}
                                {secInfo.sublist && (<List sx={{ listStyle: 'circle', ml: '1rem' }}>{secInfo.sublist.map(it => <li>{it}</li>)}</List>)}
                            </List>
                        )
                    }

                </Box>
            )
        })
    }


    if (type === "emergency") {
        return (
            <>
                <Box style={styles} id={type}>
                    <Typography variant="h6" sx={emergency.title}>
                        {section.title}
                    </Typography>
                    {section.sections.map(secEmergency => {
                        return (
                            <>
                                <Typography sx={emergency.subtitle}>
                                    {secEmergency.title}
                                </Typography>
                                <List sx={emergency.list}>
                                    {
                                        secEmergency.list.map(item => {
                                            return (
                                                <li>{item}</li>
                                            )
                                        })
                                    }
                                </List>
                            </>
                        )
                    })}
                </Box>
            </>
        )
    }

    if (type === "turnoOnline") {
        return (
            <>
                <Box style={styles}>
                    <Alert severity="error" sx={alert}>
                        <AlertTitle className="title">Turnos online</AlertTitle>
                        Exclusivos para <strong>asociados registrados</strong> en el sitio web de Swiss Medical Medicina Privada.
                    </Alert>
                </Box>
            </>
        )
    }

    if (type === "alert") {
        return (
            <>
                <Box style={styles}>
                    <Alert severity="error" color='warning' sx={alertOdonto}></Alert>
                    <Typography sx={alertOdonto} className="textAlert">En nuestro centro especializado en implantes dentales, atendido por experimentados profesionales, <strong>se realizan tratamientos de acuerdo a protocolos internacionales, utilizando materiales de última generación y con la mejor tecnología existente</strong>.</Typography>
                </Box>
            </>
        )
    }

    if (type === "alertCampana") {
        return (
            <>
                <Box style={styles}>
                    <Alert severity="error" color='warning' sx={alertOdonto}></Alert>
                    <Typography sx={alertOdonto} className="textAlert">El Swiss Medical Center Campana cuenta, además, con <strong>consultorios de ART</strong>  y una sucursal de <strong> Swiss Medical Medicina Privada.</strong></Typography>
                </Box>
            </>
        )
    }
}

export default Seccion;