import { Button } from "@mui/material"

const styles = {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "bold",
    color: "#fff",
    textTransform: "initial",
    borderRadius: "40px",
    backgroundColor: "#E4002B",
    borderColor: "#E4002B",
    width: "100%",
    height: "40px",
    padding: "15px",
    "&:hover":{
        backgroundColor: 'rgb(210 25 25 / 75%)',
        borderColor: '#fff'
    }
}

const CustomRedButton = ({onClick, text, sx, disabled}) => {
    return (
        <>
            <Button variant='contained' sx={{...styles, ...sx}} onClick={onClick} disabled={disabled}>{text}</Button>  
        </>
    )
}
export default CustomRedButton;