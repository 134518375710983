import React from 'react'
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FormContacto from './FormContacto/FormContacto';
import { useEffect } from 'react';
import { toggleOpen } from '../../../Redux/Reducers/signinModalReducer';
import { useDispatch } from 'react-redux';


const styles = {
    section: {
        marginY: { xs: '1rem', md: '1.5rem', lg: '2rem' },
        marginX: { xs: '1rem', sm: '5rem', md: '6rem', lg: '8.5rem' },
        marginBottom: { xs: '6rem', sm: '5rem', md: '3rem', lg: '7rem' },
        "& .textTypography": {
            fontFamily: "'Lato', sans-serif",
            marginBottom: { xs: '1.5rem', sm: '2rem' },
            maxWidth: { xs: '270px', sm: '615px' },
            margin: { md: 'auto' }

        },
        "& .btnSutmit": {
            width: { xs: '100%', sm: '25%', md: '20%' },
            borderRadius: '2rem',
            marginY: '2.5rem',
            paddingY: '12px',
            background: '#E4002B',
            textTransform: 'capitalize',
            fontFamily: "'Lato', sans-serif",
            fontWeight: 'bold',
            ':hover': {
                background: '#E4002B',
            },
        },
        "& .containerPaper": {
            marginTop: '3rem',
            marginBottom: '8rem',
        },
        "& .imageContac": {
            borderRadius: '4px',
            marginBottom: { md: '0.5rem' }
        },
        "& .containerTurn": {
            padding: { xs: '1.5rem', sm: '2rem', md: '2rem', lg: '3rem' },
            display: { md: 'flex' },
            flexDirection: { md: 'column-reverse' },
        },
        "& .typographyTurn": {
            fontFamily: "'Lato', sans-serif",
            color: '#797979',
            marginBottom: { xs: '2rem' },
            maxWidth: { md: '525px', lg: '800px' }
        },
        "& .btnTurnUp": {
            width: { xs: '100%', sm: '35%' },
            borderRadius: '2rem',
            paddingY: '12px',
            background: '#E4002B',
            textTransform: 'initial',
            fontFamily: "'Lato', sans-serif",
            fontWeight: 'bold',
            ':hover': {
                background: '#E4002B',
            },
            marginRight: '2rem'

        },
        "& .btnTurnDown": {
            width: { xs: '100%', sm: '50%' },
            borderRadius: '2rem',
            paddingY: '12px',
            color: '#E4002B',
            textTransform: 'initial',
            fontFamily: "'Lato', sans-serif",
            fontWeight: 'bold',
            borderColor: '#E4002B',
            ':hover': {
                borderColor: '#E4002B',
            },
            marginTop: { xs: '1rem', sm: '0' },

        },
        "& .btnTurnos": {
            display: { sm: 'flex' },
            flexDirection: { sm: 'row' },
            alignItems: { sm: 'center' },
            marginBottom: { md: '1.5rem' }
        }
    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "8rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerTitle": {
            marginTop: { xs: '1rem', md: '9rem' },
            fontWeight: "bold",
            fontSize: "2.125rem",
            fontFamily: "'Lato', sans-serif",

        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const Contacto = () => {

    const dispatch = useDispatch();

    const { headerSection, section } = styles;

    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <Box sx={headerSection}>
                <Typography className="headerTitle">Contacto</Typography>
                <HorizontalRuleIcon className="headerIcon" />
            </Box>
            <Box sx={section}>
                <Box>
                    <Typography className="textTypography">Envíenos su consulta, comentario y/o sugerencia completando el siguiente formulario.</Typography>
                    <Grid container sx={{ marginTop: { md: '2rem' } }}>
                        <FormContacto />
                    </Grid>
                </Box>
                <Box>
                    <Paper elevation={4}>
                        <Grid display={'flex'} flexDirection='row-reverse'
                            className='containerPaper' container>
                            <Grid items>
                                <img className='imageContac' src="/images/info/contacto.jpg" alt="Image Contacto" />
                            </Grid>
                            <Grid className='containerTurn' items>
                                <Typography className='typographyTurn'>Exclusivo para asociados de Swiss Medical Medicina Privada registrados en el sitio web.
                                </Typography>
                                <Box className='btnTurnos'>
                                    <Button
                                        onClick={() => dispatch(toggleOpen(true))}
                                        className='btnTurnUp'
                                        size="small"
                                        variant="contained"
                                    >
                                        Turnos online
                                    </Button>
                                    <Button
                                        href="tel:0810-333-8876"
                                        className='btnTurnDown'
                                        size="small"
                                        variant='outlined'
                                    >
                                        Central de turnos 0810-333-8876
                                    </Button>

                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Box>
        </>
    )
}

export default Contacto