/* ckOrdenes */
import { AxiosInstanceApiManager2 } from "../Axios-instance-sinToken"

let RecuestApiSinToken = {
    getEspecialidades(edad, sexo, namePlan, gamaPlan) {
        return  AxiosInstanceApiManager2.get(`/adminsitio/registernosociosmg/apim/cons/especialidades?edad=${edad}&sexo=${sexo}&plan=${namePlan}&gama=${gamaPlan}`).then(({ data }) => data);
    },
    getPracticas(edad, sexo, espe, namePlan, gamaPlan) {
        return AxiosInstanceApiManager2.get(`/adminsitio/registernosociosmg/apim/cons/practicas?edad=${edad}&sexo=${sexo}&espe=${espe}&plan=${namePlan}&gama=${gamaPlan}`).then(({ data }) => data)
    },
    getCentrosPorEspe(userLogin, espe, prac, edad, namePlan, gamaPlan, servBasico) {
        return AxiosInstanceApiManager2.get(`/adminsitio/registernosociosmg/apim/cons/centros?edad=${edad}&especialidad=${espe}&practica=${prac}&usuLogin=${userLogin}&plan=${namePlan}&gama=${gamaPlan}&servBasico=${servBasico}`).then(({ data }) => data)
    },  //api-smg/V2/edns/turnos/CMA/centros?edad=37&especialidad=${espe}&practica=${prac}&usuLogin=${userLogin}&plan=2&gama=4783
    
    getPrestadores(practica, espe, centros, namePlan, gamaPlan) {
        return AxiosInstanceApiManager2.get(`/adminsitio/registernosociosmg/apim/cons/prestadores?sexoPrestador=&especialidad=${espe}&practica=${practica}&centro=${centros}&todos=S&plan=${namePlan}&gama=${gamaPlan}`).then(({ data }) => data)
    },
    getEstudios(body) {
        return AxiosInstanceApiManager2.post(`/adminsitio/registernosociosmg/apim/turnos/informes-ris`, body).then(({ data }) => data)
    },
    
}
export default RecuestApiSinToken;
