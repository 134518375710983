import * as Yup from 'yup';
import moment from 'moment';

const ERR_REQUIRED = 'Este campo es requerido';

export const RegisterSchemaF1 = Yup.object().shape({
    nombre: Yup.string().min(2, 'Ingrese un nombre válido').max(50, 'Ingrese un nombre válido').required(ERR_REQUIRED),
    apellido: Yup.string().min(2, 'Ingrese un apellido válido').max(50, 'Ingrese un apellido válido').required(ERR_REQUIRED),
    tipoDoc: Yup.string().required(ERR_REQUIRED),
    dni: Yup.string().min(6, 'Ingrese un DNI válido').max(9, 'Ingrese un DNI válido').required(ERR_REQUIRED),
    email: Yup.string().email('Ingrese un email válido').required(ERR_REQUIRED),
    repetirEmail: Yup.string().oneOf([Yup.ref('email'), null], 'El email ingresado no coincide con el primero').required(ERR_REQUIRED)
});

export const RegisterSchemaF2 = Yup.object().shape({
    nacionalidad: Yup.string().required(ERR_REQUIRED),
    telefono: Yup.number().min(4, 'Ingresar un número válido').required(ERR_REQUIRED),
    codArea: Yup.number().min(2, 'Ingresar un código de área válido').required(ERR_REQUIRED),
    provincia: Yup.string().required(ERR_REQUIRED),
    ciudad: Yup.string().min(3, 'Indicar una ciudad válida').required(ERR_REQUIRED),
    direccion: Yup.string().min(3, 'Indique una dirección válida').required(ERR_REQUIRED),
    codPostal: Yup.string().nullable().optional(),
    piso: Yup.string().nullable().optional(),
    depto: Yup.string().nullable().optional(),
    fechaNacimiento: Yup.string()
    .test("DOB","Debes ser mayor de 16 años", value => {     
        return moment().diff(moment(value),'years') >= 16;
        }
    ),
    genero: Yup.string().required(ERR_REQUIRED),
    password: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!._%*?&])[A-Za-z\d@$!._%*?&]{8,}$/,
        "La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial"
      ).required(ERR_REQUIRED),
    repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden').required(ERR_REQUIRED),
    obraSocial: Yup.string().required(ERR_REQUIRED),
    tipoPlan: Yup.string().nullable().optional(),
    otro_obraSocial: Yup.string().nullable().optional(),
    otro_tipoPlan: Yup.string().nullable().optional(),
 })

