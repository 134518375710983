import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import { toggleOpen } from '../../../../../Redux/Reducers/signinModalReducer';
import { useDispatch } from 'react-redux';


const styles = {
    card: {
        borderRadius: '15px',
        maxWidth: '310px',
        maxHeight: '520px',
        "& .imgContainer": {
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            marginBottom: "-1rem",
            transform: "translate(3rem, -3rem)",
            overflow: "hidden"
        },
        "& .imgCard": {
            paddingTop: "3rem",
            height: "84%"
        },
        "& .cardBtn": {
            marginTop: "1rem",
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold",
            color: "#E4002B",
            textTransform: "initial",
            borderRadius: "40px",
            borderColor: "#E4002B",
            height: "40px",
            padding: "15px",
        },
        "& .actionBox": {
            padding: "1rem",
            paddingTop: "0"
        },
        "& .MuiCardContent-root": {
            height: "122px",
        }
    }
}

const CenterCard = ({ title, address, thumb, href }) => {

    const { card } = styles;
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = () => {
        history.push(href);
    };

    return (
        <>
            <Card sx={card}>
                <CardActionArea className='cardBox' onClick={handleClick}>
                    <Box className="imgContainer">
                        <CardMedia
                            className="imgCard"
                            component="img"
                            image={thumb}
                            alt="Centro médico"
                        />
                    </Box>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{fontSize: "1.3rem"}}>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className='addressBox'>
                            {address}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className='actionBox'>
                    <Button onClick={() => dispatch(toggleOpen(true))} size="small" variant='outlined' className='cardBtn'>
                        Turnos online
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}

export default CenterCard;