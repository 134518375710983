import Axios from "axios";
import Swal from "sweetalert2";
import { ENVIRONMENT_URL } from "./constants";

export const AxiosInstance = Axios.create({
    baseURL: ENVIRONMENT_URL
});

AxiosInstance.interceptors.request.use(
  async request => {
    const token  = sessionStorage.getItem('token');
    const apimToken = sessionStorage.getItem('apimToken');
    if(token){
      request.headers["Authorization"] = token;
    }
    if(apimToken){
      request.headers["apim_recaptcha"] = apimToken;
    }
    return request;
  },
  error => {
  Promise.reject(error);
  }
);

const refreshToken = async () => {
  const token  = sessionStorage.getItem('token');
  const refreshToken = sessionStorage.getItem('refreshToken');
  return Axios({
    baseURL: ENVIRONMENT_URL,
    method: 'post',
    url: '/adminsitio/registernosociosmg/login/refresh',
    headers: { 
      'Accept': 'application/json', 
      'Content-Type': 'application/json',
      'accessToken': token,
      'refreshToken': refreshToken,
      Authorization: null
    }
  })
  .then(({ data }) => data)
  .catch(e => Swal.fire({
    title: "Vencimiento de sesión",
    text: "Tu sesión expiró. Por favor, ingrese nuevamente para seguir usando nuestro sitio web.",
    willClose: () => {
      sessionStorage.clear();
      window.location.reload();
    }
  }));

}

AxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 403 && !originalConfig._retry && originalConfig.url !== '/adminsitio/registernosociosmg/login/refresh') {
        originalConfig._retry = true;
        const { token } = await refreshToken();
        sessionStorage.setItem("token", token);
        return AxiosInstance(originalConfig);
      }
    }
    return Promise.reject(error);
  }

)