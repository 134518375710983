import { Button } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { useState } from "react";

const FileInput = ({label, newFile, fileName, id}) => {

    const [file, setFile] = useState();
  
    const renameFile = (originalFile, newName) => {
      return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
      });
    }
  
    const handleUploadFile = (e) => {
      const timestamp = new Date().getTime();
      const fileRenamed = renameFile(e.target.files[0], `${fileName}-${timestamp}`) 
      setFile(fileRenamed);
      newFile(id, fileRenamed)
    }

    return (
        <>
            <div className="input-image" style={{display:'flex', justifyContent:'center'}}>
            {file ?
                <>
                    <div style={{position: 'relative', width:'260px', aspectRatio:'2/1', marginBottom:'1rem' }}>            
                        <img alt='docimage' style={{ width:'100%', margin:'0', height:"100%", objectFit:'cover'}} src={URL.createObjectURL(file)} />
                        <Button
                        htmlFor="raised-button-file"
                        className="shapefile-icon"
                        component="label"
                        title='Cargar otra imagen'
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            p:'5px',
                            minWidth: '32px'
                        }}
                        >
                            <PublishIcon sx={{color:'#fff', fontSize:'1.8rem'}}/>
                        </Button>
                    </div>
                </> : <>
                    <input
                        style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        accept="image/x-png,image/jpg,image/jpeg"
                        onChange={handleUploadFile}
                    />
                    <Button
                        htmlFor="raised-button-file"
                        className="shapefile-icon"
                        component="label"
                        sx={{
                        width: '260px',
                        aspectRatio:'2/1',
                        marginBottom:'1rem'
                        }}
                    >
                        <div className="botonCamara" style={{width:'100%', height:'80%', margin:'0'}}>
                            <img alt="img" src="/images/camaraQr.svg"></img>
                            <p style={{color:'#000', fontFamily:"'Lato', sans-serif", fontSize: '.8rem' }}>{label}</p>
                        </div>
                    </Button>
                </>
            }
            </div>
        </>
    )
}

export default FileInput;