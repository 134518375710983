import { useState, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import { Formik, Form } from 'formik';
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom";
import useIsMobile from "../../../../Utils/useIsMobile";
import RequestApi from "../../../../Request-api/RequestApi";
import ToolTipOS from "./ToolTIp/ToolTip";
import { RegisterSchemaF2 } from "../RegisterSchema";
import FieldCustom from "../../../../UI/FieldCustom/FieldCustom";

const DatosGenerales = ({next, setRegistro}) => {
    const { isMobile } = useIsMobile();
    const [planes, setPlanes] = useState([]);
    const [tipoPlanes, setTipoPlanes] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]);
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
        RequestApi.getPlanes()
        .then((plans) => {
            setPlanes(plans?.map((p) => {
                return { name: p.planDesc, value: p.planPk };
                }));
        });
        
        RequestApi.getGeneros()
        .then(gen => {
            setGeneros(gen.map(n => { 
                return { 
                    name: n.name, value: n.id 
                } 
            }))
        });

        RequestApi.getNacionalidades()
        .then(nac => {
            setNacionalidades(nac.map(n => { 
                return { 
                    name: n.nacionalidadDesc, value: n.nacionalidadPk 
                } 
            }))
        })

        RequestApi.getProvincias()
        .then(prov => {
            setProvincias(prov.map(p => { 
                return { 
                    name: p.nombreProvincia, value: p.idProvincia 
                } 
            }))
        })

    }, []);

    const handleSelectPlan = (e) => {
        const {value} = e.target;
        RequestApi.getTipoPlan(value)
        .then((tipoPlanes) => {
            setTipoPlanes(tipoPlanes?.map((p) => {
                return { name: p.gamaDesc, value: p.gamaPk };
            }));
        });
    }

    const saveRegistro = (values) => {
        setRegistro(values);
    }

    const handleConfirmar = (values) => {
        saveRegistro(values);
        next();
        /* updateRecaptcha.current.execute(); */
    }

    return (
        <>
            <Formik
                initialValues={{
                    nacionalidad: "",
                    telefono: "",
                    codArea: "",
                    provincia: "",
                    ciudad: "",
                    direccion: "",
                    codPostal: "",
                    piso: "",
                    depto: "",
                    fechaNacimiento: "",
                    genero: "",
                    password: "",
                    repeatPassword: "",
                    obraSocial: "",
                    tipoPlan: "",
                    otro_obraSocial: null,
                    otro_tipoPlan: null
                }}
                validationSchema={RegisterSchemaF2}
                onSubmit={handleConfirmar}
            >
                {({ errors, touched, values, handleChange }) => (                 
                    <Form>
                        <Grid container>
                            <Grid container>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}>
                                    <FieldCustom
                                        id="nacionalidad"
                                        name="nacionalidad"
                                        placeholder='Seleccionar'
                                        label='Nacionalidad'
                                        variant='select'
                                        options={nacionalidades}
                                        value={values.nacionalidad}
                                        onChange={handleChange}
                                        error={touched.nacionalidad && Boolean(errors.nacionalidad)}
                                    />
                                    <FieldCustom
                                        id="provincia"
                                        name="provincia"
                                        placeholder='Seleccionar'
                                        label='Provincia'
                                        variant='select'
                                        options={provincias}
                                        value={values.provincia}
                                        onChange={handleChange}
                                        error={touched.provincia && Boolean(errors.provincia)}
                                    />
                                    <FieldCustom
                                        id="ciudad"
                                        name="ciudad"
                                        placeholder='Ingresá tu ciudad'
                                        label='Ciudad'
                                        value={values.ciudad}
                                        onChange={handleChange}
                                        error={touched.ciudad && Boolean(errors.ciudad)}
                                        helperText={touched.ciudad && errors.ciudad}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}> 
                                    <FieldCustom
                                        id="direccion"
                                        name="direccion"
                                        placeholder='Ingresá tu dirección'
                                        label='Dirección (calle y número)'
                                        value={values.direccion}
                                        onChange={handleChange}
                                        error={touched.direccion && Boolean(errors.direccion)}
                                        helperText={touched.direccion && errors.direccion}
                                    />
                                    <FieldCustom
                                        id="codPostal"
                                        name="codPostal"
                                        placeholder='Ingresá tu código postal'
                                        label='Código postal'
                                        value={values.codPostal}
                                        onChange={handleChange}
                                        error={touched.codPostal && Boolean(errors.codPostal)}
                                        helperText={touched.codPostal && errors.codPostal}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}>
                                    <FieldCustom
                                        id="piso"
                                        name="piso"
                                        placeholder='Ingresá tu piso'
                                        label='Piso (opcional)'
                                        value={values.piso}
                                        onChange={handleChange}
                                        error={touched.piso && Boolean(errors.piso)}
                                        helperText={touched.piso && errors.piso}
                                    />
                                    <FieldCustom
                                        id="depto"
                                        name="depto"
                                        placeholder='Ingresá tu depto'
                                        label='Depto (opcional)'
                                        value={values.depto}
                                        onChange={handleChange}
                                        error={touched.depto && Boolean(errors.depto)}
                                        helperText={touched.depto && errors.depto}
                                    />
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{my: "2.5rem"}}
                                style={{ width: isMobile ? "97%" : "630px" }}
                                orientation="horizontal"
                                flexItem
                            />
                            <Grid container>
                                <Grid xs={12} sm={6} md={4} item container sx={{px:'1rem'}}>    
                                    <Grid xs={4} item sx={{pr:'1rem'}}>
                                        <FieldCustom
                                            id="codArea"
                                            name="codArea"
                                            placeholder='Cod'
                                            label='Teléfono'
                                            value={values.codArea}
                                            onChange={handleChange}
                                            error={touched.codArea && Boolean(errors.codArea)}
                                            helperText={touched.codArea && errors.codArea}
                                        />
                                    </Grid>
                                    <Grid xs={8} item sx={{pl: '1rem'}}>
                                        <FieldCustom 
                                            id="telefono"
                                            name="telefono"
                                            placeholder='Número'
                                            label='hidden'
                                            value={values.telefono}
                                            onChange={handleChange}
                                            error={touched.telefono && Boolean(errors.telefono)}
                                            helperText={touched.telefono && errors.telefono}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <FieldCustom
                                            id="genero"
                                            name="genero"
                                            placeholder='Seleccionar'
                                            label='Género'
                                            variant='select'
                                            options={generos}
                                            value={values.genero}
                                            onChange={handleChange}
                                            error={touched.genero && Boolean(errors.genero)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item container sx={{px:'1rem'}}>                                 
                                        <FieldCustom 
                                            id="fechaNacimiento"
                                            name="fechaNacimiento"
                                            placeholder='Número'
                                            label='Fecha de nacimiento'
                                            type='date'
                                            value={values.fechaNacimiento}
                                            onChange={handleChange}
                                            error={touched.fechaNacimiento && Boolean(errors.fechaNacimiento)}
                                            helperText={touched.fechaNacimiento && errors.fechaNacimiento}
                                        />
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{my: "2.5rem"}}
                                style={{ width: isMobile ? "97%" : "630px" }}
                                orientation="horizontal"
                                flexItem
                            />
                            <Grid container>
                                <Grid xs={12} sm={6} md={4} item container sx={{px:'1rem'}}>
                                    <FieldCustom
                                        id="obraSocial"
                                        name="obraSocial"
                                        placeholder='Seleccionar'
                                        label={<><ToolTipOS /> Obra Social / Prepaga</>}
                                        variant='select'
                                        options={planes}
                                        value={values.obraSocial}
                                        onChange={(e)=>{
                                            handleChange(e);
                                            handleSelectPlan(e);
                                        }}
                                        error={touched.obraSocial && Boolean(errors.obraSocial)}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}>
                                    {values.obraSocial === (-1) && (
                                        <>
                                            <FieldCustom 
                                                id="otro_obraSocial"
                                                name="otro_obraSocial"
                                                placeholder='Ingresá tu obra social'
                                                label='¿Cuál?'
                                                value={values.otro_obraSocial}
                                                onChange={handleChange}
                                                error={touched.otro_obraSocial && Boolean(errors.otro_obraSocial)}
                                                helperText={touched.otro_obraSocial && errors.otro_obraSocial}
                                            />
                                        </>
                                    )} 
                                        <FieldCustom
                                            id="tipoPlan"
                                            name="tipoPlan"
                                            placeholder='Seleccionar'
                                            label={<><ToolTipOS /> Tipo de plan</>}
                                            variant='select'
                                            options={tipoPlanes}
                                            value={values.tipoPlan}
                                            onChange={handleChange}
                                            error={touched.tipoPlan && Boolean(errors.tipoPlan)}
                                        />
                                    {values.tipoPlan === (-1) && (
                                            <FieldCustom 
                                                id="otro_tipoPlan"
                                                name="otro_tipoPlan"
                                                placeholder='Ingresá tu tipo de plan'
                                                label='Tipo de plan'
                                                value={values.otro_tipoPlan}
                                                onChange={handleChange}
                                                error={touched.otro_tipoPlan && Boolean(errors.otro_tipoPlan)}
                                                helperText={touched.otro_tipoPlan && errors.otro_tipoPlan}
                                            />
                                        )
                                    }
                                </Grid>       
                            </Grid>
                            <Divider
                                sx={{my: "2.5rem"}}
                                style={{ width: isMobile ? "97%" : "630px" }}
                                orientation="horizontal"
                                flexItem
                            />
                            <Grid container>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}>
                                    <FieldCustom 
                                        id="password"
                                        name="password"
                                        type='password'
                                        placeholder='Ingresá una contraseña'
                                        label='Contraseña'
                                        value={values.password}
                                        onChange={handleChange}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} item sx={{px:'1rem'}}>
                                    <FieldCustom 
                                        id="repeatPassword"
                                        name="repeatPassword"
                                        type='password'
                                        placeholder='Repetí tu contraseña'
                                        label='Repetir contraseña'
                                        value={values.repeatPassword}
                                        onChange={handleChange}
                                        error={touched.repeatPassword && Boolean(errors.repeatPassword)}
                                        helperText={touched.repeatPassword && errors.repeatPassword}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid xs={12} item>
                                    <div
                                        style={{
                                        paddingTop: "40px",
                                        paddingBottom: "40px",
                                        maxWidth: '500px'
                                        }}
                                    >
                                        <ButtonCustom width="40%" type='submit' name="Continuar"/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    )
}
export default DatosGenerales;