import { SvgIcon } from "@mui/material";

function ViewIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M15.75 6.5625V13.7946C15.75 14.4601 15.1831 15 14.4844 15H3.51562C2.81689 15 2.25 14.4601 2.25 13.7946V6.5625C2.25 5.89704 2.81689 5.35714 3.51562 5.35714H5.83594L6.16025 4.53097C6.34482 4.06138 6.8168 3.75 7.34414 3.75H10.6532C11.1806 3.75 11.6525 4.06138 11.8371 4.53097L12.1641 5.35714H14.4844C15.1831 5.35714 15.75 5.89704 15.75 6.5625ZM12.1641 10.1786C12.1641 8.51618 10.7455 7.16518 9 7.16518C7.25449 7.16518 5.83594 8.51618 5.83594 10.1786C5.83594 11.841 7.25449 13.192 9 13.192C10.7455 13.192 12.1641 11.841 12.1641 10.1786ZM11.3203 10.1786C11.3203 11.3965 10.2788 12.3884 9 12.3884C7.72119 12.3884 6.67969 11.3965 6.67969 10.1786C6.67969 8.96066 7.72119 7.96875 9 7.96875C10.2788 7.96875 11.3203 8.96066 11.3203 10.1786Z" fill="#363636"/>
      </SvgIcon>
    );
  }

export default ViewIcon;