import { useEffect, useState } from "react";
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom";
import useIsMobile from "../../../../Utils/useIsMobile";
import ModalTerminos from "./ModalTerminos/ModalTerminos";
import { Grid, Divider } from "@mui/material";
import RequestApi from "../../../../Request-api/RequestApi";
import { Formik, Form } from "formik";
import FieldCustom from "../../../../UI/FieldCustom/FieldCustom";
import ToolTipOS from "../../../Register/Forms/DatosGenerales/ToolTIp/ToolTip";

const ProductForm = ({user, next, setLoading}) => {
    const { isMobile } = useIsMobile();
    const [planes, setPlanes] = useState([]);
    const [tipoPlanes, setTipoPlanes] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [terminosCheck, setTerminosCheck] = useState(false);
    const [openTerminos, setOpenTerminos] = useState(true);
    const [terminosId, setTerminosId] = useState();

    useEffect(()=>{
        RequestApi.getPlanes()
        .then((plans) => {
            setPlanes(plans?.map((p) => {
                return { name: p.planDesc, value: p.planPk };
                }));
        });

        RequestApi.getNacionalidades()
        .then(nac => {
            setNacionalidades(nac.map(n => { 
                return { 
                    name: n.nacionalidadDesc, value: n.nacionalidadPk 
                } 
            }))
        })

        RequestApi.getProvincias()
        .then(prov => {
            setProvincias(prov.map(p => { 
                return { 
                    name: p.nombreProvincia, value: p.idProvincia 
                } 
            }))
        })
    },[])

    const handleSelectPlan = (e) => {
        const {value} = e.target;
        RequestApi.getTipoPlan(value)
        .then((tipoPlanes) => {
            setTipoPlanes(tipoPlanes?.map((p) => {
                return { name: p.gamaDesc, value: p.gamaPk };
            }));
        });
    }

    const saveRegisterLite = (values) => {
        setLoading(true)
        RequestApi.crearUsuarioLite({
            "idPersona": user.idPersona,
            "domDireccion": values.direccion,
            "nacionalidad": values.nacionalidad,
            "provincia": values.provincia,
            "ciudad": values.ciudad,
            "codigoPostal": values.codPostal,
            "telefono1": values.telefono,
            "planPk": values.obraSocial,
            "gamaPk": values.tipoPlan,
            "planEspecial": values.otro_obraSocial,
            "gamaEspecial": values.otro_tipoPlan
        }, user.jwtToken).then(res => {
            if(res.estado === 0 && terminosCheck) {
                RequestApi.aceptarTerminos({
                    "idPersona": user.idPersona,
                    "idTermino": terminosId,
                    "userData": window.clientInformation.userAgent,
                    "ip": window.CLIENT_IP,
                    "canal": isMobile ? 2 : 1,
                    "altaUsr": isMobile?"MOBILE":"WEB"
                }).then(r => {
                    setLoading(false);
                    next();
                })
            } else {
                setLoading(false);
            }
        })
    }

    return (
        <>
            <ModalTerminos
                open={openTerminos}
                setOpen={setOpenTerminos}
                setTerminosCheck={setTerminosCheck}
                setTerminosId={setTerminosId}
            />
            <Formik
                initialValues={{
                    direccion: "",
                    nacionalidad: "",
                    provincia: "",
                    ciudad: "",
                    codPostal: "",
                    telefono: "",
                    obraSocial: "",
                    tipoPlan: "",
                    otro_obraSocial: null,
                    otro_tipoPlan: null
                }}
                //falta validar
                onSubmit={values => {
                    saveRegisterLite(values);
                }}
            >
                {({ errors, touched, values, handleChange }) => (                 
                    <Form >
                        <Grid container pt="5rem">
                            <Grid container>
                                <Grid xs={12} sm={6} item sx={{px:'1rem'}}>
                                    <FieldCustom
                                        id="nacionalidad"
                                        name="nacionalidad"
                                        placeholder='Seleccionar'
                                        label='Nacionalidad'
                                        variant='select'
                                        options={nacionalidades}
                                        value={values.nacionalidad}
                                        onChange={handleChange}
                                        error={touched.nacionalidad && Boolean(errors.nacionalidad)}
                                    />
                                    <FieldCustom
                                        id="provincia"
                                        name="provincia"
                                        placeholder='Seleccionar'
                                        label='Provincia'
                                        variant='select'
                                        options={provincias}
                                        value={values.provincia}
                                        onChange={handleChange}
                                        error={touched.provincia && Boolean(errors.provincia)}
                                    />
                                    <FieldCustom
                                        id="ciudad"
                                        name="ciudad"
                                        placeholder='Ingresá tu ciudad'
                                        label='Ciudad'
                                        value={values.ciudad}
                                        onChange={handleChange}
                                        error={touched.ciudad && Boolean(errors.ciudad)}
                                        helperText={touched.ciudad && errors.ciudad}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item sx={{px:'1rem'}}> 
                                    <FieldCustom
                                        id="direccion"
                                        name="direccion"
                                        placeholder='Ingresá tu dirección'
                                        label='Dirección (calle y número)'
                                        value={values.direccion}
                                        onChange={handleChange}
                                        error={touched.direccion && Boolean(errors.direccion)}
                                        helperText={touched.direccion && errors.direccion}
                                    />
                                    <FieldCustom
                                        id="codPostal"
                                        name="codPostal"
                                        placeholder='Ingresá tu código postal'
                                        label='Código postal'
                                        value={values.codPostal}
                                        onChange={handleChange}
                                        error={touched.codPostal && Boolean(errors.codPostal)}
                                        helperText={touched.codPostal && errors.codPostal}
                                    />
                                    <FieldCustom 
                                        id="telefono"
                                        name="telefono"
                                        placeholder='Número'
                                        label='Teléfono'
                                        value={values.telefono}
                                        onChange={handleChange}
                                        error={touched.telefono && Boolean(errors.telefono)}
                                        helperText={touched.telefono && errors.telefono}
                                    />
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{my: "2.5rem"}}
                                style={{ width: '100%' }}
                                orientation="horizontal"
                                flexItem
                            />
                            <Grid container>
                                <Grid xs={12} item container sx={{px:'1rem'}}>
                                    <FieldCustom
                                        id="obraSocial"
                                        name="obraSocial"
                                        placeholder='Seleccionar'
                                        label={<><ToolTipOS /> Obra Social / Prepaga</>}
                                        variant='select'
                                        options={planes}
                                        value={values.obraSocial}
                                        onChange={(e)=>{
                                            handleChange(e);
                                            handleSelectPlan(e);
                                        }}
                                        error={touched.obraSocial && Boolean(errors.obraSocial)}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item sx={{px:'1rem'}}>
                                    {values.obraSocial === (-1) && (
                                        <>
                                            <FieldCustom 
                                                id="otro_obraSocial"
                                                name="otro_obraSocial"
                                                placeholder='Ingresá tu obra social'
                                                label='¿Cuál?'
                                                value={values.otro_obraSocial}
                                                onChange={handleChange}
                                                error={touched.otro_obraSocial && Boolean(errors.otro_obraSocial)}
                                                helperText={touched.otro_obraSocial && errors.otro_obraSocial}
                                            />
                                        </>
                                    )} 
                                        <FieldCustom
                                            id="tipoPlan"
                                            name="tipoPlan"
                                            placeholder='Seleccionar'
                                            label={<><ToolTipOS /> Tipo de plan</>}
                                            variant='select'
                                            options={tipoPlanes}
                                            value={values.tipoPlan}
                                            onChange={handleChange}
                                            error={touched.tipoPlan && Boolean(errors.tipoPlan)}
                                        />
                                    {values.tipoPlan === (-1) && (
                                            <FieldCustom 
                                                id="otro_tipoPlan"
                                                name="otro_tipoPlan"
                                                placeholder='Ingresá tu tipo de plan'
                                                label='Tipo de plan'
                                                value={values.otro_tipoPlan}
                                                onChange={handleChange}
                                                error={touched.otro_tipoPlan && Boolean(errors.otro_tipoPlan)}
                                                helperText={touched.otro_tipoPlan && errors.otro_tipoPlan}
                                            />
                                        )
                                    }
                                </Grid>       
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: "40px", paddingBottom: "40px", maxWidth: '500px'}}>
                            <ButtonCustom width="40%" type='submit' name="Continuar"/>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ProductForm;