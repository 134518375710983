import { Box, Button, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const TurnCard = ({ item }) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        minWidth: "320px",
        height: "170px",
        position: "relative",
        border: "1px solid red",
        boxShadow: "4px 4px 5px rgb(244,61,39,0.25)",
        borderRadius: "6px",
        p: "12px 24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "4px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "95%",
          padding: "3px 8px",
          borderBottom: "1px solid red",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <CalendarMonthIcon color="error" />
        <Typography
          variant="body2"
          component="p"
          sx={{
            fontSize: "12px",
            color: "#D52D19",
            fontWeight: "600",
          }}
        >
          {item?.nombre_abre_atencion} {item?.ape_razon_atencion}
        </Typography>
      </Box>
      {/* <Typography
        variant="body1"
        sx={{ fontWeight: "600", fontSize: "13.5px" }}
      >
        {item?.exten_deno}
      </Typography> */}
      <Box
        sx={{
          position: "relative",
          left: "0px",
          top: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          height: "100%",
          gap: "6px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", fontSize: "13.5px" }}
        >
          Nombre:&nbsp;
          <Box component="span" sx={{ fontWeight: "300" }}>
            {item?.nombre} {item?.ape}
          </Box>
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", fontSize: "13.5px" }}
        >
          Fecha desde:&nbsp;
          <Box component="span" sx={{ fontWeight: "300" }}>
            {item?.fecha_hasta}
          </Box>
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", fontSize: "13.5px" }}
        >
          Fecha hasta:&nbsp;
          <Box component="span" sx={{ fontWeight: "300" }}>
            {item?.fecha_desde_dia}
          </Box>
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", fontSize: "13.5px" }}
        >
          Lugar de atención:&nbsp;
          <Box component="span" sx={{ fontWeight: "300" }}>
            {item?.centro_deno}
          </Box>
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", fontSize: "13.5px" }}
        >
          Dirección:&nbsp;
          <Box component="span" sx={{ fontWeight: "300" }}>
            {item?.domicilio}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default TurnCard;
