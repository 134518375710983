import { Container, Box, Grid } from "@mui/material";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import Especialidades from "./Especialidades/Especialidades";
import GuardiaAgilCard from "./GuardiaAgilCard/GuardiaAgilCard";
import Seccion from "./Seccion/Seccion";


const style = {
    "& > *": {
        paddingTop: "1rem"
    },
    "& .boxContent": {
        marginBottom: "3rem"
    },
    "& .textRed": {
        color: "#E4002B",
    },
    "& button": {
        marginRight: "5px",
        paddingLeft: "1vw",
        paddingRight: "1vw",
        paddingTop: "2px",
        paddingBottom: "2px",
        fontFamily: "'Lato', sans-serif",
        borderRadius: "40px",
        fontWeight: "bold",
        textTransform: "initial",
        lineHeight: "1.5",
        fontSize: ".6rem",
        height: "40px",
        "@media only screen and (min-width: 900px)": {
            fontSize: ".7rem"
        },
        "@media only screen and (min-width: 1250px)": {
            fontSize: ".8rem",
            whiteSpace: "nowrap",
            paddingLeft: "1.5vw",
            paddingRight: "1.5vw",
            marginRight: ".5rem",
        },
        "@media only screen and (min-width: 1300px)": {
            paddingLeft: "2vw",
            paddingRight: "2vw",
        },
    },
    "& .map": {
        width: { xs: '100%', md: '80%' },
        aspectRatio: '4 / 3',
        borderRadius: '10px',
        maxWidth: '500px'
    }
}

const BodyCM = ({ location, sections, address, mapRef, specialties, centerId }) => {

    const handleCall = (num) => {
        window.open(`tel:0810-333-887`);
    }

    return (
        <>
            <Container sx={style}>
                <Box>
                    <Breadcrumb location={location} />
                </Box>
                <Grid container className='boxContent'>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mx: '1rem' }}>
                            {sections.map((section,index) => {
                                return (
                                    <Seccion section={section} key={index}/>
                                )
                            })}
                        </Box>
                        <Box sx={{ mt: '2rem' }}>
                            {specialties && <Especialidades centerId={centerId} specialties={specialties} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className='boxItem'>
                            {/* <Button variant="contained" className="btnTurno"> Turnos online </Button>
                            <Button variant="outlined" className="btnLlamar" onClick={handleCall}> Central de turnos 0810-333-8876 </Button> */}
                            <Box sx={{ paddingTop: "2rem" }} display='flex' justifyContent='center' >
                                <iframe src={mapRef} title={address} className='map' width="100%" height="100%" style={{ border: "none" }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </Box>
                            {
                                location === "Swiss Medical Center Barrio Norte"
                                && (
                                    <Box sx={{ paddingTop: "2rem" }} display='flex' justifyContent='center' >
                                        <GuardiaAgilCard />
                                    </Box>
                                )
                            }
                            <Box>
                                {/* <CustomRedButton /> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default BodyCM;