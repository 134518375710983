import React, { useEffect, useState } from "react";
import RequestAPI from "../../../../../../../../Request-api/RequestApi";
import moment from "moment";
import Spinner from "../../../../../../../../UI/Spinner/Spinner";
import "./SelectTurno.css";
import TurnoCard from "./Components/TurnoCard/TurnoCard";
import ButtonCustom from "../../../../../../../../UI/ButtonCustom/ButtonCustom";
import { textToUpperCase } from "../../../../../../../../Utils/useTextToUpperCase";
import ModalTurnoFilters from "./Components/ModalTurnoFilters/ModalTurnoFilters";
import { useSelector } from "react-redux";
import utils from "../../../../../../../../Utils/useDate";

const SelectTurno = ({ setStep, data, setData, integranteSeleccionado }) => {
  let idCentros = data.centrosSelected.toString().replace(/,/g, ";");
  let [turnosDispo, setTurnosDispo] = useState([]);
  const [ultimaFecha, setUltimaFecha] = useState("");
  const [turnos, setTurnos] = useState([]);
  const [retros, setRetros] = useState(0);
  const [spinner, setSpinner] = useState(true);
  const [centroSeleccionado, setCentroSeleccionado] = useState({});
  const [openFilters, setOpenFIlters] = useState(false);
  const [errorNoTurno, setErorrNoTurno] = useState(false);
  const datosLogin = useSelector((state) => state.authLogin);
  const SelectTurno = (turno) => {
    setData((prev) => {
      return { ...prev, turno };
    });
    setCentroSeleccionado(turno);
  };

  const handleAceptar = () => {
    if (centroSeleccionado) {
      setStep(4);
    }
  };

  const parceTurnosPorPrestador = (turnos) => {
    let turs = Array.isArray(turnos) ? turnos : [turnos];
    let agrupadoPorPrestador = [];
    turs.forEach((turn) => {
      let agrgado = false;
      agrupadoPorPrestador.forEach((p) => {
        if (p.prestador === turn.prestador) {
          p.turnosXCentro.push(turn);
          agrgado = true;
        }
      });
      if (!agrgado) {
        agrupadoPorPrestador.push({
          namePrestador: turn["nombre-abre"] + " " + turn["ape-razon"],
          prestador: turn.prestador,
          turnosXCentro: [turn],
        });
      }
    });
    return agrupadoPorPrestador;
  };

  const getTurnos = (fecha, centros, sexo, prestador, horaDesde, horaHasta) => {
    let body = {
      prestadorId: prestador || 0,
      dias: "LUN;MAR;MIE;JUE;VIE;SAB;DOM;",
      fechaDesde: fecha,
      fechaHasta: fecha,
      horaDesde: horaDesde || "00:00",
      horaHasta: horaHasta || "23:59",
      primeraVez: "",
      estadoTurno: "L",
      idCentros: centros,
      practicas: data.practicSelected.value,
      traerTurnoCompleto: "T",
      esConsultorio: true,
      sistema: "CMA",
      especialidadId: data.espeSelected.value,
      cantidad: 1,
      sexoPrestador: sexo || "",
      usuLogin:
        utils.convertDate(integranteSeleccionado.fechaNac) +
        integranteSeleccionado.doc +
        "@swissmedical.com.ar",
      "resu-temporal": 0,
    };
    RequestAPI.getHsTurnos(body).then((res) => {
      setSpinner(false);
      setTurnosDispo((prev) => [
        ...prev,
        {
          fecha: fecha,
          turs: parceTurnosPorPrestador(
            res.respuesta["resultado-busqueda"].turno
          ),
        },
      ]);
      setTurnos({
        fecha: fecha,
        turs: parceTurnosPorPrestador(
          res.respuesta["resultado-busqueda"].turno
        ),
      });
    });
  };

  const fechaAteriorTurno = () => {
    let cantRetros = retros;
    if (turnosDispo.length > cantRetros) {
      cantRetros = cantRetros + 1;
      setTurnos(turnosDispo[turnosDispo.length - (1 + cantRetros)]);
      setRetros(cantRetros);
    }
  };

  const getFechaTurnoDispo = (fecha, sexo, prestador, horaDesde, horaHasta) => {
    setSpinner(true)
    let fechaDesde = fecha || moment().format("DD/MM/YYYY");

    let body = {
      prestadorId: prestador || 0,
      dias: "LUN;MAR;MIE;JUE;VIE;SAB;DOM;",
      fechaDesde: fechaDesde,
      horaDesde: horaDesde || "00:00",
      horaHasta: horaHasta || "23:59",
      primeraVez: "",
      estadoTurno: "L",
      idCentros: idCentros,
      practicas: data.practicSelected.value,
      traerTurnoCompleto: "T",
      esConsultorio: true,
      sistema: "CMA",
      especialidadId: data.espeSelected.value,
      cantidad: 1,
      sexoPrestador: sexo || "",
      usuLogin:
        utils.convertDate(integranteSeleccionado.fechaNac) +
        integranteSeleccionado.doc +
        "@swissmedical.com.ar",
      "resu-temporal": 0,
    };

    RequestAPI.getFechaTurnoDispo(body).then((res) => {
      if (!Boolean(res.respuesta["resultado-busqueda"])) {
        setErorrNoTurno(true);
        setSpinner(false);
      }
      setSpinner(false);

      let fecha =
        res.respuesta["resultado-busqueda"]?.turno[
          "fecha-turno"
        ]; /* validar que no este vasio */

      setUltimaFecha(fecha);
      getTurnos(fecha, idCentros, sexo, prestador, horaDesde, horaHasta);
    });
  };
  useEffect(() => {
    getFechaTurnoDispo();
  }, []);

  const fechaSiguienteTurno = () => {
    let cantRetros = retros;
    if (cantRetros > 0) {
      cantRetros = cantRetros - 1;
      setTurnos(turnosDispo[turnosDispo.length - (1 + cantRetros)]);
      setRetros(cantRetros);
    } else {
      getFechaTurnoDispo(
        moment(ultimaFecha, "DD/MM/YYYY").add(1, "days").format("DD/MM/YYYY")
      );
    }
  };

  const styles = {
    buttonFilter: {
      display: "flex",
      margin: "10px",
      /*    border:"1px solid black", */
      padding: "0px 10px",
    },
    img: {
      paddingRight: "10px",
    },
  };
  return (
    <div>
     
        
     
      {errorNoTurno && <p style={{ textAlign: "center" }}>no hay turno</p>}
      {!spinner && !errorNoTurno && (
           <div>
             <div style={{ display: "flex", paddingLeft: "10px" }}>
            <p className="text-turno-fecha">Médicos disponibles el:</p>
            <p className="subText-turno-fecha">{turnos.fecha}</p>
            <img src="../../images/Calendar.svg" alt="img" />
           </div>
           <div onClick={() => setOpenFIlters(true)} style={styles.buttonFilter}>
            <img style={styles.img} src="../../images/Filtros.svg" alt="img" />
            <p>Filtros de búsqueda</p>
           </div>
          
        </div>
      )}
      <p>
      <div
          onClick={
            turnosDispo.length > 1 &&
            turnosDispo.length !== retros + 1 &&
            fechaAteriorTurno
          } style={{ color: "black",marginLeft:"30%"}}
        >
          <img src="../../images/ArrowBack.svg" alt="img" /> Anterior Turno
        </div>
        <div /* className="turno-button-next" */ style={{ color: "black",marginLeft:"30%",marginTop:"30px"}}>
          <p onClick={fechaSiguienteTurno} className={"text-siguiente"}>
            Próximo Turno <img src="../../images/ArrowNext.svg" alt="img" />
          </p>
        </div>
      </p>
{errorNoTurno && (
        <ModalTurnoFilters
          getFechaTurnoDispo={getFechaTurnoDispo}
          open={openFilters}
          setOpen={setOpenFIlters}
          data={data}
          centros={idCentros}
        />
      )}
      <div className="container-horarios-turnos">
        {spinner ? (
          <Spinner />
        ) : (
          <div>
            {turnos &&
              turnos?.turs?.map((turn) => {
                return (
                  <div className="container-card-turno">
                    <p className="text-name-presta">
                      {textToUpperCase(turn.namePrestador)}
                    </p>
                    <p style={{ margin: "2px 0px" }}>
                      {data.espeSelected.name}
                    </p>
                    <TurnoCard
                      turnosXCentro={turn.turnosXCentro}
                      SelectTurno={SelectTurno}
                      centroSeleccionado={centroSeleccionado}
                      centros={data.centros}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {!spinner && (
        <ButtonCustom
          disabled={!centroSeleccionado}
          onClick={handleAceptar}
          width={"95%"}
          name={"Reservar turno"}
        />
      )}
    </div>
  );
};

export default SelectTurno;
