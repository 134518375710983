import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function ToolTipOS() {
  return (
    <Tooltip placement="top-start" title="Este campo es importante para la funcionalidad de Gestion de Turnos Online desde el sitio">
      <IconButton sx={{p:0}}>
        <InfoIcon sx={{width: '20px'}} />
      </IconButton>
    </Tooltip>
  );
}
