export const ENVIRONMENT_URL = process.env.REACT_APP_API_URL || "/api";
// export const ENVIRONMENT_URL = "http://localhost:8080"; 
export const BREACKPOINT_MOBILE = 640;
export const BREACKPOINT_MEDIUM = 850;
export const BREACKPOINT_LARGE = 1200;
export const RECAP_KEY = '6Le0Rp0iAAAAAPvN-az9z-vG4d64zU0r-ea7xLQ4';
export const idTermsNs = {
    web: 27,
    mobile: 28
}
export const idServices = {
    edns: 10,
    prepaga: 1
}
