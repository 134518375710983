const initialState = {
  domicilio: {
    nacionalidad: "",
    provincia: "",
    ciudad: "",
    direccion: "",
    codPostal: "",
    piso: "",
    depto: "",
  },
  contacto: {
    email: "",
    telefono1: ""
  },
  prepaga: {
    planPk: -1,
    planDesc: "",
    gamaPk: -1,
    gamaDesc: "",
    planEspecial: "",
    gamaEspecial: ""
  },
  loading: false,
};

const SET_EDIT_PROFILE = "SET_EDIT_PROFILE";
const SET_EDIT_PROFILE_LOADING = "SET_EDIT_PROFILE_LOADING";

export const setEditProfile = (key, value) => {
  return {
    type: SET_EDIT_PROFILE,
    payload: { key, value },
  };
};

const editProfile = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDIT_PROFILE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SET_EDIT_PROFILE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default editProfile;
