import { Typography, Box } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CentersRow from './CentersRow/CentersRow';

const styles = {
    cardSection:{
        marginY: "3rem",
        marginLeft: "1rem",
        marginRight: "1rem",
        "@media only screen and (min-width: 640px)": {
            marginLeft: "5rem",
            marginRight: "5rem",
        },
    },
    headerSection: {
        marginY: "5rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerSubtitle": {
            paddingTop: "5rem",
            fontSize: "1rem",
            color: "#797979",
            letterSpacing: ".6rem"
        },
        "& .headerTitle": {
            marginTop: "1rem",
            fontWeight: "bold",
            fontSize: "1.5rem",
        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const CentrosMedicos = () => {

    const { cardSection, headerSection } = styles;

    return (
        <>
            <Box sx={{backgroundColor: "#F6F6F6"}}>
                <Box sx={headerSection}>
                    <Typography className="headerSubtitle">PRESTIGIOSOS PROFESIONALES</Typography>
                    <Typography className="headerTitle">Nuestros centros médicos</Typography>
                    <HorizontalRuleIcon className="headerIcon"/>
                </Box>
                <Box sx={cardSection}>
                   <CentersRow />
                </Box>
            </Box>
        </>
    )
}

export default CentrosMedicos;