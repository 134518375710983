import Axios from "axios";
import { ENVIRONMENT_URL} from "./constants";


export const AxiosInstanceApiManager2 = Axios.create({
    baseURL: ENVIRONMENT_URL,
    headers: { 
        'apim_recaptcha': sessionStorage.getItem('apimToken')
    },
})

AxiosInstanceApiManager2.interceptors.request.use(
    async request => {
      const apimToken = sessionStorage.getItem('apimToken')
      request.headers["apim_recaptcha"] = apimToken;
      return request;
    },
    error => {
    Promise.reject(error);
    }
  );
