import React from 'react';
import { Typography, Box, Grid, CardMedia, Container,  Breadcrumbs, Link, List,Button } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useEffect } from 'react';
import Navbar from '../../componentes/Navbar/Navbar';
import Footer from '../../componentes/Footer/Footer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from 'react-router-dom';


const styles = {
    section: {
        marginY: { xs: '1rem', sm: '-1rem', md: '2rem', lg: '2.5rem' },
        marginX: { xs: '1rem', sm: '4rem', md: '4rem', lg: '0' },
        marginBottom: { xs: '6rem', sm: '5rem', md: '3rem', lg: '7rem' },
        "& .subtitleAcc": {
            fontFamily: "'Lato', sans-serif",
            fontWeight: "bold"
        },
        "& .gridImg": {
            display: "flex",
            width: "100%",
            justifyContent: { xs: 'center', md: 'flex-end' },
            marginTop: { sm: '2.5rem', md: '-1rem' },
            paddingLeft: { md: '3rem' }
        },
        "& .imgCard": {
            maxWidth: { md: '372px', lg: '400px' },
            height: { md: '371px' },
            borderRadius: { md: '25px' },
        },
        "& .containerImgText": {
            display: "flex",
            alignItems: 'flex-start',
            flexDirection: 'row-reverse',
        },
        "& .containerText": {
            marginTop: { xs: '2rem', md: '0' },
            marginBottom: '1.5rem'
        },
        "& .btn": {
            fontFamily: "'Lato', sans- serif",
            fontWeight: "bold",
            color: '#E4002B',
            textTransform: 'initial',
            borderRadius: '40px',
            borderColor: '#E4002B',
            height: '40px',
            padding: '15px',
            paddingX: { lg: '1.5rem' },
            marginY: { xs: '2rem', md: '2rem' },
            ':hover': {
                borderColor: '#E4002B',
            }
        },
        "& .listTurnos" : {
            fontFamily: "'Lato', sans-serif",
            paddingLeft: '2rem',
            listStyle: "disc outside none",
            color: "#797979"
        },
        "& .itemList" : {
           marginBottom : '1rem'
        }


    },
    headerSection: {
        marginX: '1.5rem',
        marginTop: "8rem",
        marginBottom: "0",
        textAlign: "center",
        fontFamily: "'Lato', sans-serif",
        "& .headerTitle": {
            marginTop: { xs: '1rem', md: '9rem' },
            fontWeight: "bold",
            fontSize: "2.125rem",
            fontFamily: "'Lato', sans-serif",

        },
        "& .headerIcon": {
            color: "#E4002B",
            fontSize: "3rem",
            marginTop: "-10px"
        }
    }
}

const TurnosResultados = ()=>{

    const { headerSection, section } = styles;
    const history = useHistory();
    const handleClick = (e) => {
        e.preventDefault();
        history.push("/")
    }
    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []); 

    const handleNoSocioBtn = () => {
        window.open('/login', '_blank');
    }

    return(
        <>
            <Navbar />
            <Container sx={{
                mt: '74px',
                maxWidth: '1366px',
                px: { xs: 0 },
            }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                    pt=".5rem"
                >
                    <Link
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/"
                        onClick={handleClick}
                    >
                    Inicio
                    </Link>,
                    <Typography key="2" color="text.primary">
                     Turnos y Resultados online
                    </Typography>
                </Breadcrumbs>
                <Box sx={headerSection}>
                    <Typography className="headerTitle">Turnos y Resultados online</Typography>
                    <HorizontalRuleIcon className="headerIcon" />
                </Box>
                <Box sx={section}>
                    <Grid className="containerImgText" container>
                        <Grid className="gridImg" item sx={12} md={5}>
                            <CardMedia className="imgCard" component="img" image="/images/cards/card_five.jpg" alt="Emergencias y Urgencias" />
                        </Grid>
                        <Grid item sx={12} md={7} >
                            <Box className='containerText'>
                                <Typography className="subtitleAcc">
                                Nueva funcionalidad para pacientes NO asociados a Swiss Medical.
                                </Typography>
                                <Box sx={{mt: "1rem"}}>
                                  <List className='listTurnos'>
                                    <li className='itemList'>Gestioná tus turnos: reservas, modificaciones y cancelaciones.</li>
                                    <li className='itemList'>Podrás ver tus estudios realizados en Swiss Medical Centers, clínicas y sanatorios de Swiss Medical Group</li>
                                    <li className='itemList'>Consultá y compartí con tu médico los resultados de tus estudios de manera segura.</li>
                                    <li className='itemList'>Podrás registrar a tus hijos de hasta 16 años dentro de tu grupo familiar.</li>
                                  </List>
                                   
                                </Box>
                                <Typography sx={{fontWeight: "bold", color: "#E4002B", marginTop : "2rem"}}>
                                    Regístrate para realizar tus gestiones médicas
                                </Typography>
                                <Button
                                        className='btn'
                                        size="small"
                                        variant='outlined'
                                        onClick={handleNoSocioBtn}
                                    >
                                        Turnos y resultados online
                                    </Button>
                            </Box>
                            
                           
                            
                        </Grid>
                        
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    )
}

export default TurnosResultados;