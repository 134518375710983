import { createRef, useEffect, useState } from "react";
import { Box, FormGroup, FormControlLabel, Switch, Checkbox} from "@mui/material";
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom";
import useIsMobile from "../../../../Utils/useIsMobile";
import ModalTerminos from "./ModalTerminos/ModalTerminos"
import RequestApi from "../../../../Request-api/RequestApi";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {setAuthLogin} from '../../../../Redux/Reducers/authLoginReducer';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAP_KEY } from '../../../../constants';

const SelectProducto = ({ state, getTipoDoc, productos, setLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const [terminosCheck, setTerminosCheck] = useState(false);
  const [idTerminos, setIdTerminos] = useState("")
  const [openTerminos, setOpenTerminos] = useState(true);
  const recaptchaInstance = createRef();
  const [tokenRec, setTokenRec] = useState();

  const handleRecaptcha = (token) => {
      setTokenRec(token)
  }

  useEffect(()=>{
      setLoading(true)
      recaptchaInstance.current.executeAsync().then(()=>{
         setLoading(false)
      });
  },[])

  const getGenero = (id) => {
    const idx = parseInt(id);
    let object 
    switch (idx) {
      case 1:
        object = {
          id: 1,
          value: "I",
          name: "Indeterminado"
      }
        break
      case 2:
        object ={
          id: 2,
          value: "M",
          name: "Masculino",
        }
        break;
      case 3:
        object ={
          id: 3,
          value: "F",
          name: "Femenino",
        }
        break;  
      default:
        break;
    }
    return object
  }



  const handleAceptar = () => {
    setLoading(true)
      RequestApi.crearUsuario({
        datosPersonales: {
          tipoDoc: getTipoDoc(state.preRegistro.tipoDoc),
          nroDoc: state.preRegistro.dni,
          nombre: state.preRegistro.nombre,
          apellido1: state.preRegistro.apellido,
          apellido2: null,
          telefono1: state.registro.telefono,
          telefono2: null,
          genero: getGenero(state.registro.genero),
          estadoCivil: state.registro.estadoCivil,
          fechaNac: state.registro.fechaNacimiento,
          domDireccion: state.registro.direccion,
          piso: state.registro.piso,
          depto: state.registro.depto,
          nacionalidad: state.registro.nacionalidad,
          provincia: state.registro.provincia,
          ciudad: state.registro.ciudad,
          codigoPostal: state.registro.codPostal,
          email: state.preRegistro.email,
          planPk: state.registro.obraSocial,
          gamaPk: state.registro.tipoPlan,
          planEspecial: state.registro.otro_obraSocial,
          gamaEspecial: state.registro.otro_tipoPlan
        },
        registro: {
          canal: isMobile ? 2 : 1,
          entidad: 10,
          password: state.registro.password,
          idTermino: idTerminos, //traer de terminos
        },
        extraData: {
          infoNav: navigator.userAgent,
          origen: "www.swissmedical.com/no-socios...",
          ip: "123.123.13.13",
          altaUsr: isMobile?"MOBILE":"WEB",
        },
      }).then(r => {
        history.push('/login')
        RequestApi.login({
          'nroDoc': state.preRegistro.dni,
          'tipoDoc': getTipoDoc(state.preRegistro.tipoDoc),
          'password': state.registro.password,
          'recaptchaResponse': tokenRec
        }).then(data => {
          if(data.status === 0 && data.usuario) {
            dispatch(setAuthLogin(data))
            setLoading(false)
            history.push('/pacientes');
          }
        }).catch(err => {
          history.push('/login');
          //error
        })
      });
  };

  const handleClickTerminos = () => {
    setOpenTerminos(true)
  }

  return (

    <>
      <ModalTerminos 
      open={openTerminos}
      setOpen={setOpenTerminos}
      setTerminosCheck={setTerminosCheck}
      setIdTerminos={setIdTerminos}
      />
      <Box display='flex' justifyContent='center'>
        <FormGroup>
          {productos?.map(p => <FormControlLabel disabled control={<Switch disabled defaultChecked/>} label={p.nombreEntidad}/>)}
          <FormControlLabel control={<Switch checked={true} />} label="Experiencia digital centros médicos" />
          <FormControlLabel control={<Checkbox sx={{color: "#E4002B",'&.Mui-checked':{color: "#E4002B"}}} checked={terminosCheck} />} componentsProps={{typography:{className:"textResetPass"}}} onClick={handleClickTerminos} label="Ver Terminos y Condiciones" />
        </FormGroup>
      </Box>
      <div style={{ paddingTop: "100px", paddingLeft: isMobile ? "0px" : "37%" }}>
        <ButtonCustom
          width={isMobile ? "96%" : "40%"}
          onClick={() => handleAceptar()}
          name={"Continuar"}
          disabled={!terminosCheck}
        />
      </div>
      <ReCAPTCHA
            ref={recaptchaInstance}
            sitekey={RECAP_KEY}
            size="invisible"
            onChange={handleRecaptcha}
        />
    </>
  );
};

export default SelectProducto;
