import { SvgIcon } from "@mui/material";

function EditIcon(props) {
    return (
      <SvgIcon {...props}>
       <path d="M15.5099 7.21506L6.58645 16.1401C6.1077 16.6177 5.50847 16.9567 4.85245 17.1211L1.42795 17.9761C1.30293 18.0073 1.17197 18.0056 1.04779 17.9712C0.923601 17.9369 0.810416 17.871 0.719229 17.7799C0.628041 17.6889 0.561953 17.5758 0.527384 17.4517C0.492816 17.3276 0.490943 17.1966 0.521948 17.0716L1.37695 13.6471C1.54177 12.9906 1.88189 12.3912 2.36095 11.9131L11.2829 2.98806L15.5099 7.21356V7.21506ZM17.6249 0.876056C18.1845 1.4368 18.4988 2.19662 18.4988 2.98881C18.4988 3.78099 18.1845 4.54081 17.6249 5.10156L16.566 6.15756L12.3404 1.93056L13.3979 0.876056C13.9583 0.31575 14.7183 0.000976563 15.5107 0.000976562C16.3031 0.000976563 17.0631 0.31575 17.6234 0.876056H17.6249Z" fill="black"/>
      </SvgIcon>
    );
  }

export default EditIcon;