import { Box, Button } from "@mui/material";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import "./StepBarMobile.css"

const StepBarMobile = ({step, setStep}) => {

  const titles = [
    undefined,
    "Datos Personales",
    "Verificar email",
    "Confirmación Cuenta",
    "Terminos y condiciones"
  ]

  const handleVolver = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  return (
    <>
      <Box width="100%">
          <div className="step-mobile">
            <div className={step >= 1 ? "box-step box-active-mobile" : "box-step-mobile"}></div>
            <div className={step >= 2 ? "box-step box-active-mobile" : "box-step-mobile"}></div>
            <div className={step >= 3 ? "box-step box-active-mobile" : "box-step-mobile"}></div>
            <div className={step >= 4 ? "box-step box-active-mobile" : "box-step-mobile"}></div>
          </div>
          <div style={{ display: 'flex', justifyContent: "center", width: "100%", position: "relative" }}>
            <Button size="small" onClick={handleVolver} disabled={step === 1} sx={{position: "absolute", left: 0, top: "12px"}}>
              <KeyboardArrowLeft /> Volver
            </Button>
            <p style={{ color: "#E4002B", fontWeight: "500" }}>
              {step}/4  
            </p>
          </div>
      </Box>
      <Box sx={{
        textAlign: "center",
        color: "#363636",
        fontWeight: 500,
        fontSize: "30px",
        margin: 0
      }}>
          <p>
            {titles[step]}
          </p>
      </Box>
      <div className={"title-step-mobile"}>
      </div>
    </>
  )
}
export default StepBarMobile