import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import ButtonCustom from "../../../../UI/ButtonCustom/ButtonCustom";
import FieldCustom from "../../../../UI/FieldCustom/FieldCustom";
import RequestApi from "../../../../Request-api/RequestApi";
import Swal from "sweetalert2";
import { PassScheme } from './passScheme';

const NewPassForm = ({user, setLoading, chOption}) => {

    const handleSubmit = (values) => {
        setLoading(true)
        RequestApi.chPasswordSocio(user.idPersona, values.newPass, user.jwtToken)
        .then(r => {
            //cambio exitoso
            if(r.estado === 0) {
                setLoading(false);
                Swal.fire({
                    title: 'Cambio de contraseña',
                    text: 'Se modificó tu contraseña correctamente, ya puedes volver a intentar ingresar por nuestro sitio.',
                    icon: 'success',
                    willClose: chOption()
                })
            }
            
        })
    }

    return (
        <>
            <Formik
                initialValues={{
                    newPass: '',
                    repeatPass: ''
                }}
                validationSchema={PassScheme}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, values, handleChange }) => (
                    <Form>
                        <Grid container>
                            <FieldCustom
                                id="newPass"
                                name="newPass"
                                placeholder='Ingresá la nueva contraseña'
                                label='Nueva contraseña'
                                value={values.newPass}
                                onChange={handleChange}
                                type='password'
                                error={touched.newPass && Boolean(errors.newPass)}
                                helperText={touched.newPass && errors.newPass}
                            />
                            <FieldCustom
                                id="repeatPass"
                                name="repeatPass"
                                placeholder='Repetí la contraseña'
                                label='Repetir contraseña'
                                value={values.repeatPass}
                                onChange={handleChange}
                                type='password'
                                error={touched.repeatPass && Boolean(errors.repeatPass)}
                                helperText={touched.repeatPass && errors.repeatPass}
                            />
                        </Grid>
                        <div style={{ paddingTop: "40px", paddingBottom: "40px", maxWidth: '500px'}}>
                            <ButtonCustom width="40%" type='submit' name="Continuar"/>
                        </div>
                    </Form>
                )}

            </Formik>
        </>
    )
}

export default NewPassForm;