import { Typography, Box, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "./Icons/DeleteIcon";
import EditIcon from "./Icons/EditIcon";
import RequestApi from "../../../../../../Request-api/RequestApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

const styles = {
  position: "relative",
  marginBottom: "1.5rem",
  "& .header": {
    marginBottom: ".5rem",
  },
  "& .title": {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "start",
  },
  "& p": {
    fontSize: ".9rem",
  },
  "& .iconContainer": {
    position: "absolute",
    top: "0",
    right: "0",
  },
};

const PersonaVinculada = ({
  nombre,
  apellido1,
  estadoCuentaDesc,
  estadoVinculoDesc,
  tipoDocDesc,
  nroDoc,
  planDesc,
  gamaDesc,
  email,
  estadoCuenta,
  idEdns,
}) => {
  const parentIdEdns = useSelector((state) => state.authLogin.idEDNS);
  const history = useHistory();

  const handleDesasociar = () => {
    Swal.fire({
      title: "Desasociar familiar",
      icon: "warning",
      text: `¿Está seguro de querer desasociar a ${nombre} ${apellido1}?`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.isConfirmed) {
        RequestApi.desasociarFamiliar(parentIdEdns, idEdns).then((r) => {
          if (r.estado === 0) {
            window.location.reload();
            Swal.fire({
              title: "Familiar desasociado",
              text: "Familiar desasociado con éxito",
              icon: "success",
            });
          }
        });
      }
    });
  };

  const handleEdit = () => {
    history.push(`/pacientes/familiares/edit/${nroDoc}`);
  };

  return (
    <>
      <Box sx={styles}>
        <Box className="header">
          <Box className="iconContainer">
            {(estadoCuenta === 1 || estadoCuenta === 3) && (
              <>
                <IconButton onClick={handleDesasociar}>
                  {estadoCuenta === 3 ? <PersonRemoveIcon /> : <DeleteIcon />}
                </IconButton>
                <IconButton>
                  {estadoCuenta === 1 && <EditIcon onClick={handleEdit} />}
                </IconButton>
              </>
            )}
          </Box>
          <Typography className="title">
            {nombre} {apellido1}
          </Typography>
          <Typography>Tipo de vinculo: Hijo/a</Typography>
          <Typography>Estado cuenta: {estadoCuentaDesc}</Typography>
          <Typography>Estado vínculo: {estadoVinculoDesc}</Typography>
        </Box>
        <Typography>Tipo de documento: {tipoDocDesc}</Typography>
        <Typography>Núm. de documento: {nroDoc}</Typography>
        <Typography>
          Cobertura: {planDesc && planDesc.trim()} -{" "}
          {gamaDesc && gamaDesc.trim()}
        </Typography>
        <Typography>Email: {email}</Typography>
      </Box>
    </>
  );
};
export default PersonaVinculada;
