import StepBarDesktop from "./StepBarDesktop/StepBarDesktop"
import StepBarMobile from "./StepBarMobile/StepBarMobile"
import useIsMobile from "../../../Utils/useIsMobile"

const CustomStepBar = ({step, setStep}) => {
    const { isMobile } = useIsMobile()
    return (
        <>
            {(isMobile ?
                    <StepBarMobile step={step} setStep={setStep} />
                    :
                    <StepBarDesktop step={step} />
                )}
        </>
    )
}

export default CustomStepBar;