import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPinField from "react-pin-field";
import ButtonCustom from "../../../../../../UI/ButtonCustom/ButtonCustom";
import RequestApi from "../../../../../../Request-api/RequestApi";
import TextFieldCustom from "../../../../../../UI/TextFieldCustom/TextFieldCustom";
import Swal from "sweetalert2";
import { setEditProfile } from "../../../../../../Redux/Reducers/editProfile";
import Spinner from "../../../../../../UI/Spinner/Spinner";

const EditEmail = () => {
  const { idEDNS, idPersona, docNum, docType, nombre, apellido } =
    useSelector((state) => state.authLogin);
  const { contacto, loading } = useSelector((state) => state.editProfile);
  const auxRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [volverAintentar, setVolverAintentar] = useState(false);
  const [code, useCode] = useState("");
  const [error, setError] = useState();
  const [time, setTime] = useState({
    hours: parseInt(0),
    minutes: parseInt(1),
    seconds: parseInt(59),
  });

  const next = () => {
    setStep(step + 1);
  };

  const handleOnChange = (e) => {
    dispatch(
      setEditProfile("contacto", {
        ...contacto,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleChTel = () => {
    RequestApi.putProfile(
      {
        telefono1: contacto?.telefono1,
      },
      idEDNS
    )
      .then((r) => {
        if (r.estado === 0) {
          handleBack();
        } else {
          //error
        }
      })
      .catch((err) => {
        //error
      });
  };

  const handleChEmail = () => {
    RequestApi.sendPinMail({
      tipoDocumento: docType.id,
      nroDocumento: docNum,
      email: contacto?.email,
      nombre: nombre,
      apellido: apellido,
    }).then((r) => {
      next();
    });
  };

  const handleConfirmar = () => {
    RequestApi.checkPinMail({
      tipoDocumento: docType.id,
      nroDocumento: docNum,
      pin: code,
    }).then((r) => {
      if (r.estado === 0) {
        RequestApi.putEmail(idPersona, idEDNS, contacto?.email).then((r) => {
          if (r.estado === 0) {
            Swal.fire({
              icon: "success",
              title: "Información actualizada",
              text: "Tús datos fueron modificados correctamente.",
              willClose: () => {
                history.goBack();
              },
            });
          }
        });
      } else if (r.estado === 5) {
        setError(r.mensaje);
      }
    });
  };

  const tick = () => {
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 0)
      setVolverAintentar(true);
    else if (time.minutes === 0 && time.seconds === 0)
      setTime({
        hours: time.hours - 1,
        minutes: 59,
        seconds: 59,
      });
    else if (time.seconds === 0)
      setTime({
        hours: time.hours,
        minutes: time.minutes - 1,
        seconds: 59,
      });
    else
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds - 1,
      });
  };

  const handleReenviar = () => {
    setVolverAintentar(false);
    setTime({
      hours: parseInt(0),
      minutes: parseInt(1),
      seconds: parseInt(59),
    });
    RequestApi.sendPinMail({
      tipoDocumento: docType,
      nroDocumento: docNum,
      email: contacto?.email,
      nombre: nombre,
      apellido: apellido,
    }).then((r) => {
      console.log(r);
    });
  };

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  useEffect(() => {
    if (!auxRef.current) {
      dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: true });
      RequestApi.getProfile(idEDNS).then((response) => {
        dispatch({ type: "SET_EDIT_PROFILE_LOADING", payload: false });
        dispatch(
          setEditProfile("contacto", {
            ...contacto,
            telefono1: response.telefono,
            email: response.email,
          })
        );
      });
      auxRef.current = true;
    }
    return () => (auxRef.current = true);
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          {step === 1 ? (
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "30px",
                  p: { xs: "2rem 1rem", md: "1rem 2rem 1rem 0.5rem" },
                  mb: "2rem",
                }}
              >
                <Typography>Modificar contacto</Typography>
                <Button
                  onClick={handleBack}
                  sx={{
                    borderRadius: "8px",
                    bgcolor: "#BCBCBC",
                    color: "#FFFFFF",
                    fontWeight: "500",
                    p: "3px 20px",
                    letterSpacing: "2px",
                    "&:hover": {
                      bgcolor: "#BCBCBC",
                      filter: "hue-rotate(90deg) brightness(110%)",
                    },
                  }}
                >
                  Volver
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 },
                }}
              >
                <Box sx={{ minWidth: "300px" }}>
                  <p className="textInputs-register">Email</p>
                  <TextFieldCustom
                    onChange={handleOnChange}
                    placeholder="Ingresá tu email"
                    name="email"
                    value={contacto?.email}
                  ></TextFieldCustom>
                </Box>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleChEmail}
                  sx={{ position: "relative", top: "15px" }}
                >
                  Confirmar
                </Button>
              </Grid>
              <Divider sx={{ my: 5 }} />
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 },
                }}
              >
                <Box sx={{ minWidth: "300px" }}>
                  <p className="textInputs-register">Teléfono</p>
                  <TextFieldCustom
                    onChange={handleOnChange}
                    placeholder="Ingresá tu teléfono"
                    name="telefono1"
                    value={contacto?.telefono1}
                  ></TextFieldCustom>
                </Box>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleChTel}
                  sx={{ position: "relative", top: "15px" }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          ) : (
            <FormControl fullWidth>
              <p className="text-title" style={{ maxWidth: "400px" }}>
                Te enviamos un e-mail a<b> {contacto?.email} </b>con el código
                de confirmación. Ingresalo para continuar.
              </p>
              <p className="textInputs-step2">Ingresá el código</p>
              <ReactPinField
                onChange={useCode}
                length={6}
                secret
                style={{
                  caretColor: "transparent",
                  width: 38,
                  height: 50,
                  borderRadius: 6,
                  border: "2px solid #bdb3c6",
                  outline: "none",
                  textAlign: "center",
                  margin: 4,
                  fontSize: "20px",
                }}
              />
              {error && (
                <p style={{ display: "flex", color: "red" }}>{error}</p>
              )}
              <div style={{ display: "flex" }}>
                {volverAintentar ? (
                  <p
                    onClick={handleReenviar}
                    style={{ textDecoration: "underline red", color: "red" }}
                  >
                    Reenviar
                  </p>
                ) : (
                  <div style={{ display: "flex" }}>
                    <p className="text-title-cod-step2">
                      ¿Todavía no lo recibiste? Volver a enviar el código en
                    </p>
                    <p className="text-time-step2">
                      {`${time.minutes.toString().padStart(2, "0")}
                                :${time.seconds.toString().padStart(2, "0")}`}
                    </p>
                  </div>
                )}
              </div>
              <div className="alert-step2" style={{ maxWidth: "400px" }}>
                <p className="text-mail-step2">
                  Recordá revisar también tu bandeja de correo no deseado. Tenés
                  20 minutos para activarlo, delo contrario tendrás que
                  registrarte nuevamente.
                </p>
              </div>
              <div
                style={{
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  maxWidth: "500px",
                }}
              >
                <ButtonCustom
                  width="40%"
                  onClick={handleConfirmar}
                  name="Continuar"
                />
              </div>
            </FormControl>
          )}
        </Container>
      )}
    </>
  );
};
export default EditEmail;
