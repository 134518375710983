import { Paper, Typography, Box } from "@mui/material";
import CustomRedButton from '../../../../../UI/CustomRedButton/CustomRedButton';
import GuardiaIcon from "./GuardiaIcon";

const styles = {
    width: {xs: '100%', md: 'calc(80% - 4rem)'},
    maxWidth: '500px',
    p:{xs:'1rem', md:'2rem'},
    "& .gaTitle": {
        fontFamily: "'Lato', sans-serif",
        color: '#E4002B',
        fontWeight: 'bold',
    },
    "& .gaDescription": {
        fontFamily: "'Lato', sans-serif",
        marginBottom: '1rem'
    },
    "& .span": {
        textDecoration: 'none',
        color: '#E4002B',
        fontWeight: 'bold',
    },
    "& .spanblack": {
        color: "#000",
        fontWeight: 'bold',
    },
    "& .infoPie": {
        fontFamily: "'Lato', sans-serif",
        fontStyle: 'italic',
        fontSize: ".8rem",
        mt: '1rem'
    }
}

const GuardiaAgilCard = () => {
    return (
        <>
            <Paper elevation={1} sx={styles}>
                <Box display='flex' alignItems='center'>
                    <GuardiaIcon  sx={{mr:'1rem', fontSize:'2.5rem'}}/>
                    <Typography variant="h5" className="gaTitle">
                        Guardia ágil, vos elegís donde esperar
                    </Typography>
                </Box>
                <Box sx={{mt:'1.5rem'}}>
                    <Typography className='gaDescription'>
                    En nuestra <span className="span">App Swiss Medical Mobile</span> o ingresando en <span><a href="www.swissmedical.com" className="span">www.swissmedical.com</a> </span> 
                    a <span className="spanblack">“Mi Swiss Medical”</span> accedés a Guardia ágil*, la funcionalidad que te permite reservar tu lugar en la guardia de nuestras clínicas y centros médicos desde dónde estés, evitando la aglomeración en la sala de espera. 
                    </Typography>
                    <Typography component='small' className='infoPie'>*Exclusivo para asociados de Swiss Medical Medicina Privada.</Typography>
                </Box>
                <Box>
                    <CustomRedButton text='Ver video' sx={{mt:'1rem', maxWidth: '150px', fontSize:{xs:'.7rem'}}}/>
                </Box>
            </Paper>
        </>
    )
}

export default GuardiaAgilCard;