import { Button, Grid, Typography } from "@mui/material"
import React from "react"

const styles = {
    "& button":{
        color: '#363636',
        fontWeight: '500',
        cursor: 'pointer',
        fontFamily: "'Lato', sans-serif",
        textTransform: 'capitalize',
        width: '100px'
    },
    "& p": {
        fontFamily: "'Lato', sans-serif",
        color: "#E4002B", 
        fontWeight: "500"
    }
}

const StepBar = ({step, setStep, handleVolver, handleSiguiente, siguienteOn, totalSteps}) => {
    
    return (
        <>
            <Grid container justifyContent='space-between' alignItems='center' sx={styles}>
                <Grid item sx={4}>
                    <Button>{"< Volver"}</Button>
                </Grid>
                <Grid item sx={4}>
                    <Typography>{step}/{totalSteps}</Typography>
                </Grid>
                <Grid item sx={4}>
                    <Button disabled={!siguienteOn}>{"Siguiente >"}</Button>
                </Grid>
            </Grid>
        </>
    )
}
export default StepBar