import React from "react";
import Slider from "react-slick";
import "./styles.css"

export default function SliderCards({
  children,
  numberCards = 3,
  dots = true,
  centerMode = false,
}) {
  return (
    <Slider
      dots={dots}
      className="center"
      centerPadding="20px"
      autoplay={true}
      touchMove={true}
      autoplaySpeed={2000}
      draggable={true}
      centerMode={centerMode}
      slidesToShow={numberCards}
      slidesToScroll={numberCards}
    >
      {children}
    </Slider>
  );
}
