import {
  SET_DOWNLOAD_ESTUDIOS,
  SET_ESTUDIOS_IMAGEN,
  SET_ESTUDIOS_LABORATORIO,
  SET_HISTORIAL_DASHBOARD,
  SET_TURNOS_DASHBOARD,
} from "../Actions/Types/estudios";

const initialState = {
  processingDownload: false,
  imagenEstudio: [],
  laboratorioEstudio: [],
  turnosDashboard: [],
  historialDashboard: [],
  processingImgEstudio: false,
  processingLabEstudio: false,
  processingTurnosDashboard: false,
  processingHistorialDashboard: false,
};

const estudios = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOWNLOAD_ESTUDIOS: {
      return {
        ...state,
        processingDownload: action.payload,
      };
    }
    case SET_ESTUDIOS_IMAGEN: {
      return {
        ...state,
        imagenEstudio: action.payload.imagenEstudio,
        processingImgEstudio: action.payload.processingImgEstudio,
      };
    }
    case SET_ESTUDIOS_LABORATORIO: {
      return {
        ...state,
        laboratorioEstudio: action.payload.laboratorioEstudio,
        processingLabEstudio: action.payload.processingLabEstudio,
      };
    }
    case SET_TURNOS_DASHBOARD: {
      return {
        ...state,
        turnosDashboard: action.payload.turnosDashboard,
        processingTurnosDashboard: action.payload.processingTurnosDashboard,
      };
    }
    case SET_HISTORIAL_DASHBOARD: {
      return {
        ...state,
        historialDashboard: action.payload.historialDashboard,
        processingHistorialDashboard:
          action.payload.processingHistorialDashboard,
      };
    }

    default: {
      return state;
    }
  }
};

export default estudios;
