const initialState = {
    token: '',
    nombre: '',
    location: {
        nacionalidad: '',
        provincia: '',
        ciudad: '',
        codPostal: '',
        direccion: ''
    },
    tel: '',
    apellido: '',
    email: '',
    genero: '',
    idEDNS: '',
    idPersona: '',
    docNum: '',
    plan: {
        name: '',
        gama: '',
        planPk: '',
        gamaPk: ''
    },
    docType: {
        id: '',
        value: '',
        name: ''
    },
    status: {
        id: '',
        desc: ''
    },
    fechaNac: '',
    cuilCuit: '',
    apimToken: ''
}

const SET_AUTHLOGIN = 'SET_AUTHLOGIN';
const RESET_AUTHLOGIN = 'RESET_AUTHLOGIN';
const RECOVERY_LOGIN = 'RECOVERY_LOGIN';
const SET_CUILCUIT = 'SET_CUILCUIT';
const SET_STATUS_PENDING = 'SET_STATUS_PENDING';

export const setCuilCuit = (value) => {
    return {
        type: SET_CUILCUIT,
        payload: {
            cuilCuit: value
        }
    }
}

export const setAuthLogin = (data) => {
    sessionStorage.setItem('token', data.token);
    sessionStorage.setItem('refreshToken', data.refreshToken);
    sessionStorage.setItem('apimToken', data.apimToken);
    return {
        type: SET_AUTHLOGIN,
        payload: {
                token: data.token,
                nombre: data.usuario.nombre,
                location: {
                    nacionalidad: data.extra.paciente.nacionalidad,
                    provincia: data.extra.paciente.provincia,
                    ciudad: data.extra.paciente.ciudad,
                    codPostal: data.extra.paciente.codigoPostal,
                    direccion: data.extra.paciente.domDireccion,
                },
                tel: data.extra.paciente.telefono1,
                apellido: data.usuario.apellido,
                email: data.extra.paciente.email,
                genero: data.usuario.genero,
                idEDNS: data.usuario.idEDNS,
                idPersona: data.usuario.idPersona,
                docNum: data.extra.paciente.nroDoc,
                docType: data.extra.paciente.tipoDoc,
                plan: {
                    name: data.extra.paciente.planDesc,
                    gama: data.extra.paciente.gamaDesc,
                    planPk: data.extra.paciente.planPk,
                    gamaPk: data.extra.paciente.gamaPk
                },
                status: {
                    id: data.extra.paciente.estadoCuenta,
                    desc: data.extra.paciente.estadoCuentaDesc
                },
                fechaNac: data.extra.paciente.fechaNac,
                apimToken: data.apimToken
              }
    }
}

export const recoveryLogin = (values) => {
    return {
        type: RECOVERY_LOGIN,
        payload: values
    }
}

export const resetAuthLogin = () => {
    return {
        type: RESET_AUTHLOGIN
    }
}

export const updateStatusToPending = () => {
    return {
        type: SET_STATUS_PENDING
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHLOGIN: 
            return {
                ...state,
                ...action.payload
            }
        case RESET_AUTHLOGIN:
            return {
                ...initialState
            }
        case RECOVERY_LOGIN:
            return {
                ...state,
                ...action.payload
            }
        case SET_CUILCUIT:
            return {
                ...state,
                ...action.payload
            }
        case SET_STATUS_PENDING:
            return {
                ...state,
                status: {
                    id: 2,
                    desc: 'Pendiente'
                },
            }
        default:
            return state;
    }
}

export default reducer