import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import LoginClientes from "./pages/LoginClientes/LoginClientes";
import Register from "./pages/Register/Register";
import Landing from "./pages/Landing/Landing";
import CentrosMedicos from './pages/CentrosMedicos/CentrosMedicos';
import HomeClientes from "./pages/Clientes/HomeClientes/HomeClientes";
import InformacionGeneral from './pages/InformacionGeneral/InformacionGeneral';
import PrivateRoute from './Utils/PrivateRoute';
import RegisterLite from './pages/LoginClientes/RegisterLite/RegisterLite';
import RecoveryEmail from './pages/RecoveryEmail/RecoveryEmail';
import UploadForm from './pages/UploadForm/UploadForm';
import Oftalmologia from './pages/Novedades/Oftalmologia';
import Emergencias from './pages/Emergencias/Emergencias';
import EconsultaReceta from './pages/EconsultaReceta';
import TurnosResultados from './pages/TurnosResultados';


const Routes = () => {
    
    return(
        <Router>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route path="/login" component={LoginClientes}/>
                <Route path="/register" component={Register} />
                <Route path="/registerlite/:idPersona" component={RegisterLite} />
                <Route exact path="/recuperar-email" component={RecoveryEmail} />
                <Route exact path="/resubir-documentos" component={UploadForm} />
                <Route exact path='/centrosmedicos/:center_id' component={CentrosMedicos} />
                <Route path='/info/*' component={InformacionGeneral} />
                <Route path='/oftalmologia' component={Oftalmologia} />
                <Route path='/emergencias' component={Emergencias} />
                <Route path='/consulta-receta' component={EconsultaReceta} />
                <Route path='/turnos-resultados' component={TurnosResultados} />
                <PrivateRoute path='/pacientes'>
                    <HomeClientes/>
                </PrivateRoute>
                <Route component={LoginClientes}/>
            </Switch>
        </Router>
    )
}
export default Routes;