import * as React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardHeader, Divider, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";

const DeskItem = ({ icon, name, path, children, textButton }) => {
  const history = useHistory();

  const goTo = () => {
    history.push(path);
  };

  return (
    <Card sx={{ width: "100%", m: 1 }}>
      <CardHeader
        action={
          // <IconButton aria-label="Icono" onClick={() => goTo()}>
          //   <KeyboardArrowRightIcon />
          // </IconButton>
          <Button
            onClick={() => goTo()}
            sx={{
              bgcolor: "red",
              color: "#FFF",
              height: "26px",
              fontSize: "11px",
              width: "225px",
              p: "3px 8px",
              borderRadius: "8px",
              fontWeight: "600",
              "&:hover": {
                bgcolor: "rgb(150, 34, 9, 0.8)",
              },
            }}
          >
            {textButton}
          </Button>
        }
        title={
          <>
            <Box display="flex" alignItems="center" pb={0}>
              {icon}
              <Typography
                pl=".4rem"
                fontWeight="bold"
                fontSize=".8rem"
                sx={{ fontFamily: "'Lato', sans-serif" }}
              >
                {name}
              </Typography>
            </Box>
          </>
        }
      />
      <Divider />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default DeskItem;
