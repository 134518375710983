import { FormControl, TextField } from "@mui/material";

const Default = ({label, variant, ...rest}) => {
    return (
        <>
          <FormControl fullWidth >
            <p style={{visibility: label === 'hidden' && 'hidden', fontSize: '15px', color:'#363636', fontWeight: 'bold'}}>{label}</p>
            <TextField 
              variant={variant === 'default' ? 'outlined' : variant}
              {...rest}
            />  
          </FormControl>
        </>
    )
}

export default Default;