import { CircularProgress } from '@mui/material'
import React from 'react'
import "./Spinner.css"
const Spinner = () => {
  return (
         <div className="spinner-container">
            <CircularProgress style={{color:"#e4002b"}}/>
        </div>
  )
}

export default Spinner