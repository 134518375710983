import {
  Alert,
  Box,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Close, HorizontalRule } from "@mui/icons-material";

const PopUp = ({ open, handleClose }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        disableEnforceFocus
        disableAutoFocus
      >
        <Box
          sx={{
            width: "90%",
            maxWidth: "450px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            p: "1rem",
            overflow: "hidden",
          }}
        >
          <img
            alt="logo sanidad"
            src="./images/LogoSanidad.svg"
            style={{ marginBottom: "-35px" }}
          />
          <IconButton
            onClick={handleClose}
            aria-label="fingerprint"
            sx={{ position: "absolute", right: "1rem" }}
          >
            <Close sx={{ color: "#797979" }} />
          </IconButton>
          <Box sx={{ textAlign: "center", px: "1rem" }}>
            <Typography
              sx={{
                color: "#797979",
                fontSize: ".9rem",
                fontFamily: "'Lato', sans-serif",
                fontWeight: "400",
                textTransform: "uppercase",
                letterSpacing: "4px",
                wordWrap: "break-word",
              }}
            >
              21 de septiembre
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                color: "#E4002B",
                fontFamily: "'Lato', sans-serif",
                marginTop: "6px",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Día de la Sanidad
            </Typography>
            <HorizontalRule
              sx={{
                textAlign: "center",
                color: "#E4002B",
                fontSize: "3rem",
                marginTop: "-10px",
              }}
            />
          </Box>
          <Box sx={{ textAlign: "center", px: { sm: "1rem" } }}>
            <Typography
              sx={{
                color: "#363636",
                fontSize: "1rem",
                fontFamily: "'Lato', sans-serif",
                fontWeight: "400",
                wordWrap: "break-word",
              }}
            >
              Les informamos que con motivo del día de la sanidad, el sábado 21
              de septiembre
              <spacer> </spacer>
              <Typography
                component="span"
                sx={{
                  color: "#363636",
                  fontSize: "1rem",
                  fontFamily: "'Lato', sans-serif",
                  fontStyle: "italic",
                  fontWeight: "600",
                  wordWrap: "break-word",
                }}
              >
                no habrá atención programada en clínicas, centros médicos y
                odontológicos.
              </Typography>
            </Typography>
            <Divider sx={{ my: "1rem" }} />
            <Typography
              sx={{
                color: "#666666",
                fontSize: ".8rem",
                fontFamily: "'Lato', sans-serif",
                fontWeight: "400",
                wordWrap: "break-word",
                textAlign: "center"
              }}
            >
              Sólo permanecerá activo el servicio de guardia de las clínicas y
              del Swiss Medical Center Barrio Norte, así como también la guardia
              odontológica de la Clínica Charcas (de 8 a 19:30hs).
            </Typography>
            <Typography
              sx={{
                color: "#666666",
                fontSize: ".8rem",
                fontFamily: "'Lato', sans-serif",
                fontWeight: "400",
                wordWrap: "break-word",
                textAlign: "center",
                mt: 1
              }}
            >
              En Swiss Medical Center DOT estará activo el servicio de
              Resonancia Magnética con turno previo. Así mismo, todas nuestras
              sucursales, los vacunatorios y el Centro Regional de Hemoterapia
              estarán cerrados.
            </Typography>
          </Box>
          <Box>
            <Alert
              severity="error"
              sx={{
                background: "#F6F6F6",
                mx: "-1rem",
                mb: "-1rem",
                mt: "1rem",
                py: "1rem",
                px: { sm: "2rem" },
              }}
            >
              <Typography
                sx={{
                  color: "#E4002B",
                  fontSize: "1rem",
                  fontFamily: "'Lato', sans-serif",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Todas nuestras sucursales, laboratorios, servicios de imágenes,
                vacunatorios y el Centro Regional de Hemoterapia estarán
                cerrados.
              </Typography>
            </Alert>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default PopUp;
