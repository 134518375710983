import { SvgIcon } from "@mui/material";

const DebitIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.2524 0C31.0151 0 31.6653 0.209524 32.1982 0.624762C32.7311 1.04 33 1.54667 33 2.14095V17.859C33 18.4533 32.7311 18.96 32.1982 19.3752C31.6653 19.7905 31.0151 20 30.2524 20H2.74756C1.98489 20 1.33467 19.7905 0.801778 19.3752C0.268889 18.96 0 18.4533 0 17.859V2.14095C0 1.54667 0.268889 1.04 0.801778 0.624762C1.33467 0.209524 1.98489 0 2.74756 0H30.2524ZM31.1716 17.859V2.14095C31.1716 1.93143 31.0836 1.76 30.9124 1.62667C30.7413 1.49333 30.5213 1.42476 30.2524 1.42476H2.74756C2.47867 1.42476 2.25867 1.49333 2.08756 1.62667C1.91644 1.76 1.82844 1.93143 1.82844 2.14095V17.859C1.82844 18.0686 1.91644 18.24 2.08756 18.3733C2.25867 18.5067 2.47867 18.5752 2.74756 18.5752H30.2524C30.5213 18.5752 30.7413 18.5067 30.9124 18.3733C31.0836 18.24 31.1716 18.0686 31.1716 17.859ZM11.0049 14.8229V15.181C11.0049 15.539 10.7751 15.7181 10.3156 15.7181H6.18933C5.72978 15.7181 5.5 15.539 5.5 15.181V14.8229C5.5 14.4648 5.72978 14.2857 6.18933 14.2857H10.3156C10.7751 14.2857 11.0049 14.4648 11.0049 14.8229ZM22.0049 14.8229V15.181C22.0049 15.539 21.7751 15.7181 21.3156 15.7181H13.5227C13.0631 15.7181 12.8333 15.539 12.8333 15.181V14.8229C12.8333 14.4648 13.0631 14.2857 13.5227 14.2857H21.3156C21.7751 14.2857 22.0049 14.4648 22.0049 14.8229Z" fill="#E4002B"/>
            </svg>
        </SvgIcon>
    )
}

export default DebitIcon;